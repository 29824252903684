import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Articulo } from 'src/app/entidades/articulo';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ParametrosFacturaVentaService } from '../parametros.service';
import { DetalleFactura } from 'src/app/entidades/detalle-factura';

@Component({
  selector: 'app-detalle-factura-venta',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
@AutoUnsubscribe()
export class DetalleFacturaVentaComponent implements OnInit {
  @Input() forma: FormGroup;
  verCostos:boolean=true;

  filtroArticulo = new FormControl();
  articulosFiltrados: Observable<Articulo[]>;
  subsIdArticulo: Subscription;

  get articulos(): Articulo[] {
    return this.servicioParametros.parametros.articulos;
  }

  get soloVista(): boolean {
    return this.servicioParametros.parametros.soloVista;
  }

  constructor(private servicioParametros: ParametrosFacturaVentaService) {}

  ngOnInit() {
    this.articulosFiltrados = this.filtroArticulo.valueChanges.pipe(
      startWith<string | Articulo>(''),
      map(articulo => {
        return typeof articulo === 'string'
          ? articulo
          : articulo == null
          ? ''
          : articulo.clave;
      }),
      map(articulo => this.filtrar(articulo))
    );

    this.subsIdArticulo = this.forma
      .get('articuloId')
      .valueChanges.subscribe(() => {
        this.verificarIdArticulo();
      });

    this.verificarIdArticulo();
    this.sustituirFuncionTocado();
    const formaCostos = this.forma.value as DetalleFactura;
    //console.dir(fromaValor);
    if (formaCostos.tieneCostos) {
      this.verCostos = false;
    }
  }

  verificarIdArticulo(): void {
    if (this.forma.get('articuloId').value) {
      const idArticulo = this.forma.get('articuloId').value;
      const articulo = this.articulos.find(o => o.id === idArticulo);
      if (articulo) {
        this.filtroArticulo.setValue(articulo);
      }
    }
  }

  articuloSeleccionado(idArticulo: number): void {
    this.forma.get('articuloId').setValue(idArticulo);
  }

  sustituirFuncionTocado(): void {
    const funcionOriginal = this.forma.get('articuloId').markAsTouched;
    this.forma.get('articuloId').markAsTouched = (opts: { onlySelf: true }) => {
      const funcionEnlazada = funcionOriginal.bind(
        this.forma.get('articuloId')
      );
      funcionEnlazada(opts);
      this.filtroArticulo.markAsTouched(opts);
    };
  }

  marcarComoTocado(): void {
    this.forma.get('articuloId').markAsTouched({ onlySelf: true });
  }

  campoMostrar(articulo: Articulo): string {
    if (articulo) {
      return `${articulo.clave}-${articulo.descripcion}`;
    } else {
      return '';
    }
  }

  private filtrar(dato: string): Articulo[] {
    if (!dato) {
      dato = '';
    }
    const valorFiltro = dato.toLowerCase();

    const direcciones = this.articulos.filter(
      articulo =>
        articulo.clave.toLowerCase().indexOf(valorFiltro) === 0 ||
        articulo.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );

    return direcciones;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HotTableRegisterer } from '@handsontable/angular';
import Handsontable from 'handsontable';
import { Contexto } from 'src/app/api/contexto.service';
import { ReportePenasquitoEstatusDiarioFriedrich } from 'src/app/entidades/reporte-penasquito-estatus-diario-driedrich';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as esMX from 'handsontable/commonjs/i18n/languages/es-MX'


Handsontable.languages.registerLanguageDictionary(esMX.default);


@Component({
  selector: 'app-estatus-diario-friedrich',
  templateUrl: './estatus-diario-friedrich.component.html',
  styleUrls: ['./estatus-diario-friedrich.component.scss']
})
export class EstatusDiarioFriedrichComponent implements OnInit {

  forma: FormGroup;

  datos = [];
  settings: Handsontable.GridSettings = {};
  cargarDatos = false;
  language = 'es-MX';
  colores = [];

  private hotRegisterer = new HotTableRegisterer();
  id = 'hotInstance';


  constructor(private formBuilder: FormBuilder, private ctx: Contexto, public spinner: LoadingService) { }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      fechaInicio: [],
      fechaFinal: []
    });

    
  }

  solicitarReporte() {

    const model = this.forma.value as ReportePenasquitoEstatusDiarioFriedrich;

    this.spinner.show('Generando información...')
    this.ctx.reporteador
      .ObtenerReportePenasquitoEstatusDiarioFriedrich(model)
      .toPromise()
      .then((respuesta) => {

        this.datos = respuesta.map( dato => Object.values(dato))
        const colorEstatus = respuesta.map( dato => dato.color)
        this.colores = [...colorEstatus]

        this.cargarDatosExcel(colorEstatus);

        this.spinner.hide();
        this.hotRegisterer.getInstance(this.id).updateSettings(this.settings);


      })
      .catch((e) => { });
  }

  cargarDatosExcel( colorEstatus ) {

    this.cargarDatos = false; 

    // Estilos de la primer fila que son los titulos
    const headers = {
      renderer(instance, td, row, col, prop, value, cellProperties) {  
      td.innerText = value;
       if(row == 0 ){
         td.style.background = '#002060';
         td.style.color = '#ffffff';
         td.style.alignContent = 'center';
         td.style.textAlign = 'center';
       }
       return td;
     },
    }

    this.settings = {
      data: [
        ['Asunto de correo', 'Fecha de solicitud', 'Origen', 'Destino', 'OC', 'Descripción / Material', 'Estatus', 'Fecha de recolección', 'Fecha de entrega', 'Comentarios', 'CV'],
        ...this.datos
        // [2009, 0, 2941, 4303, 354, 5814],
        // [2010, 5, 2905, 2867, "=SUM(A4,2,3)", "=$B1"],
        // [2011, 4, 2517, 4822, 552, 6127]
      ],
      columns: [
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        {
          // Estilo de la fila de Estatus
         renderer(instance, td, row, col, prop, value, cellProperties) {  
          td.innerText = value;
           if(row != 0 ){

              td.style.background = instance.getDataAtRow(row)[instance.getDataAtRow(row).length - 1];
            
           }else {
            td.style.background = '#002060';
            td.style.color = '#ffffff'
            td.style.alignContent = 'center';
            td.style.textAlign = 'center';
           }
           return td;
         },
        },
        headers,
        headers,
        headers,
        headers,
        {
          renderer(instance, td, row, col, prop, value, cellProperties) {  
            td.innerText = value;
             if(row != 0 ){
              td.style.display = 'none'
             }else {
              td.style.display = 'none'
             }
             return td;
           },

        }
      ],
      rowHeaders: true,
      rowHeights: 40,
      colHeaders: true,
      formulas: true,
      manualColumnMove: true,
      manualRowMove: true,
      multiColumnSorting: true,
      comments: true,
      licenseKey: 'non-commercial-and-evaluation',        
    };

    this.cargarDatos = true;

  }

  descargarExcel() {

    // Crear libro de excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Estatus diario friedrich');

    // Estilo de los titulos
    const headerStyle: Partial<ExcelJS.Style> = {
      font: { bold: true, color: { argb: 'ffffff'}},
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060'}
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle'
      },
    }

     // Agregar los datos 
     this.hotRegisterer.getInstance(this.id).getData().map((item, index) => {
       
        
       worksheet.addRow(item).eachCell((cell) => {

        // Poner los estilos a cada una de las celdas de los titulos
        if(Number(cell.row) == 1){
          cell.style = headerStyle;
        }

        // Agregar los datos y darle el estilo del color a las celdas de Estatus
        if(Number(cell.col) == 7 && Number(cell.row) != 1 ){
        
          cell.style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: item[item.length - 1].substring(1)}
            }
          };
        }
       })
     });

     // Ajustar el nacho de las celdas a que se ajusten al texto
    worksheet.columns.map((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 0;
          maxLength = Math.max(maxLength, columnLength);
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

  worksheet.spliceColumns(worksheet.columns.length, 1);

     // Guardar el libro creado como un blob y poder descargarlo 
     workbook.xlsx.writeBuffer().then((buffer) => {
       const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       saveAs(blob, `Reporte_penasquito_estatus_diario_friedrich.xlsx`);
     });

  }

}

import { Injectable } from '@angular/core';
import { ParametrosFacturaVenta } from './parametros';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Articulo } from 'src/app/entidades/articulo';
import { ProyectoExterno } from 'src/app/entidades/proyecto-externo';
import { Venta } from 'src/app/entidades/venta';
import { Factura } from 'src/app/entidades/factura';

@Injectable({
  providedIn: 'root'
})
export class ParametrosFacturaVentaService {
  private _parametros = {} as ParametrosFacturaVenta;
  public get parametros(): ParametrosFacturaVenta {
    return this._parametros;
  }

  constructor(private ctx: Contexto) {}

  cargarParametros(
    edicion: boolean,
    idVenta?: number,
    idFactura?: number
  ): Observable<any> {
    this._parametros.soloVista = edicion;
    return of(true).pipe(
      flatMap(() => this.cargarFactura(idFactura)),
      flatMap(() => this.cargarVenta(idVenta)),
      flatMap(() => this.cargarArticulos()),
      flatMap(() => this.cargarProyectosExternos())
    );
  }

  cargarVenta(idVenta?: number): Observable<Venta> {
    if (idVenta) {
      return this.ctx.ventas
        .obtenerVentaCompleta(idVenta)
        .pipe(map(venta => (this._parametros.venta = venta)));
    } else {
      return of(undefined);
    }
  }

  cargarFactura(idFactura?: number): Observable<Factura> {
    if (idFactura) {
      return this.ctx.facturasVenta
        .obtener(idFactura)
        .pipe(map(factura => (this._parametros.factura = factura)));
    } else {
      return of(undefined);
    }
  }

  cargarProyectosExternos(): Observable<ProyectoExterno[]> {
    return this.ctx.proyectosExternos
      .obtenerTodos()
      .pipe(map(proyectos => (this._parametros.proyectosExternos = proyectos)));
  }

  cargarArticulos(): Observable<Articulo[]> {
    return this.ctx.articulos
      .obtenerTodos()
      .pipe(map(articulos => (this._parametros.articulos = articulos)));
  }
}

import { TipoCarga } from 'src/app/entidades/tipo-carga';
import { UnidadesPeso } from 'src/app/entidades/unidades-peso';
import { UnidadesLongitud } from 'src/app/entidades/unidades-longitud';
import { MedidaLegal } from 'src/app/entidades/medida-legal';
import { Frontera } from 'src/app/entidades/frontera';
import { Aduana } from 'src/app/entidades/aduana';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Proyecto } from 'src/app/entidades/proyecto';

export interface DatosSolicitudCompra {
  idCompra: number;
  idProyecto: number;
  idCliente: number;
}

export interface DatosSolicitudVenta {
  idVenta: number;
  proyecto: Proyecto;
}

export interface ProveedorSolicitud {
  proveedorId: number;
  monedaId: number;
}

export class CatalogoServicio {
  tiposCarga: TipoCarga[] = [];
  unidadesPeso: UnidadesPeso[] = [];
  unidadesLongitud: UnidadesLongitud[] = [];
  medidasLegales: MedidaLegal[] = [];
  fronteras: Frontera[] = [];
  aduanas: Aduana[] = [];
  origenes: Direccion[] = [];
  destinos: Direccion[] = [];
}

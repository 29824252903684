
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OnInit, Inject, Component } from '@angular/core';


@Component({
    templateUrl: './form-mensaje.component.html',
    styleUrls: ['./form-mensaje.component.scss']
})
export class FormaMensajeGlobalComponent implements OnInit {

    titulo: string;
    mensaje: string;
    constructor(

        @Inject(MAT_DIALOG_DATA) public datos: { titulo: string, mensaje: string },
        private modal: MatDialogRef<FormaMensajeGlobalComponent>
    ) { }

    ngOnInit() {

        this.titulo = this.datos.titulo;
        this.mensaje = this.datos.mensaje;
    }

    ok(): void {
        this.modal.close("ok");
    }

    cerrar(): void {
        this.modal.close();
    }
}

import { DetallePermiso } from './detalle-permiso';
import { RolEspecifico } from './rol-especifico';
import { Socio } from './socio';

export class Rol {
  id: number;
  nombre: string;
  tipoUsuarioId: number;
  socioId: number;
  esEspecifico: boolean;
  detallesPermiso: DetallePermiso[];
  rolesEspecifico: RolEspecifico[];
  socios: Socio[];
}

import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormControl,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { LocalidesSocio } from 'src/app/entidades/localidades-socio';
import { Socio } from 'src/app/entidades/socio';
import { startWith, map } from 'rxjs/operators';
import { Pais } from 'src/app/entidades/pais';
import { Estado } from 'src/app/entidades/estado';

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaLocalidadesSocioComponent implements OnInit {
    forma: FormGroup;
    model: LocalidesSocio;
    idSeleccionado: number = 0;
    cargando: boolean = false;
    subsGuardar: Subscription;
    guardado: EventEmitter<void> = new EventEmitter<void>();
    get f() {
        return this.forma.controls;
    }

    proveedores: Socio[] = [];
    proveedoresFiltrados: Observable<Socio[]>;
    proveedoresSinFiltrar: Socio[];
    filtroProveedor = new FormControl();

    paises: Pais[] = [];
    paisesFiltrados: Observable<Pais[]>;
    paisesSinFiltrar: Pais[];
    filtroPais = new FormControl();

    estados: Estado[] = [];
    estadosFiltrados: Observable<Estado[]>;
    estadosSinFiltrar: Estado[];
    filtroEstados = new FormControl();

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: number,
        private ventana: MatDialogRef<FormaLocalidadesSocioComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta
    ) {
        if (id > 0) {
            this.idSeleccionado = id;
            this.ctx.localidadesSocio
                .obtener(id)
                .toPromise()
                .then((resultado) => {
                    this.model = resultado;
                    this.cargarEstados(this.model.paisId);
                    Object.assign(this.forma.value, this.model);
                    this.forma.reset(this.forma.value);
                })
                .catch((e) => {
                    console.log(e);
                    this.alerta.mostrarError('Error al obtener información');
                });
        } else {
            this.idSeleccionado = 0;
            this.model = new LocalidesSocio();
        }
    }

    ngOnInit() {
        this.forma = this.formBuilder.group({
            id: [0, Validators.nullValidator],
            socioId: [0, Validators.required],
            socioDescripcion: ['', Validators.nullValidator],
            paisDescripcion: ['', Validators.nullValidator],
            paisId: [0, Validators.required],
            estadoDescripcion: ['', Validators.nullValidator],
            estadoId: [0, Validators.required],
            esOrigen: [false],
            esDestino: [false],
        });
        this.cargarProveedores();
        this.cargarPaises();
    }

    cargarProveedores() {
        this.ctx.socios
            .obtenerProveedores()
            .toPromise()
            .then((resultado) => {
                this.proveedoresSinFiltrar = resultado;
                this.proveedoresFiltrados = this.filtroProveedor.valueChanges.pipe(
                    startWith<string | Socio>(''),
                    map((t) =>
                        typeof t === 'string'
                            ? t
                            : t == null
                            ? ''
                            : t.nombreCorto
                    ),
                    map((t) => this.filtrarProveedor(t))
                );
            })
            .catch((e) => {});
    }

    cargarPaises() {
        this.ctx.pais
            .obtenerTodos()
            .toPromise()
            .then((resultado) => {
                this.paisesSinFiltrar = resultado;
                this.paisesFiltrados = this.filtroPais.valueChanges.pipe(
                    startWith<string | Pais>(''),
                    map((t) =>
                        typeof t === 'string'
                            ? t
                            : t == null
                            ? ''
                            : t.descripcion
                    ),
                    map((t) => this.filtrarPais(t))
                );
            })
            .catch((e) => {});
    }

    cargarEstados(paisId: number) {
        this.ctx.estado
            .obtenerEstadosPais(paisId)
            .toPromise()
            .then((resultado) => {
                this.estadosSinFiltrar = resultado;
                this.estadosFiltrados = this.filtroEstados.valueChanges.pipe(
                    startWith<string | Estado>(''),
                    map((t) =>
                        typeof t === 'string'
                            ? t
                            : t == null
                            ? ''
                            : t.descripcion
                    ),
                    map((t) => this.filtrarEstado(t))
                );
            })
            .catch((e) => {});
    }

    private filtrarProveedor(nombre: string): Socio[] {
        const valorFiltro = nombre.toLowerCase();
        let filtro = this.proveedoresSinFiltrar.filter(
            (t) => t.nombreCorto.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    private filtrarPais(nombre: string): Pais[] {
        const valorFiltro = nombre.toLowerCase();
        let filtro = this.paisesSinFiltrar.filter(
            (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    private filtrarEstado(nombre: string): Estado[] {
        const valorFiltro = nombre.toLowerCase();
        let filtro = this.estadosSinFiltrar.filter(
            (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    proveedorSeleccionado(proveedor: Socio) {
        this.f['socioDescripcion'].setValue(proveedor.nombreCorto);
        this.f['socioId'].setValue(proveedor.id);
    }

    paisSeleccionado(pais: Pais) {
        this.f['paisDescripcion'].setValue(pais.descripcion);
        this.f['paisId'].setValue(pais.id);
        this.cargarEstados(pais.id);
    }

    estadoSeleccionado(estado: Estado) {
        this.f['estadoDescripcion'].setValue(estado.descripcion);
        this.f['estadoId'].setValue(estado.id);
    }

    limpiar(): void {
        this.forma.reset();
    }

    guardar(): void {
        if (this.forma.valid) {
            const model = this.forma.value as LocalidesSocio;

            if (this.idSeleccionado === 0) {
                this.ctx.localidadesSocio
                    .guardar(model)
                    .toPromise()
                    .then(() => {
                        this.alerta.mostrarExito(
                            'Localidad guardado con éxito'
                        );
                        this.guardado.emit();
                        this.cerrar();
                    })
                    .catch((e) => {
                        console.log(e);
                        if (e.error === 'Existe') {
                            this.alerta.mostrarAdvertencia(
                                '¡Ya existe la localidad! '
                            );
                        } else if (e.error === 'ErrorGuardar') {
                            this.alerta.mostrarAdvertencia(
                                '¡Error al guardar la localidad! '
                            );
                        } else {
                            this.alerta.mostrarError(
                                '¡Error interno al guardar! '
                            );
                        }
                    });
            } else {
                this.ctx.localidadesSocio
                    .actualizar(this.idSeleccionado.toString(), model)
                    .toPromise()
                    .then(() => {
                        this.alerta.mostrarExito(
                            'Localidad actualizado con éxito'
                        );
                        this.guardado.emit();
                        this.cerrar();
                    })
                    .catch((e) => {
                        console.log(e);
                        if (e.error === 'Existe') {
                            this.alerta.mostrarAdvertencia(
                                '¡Ya existe una localidad! '
                            );
                        } else {
                            this.alerta.mostrarError(
                                '¡Error interno al guardar! '
                            );
                        }
                    });
            }
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    cerrar(): void {
        this.ventana.close();
    }
}

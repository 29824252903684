import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DatosDialogo {
  titulo: string;
  botonVerdad: string;
  botonFalso: string;
}

@Component({
  selector: 'app-confirmacion-cerrar',
  templateUrl: './ventana-confirmacion.component.html',
  styleUrls: ['./ventana-confirmacion.component.scss']
})
export class VentanaConfirmacionComponent implements OnInit {
  constructor(
    private ventana: MatDialogRef<VentanaConfirmacionComponent>,
    @Inject(MAT_DIALOG_DATA) public datos: DatosDialogo
  ) {}

  ngOnInit() {}

  confirmar() {
    this.ventana.close(true);
  }

  cancelar() {
    this.ventana.close(false);
  }
}

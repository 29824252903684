import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { Evidencia } from 'src/app/entidades/evidencia';

@Component({
  selector: 'app-visor-evidencias',
  templateUrl: './visor-evidencias.component.html',
  styleUrls: ['./visor-evidencias.component.scss']
})
export class VisorEvidenciasComponent implements OnInit, OnDestroy {
  rutaArchivo: string = '';
  subsDocumento: Subscription;
  subsSubirDocumentos: Subscription;
  subsNuevoDocumento: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public evidencia: Evidencia, private ctx: Contexto, public ventanaDocumento: MatDialog) { }

  ngOnInit() {
    this.cargarArchivo();
  }

  async cargarArchivo(): Promise<void> {
    // this.subsDocumento = this.ctx
    // .rastreo
    // .descargarEvidencia(this.evidencia.id)
    // .subscribe(
    //   archivo => {
    //       let tipo = 'octet/stream';
    //       let extension = this.evidencia.extension;

    //         tipo = 'image/' + extension;

    //       let blob = new Blob([archivo], { type: tipo });
    //       this.rutaArchivo = window.URL.createObjectURL(blob);
    //   }
    // );

    let a = await this.ctx.rastreo.descargarEvidencia(this.evidencia.id).toPromise();
    if (a.size > 0) {
      this.rutaArchivo = window.URL.createObjectURL(a);

    } else {

    }
  }

  async descargarDocumento(): Promise<void> {
    let archivo = await this.ctx.rastreo.descargarEvidencia(this.evidencia.id).toPromise();
    let nombreArchivo = `${this.evidencia.nombreArchivo}${this.evidencia.extension}`;

    let a = document.createElement("a");
    let blob = new Blob([archivo], { type: "octet/stream" });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = nombreArchivo;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      a.remove();
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.subsDocumento) { this.subsDocumento.unsubscribe(); }
  }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormaPermisoComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { Contexto } from '../../../api/contexto.service';
import { ServicioAlerta } from '../../../utilerias/alerta.service';
import { Rol } from '../../../entidades/rol';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'catalogo-permiso',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoPermisoComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  formaTabla: FormGroup;
  fuenteDatos: MatTableDataSource<Rol> = new MatTableDataSource([]);
  columnasMostradas = ['nombre', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }
 
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  subsEliminar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formaTabla = this.formBuilder.group(
      {
        buscar: ['']
      }
    );

    this.cargarRoles();
  }


  cargarRoles(): void {
    this.cargando = true;
    this.ctx.roles.obtenerTodos()
      .subscribe(roles => {
        this.fuenteDatos = new MatTableDataSource(roles);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }

  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaPermisoComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarRoles();
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  limpiar(): void {
    this.formaTabla.reset();
    this.cargarRoles()
  }

  eliminar(id: number): void {
    this.subsEliminar = this.ctx.roles.eliminar(id.toString()).subscribe(() => {
      this.alerta.mostrarExito('Rol eliminado');
      this.cargarRoles();
    },
      error => {
        this.alerta.mostrarError('Error al eliminar el rol');
      }
    );
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subsActivar) { this.subsActivar.unsubscribe(); }
    if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
    if (this.subsEliminar) { this.subsEliminar.unsubscribe(); }
  }
}
import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { LocalidesSocio } from '../entidades/localidades-socio';
import { AsignacionLocalidadesSocio } from '../entidades/asignacion-localidades-socio';

export class RepositorioLocalidadesSocio extends RepositorioCatalogo<LocalidesSocio> {

    constructor(http: HttpClient) {
        super(http, "LocalidadesSocio");
    }

    activar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    obtenerTodosActivos(): Observable<LocalidesSocio[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<LocalidesSocio[]>(ruta);

    }


    obtenerTodosOrigenes(): Observable<LocalidesSocio[]> {
        const ruta = `${this.ruta}/ObtenerTodosOrigenes`;
        return this.clienteHttp.get<LocalidesSocio[]>(ruta);

    }

    obtenerTodosDestinos(): Observable<LocalidesSocio[]> {
        const ruta = `${this.ruta}/ObtenerTodosDestinos`;
        return this.clienteHttp.get<LocalidesSocio[]>(ruta);

    }


    asignar(asignacion: AsignacionLocalidadesSocio): Observable<AsignacionLocalidadesSocio> {
        const ruta = `${this.ruta}/Asignar`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionLocalidadesSocio) => asig)
        );
    }

    desasignar(asignacion: AsignacionLocalidadesSocio): Observable<void> {
        const ruta = `${this.ruta}/Desasignar/${asignacion.localidadesSocioId}/${asignacion.clienteId}`;
        return this.clienteHttp.delete(ruta).pipe(map(() => null));
    }

    obtenerLocalidadesSocioCliente(IdCliente: number): Observable<LocalidesSocio[]> {
        const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
        return this.clienteHttp.get<LocalidesSocio[]>(ruta);
    }
}



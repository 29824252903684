import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaTipoEstatusClienteComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TipoEstatusCliente } from 'src/app/entidades/tipo-estatus-cliente';

@Component({
  selector: 'catalogo-aduana',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoTipoEstatusClienteComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<TipoEstatusCliente> = new MatTableDataSource([]);
  columnasMostradas = ['id', 'descripcion', 'color', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  subsTiposEstatusClientes: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarTiposEstatusClientes();
  }

  cargarTiposEstatusClientes(): void {
    this.cargando = true;
    this.subsTiposEstatusClientes = this.ctx.tiposEstatusClientes
      .obtenerTodos()
      .subscribe(tiposEstatusClientes => {
        this.fuenteDatos = new MatTableDataSource(tiposEstatusClientes);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.fuenteDatos.sort.sort({ start: 'desc', disableClear: false, id: 'id' });
        this.cargando = false;
      });
      
  }

  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaTipoEstatusClienteComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarTiposEstatusClientes();
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
  }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Autorizaciones } from '../entidades/autorizaciones';

export class RepositorioAutorizaciones extends RepositorioCatalogo<Autorizaciones> {
  constructor(http: HttpClient) {
    super(http, 'Autorizaciones');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }
}


import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'app-buscador-cv',
    templateUrl: './buscador-cv.component.html',
    styleUrls: ['./buscador-cv.component.scss'],
})
export class BuscadorCvComponent implements OnInit {
    fromVentas: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<BuscadorCvComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {noCV: number, fromVentas: boolean},
    ) {
    }

    ngOnInit(): void {
        this.fromVentas = this.data.fromVentas;
    }

    close(close: boolean) {
        if (close) {
            console.log(close);

            this.dialogRef.close(close);
        }
    }

}
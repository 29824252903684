import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Compra } from '../entidades/compra';
import { Venta } from '../entidades/venta';
import { DocumentoDuplicar } from '../entidades/documento-duplicar';
import { Documento } from '../entidades/documento';
import { Motivo } from '../entidades/motivo-no-envio';
import { CompraExportacion } from '../entidades/compra-exportacion';
import { Respuesta } from '../entidades/respuesta';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';


export class RepositorioDocumentoCompra extends RepositorioCatalogo<Documento> {
    constructor(
        http: HttpClient
    ) {
        super(http, 'DocumentosCompra');
    }

    obtenerSinProcesar(proyectoId: number): Observable<Documento[]> {
        const ruta = `${this.ruta}/SinProcesar/${proyectoId}`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    obtenerTodosSinProcesar(): Observable<Documento[]> {
        const ruta = `${this.ruta}/SinProcesar`;
        return this.clienteHttp.get<Documento[]>(ruta);
    }

    reenviarEnviarCostos(idCostos: number): Observable<boolean> {
        const ruta = `${this.ruta}/ReenviarEnviarCostos/${idCostos}`;
        return this.clienteHttp.get(ruta).pipe(
            map((enviada: boolean) => {
                return enviada;
            })
        );
    }

    autorizar(
        idDocumento: number,
        prepararEnvioSap: boolean
    ): Observable<boolean> {
        const ruta = `${this.ruta}/AutorizarCostos/${idDocumento}/${prepararEnvioSap}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    prepararParaEnvioSap(idDocumento: number): Observable<boolean> {
        const ruta = `${this.ruta}/PrepararEnvioSap/${idDocumento}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    enviarSolicitudCompra(
        idDocumento: number
    ): Observable<{ mensaje: string }> {
        const ruta = `${this.ruta}/EnviarSolicitudCompra/${idDocumento}`;
        return this.clienteHttp.get<{ mensaje: string }>(ruta);
    }

    descargarArchivoServicio(idArhivo: number): Observable<Blob> {
        const ruta = `${this.ruta}/ArchivosServicios/Descargar/${idArhivo}`;
        return this.clienteHttp.get(ruta, {
            responseType: 'blob',
        }).pipe(map((pdf: Blob) => pdf));
    }

    descargarArchivoProveedor(idArhivo: number): Observable<Blob> {
        const ruta = `${this.ruta}/ArchivosProveedor/Descargar/${idArhivo}`;
        return this.clienteHttp.get(ruta, {
            responseType: 'blob',
        }).pipe(map((pdf: Blob) => pdf));
    }

    obtenerCotizaciones(idProveedor: number): Observable<Documento[]> {
        const ruta = `${this.ruta}/Cotizaciones/PorProveedor/${idProveedor}`;
        return this.clienteHttp.get(ruta).pipe(
            map((cotizaciones: Documento[]) => {
                return cotizaciones;
            })
        );
    }

    obtenerCotizacionesDatosEquipo(
        idProveedor: number
    ): Observable<Documento[]> {
        const ruta = `${this.ruta}/DatosEquipo/PorProveedor/${idProveedor}`;
        return this.clienteHttp.get(ruta).pipe(
            map((cotizaciones: Documento[]) => {
                return cotizaciones;
            })
        );
    }

    generarCotizacion(idCostos: number): Observable<Compra[]> {
        const ruta = `${this.ruta}/Cotizaciones/${idCostos}`;
        return this.clienteHttp.post(ruta, {}).pipe(
            map((compras: Compra[]) => {
                return compras;
            })
        );
    }

    guardarCotizacion(cotizacion: Documento): Observable<Compra[]> {
        const ruta = `${this.ruta}/Costos`;
        return this.clienteHttp.put(ruta, cotizacion).pipe(
            map((compras: Compra[]) => {
                return compras;
            })
        );
    }

    actualizarCostos(costos: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/Costos`;        
        return this.clienteHttp.put<Documento>(ruta, costos);
    }

    actualizarCostosCT(documento: Documento): Promise<any> {
        const ruta = `${this.ruta}/CostosCT`;                 
        var accionOrdenCompraSap = localStorage.getItem('accionOrdenCompraSap');        
        let solicitudActualizacion = {
            Documento: documento,
            AccionOrdenCompraSap: accionOrdenCompraSap ? Number(accionOrdenCompraSap) : 0
        };                         
        return new Promise((resolve, reject) => {            
            this.clienteHttp.put(ruta,solicitudActualizacion).toPromise()
            .then( (success: any) => {                 
              resolve(success);
            })
            .catch((error) => {            
              reject(error);
            });
        });
    }


    crearVenta(idCompra: number): Observable<Venta> {
        const ruta = `${this.ruta}/CrearVenta/${idCompra}`;
        return this.clienteHttp.post(ruta, null).pipe(
            map((venta: Venta) => {
                return venta;
            })
        );
    }

    enviarDatosEquipo(idCotizacion: number): Observable<boolean> {
        const ruta = `${this.ruta}/EnviarDatosEquipo/${idCotizacion}`;
        return this.clienteHttp.get(ruta).pipe(
            map((enviada: boolean) => {
                return enviada;
            })
        );
    }

    ObtenerComprasPorEnviar(): Observable<Compra[]> {
        const ruta = `${this.ruta}/ComprasPorEnviar/`;
        return this.clienteHttp.get(ruta).pipe(
            map((compras: Compra[]) => {
                return compras;
            })
        );
    }

    generarFolio(): Observable<string> {
        const ruta = `${this.ruta}/GenerarFolio`;
        return this.clienteHttp.get(ruta).pipe(
            map((folio: string) => {
                return folio;
            })
        );
    }

    cancelar(idCompra: number, idMotivo: number): Observable<void> {
        const ruta = `${this.ruta}/CancelarDocumento/${idCompra}/${idMotivo}`;
        return this.clienteHttp.get<void>(ruta);
    }

    ActualizoCTNCR(documento: Documento): Observable<Documento> {
        const ruta = `${this.ruta}/ActualizoCTNCR`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    cancelarRs(documento: Documento, idMotivo: number): Observable<Documento> {
        const ruta = `${this.ruta}/CancelarDocumentoRs/${idMotivo}`;
        return this.clienteHttp.post<Documento>(ruta, documento);
    }

    buscoFacturaEnSap(idVenta: number): Observable<void> {
        const ruta = `${this.ruta}/BuscoFacturaEnSap/${idVenta}`;
        return this.clienteHttp.get<void>(ruta);
    }

    buscoOrdenCompraNotaCRSap(idCompra: number): Observable<void> {
        const ruta = `${this.ruta}/buscoOrdenCompraNotaCRSap/${idCompra}`;
        return this.clienteHttp.get<void>(ruta);
    }

    duplicar(documento: DocumentoDuplicar): Observable<Compra> {
        const ruta = `${this.ruta}/Duplicar`;
        return this.clienteHttp.post<Compra>(ruta, documento);
    }

    actualizarMotivoNoEnvio(motivo: Motivo): Observable<object> {
        const ruta = `${this.ruta}/ActualizarMotivoNoEnvio`;
        return this.clienteHttp.put<object>(ruta, motivo);
    }

    actualizarMotivoNoFacturar(motivo: Motivo): Observable<object> {
        const ruta = `${this.ruta}/ActualizarMotivoNoFacturar`;
        return this.clienteHttp.put<object>(ruta, motivo);
    }


    obtenerDocumentosCompraMigracion(): Observable<CompraExportacion[]> {
        let ruta = `${this.ruta}/ObtenerDocumentosCompraMigracion`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: CompraExportacion[]) => {
                return data;
            }));
    }

    obtenerDocumentosCompraMigracionSingle(id: number): Observable<CompraExportacion[]> {
        let ruta = `${this.ruta}/ObtenerDocumentosCompraMigracionSingle/${id}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: CompraExportacion[]) => {
                return data;
            }));
    }

    actualizarProveedorEnCT(documento: Documento): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/ActualizarProveedorEnCT`;

        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }

    actualizarProveedorCT(documento: Documento, accionOrdenCompraSap: Number,accionOrdenVentaSap : Number): Promise<any> {    
        const ruta = `${this.ruta}/ActualizarProveedorEnCT`;
        let solicitudActualizacion = {
            Documento: documento,
            AccionOrdenCompraSap: accionOrdenCompraSap,
            AccionOrdenVentaSap:accionOrdenVentaSap
        };        

        return new Promise((resolve, reject) => {            
          this.clienteHttp.post(ruta,solicitudActualizacion).toPromise()
          .then( (success: any) => {                 
            resolve(success);
          })
          .catch((error) => {            
            resolve([]);
          });
        });
    } 

    generarFacturaVentaParaReenviarSAP(documento: Documento): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/GenerarFacturaVentaParaReenviarSAP`;
        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }


    actualizarFacturaCompraParaReenviarSAP(documento: Documento): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/ActualizarFacturaCompraParaReenviarSAP`;
        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }

    ordenCompraEnviadaSAP(documento: Documento): Observable<Respuesta<Documento>> {
        const ruta = `${this.ruta}/OrdenCompraEnviadaSAP`;
        return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }

    /*
    createEquipo(equipo: Equipo): Promise<any> {
    const url = environment.apiUrl + '/equipo';
    return new Promise((resolve, reject) => {
      this.httpclient.post(url, equipo).toPromise()
      .then( (succcess: any) => {
        resolve(succcess);
      })
      .catch((error) => {
        let message: string;
        message = `La clave ${equipo.clave} o descripción ${equipo.descripcion} ya fué asignados a otro Equipo`;
        reject(message);
      });
    });
  }
    */
    preActualizacionProveedorCT(documentoId: number): Promise<any> {
        const ruta = `${this.ruta}/PreActualizacionProveedorCT/${documentoId}`;
        return new Promise((resolve, reject) => {
            
            this.clienteHttp.get(ruta).toPromise()
            .then( (success: any) => {                
              resolve(success);
            })
            .catch((error) => {                   
                console.log('error-PAPCT->', error);
              reject(error);
            });
        });
    }


    preActualizacionCostosCT(documentoId: number): Promise<any> {
        const ruta = `${this.ruta}/PreActualizacionCostosCT/${documentoId}`;
        return new Promise((resolve, reject) => {            
            this.clienteHttp.get(ruta).toPromise()
            .then( (success: any) => {                
              resolve(success);
            })
            .catch((error) => {                   
                console.log('error-PACCT->', error);
              reject(error);
            });
        });
    }

    tieneSeguimiento(documentoId: number): Observable<boolean> {
        const ruta = `${this.ruta}/TieneSeguimientoOnMonitor/${documentoId}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    estaFacturadaEnSAP(documentoId: number): Observable<boolean> {
        const ruta = `${this.ruta}/EstaFacturadaEnSAP/${documentoId}`;
        return this.clienteHttp.get<boolean>(ruta);
    }

    enviadaSAP(documentoId: number): Observable<boolean> {        
        const ruta = `${this.ruta}/CTEnviadaSAP/${documentoId}`;        
        return this.clienteHttp.get<boolean>(ruta);
    }


}

import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TipoCambio } from 'src/app/entidades/tipo-cambio';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Moneda } from 'src/app/entidades/moneda';
import { map } from 'rxjs/operators';
import { TipoOpcion } from 'src/app/entidades/tipoOpcion';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-forma',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaTipoOpcionComponent implements OnInit, OnDestroy {
  isChecked = true;
  forma: FormGroup;
  tipoOpcion: TipoOpcion;
  monedas: Observable<Moneda[]>;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  habilitar = false;
  get f(){
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaTipoOpcionComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) { 
    if(id > 0){
      this.idSeleccionado = id;

      this.ctx.tipoOpcion.obtener(id).subscribe(a => {
        this.tipoOpcion = a;
        Object.assign(this.forma.value, this.tipoOpcion);
        this.forma.reset(this.forma.value);
        this.habilitar = true;
      });
    }else{
      this.idSeleccionado = 0;
      this.tipoOpcion = new TipoOpcion();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.required],
      descripcion: ['', Validators.required],
      activo: false
    })
  }

  limpiar(): void{
    this.forma.reset({ id: 0 });
  }

  async guardar(): Promise<void>{

    console.log(this.forma.value);

    if(this.forma.valid){
      this.cargando = true;
      Object.assign(this.tipoOpcion, this.forma.value);
      let observable: Observable<TipoOpcion>;

      let mensaje = 'Tipo de opcion guardado.';
      let guardar = true;

      if(this.idSeleccionado === 0){
        observable = this.ctx.tipoOpcion.guardar(this.tipoOpcion);
      }else{
        guardar = false;
        mensaje = 'Tipo de opcion actualizado';
        observable = this.ctx.tipoOpcion.actualizar(
          this.idSeleccionado.toString(),
          this.tipoOpcion
        );
      }

      this.subsGuardar = observable.subscribe(
        ()=>{
          this.guardado.emit();
          this.alerta.mostrarExito(mensaje);
          this.cargando = false;
          this.limpiar();
          if(!guardar){
            this.ventana.close();
          }
        },
        () => {
          this.alerta.mostrarError('Error al guardar!');
          this.cargando = false;
        }
      );
    }else{
      this.alerta.mostrarAdvertencia('Datos invalidos.');
    }
  }

  cerrar(): void{
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Alerta } from 'src/app/entidades/alertas';
import { FormaListasComponent } from '../forma-lista/forma.component';
import { DescendienteAlerta } from '../catalogo/catalogo.component';

export interface DocumentoAlerta {
  value: string;
  viewValue: string;
}

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAlertasComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  alertas: Alerta;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  subsListas: Subscription;
  cerrado: EventEmitter<boolean> = new EventEmitter<boolean>();
  guardoAlgo = false;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }

  documentosAlerta: DocumentoAlerta[] = [
    { value: '1', viewValue: 'Cotización Compra' },
    { value: '2', viewValue: 'Cotización Venta' },
    { value: '3', viewValue: 'Solicitud de Costos' },
    { value: '4', viewValue: 'Solicitud de Equipos' },
    { value: '5', viewValue: 'Solicitud de Compra' }
  ];
  tipo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaAlertasComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private ventanaLista: MatDialog
  ) {
    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx.alertas.obtener(id).subscribe(a => {
        this.alertas = a;
        Object.assign(this.forma.value, this.alertas);
        this.forma.reset(this.forma.value);
        this.cargarTipos(this.alertas.tipo.toString());
      });
    } else {
      this.idSeleccionado = 0;
      this.alertas = new Alerta();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      clave: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20)
        ])
      ],
      descripcion: ['', Validators.required],
      tipo: ['', Validators.required],
      documento: ['', Validators.nullValidator]
    });
  }

  limpiar(): void {
    this.forma.reset();
  }

  capturar(event) {
    const opcion = event.source.selected._element.nativeElement;
    this.f['documento'].setValue(opcion.innerText.trim());
  }

  cargarTipos(tipo: string): void {
    this.f['tipo'].setValue(tipo);
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;

      Object.assign(this.alertas, this.forma.value);
      let observable: Observable<Alerta>;
      let mensaje = 'Alerta guardado.';
      let guardar = true;
      if (this.idSeleccionado === 0) {
        observable = this.ctx.alertas.guardar(this.alertas);
      } else {
        guardar = false;
        mensaje = 'Alerta actualizada';
        observable = this.ctx.alertas.actualizar(
          this.idSeleccionado.toString(),
          this.alertas
        );
      }

      this.subsGuardar = observable.subscribe(
        alerta => {
          this.guardado.emit();
          this.alerta.mostrarExito(mensaje);
          this.cargando = false;
          this.guardoAlgo = true;
          // this.limpiar();
          if (!guardar) {
            // this.ventana.close();
            this.cerrar();
          } else {
            const datosDetalle: DescendienteAlerta = {
              id: 0,
              alertaId: alerta.id,
              alertaDescripcion: alerta.descripcion
            };

            const ventanaLista = this.ventanaLista.open(FormaListasComponent, {
              data: datosDetalle,
              panelClass: 'form-container'
            });

            this.subsListas = ventanaLista.componentInstance.cerrado.subscribe(
              () => {
                this.limpiar();
              }
            );
          }
        },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        }
      );
    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.cerrado.emit(this.guardoAlgo);
    this.ventana.close();
    // this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

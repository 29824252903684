import { Component, OnInit } from '@angular/core';

@Component({
  template: ''
})
export class EnBlancoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import * as _ from 'underscore';
import { VentasPorEnviar } from 'src/app/entidades/VentasPorEnviar';
import { VentasSAPDataComponent } from '../Ventas-SAP-data/Ventas-SAP-data.component';

@Component({
  selector: 'app-ventas-sap-total-parcial',
  templateUrl: './ventas-sap-total-parcial.component.html',
  styleUrls: ['./ventas-sap-total-parcial.component.scss']
})
export class VentasSAPTotalParcialComponent implements OnInit {
  valueMXNParcial: number;
  valueUSDParcial: number;
  valueMXNTotal: number;
  valueUSDTotal: number;
  valueMXNSinSeguimiento: number;
  valueUSDSinSeguimiento: number;
  groupedData: any;
  dataValues: any;

  constructor(@Inject(MAT_DIALOG_DATA) public object: { Ventas: VentasPorEnviar[] ,titulo:string},
  private ventana: MatDialog) { }

  ngOnInit() {
    this.dataValues = this.object.Ventas;
    console.log(this.dataValues);
    this.groupDataValues();
  }

  groupDataValues(): void {
    this.groupedData = _.chain(this.dataValues)
    .filter(c => c.tipoEstatusCliente !== '')
    .groupBy(c => c.tipoEstatusCliente).value()

    const totalUSD = _.chain(this.dataValues).filter(c => c.monedaVenta == 'USD' && c.tipoEstatusCliente == 'Entrega Total').value();
    this.valueUSDTotal = totalUSD.reduce((sum, value) => {
      return sum + value.importeMonedaDocumento
    }, 0)

    const totalMXN = _.chain(this.dataValues).filter(c => c.monedaVenta == 'MXP' && c.tipoEstatusCliente == 'Entrega Total').value();
    this.valueMXNTotal = totalMXN.reduce((sum, value) => {
      return sum + value.importeMonedaDocumento
    }, 0)

    const parcialUSD = _.chain(this.dataValues).filter(c => c.monedaVenta == 'USD' && c.tipoEstatusCliente == 'Entrega Parcial').value();
    this.valueUSDParcial = parcialUSD.reduce((sum, value) => {
      return sum + value.importeMonedaDocumento
    }, 0)

    const parcialMXN = _.chain(this.dataValues).filter(c => c.monedaVenta == 'MXP' && c.tipoEstatusCliente == 'Entrega Parcial').value();
    this.valueMXNParcial = parcialMXN.reduce((sum, value) => {
      return sum + value.importeMonedaDocumento
    }, 0)

    const sinSeguimientoUSD = _.chain(this.dataValues).filter(c => c.monedaVenta == 'USD' && c.tipoEstatusCliente == '').value();
    this.valueUSDSinSeguimiento = sinSeguimientoUSD.reduce((sum, value) => {
      return sum + value.importeMonedaDocumento
    }, 0)

    const sinSeguimientoMXN = _.chain(this.dataValues).filter(c => c.monedaVenta == 'MXP' && c.tipoEstatusCliente == '').value();
    this.valueMXNSinSeguimiento = sinSeguimientoMXN.reduce((sum, value) => {
      return sum + value.importeMonedaDocumento
    }, 0)


    console.log(this.groupedData);
    console.log(totalUSD)
  } 

  abrirFormaEntregaTotal():void{
    const titulo = 'Entrega Total';
    const grouped = this.dataValues.filter(c => c.tipoEstatusCliente == 'Entrega Total');

    const forma = this.ventana.open(VentasSAPDataComponent, {
      data: {Ventas: grouped, titulo: titulo},
      panelClass: 'form-container',
      width: '90vw'
    })
  }

  abrirFormaEntregaParcial():void{
    const titulo = 'Entrega Parcial';
    const grouped = this.dataValues.filter(c => c.tipoEstatusCliente == 'Entrega Parcial');

    const forma = this.ventana.open(VentasSAPDataComponent, {
      data: {Ventas: grouped, titulo: titulo},
      panelClass: 'form-container',
      width: '90vw'
    })
  }

  abrirFormaSinSeguimiento():void{
    const titulo = 'Sin Seguimiento';
    const grouped = this.dataValues.filter(c => c.tipoEstatusCliente == '');

    const forma = this.ventana.open(VentasSAPDataComponent, {
      data: {Ventas: grouped, titulo: titulo},
      panelClass: 'form-container',
      width: '90vw'
    })
  }
  
}

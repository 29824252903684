import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Servicios } from '../entidades/servicios';

export class RepositorioServicios extends RepositorioCatalogo<Servicios> {
  constructor(http: HttpClient) {
    super(http, 'Servicios');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);

  }

  obtenerModoPorTipoServicio(tipoServicioId: number): Observable<Servicios[]> {
    const ruta = `${this.ruta}/ObtenerModoPorTipoServicio/${tipoServicioId}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Servicios[]) => {
        return data;
      })
    );
  }


}

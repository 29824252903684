import { Injectable } from '@angular/core';
import { ParametrosFacturaCompra } from './parametros';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable, of, EMPTY } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Articulo } from 'src/app/entidades/articulo';
import { ProyectoExterno } from 'src/app/entidades/proyecto-externo';
import { Compra } from 'src/app/entidades/compra';
import { Factura } from 'src/app/entidades/factura';

@Injectable({
  providedIn: 'root'
})
export class ParametrosFacturaCompraService {
  private _parametros = {} as ParametrosFacturaCompra;
  public get parametros(): ParametrosFacturaCompra {
    return this._parametros;
  }

  constructor(private ctx: Contexto) {}

  cargarParametros(
    edicion: boolean,
    idCompra?: number,
    idFactura?: number
  ): Observable<any> {
    this._parametros.soloVista = edicion;
    return of(true).pipe(
      flatMap(() => this.cargarFactura(idFactura)),
      flatMap(() => this.cargarCompra(idCompra)),
      flatMap(() => this.cargarArticulos()),
      flatMap(() => this.cargarProyectosExternos())
    );
  }

  cargarCompra(idCompra?: number): Observable<Compra> {
    if (idCompra) {
      return this.ctx.compras
        .obtenerSolicitudCotizacion(idCompra)
        .pipe(map(compra => (this._parametros.compra = compra)));
    } else {
      return of(undefined);
    }
  }

  cargarFactura(idFactura?: number): Observable<Factura> {
    if (idFactura) {
      return this.ctx.facturasCompra
        .obtener(idFactura)
        .pipe(map(factura => (this._parametros.factura = factura)));
    } else {
      return of(undefined);
    }
  }

  cargarProyectosExternos(): Observable<ProyectoExterno[]> {
    return this.ctx.proyectosExternos
      .obtenerTodos()
      .pipe(map(proyectos => (this._parametros.proyectosExternos = proyectos)));
  }

  cargarArticulos(): Observable<Articulo[]> {
    return this.ctx.articulos
      .obtenerTodos()
      .pipe(map(articulos => (this._parametros.articulos = articulos)));
  }
}

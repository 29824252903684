import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ConstructorFormaFactura } from '../constructor-forma.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable } from 'rxjs';
import { Articulo } from 'src/app/entidades/articulo';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-detalles-factura-compra',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss']
})
export class DetallesfacturaCompraComponent implements OnInit {
  @Input() formasDetalle: FormArray;

  constructor(private cf: ConstructorFormaFactura) {}

  ngOnInit() {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { Socio } from '../entidades/socio';

@Pipe({
  name: 'pipaSocio'
})
export class FormatoSocio implements PipeTransform {
  transform(socio: Socio, args?: any): any {
    let tipo = '';

    if (!socio) {
      return '';
    }

    if (socio.esProveedor) {
      tipo = 'Proveedor';
    }
    if (socio.esCliente) {
      tipo = tipo === '' ? tipo : tipo + '/';
      tipo += 'Cliente';
    }
    return `${socio.nombreComercial} (${tipo})`;
  }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { TipoCambio } from '../entidades/tipo-cambio';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioTipoCambio extends RepositorioCatalogo<TipoCambio> {
  constructor(http: HttpClient) {
    super(http, 'TiposCambio');
  }

  obtenerTipoCambio(idMoneda: number, fecha: any): Observable<TipoCambio> {
    return this.clienteHttp.get(`${this.ruta}/ObtenerTipoCambio/${idMoneda}/${fecha}`).pipe(
      map((data: TipoCambio) => {
        return data;
      })
    );
  }
}

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatPaginator,
    MatSort,
    MatTableDataSource,
    MatDialog,
} from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Motivo } from 'src/app/entidades/motivo-no-envio';
import { SelectionModel } from '@angular/cdk/collections';
import { VentanaConfirmacionComponent } from 'src/app/modulos/operaciones/componentes/ventana-confirmacion/ventana-confirmacion.component';
import { TiposMotivos } from 'src/app/entidades/tipos-motivos';
import { TiposMotivosPorDocumentoVenta } from 'src/app/entidades/tipos-motivos-doc-venta';

@AutoUnsubscribe()
@Component({
    selector: 'app-modal-motivos-no-facturar',
    templateUrl: './modal-lista-motivos-no-facturar.component.html',
    styleUrls: ['./modal-lista-motivos-no-facturar.component.scss'],
})
export class ModalListaMotivosNoFacturarComponent implements OnInit {
    motivo = {} as Motivo;
    subsGuardar: Subscription;
    mostrarBoton = true;
    mostrarBotonGuardar = true;

    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    seleccion = new SelectionModel<TiposMotivos>(true, []);
    seleccionados = new SelectionModel<TiposMotivos>(true, []);
    motivosSeleccionados: TiposMotivos[] = [];
    columnasMostradas = ['descripcion', 'select'];
    fuenteDatos: MatTableDataSource<TiposMotivos> = new MatTableDataSource([]);
    motivosPorDocumentoId: TiposMotivosPorDocumentoVenta[];

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
            return 50;
        }
        return 100;
    }
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public parametros: {
            proyectoId: number;
            documentoId: number;
            folio: string;
            tipo: string;
            cancelar: boolean;
            obtener: string;
            guardar: boolean;
        },
        private ventana: MatDialogRef<ModalListaMotivosNoFacturarComponent>,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private ventanaModal: MatDialog
    ) {}

    ngOnInit() {
        this.mostrarBoton = this.parametros.cancelar
        this.mostrarBotonGuardar = this.parametros.guardar
        this.cargarDocumentosCvs();
    }

    cargarDocumentosCvs() {

        if(this.parametros.obtener == 'Y'){
            this.ctx.motivosDocVenta.obtenerTipoMotivosNoFacturarPorDocumento( this.parametros.documentoId, this.parametros.tipo).subscribe((respuesta) => {

                this.motivosPorDocumentoId = respuesta

                this.ctx.tiposMotivos.obtenerActivos(this.parametros.tipo).subscribe((respuesta) => {
                    this.fuenteDatos = new MatTableDataSource(respuesta);
                    this.fuenteDatos.paginator = this.paginador;
                    this.fuenteDatos.sort = this.ordenador;
                
                    if(this.motivosPorDocumentoId.length > 0 ){
                        this.fuenteDatos.data.map( (motivo, index) => {
                            this.motivosPorDocumentoId.map( motivoDoc => {
                                if(motivo.id == Number(motivoDoc.tiposMotivosId) ){
                                    this.seleccion.toggle(this.fuenteDatos.data[index])
                                }
                            })
                            
                        }) 
                    }    
                });
            });            
            
        }else{
            this.ctx.tiposMotivos.obtenerActivos(this.parametros.tipo).subscribe((respuesta) => {
                this.fuenteDatos = new MatTableDataSource(respuesta);
                this.fuenteDatos.paginator = this.paginador;
                this.fuenteDatos.sort = this.ordenador;
            });
        }

       
    }

    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    guardar(): void {

        try {
            if (this.seleccion.selected.length > 0) {
                this.ventanaModal
                    .open(VentanaConfirmacionComponent, {
                        data: {
                            titulo: '¿Desea guardar los siguientes valores y asignarlos?',
                            botonVerdad: 'Si',
                            botonFalso: 'No',
                        },
                    })
                    .afterClosed()
                    .toPromise()
                    .then(async (respuesta) => {
                        if (respuesta) {
                            let resultado = await this.ctx.motivosDocVenta
                                .guardarTipoMotivosPorDocumentoVenta(
                                    this.parametros.tipo,
                                    +this.parametros.folio,
                                    this.seleccion.selected
                                )
                                .toPromise();
                            if (resultado.ok) {
                                this.alerta.mostrarExito(resultado.mensaje);
                                this.cerrar();
                            } else {
                                this.alerta.mostrarError(resultado.mensaje);
                            }
                        }
                    });
            } else {
                this.alerta.mostrarAdvertencia(
                    'Seleccione los motivos a no facturar...'
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    cerrar(): void {
        this.ventana.close(false);
    }

    isAllSelected() {
        const numSelected = this.seleccion.selected.length;
        const numRows = this.fuenteDatos.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.seleccion.clear() :
            this.fuenteDatos.data.forEach(row => this.seleccion.select(row));
    }
    

    // masterToggle() {
    //     this.isAllSelected()
    //         ? this.fuenteDatos.data.forEach((row) => {
    //               this.seleccion.clear();
    //               this.motivoSeleccionado(row);
    //           })
    //         : this.fuenteDatos.data.forEach((row) => {
    //               this.seleccion.select(row);
    //               this.motivoSeleccionado(row);
    //           });
    // }

    // motivoSeleccionado(motivo: TiposMotivos) {
    //     const doc = this.motivosSeleccionados.find(
    //         (e) => e.id == motivo.id && e.seleccionado == true
    //     );
    //     if (doc == null || doc == undefined) {
           
    //         motivo.seleccionado = true;
    //         this.motivosSeleccionados.push(motivo);
    //     } else {
           
    //         this.motivosSeleccionados = this.motivosSeleccionados.filter(
    //             (e) => e.id != motivo.id
    //         );
    //     }
    // }

}

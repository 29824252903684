import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatOption } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Unidad } from 'src/app/entidades/unidad';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { TipoUnidad } from 'src/app/entidades/tipo-unidad';
import { Pais } from 'src/app/entidades/pais';
import { Estado } from 'src/app/entidades/estado';
import { Ciudad } from 'src/app/entidades/ciudad';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaCiudadComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  ciudad: Ciudad;
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }
 
  paises: MatTableDataSource<Pais> = new MatTableDataSource([]);
  estados: MatTableDataSource<Estado> = new MatTableDataSource([]);
  ciudades: MatTableDataSource<Ciudad> = new MatTableDataSource([]);

  disableEstado: boolean;
  disableCiudad: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaCiudadComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx
        .ciudad
        .obtener(id)
        .subscribe(
          a => {
            this.ciudad = a;
            Object.assign(this.forma.value,this.ciudad);
            this.forma.reset(this.forma.value);
            
          });
    }
    else {
      this.idSeleccionado = 0;
      this.ciudad = new Ciudad();
      this.disableEstado = true;
      this.disableCiudad = true;
      this.cargarPaises();
    }

  
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        paisId: ['', Validators.nullValidator],
        estadoId: ['', Validators.nullValidator],
        descripcion: ['', Validators.required],
      },
    );
  }

  

  cargarPaises(): void {
    this.ctx.pais.obtenerTodos().subscribe((a) => {
      this.paises.data = a;
    });
  }

  cargarEstadosPais(event) {
    const idPais = event.value;
    this.disableEstado = false;
    if (idPais > 0) {
      this.ctx.estado.obtenerEstadosPais(idPais).subscribe((estados) => {
        this.estados.data = estados;
      });
    }
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.ciudad, this.forma.value);

      let observable: Observable<Ciudad>;
      let mensaje = 'Ciudad guardada.';
      let guardar = true;
      if (this.idSeleccionado == 0) {
        observable = this.ctx.ciudad.guardar(this.ciudad);
      } else {
        guardar = false;
        mensaje = 'Ciudad actualizada';
        observable = this.ctx
          .ciudad
          .actualizar(this.idSeleccionado.toString(), this.ciudad);
      }

      this.subsGuardar = observable.subscribe(() => {
        this.guardado.emit();
        this.alerta.mostrarExito(mensaje);
        this.cargando = false;
        this.limpiar();
        if(!guardar){
          this.ventana.close();
        }
      },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

import { Component, OnInit, OnDestroy, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { flatMap, startWith, map } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TipoReporte } from 'src/app/entidades/tipo-reportes';
import { AsignacionFiltroReporte } from 'src/app/entidades/asignacion-filtro-reporte';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FiltroRastreo } from 'src/app/entidades/filtro-rastreo';
import { FiltroReporte } from 'src/app/entidades/filtro-reporte';

@AutoUnsubscribe()
@Component({
  selector: 'asignacion-filtro-reporte',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAsignacionFiltroReporteComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  forma: FormGroup;
  tipoReporteSeleccionado: number;
  tipo: TipoReporte[] = [];
  tipoFiltrados: Observable<TipoReporte[]>;
  tipoSinFiltrar: TipoReporte[];
  filtroTipo = new FormControl();
  get f() { return this.forma.controls; }


  fuenteDatos: MatTableDataSource<FiltroReporte> = new MatTableDataSource([]);
  columnasMostradas = [
    'clave',
    'descripcion',
    'asignacion'
  ];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  tabla: FiltroReporte[];
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.cargando = false;
    this.forma = this.formBuilder.group(
      {
        tipoReporteId: [0, Validators.nullValidator],
        tipoReporteDescripcion: ['', Validators.required],
      },
    );
    this.cargarTipoReportes();
  }

  cargarTipoReportes() {
    this.ctx.tipoReporte.obtenerTodosActivos().toPromise().then(resultado => {
      this.tipoSinFiltrar = resultado;
      this.tipoFiltrados = this.filtroTipo.valueChanges
        .pipe(
          startWith<string | TipoReporte>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.descripcion),
          map(t => this.filtrarTipoReportes(t))

        );
    }).catch(e => {

    });
  }

  private filtrarTipoReportes(nombre: string): TipoReporte[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.tipoSinFiltrar.filter(t => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }


  reporteSeleccionado(tipo: TipoReporte) {
    this.f['tipoReporteDescripcion'].setValue(tipo.descripcion);
    this.f['tipoReporteId'].setValue(tipo.id);
    this.cargarTabla(tipo.id);
  }



  public cargarTabla(tipoReporte: number): void {
    this.tipoReporteSeleccionado = tipoReporte;
    this.cargando = true;
    this.ctx.filtroReporte
      .obtenerFiltroTipoReporte(tipoReporte)
      .pipe(
        flatMap(direcciones => {
          this.tabla = direcciones;
          return this.ctx.filtroReporte.obtenerTodosActivos();
        })
      )
      .subscribe(direcciones => {
        this.fuenteDatos = new MatTableDataSource(direcciones);
        this.cargando = false;
      });
  }

  cambiarEstado(seleccionado: boolean, idDireccion: number): void {
    if (seleccionado) {
      this.asignar(idDireccion);
    } else {
      this.desasignar(idDireccion);
    }
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  asignar(id: number): void {
    const asignacion = new AsignacionFiltroReporte();
    asignacion.tipoReporteId = this.tipoReporteSeleccionado;
    asignacion.filtroReporteId = id;

    this.ctx.tipoReporte
      .asignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación guardada');
        },
        error => {
          this.alerta.mostrarError('Error al asignar dirección');
        }
      );
  }

  desasignar(id: number): void {
    const asignacion = new AsignacionFiltroReporte();
    asignacion.tipoReporteId = this.tipoReporteSeleccionado;
    asignacion.filtroReporteId = id;

    this.ctx.tipoReporte
      .desasignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación eliminada');
        },
        error => {
          this.alerta.mostrarError('Error al quitar la asignación la dirección');
        }
      );
  }

  modoAsignada(id: number): boolean {
    const direccion = this.tabla.find(d => d.id === id);

    if (direccion) {
      return true;
    } else {
      return false;
    }
  }

  limpiarFiltro(): void {
    this.forma.reset();
    this.cargarTipoReportes();
    this.f['tipoReporteDescripcion'].setValue(null);
    this.f['tipoReporteId'].setValue(null);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormsModule } from '@angular/forms';
import { Proveeduria } from 'src/app/entidades/proveeduria';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-proveeduria',
  templateUrl: './proveeduria.component.html',
  styleUrls: ['./proveeduria.component.scss']
})
export class ProveeduriaComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<Proveeduria> = new MatTableDataSource([]);
  columnasMostradas = ['codigo', 'nombre', 'estadoOrigen', 'ciudadOrigen', 'estadoDestino', 'ciudadDestino', 'camion', 'plataforma', 'fecha'];
  cargando = true;
  rowExapandido: Proveeduria | null = null;

  estadosYCiudadesOrigen: { estado: string, ciudad: string }[] = [];
  ciudadesOrigenFiltradas: string[] = [];
  estadosYCiudadesDestino: { estado: string, ciudad: string }[] = [];
  ciudadesDestinoFiltradas: string[] = [];

  estadosOrigen: string[] = [];
  ciudadesOrigen: string[] = [];
  estadosDestino: string[] = [];
  ciudadesDestino: string[] = [];
  plataforma: string[] = [];

  filtroEstadoOrigen: string = '';
  filtroCiudadOrigen: string = '';
  filtroEstadoDestino: string = '';
  filtroCiudadDestino: string = '';
  filtroPlataforma: string = '';

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }
  
  constructor(private ctx: Contexto, public ventanaDocumento: MatDialog) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  cargarInformacion(): void {
    this.cargando = true;
    this.ctx.proveeduria
      .obtenerTodos()
      .subscribe(Proveedurias => {
        const datosFiltrados = this.validarRegistros(Proveedurias);
        this.fuenteDatos = new MatTableDataSource(datosFiltrados);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
        this.estadosOrigen = this.obtenerValoresUnicos('estadoOrigen');
        this.ciudadesOrigen = this.obtenerValoresUnicos('ciudadOrigen');
        this.estadosDestino = this.obtenerValoresUnicos('estadoDestino');
        this.ciudadesDestino = this.obtenerValoresUnicos('ciudadDestino');
        this.plataforma = this.obtenerValoresUnicos('plataforma');
        this.estadosYCiudadesOrigen = Proveedurias.map(proveeduria => ({ estado: proveeduria.estadoOrigen, ciudad: proveeduria.ciudadOrigen }));
        this.estadosYCiudadesDestino = Proveedurias.map(proveeduria => ({ estado: proveeduria.estadoDestino, ciudad: proveeduria.ciudadDestino }));
      });
  }

  estadoOrigenSeleccionado() {
    const ciudadesFiltradas = this.estadosYCiudadesOrigen
      .filter(item => item.estado === this.filtroEstadoOrigen)
      .map(item => item.ciudad);
    // Utilizar un Set para eliminar duplicados
    const ciudadesUnicasSet = new Set(ciudadesFiltradas);
    // Convertir el Set a un array
    this.ciudadesOrigenFiltradas = Array.from(ciudadesUnicasSet);
  }

  estadoDestinoSeleccionado() {
    const ciudadesFiltradas = this.estadosYCiudadesDestino
      .filter(item => item.estado === this.filtroEstadoDestino)
      .map(item => item.ciudad);
    // Utilizar un Set para eliminar duplicados
    const ciudadesUnicasSet = new Set(ciudadesFiltradas);
    // Convertir el Set a un array
    this.ciudadesDestinoFiltradas = Array.from(ciudadesUnicasSet);
  }
  
  
  validarRegistros(registros: Proveeduria[]): Proveeduria[] {
    const registrosValidados: Proveeduria[] = [];
    const registroMap = new Map<string, Proveeduria>();
  
    registros.forEach(registro => {
      const clave = `${registro.codigo}-${registro.nombre}-${registro.estadoOrigen}-${registro.ciudadOrigen}-${registro.estadoDestino}-${registro.ciudadDestino}-${registro.camion}-${registro.plataforma}`;
  
      if (!registroMap.has(clave) || registroMap.get(clave).fecha < registro.fecha) {
        registroMap.set(clave, registro);
      }
    });
  
    registroMap.forEach(registro => registrosValidados.push(registro));
  
    return registrosValidados;
  }

  obtenerValoresUnicos(columna: string): string[] {
    const valoresUnicos = new Set<string>();

    this.fuenteDatos.data.forEach(registro => {
      valoresUnicos.add(registro[columna]);
    });

    return Array.from(valoresUnicos);
  }
  
  filtros() {
    // Construir un objeto con los valores de los filtros seleccionados
    const filtros = {
      estadoOrigen: this.filtroEstadoOrigen,
      ciudadOrigen: this.filtroCiudadOrigen,
      estadoDestino: this.filtroEstadoDestino,
      ciudadDestino: this.filtroCiudadDestino,
      plataforma: this.filtroPlataforma
    };

    // Aplicar los filtros a la fuente de datos
    this.aplicarFiltros(filtros);
  }

  aplicarFiltros(filtros: any) {
    // Obtener los valores únicos de las columnas
    const estadosOrigen = this.obtenerValoresUnicos('estadoOrigen');
    const ciudadesOrigen = this.obtenerValoresUnicos('ciudadOrigen');
    const estadosDestino = this.obtenerValoresUnicos('estadoDestino');
    const ciudadesDestino = this.obtenerValoresUnicos('ciudadDestino');
    const plataformas = this.obtenerValoresUnicos('plataforma');

    // Aplicar los filtros
    this.fuenteDatos.filterPredicate = (data: Proveeduria, filter: string) => {
      const { estadoOrigen, ciudadOrigen, estadoDestino, ciudadDestino, plataforma } = JSON.parse(filter);

      const estadoOrigenValido = !estadoOrigen || data.estadoOrigen === estadoOrigen;
      const ciudadOrigenValida = !ciudadOrigen || data.ciudadOrigen === ciudadOrigen;
      const estadoDestinoValido = !estadoDestino || data.estadoDestino === estadoDestino;
      const ciudadDestinoValida = !ciudadDestino || data.ciudadDestino === ciudadDestino;
      const plataformaValida = !plataforma || data.plataforma === plataforma;

      return estadoOrigenValido && ciudadOrigenValida && estadoDestinoValido && ciudadDestinoValida && plataformaValida;
    };

    // Asignar los valores seleccionados a las propiedades
    this.filtroEstadoOrigen = filtros.estadoOrigen;
    this.filtroCiudadOrigen = filtros.ciudadOrigen;
    this.filtroEstadoDestino = filtros.estadoDestino;
    this.filtroCiudadDestino = filtros.ciudadDestino;
    this.filtroPlataforma = filtros.plataforma;

    // Actualizar los valores únicos de las listas desplegables
    this.estadosOrigen = estadosOrigen;
    this.ciudadesOrigen = ciudadesOrigen;
    this.estadosDestino = estadosDestino;
    this.ciudadesDestino = ciudadesDestino;
    this.plataforma = plataformas;

    // Aplicar los filtros a la fuente de datos
    this.fuenteDatos.filter = JSON.stringify(filtros);
  }

  limpiarFiltros() {
    // Restablecer los valores de los filtros
    this.filtroEstadoOrigen = '';
    this.filtroCiudadOrigen = '';
    this.filtroEstadoDestino = '';
    this.filtroCiudadDestino = '';
    this.filtroPlataforma = '';
  
    // Limpiar la lista de ciudades filtradas
    this.ciudadesOrigenFiltradas = [];
    this.ciudadesDestinoFiltradas = [];
    // Volver a cargar la información
    this.cargarInformacion();
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  formatearFecha(fecha: Date | string): string {
    const fechaObj = typeof fecha === 'string' ? new Date(fecha) : fecha;
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return fechaObj.toLocaleDateString('es-ES', options);
  }

  exportarAExcel(): void {
    // Obtener los datos filtrados
    const data = this.fuenteDatos.filteredData || [];
  
    if (data.length === 0) {
      console.warn('No hay datos filtrados para exportar a Excel');
      return;
    }
  
    // Crear un nuevo libro de Excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Proveeduria');
  
    // Definir el estilo para el encabezado
    const headerStyle: Partial<ExcelJS.Style> = {
      font: { bold: true, color: { argb: 'FFFFFF' } }, // Color y estilo de las letras
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF5733' } // Color de fondo
      }
    };
  
    // Agregar encabezados a la hoja de trabajo con estilo
    const headerRow = worksheet.addRow(['Id','Codigo', 'Nombre', 'Estado de origen', 'Ciudad de origen', 'Estado destino', 'Ciudad destino', 'Camion', 'Plataforma', 'Fecha']);
    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
    });
  
    // Ajustar el ancho de algunas columnas
    worksheet.getColumn('C').width = 25;  // Ajusta el ancho de la columna 'Nombre'
    worksheet.getColumn('D').width = 25; // Ajusta el ancho de la columna 'Estado de origen'
    worksheet.getColumn('E').width = 25; // Ajusta el ancho de la columna 'Ciudad de origen'
    worksheet.getColumn('F').width = 25; // Ajusta el ancho de la columna 'Estado destino'
    worksheet.getColumn('G').width = 25; // Ajusta el ancho de la columna 'Ciudad destino'
    worksheet.getColumn('H').width = 25;  // Ajusta el ancho de la columna 'Camion'
    worksheet.getColumn('I').width = 25;  // Ajusta el ancho de la columna 'Plataforma'
    worksheet.getColumn('J').width = 10;  // Ajusta el ancho de la columna 'Fecha'
  
    // Formatear la columna de fecha
    data.forEach((rowData) => {
      const formattedFecha = this.formatearFecha(rowData.fecha);
      worksheet.addRow([...Object.values(rowData).slice(0, -1), formattedFecha]).getCell('J').numFmt = 'd/m/yyyy';
    });
  
    // Agregar datos a la hoja de trabajo
    data.forEach((rowData) => {
      worksheet.addRow(Object.values(rowData));
    });
  
    // Guardar el archivo Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'proveeduria_data.xlsx');
    });
  }
  

}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { MotivoCierre } from '../entidades/motivo-cierre';

export class RepositorioMotivoCierre extends RepositorioCatalogo<
  MotivoCierre
> {
  constructor(http: HttpClient) {
    super(http, 'MotivosCierre');
  }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaTipoDocumentoComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TipoDocumento } from 'src/app/entidades/tipo-documento';

@Component({
  selector: 'catalogo-tipoDocumento',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoTipoDocumentoComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<TipoDocumento> = new MatTableDataSource([]);
  columnasMostradas = ['descripcion', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  subsArticulos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarArticulos();
  }

  cargarArticulos(): void {
    this.cargando = true;
    this.ctx.tiposDocumento
      .obtenerTodos()
      .subscribe(tiposDocumento => {
        this.fuenteDatos = new MatTableDataSource(tiposDocumento);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }

  
  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaTipoDocumentoComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarArticulos();
      });
  }

  // cambiarEstado(activo: boolean, id: number): void {
  //   if (activo) {
  //     this.activar(id);
  //   }
  //   else {
  //     this.desactivar(id);
  //   }
  // }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  // activar(id: number): void {
  //   this.subsActivar = this.ctx
  //     .tiposDocumento.activar(id)
  //     .subscribe(() => { },
  //       error => {
  //         this.alerta.mostrarError('Error al activar');
  //       }
  //     );
  // }

  // desactivar(id: number): void {
  //   this.subsActivar = this.ctx
  //     .tiposDocumento.desactivar(id)
  //     .subscribe(() => { },
  //       error => {
  //         this.alerta.mostrarError('Error al desactivar');
  //       }
  //     );
  // }

  eliminar(id: number): void {
    this.subsActivar = this.ctx
      .tiposDocumento.eliminar(id.toString())
      .subscribe(() => {
        this.alerta.mostrarError('Registro eliminado');
        this.cargarArticulos();
      },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subsArticulos) { this.subsArticulos.unsubscribe(); }
    if (this.subsActivar) { this.subsActivar.unsubscribe(); }
    if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
  }
}
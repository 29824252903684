import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import * as _ from 'underscore';
import * as XLSX from "xlsx";
import { ComprasPorEnviar } from 'src/app/entidades/ComprasPorEnviar';

@Component({
    selector: 'app-Compras-SAP-data',
    templateUrl: './Compras-SAP-data.component.html',
    styleUrls: ['./Compras-SAP-data.component.scss']
})
export class ComprasSAPDataComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['CodigoCliente', 'NombreCliente', 'Proveedor', 'Proyecto', 'Mercancia',
        'Servicio', 'FechaDocumento', 'SolicitudCotizacion',
        'Compra', 'Venta', 'Importe', 'xMonitor', 'EstatusSAP', 'MonedaCompra'];

    Compras: ComprasPorEnviar[];
    titulo: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { Compras: ComprasPorEnviar[], titulo: string },
        private ventanas: MatDialog) {
    }

    ngOnInit(): void {
        this.titulo = this.object.titulo;
        this.Compras = this.object.Compras;
        this.fuenteDatos = new MatTableDataSource(this.Compras);
        this.fuenteDatos.sort = this.ordenador;
    }

    exportExcel() {

        let tabla = document.getElementById('tabla');

        let wb = XLSX.utils.table_to_book(tabla, <XLSX.Table2SheetOpts>{
            sheet: `${this.titulo.replace('/', '-')}`
        });
        XLSX.writeFile(wb, `Compras ${this.titulo.replace('/', '-')}.xlsx`);

    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();

        if (this.fuenteDatos.paginator) {
            this.fuenteDatos.paginator.firstPage();
        }
    }


}
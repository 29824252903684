import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfMake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
 
import { Subscription } from 'rxjs';
import { element } from '@angular/core/src/render3';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { CotizacionesGlobal } from 'src/app/entidades/cotizacion-globales';


@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './cotizacion.html',
  styleUrls: ['./cotizacion.scss']
})
export class DescargarPDFCotizacionGlobalComponent implements OnInit {
  rutaArchivo = '';
  title = 'Cotización Global de Ventas';
  pdfMake: any;
  pdfFonts: any;

  pdfObj = null;
  imagenLogo = null;
  archivo: any;

  reporte: CotizacionesGlobal;
  subReporte: Subscription;
 
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public parametros: {proyectoId: number;},
    private ctx: Contexto,
    public ventanaDocumento: MatDialog
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
  }

  ngOnInit() {
    this.cargarInformacion(this.parametros.proyectoId);
    this.generar();
  }

  cargarInformacion(proyectoId: number): void {
    console.log(proyectoId);
    this.subReporte = this.ctx.proyectos.obtenerCVGlobales(proyectoId)
      .subscribe((cotizacionesGloables) => {
        this.reporte = cotizacionesGloables;
        console.dir(this.reporte);
      });
  }

  async generar(): Promise<void> {
    let imagenBase64;

    const toDataURL = (url) =>
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );

    await toDataURL('/images/logo.jpg').then((dataUrl) => {
      imagenBase64 = dataUrl;
    });

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const aa = {
      header: {
        text: '\nCOTIZACIÓN DE VENTA',
        alignment: 'center',
        bold: true,
        fontSize: 8,
        italics: true
      },
      footer: {
        table: {
          widths: [10, '*', 20],
          body: [
            [
              {
                border: [false, false, false, false],
                text: ''
              },
              {
                border: [false, false, false, false],
                text: '',
                fontSize: 4
              },
              {
                border: [false, false, false, false],
                fillColor: '#99190c',
                text: '\n\n\n'
              }
            ]
          ]
        }
      },
      content: [{
        table: { widths: [3, 200, '*'], body: [] }
      }],
      styles: {
        titulo: {
          fontSize: 12,
          bold: true,
          alignment: 'right'
        },
        subtitulo: {
          fontSize: 8,
          color: '#D0D0D0',
          alignment: 'right'
        },
        tituloTabla: {
          fontSize: 9,
          bold: true,
          alignment: 'center',
          fillColor: '#9B9B9B'
        },
        subTituloTabla00: {
          fontSize: 9,
          bold: true,
          alignment: 'center'
        },
        subTituloTabla01: {
          fontSize: 10,
          bold: true,
          alignment: 'right'
        },
        subTituloTabla02: {
          fontSize: 10,
          bold: true,
          alignment: 'left'
        },
        contenido00: {
          fontSize: 9,
          bold: false,
          alignment: 'center'
        },
        contenido01: {
          fontSize: 9,
          bold: false,
          alignment: 'right'
        },
        contenido02: {
          fontSize: 9,
          bold: false,
          alignment: 'left'
        },
        contenido03: {
          fontSize: 8,
          bold: true,
          alignment: 'center'
        },
        importante: {
          fontSize: 9,
          bold: true,
          alignment: 'center',
          fillColor: 'yellow'
        }
      }
    };

    const encabezado = [
      {
        border: [false, false, false, false],
        fillColor: '#99190c',
        text: ''
      },
      {
        border: [false, false, false, false],
        // text: 'imagenBase64',
        // fontSize: 4,
        image: imagenBase64,
        width: 200,
        opacity: 0.5
      },
      {
        border: [false, false, false, false],
        stack: [
          'MOTION SI MÉXICO S.A. DE C.V.',
          {
            text: 'Fuente de los Vientos 6470',
            style: 'subtitulo'
          },
          { text: 'Fracc. las Fuentes', style: 'subtitulo' },
          {
            text: 'Chihuahua, Chihuahua 31207',
            style: 'subtitulo'
          },
          { text: 'México', style: 'subtitulo' }
        ],
        style: 'titulo'
      }];
    const saltoLinea = [
      {
        border: [false, false, false, false],
        fillColor: '#99190c',
        text: ''
      },
      {
        border: [false, false, false, false],
        text: '',
        style: 'contenido02',
        colSpan: 2
      }
    ];

    let contLineas = 0;
    // aa.content[0].table.body.push(encabezado);
    aa.content[0].table.body.push([{
      border: [false, false, false, false],
      fillColor: '#99190c',
      text: ''
    },
    {
      border: [false, false, false, false],
      // text: 'imagenBase64',
      // fontSize: 4,
      image: imagenBase64,
      width: 200,
      opacity: 0.5
    },
    {
      border: [false, false, false, false],
      stack: [
        'MOTION SI MÉXICO S.A. DE C.V.',
        {
          text: 'Fuente de los Vientos 6470',
          style: 'subtitulo'
        },
        { text: 'Fracc. las Fuentes', style: 'subtitulo' },
        {
          text: 'Chihuahua, Chihuahua 31207',
          style: 'subtitulo'
        },
        { text: 'México', style: 'subtitulo' }
      ],
      style: 'titulo'
    }]);
    contLineas += 5;
    // this.reporte.forEach((element) => {
    //   //Dirigido a:
    //   aa.content[0].table.body.push([
    //     {
    //       border: [false, false, false, false],
    //       fillColor: '#99190c',
    //       text: ''
    //     },
    //     {
    //       border: [false, false, false, false],
    //       stack: [
    //         { text: element.contacto, style: 'subTituloTabla02' },
    //         { text: element.cliente, style: 'subTituloTabla02' }
    //       ]
    //     },
    //     {
    //       border: [false, false, false, false],
    //       stack: [
    //         {
    //           text: element.fechaDocumento.toLocaleUpperCase(),
    //           style: 'subTituloTabla01'
    //         },
    //         {
    //           text: 'Referencia: ' + element.referencia,
    //           style: 'subTituloTabla01',
    //           color: '#99190c'
    //         }
    //       ]
    //     }
    //   ]);
    //   contLineas += 4;
    //   //fin Dirigido a:
    //   //Introduccion
    //   aa.content[0].table.body.push(
    //     [
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text:
    //           'En respuesta a su amable solicitud, nos complace en presentar a usted la siguiente propuesta:',
    //         style: 'contenido02',
    //         colSpan: 2//,
    //         // margin: [0, 20]
    //       }
    //     ]);
    //   contLineas += 4;
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   aa.content[0].table.body.push(
    //     [
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: element.referenciaServicio,
    //         style: 'contenido02',
    //         colSpan: 2//,
    //         // margin: [0, 20]
    //       }
    //     ]);
    //   contLineas += 4;
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //Detalles de la cotización de venta
    //   let linea = 0;
    //   element.detalles.forEach((detalle) => {
    //     //encabezado del servicio
    //     linea++;
    //     aa.content[0].table.body.push(
    //       [
    //         {
    //           border: [false, false, false, false],
    //           fillColor: '#99190c',
    //           text: ''
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: 'Servicio ' + linea.toString() + ': ' + detalle.cantidad + ', ' + detalle.descripcion, // + ', ' +  detalle.totalLinea + ' ' + element.moneda + detalle.impuestos,
    //           style: 'contenido02',
    //           colSpan: 2
    //         }
    //       ],
    //       [
    //         {
    //           border: [false, false, false, false],
    //           fillColor: '#99190c',
    //           text: ''
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: detalle.totalLinea + ' ' + element.moneda + detalle.impuestos,//' más I.V.A. menos Retención',
    //           style: 'contenido02',
    //           colSpan: 2
    //         }
    //       ]
    //     );
    //     contLineas += 2;
    //     //salto de renglon
    //     aa.content[0].table.body.push(saltoLinea);
    //     contLineas += 1;
    //     //fin salto de reglon
    //     //Origenes del servicio
    //     detalle.origen.forEach((origenes) => {
    //       aa.content[0].table.body.push([
    //         {
    //           border: [false, false, false, false],
    //           fillColor: '#99190c',
    //           text: ''
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: 'Origen:',
    //           style: 'contenido02',
    //           colSpan: 2
    //         }
    //       ]);
    //       aa.content[0].table.body.push([
    //         {
    //           border: [false, false, false, false],
    //           fillColor: '#99190c',
    //           text: ''
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: origenes,
    //           style: 'contenido02',
    //           colSpan: 2
    //         }
    //       ]);
    //       if (origenes.length > 118) {
    //         contLineas += ((origenes.length) / 118) + 1;
    //       }
    //       else {
    //         contLineas += 2;
    //       }
    //     });
    //     //Fin de los Origenes
    //     //salto de renglon
    //     aa.content[0].table.body.push(saltoLinea);
    //     contLineas += 1;
    //     //fin salto de reglon
    //     //Destinos del servicio
    //     detalle.destino.forEach((destinos) => {
    //       aa.content[0].table.body.push([
    //         {
    //           border: [false, false, false, false],
    //           fillColor: '#99190c',
    //           text: ''
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: 'Destino:',
    //           style: 'contenido02',
    //           colSpan: 2
    //         }
    //       ]);
    //       aa.content[0].table.body.push([
    //         {
    //           border: [false, false, false, false],
    //           fillColor: '#99190c',
    //           text: ''
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: destinos,
    //           style: 'contenido02',
    //           colSpan: 2
    //         }
    //       ]);
    //       if (destinos.length > 118) {
    //         contLineas += ((destinos.length) / 118) + 1;
    //       }
    //       else {
    //         contLineas += 2;
    //       }
    //     });
    //     //Fin de los Destinos
    //   });
    //   //fin del detalle de venta
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //costos adicionales
    //   if (element.adicionales.length > 0) {
    //     //encabezado de costos adicionales
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: 'Costos adicionales:',
    //         style: 'contenido02',
    //         colSpan: 2
    //       }
    //     ]);
    //     //fin encabezado de costos adicionales
    //     element.adicionales.forEach((adicional) => {
    //       aa.content[0].table.body.push([
    //         {
    //           border: [false, false, false, false],
    //           fillColor: '#99190c',
    //           text: ''
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: adicional.descripcion + ' ' + adicional.totalLinea,
    //           style: 'contenido02',
    //           colSpan: 2
    //         }
    //       ]);
    //       contLineas += 1;
    //     });
    //     //total de los costos adicionales
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: 'Total costos adicionales: ' + element.subServicios + ' ' + element.moneda,
    //         style: 'contenido02',
    //         colSpan: 2
    //       }
    //     ]);
    //     contLineas += 1;
    //     //fin total de los costos adicionales
    //   }
    //   //fin de costos adicionales
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //total de la venta
    //   aa.content[0].table.body.push([
    //     {
    //       border: [false, false, false, false],
    //       fillColor: '#99190c',
    //       text: ''
    //     },
    //     {
    //       border: [false, false, false, false],
    //       table: {
    //         body: [
    //           [{
    //             border: [false, false, false, false],
    //             text: 'Subtotal:',
    //             style: 'contenido02'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: element.subtotal,
    //             style: 'contenido01'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: '',
    //             style: 'contenido01'
    //           },
    //           ],
    //           [{
    //             border: [false, false, false, false],
    //             text: 'I.V.A.:',
    //             style: 'contenido02'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: element.montoTraslado,
    //             style: 'contenido01'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: '',
    //             style: 'contenido01'
    //           },
    //           ],
    //           [{
    //             border: [false, false, false, false],
    //             text: 'Retención:',
    //             style: 'contenido02'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: element.montoRetencion,
    //             style: 'contenido01'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: '',
    //             style: 'contenido01'
    //           },
    //           ],
    //           [{
    //             border: [false, false, false, false],
    //             text: 'Total:',
    //             style: 'contenido02'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: element.total,
    //             style: 'contenido01'
    //           },
    //           {
    //             border: [false, false, false, false],
    //             text: element.moneda,
    //             style: 'contenido01'
    //           },
    //           ],
    //         ]
    //       },
    //       style: 'contenido02',
    //       colSpan: 2
    //     }
    //   ]);
    //   //fin total de la venta
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //incluye
    //   if (element.incluye !== null) {
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: 'Incluye:',
    //         fontSize: 9.5,
    //         bold: true,
    //         italics: true,
    //         alignment: 'left',
    //         colSpan: 2
    //       }
    //     ]);
    //     contLineas += 1;
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: element.incluye,
    //         fontSize: 9,
    //         alignment: 'left',
    //         colSpan: 2
    //       }
    //     ]);
    //     if (element.incluye.length > 118) {
    //       contLineas += ((element.incluye.length) / 118) + 1;
    //     }
    //     else {
    //       contLineas += 2;
    //     }
    //   }
    //   //fin incluye
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //no incluye
    //   if (element.noincluye !== null) {
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: 'No Incluye:',
    //         fontSize: 9.5,
    //         bold: true,
    //         italics: true,
    //         alignment: 'left',
    //         colSpan: 2
    //       }
    //     ]);
    //     contLineas += 1;
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: element.noincluye,
    //         fontSize: 9,
    //         alignment: 'left',
    //         colSpan: 2
    //       }
    //     ]);
    //     if (element.noincluye.length > 118) {
    //       contLineas += ((element.noincluye.length) / 118) + 1;
    //     }
    //     else {
    //       contLineas += 2;
    //     }
    //   }
    //   //fin no incluye
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //terminos y condiciones
    //   if (element.terminos !== null) {
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: 'Terminos y Condiciones:',
    //         fontSize: 9.5,
    //         bold: true,
    //         italics: true,
    //         alignment: 'left',
    //         colSpan: 2
    //       }
    //     ]);
    //     contLineas += 1;
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: element.terminos,
    //         fontSize: 9,
    //         alignment: 'left',
    //         colSpan: 2
    //       }
    //     ]);
    //     if (element.terminos.length > 118) {
    //       contLineas += ((element.terminos.length) / 118) + 1;
    //     }
    //     else {
    //       contLineas += 2;
    //     }
    //   }
    //   //fin terminos y condiciones
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //saludos
    //   if (element.saludos !== null) {
    //     aa.content[0].table.body.push([
    //       {
    //         border: [false, false, false, false],
    //         fillColor: '#99190c',
    //         text: ''
    //       },
    //       {
    //         border: [false, false, false, false],
    //         text: element.saludos,
    //         fontSize: 9,
    //         alignment: 'left',
    //         colSpan: 2
    //       }
    //     ]);
    //     if (element.saludos.length > 118) {
    //       contLineas += ((element.saludos.length) / 118) + 1;
    //     }
    //     else {
    //       contLineas += 2;
    //     }
    //   }
    //   //fin saludos
    //   //salto de renglon
    //   aa.content[0].table.body.push(saltoLinea);
    //   contLineas += 1;
    //   //fin salto de reglon
    //   //saltos de linea y de pagina
    //   //firma
    //   aa.content[0].table.body.push([
    //     {
    //       border: [false, false, false, false],
    //       fillColor: '#99190c',
    //       text: ''
    //     },
    //     {
    //       border: [false, false, false, false],
    //       stack:  [
    //         {
    //           border: [false, false, false, false],
    //           text: element.empleado,//'Ivan Morales',
    //           fontSize: 8,
    //           bold: true,
    //           alignment: 'center'
    //         },
    //         { border: [false, false, false, false], text: '' },
    //         {
    //           border: [false, false, false, false],
    //           text: '',
    //           fontSize: 8,
    //           alignment: 'center'
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text:
    //             'Oficina Mex: ' + element.telefonoEmpleado,//Móvil Mex: +52 1(614) 488-3662,
    //           fontSize: 8,
    //           alignment: 'center'
    //         },
    //         { border: [false, false, false, false], text: '' },
    //         {
    //           border: [false, false, false, false],
    //           text:'Email: ' + element.correoEmpleado,
    //           fontSize: 8,
    //           alignment: 'center'
    //         },
    //         {
    //           border: [false, false, false, true],
    //           text: '',
    //           fontSize: 9,
    //           alignment: 'center',
    //           margin: [0, 5]
    //         },
    //         { border: [false, false, false, false], text: '' },
    //         {
    //           border: [false, false, false, true],
    //           text: '',
    //           fontSize: 9,
    //           alignment: 'center',
    //           margin: [0, 5]
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: '____________________________________________________________________________________________',
    //           fontSize: 6,
    //           alignment: 'center',
    //           margin: [0, 5]
    //         },
    //         {
    //           border: [false, false, false, false],
    //           text: 'Representantes de Motion SI MÉXICO, S.A. de C.V.',
    //           fontSize: 6,
    //           alignment: 'center',
    //           margin: [0, 5]
    //         },
    //       ],
           
    //       alignment: 'center',
    //       colSpan: 2,
    //       margin: [0, 20]
    //     }
    //   ]);
    //   contLineas += 1;
    //   //fin firma
    //   //fin saltos de linea y de pagina
    // });

    // console.log(aa);
    this.pdfObj = pdfMake.createPdf(aa);

    this.archivo = pdfMake.createPdf(aa);

    this.archivo.getBlob((blod) => {
      const blob = new Blob([blod], { type: 'application/pdf' });
      this.rutaArchivo = window.URL.createObjectURL(blob);
    });
  }

  descargar(): void {
    if (this.pdfObj != null) {
      const pdfDocGenerator = pdfMake.createPdf(this.archivo);
      pdfDocGenerator.getBlob((blob) => {
        const b = new Blob([blob], { type: 'application/pdf' });
        console.log(b);
      });

      let pdf = this.pdfObj.download('cotizacion.pdf');
    }
  }
}

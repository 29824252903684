import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ReporteBOL,
  ReporteLC,
  Reportes,
  ReporteCotizacion
} from '../entidades/reportes';
import { Pareto } from '../entidades/pareto';

export class RepositorioReportes extends RepositorioCatalogo<Reportes> {
  constructor(http: HttpClient) {
    super(http, 'Reportes');
  }

  obtenerBOL(idCotizacion: number): Observable<ReporteBOL[]> {
    const ruta = `${this.ruta}/ObtenerBOL/${idCotizacion}`;
    return this.clienteHttp.get(ruta).pipe(
      map((cotizacion: ReporteBOL[]) => {
        return cotizacion;
      })
    );
  }

  obtenerLC(idCotizacion: number): Observable<ReporteLC[]> {
    const ruta = `${this.ruta}/obtenerLC/${idCotizacion}`;
    return this.clienteHttp.get(ruta).pipe(
      map((cotizacion: ReporteLC[]) => {
        return cotizacion;
      })
    );
  }

  obtenerCotizacion(idCotizacion: number): Observable<ReporteCotizacion[]> {
    const ruta = `${this.ruta}/ObtenerCotizacion/${idCotizacion}`;
    return this.clienteHttp.get(ruta).pipe(
      map((cotizacion: ReporteCotizacion[]) => {
        return cotizacion;
      })
    );
  }

  obtenerTablaPareto(): Observable<Pareto[]> {
    const ruta = `${this.ruta}/TablaPareto`;
    return this.clienteHttp.get<Pareto[]>(ruta);
  }



}

import { HttpClient } from '@angular/common/http';
import { Direccion } from '../entidades/direcciones-entregas';
import { AsignacionClienteDireccion } from '../entidades/AsignacionClienteDireccion';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

export class RepositorioDireccionEntrega extends RepositorioCatalogo<Direccion> {
  constructor(http: HttpClient) {
    super(http, 'DireccionesServicios');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  obtenerLocation(id: number): Promise<any> {
    const ruta = `${this.ruta}/Location/${id}`;
    return new Promise(async (resolve, reject) => {
      this.clienteHttp.get(ruta).toPromise()
      .then((direccion: any)=> {
        if (direccion) {
          if (direccion.lat && direccion.lng) {
            resolve({
              lat: Number(direccion.lat),
              lng: Number(direccion.lng)
            });
          } else {
            resolve(null);
          }

        } else {
          resolve(null);
        }
      })
      .catch(error => {

      });
    });

  }

  asignar(
    asignacion: AsignacionClienteDireccion
  ): Observable<AsignacionClienteDireccion> {
    const ruta = `${this.ruta}/Asignar`;
    return this.clienteHttp.post(ruta, asignacion).pipe(
      map((asig: AsignacionClienteDireccion) => asig)
    );
  }

  desasignar(asignacion: AsignacionClienteDireccion): Observable<void> {
    const ruta = `${this.ruta}/Desasignar/${asignacion.direccionServicioId}/${asignacion.clienteId}`;
    return this.clienteHttp.delete(ruta).pipe(map(() => null));
  }

  obtenerDestinos(): Observable<Direccion[]> {
    const ruta = `${this.ruta}/Destinos`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

  obtenerOrigenes(): Observable<Direccion[]> {
    const ruta = `${this.ruta}/Origenes`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

  obtenerDestinosCliente(idCliente: number): Observable<Direccion[]> {
    const ruta = `${this.ruta}/DestinosCliente/${idCliente}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

  obtenerOrigenesCliente(idCliente: number): Observable<Direccion[]> {
    const ruta = `${this.ruta}/OrigenesCliente/${idCliente}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

  obtenerTodosCliente(IdCliente: number): Observable<Direccion[]> {
    const ruta = `${this.ruta}/Cliente/${IdCliente}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

  obtenerTodasDirecciones(): Observable<Direccion[]> {
    const ruta = `${this.ruta}/ObtenerTodasDirecciones`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }



  obtenerTodasDireccionesOrigenes(): Observable<Direccion[]> {
    const ruta = `${this.ruta}/ObtenerTodasDireccionesOrigenes`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

  obtenerTodasDireccionesDestinos(): Observable<Direccion[]> {
    const ruta = `${this.ruta}/ObtenerTodasDireccionesDestinos`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }


  obtenerOrigenesReporte(): Observable<Direccion[]> {
    const ruta = `${this.ruta}/ObtenerOrigenesReporte`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

  obtenerDestinosReporte(): Observable<Direccion[]> {
    const ruta = `${this.ruta}/ObtenerDestinosReporte`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Direccion[]) => {
        return data;
      })
    );
  }

}


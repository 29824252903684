import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';

import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ActivatedRoute } from '@angular/router';
import { Documento } from 'src/app/entidades/documento';
import { flatMap } from 'rxjs/operators';
import { Moneda } from 'src/app/entidades/moneda';
import { ParametrosComponentes } from 'src/app/modulos/operaciones/parametros';
import { ComponentesService } from 'src/app/modulos/operaciones/servicios/componentes.service';
import { FormControl } from '@angular/forms';
import { AsignacionProyectosCV } from 'src/app/entidades/asignacion-proyectos-cv';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-pdf-cotizacion-global',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoPDFCotizacionGlobalComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  proyectoId: number = 0;
  private sub: any;

  monedaId = new FormControl(1);
  fuenteDatos: MatTableDataSource<Documento> = new MatTableDataSource([]);
  columnasMostradas = [
    'asignacion',
    'folio',
    'fechaDocumento',
    'folioCT',
    'comentarioEmpresa',
    'monedaAbreviatura',
  ];
  cargando = true;

  monedas: Moneda[];
  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  tabla: Documento[];
  constructor(private route: ActivatedRoute, public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.proyectoId = +params['proyectoId'];
      console.log(this.proyectoId);
      this.cargarTabla();
      this.cargarMonedas();
    });
  }

  public cargarMonedas(): void {
    this.ctx.monedas.obtenerTodos().subscribe(resultado => {
      this.monedas = resultado;
    });
  }

  public buscarCVs(moneda: any): void {
    this.cargando = true;
    this.ctx.documentosVenta
      .obtenerTodosProyectoId(this.proyectoId, moneda.value)
      .pipe(
        flatMap(documentos => {
          this.tabla = documentos;
          return this.ctx.documentosVenta.obtenerCVSProyectoId(this.proyectoId, moneda.value);
        })
      )
      .subscribe(documentos => {
        this.fuenteDatos = new MatTableDataSource(documentos);
        this.cargando = false;
      });
  }

  public cargarTabla(): void {
    this.cargando = true;
    this.ctx.documentosVenta
      .obtenerTodosProyectoId(this.proyectoId, this.monedaId.value)
      .pipe(
        flatMap(documentos => {
          this.tabla = documentos;
          return this.ctx.documentosVenta.obtenerCVSProyectoId(this.proyectoId, this.monedaId.value);
        })
      )
      .subscribe(documentos => {
        this.fuenteDatos = new MatTableDataSource(documentos);
        this.cargando = false;
      });
  }


  cambiarEstado(seleccionado: boolean, ventaId: number): void {
    if (seleccionado) {
      this.asignar(ventaId);
    } else {
      this.desasignar(ventaId);
    }
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  asignar(id: number): void {
    const asignacion = new AsignacionProyectosCV();
    asignacion.proyectoId = this.proyectoId;
    asignacion.documentoVentaId = id;

    this.ctx.documentosVenta.asignarProyectoCV(asignacion).subscribe(() => {
      this.alerta.mostrarExito('Asignación guardada');
    },
      error => {
        this.alerta.mostrarError('Error al asignar dirección');
      }
    );
  }

  desasignar(id: number): void {
    const asignacion = new AsignacionProyectosCV();
    asignacion.proyectoId = this.proyectoId;
    asignacion.documentoVentaId = id;
    console.dir(asignacion);
    this.ctx.documentosVenta.desasignarProyectoCV(asignacion).subscribe(
      () => {
        this.alerta.mostrarExito('Asignación eliminada');
      },
      error => {
        this.alerta.mostrarError('Error al eliminar la asignación');
      }
    );
  }


  modoAsignada(id: number): boolean {
    const direccion = this.tabla.find(d => d.id === id);

    if (direccion) {
      return true;
    } else {
      return false;
    }
  }
}
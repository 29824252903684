import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Subscription } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { Documento } from 'src/app/entidades/documento';
import { Router } from '@angular/router';
import { ComponentesService } from 'src/app/modulos/operaciones/servicios/componentes.service';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { ParametrosComponentes } from 'src/app/modulos/operaciones/parametros';

@Component({
  selector: 'cotizaciones-compra',
  templateUrl: './cotizaciones-compra.component.html',
  styleUrls: ['./cotizaciones-compra.component.scss']
})
@AutoUnsubscribe()
export class CotizacionesCompraComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  @Output() seleccionado = new EventEmitter<number>();

  cargando = false;
  fechaFiltro:Date=new Date("2023-01-02");

  idProveedor: number;
  subsCotizaciones: Subscription;
  subsGuardar: Subscription;
  subsParametrosCompras: Subscription;

  fuenteDatos: MatTableDataSource<Documento> = new MatTableDataSource([]);
  columnasMostradas = [
    'folio',
    'proyectoFolio',
    'fechaDocumento',
    'comentarioEmpresa',
    'monedaAbreviatura',
    'total',
    'opciones'
  ];

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  public get params(): ParametrosComponentes {
    return this.parametrosService.parametros;
  }

  constructor(
    private ctx: Contexto,
    private autenticacion: Autenticacion,
    private router: Router,
    private spinner: LoadingService,
    private parametrosService: ComponentesService
  ) {}

  ngOnInit() {
    this.idProveedor = this.autenticacion.perfil.id;
    this.cargarCotizaciones();
  }

  cargarCotizaciones(): void {
    this.cargando = true;
    this.subsCotizaciones = this.ctx.documentosCompra
      .obtenerCotizaciones(this.idProveedor)
      .subscribe(cotizaciones => {
        this.fuenteDatos = new MatTableDataSource(cotizaciones);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  llenarCostos(proyectoId: number, documentoId: number): void {
    this.spinner.show('Cargando documento');
    this.parametrosService
      .cargarParametros({
        tipoComponente: 'CC',
        proyectoId,
        modoDocumento: 't',
        idDocumento: documentoId,
        urlRegreso: ''
      })
      .toPromise()
      .then(() => {
        this.router
          .navigate(['Proveedores/CotizacionCompra'])
          .then(() => this.spinner.hide());
      });
  }

  llenarDatosEquipo(proyectoId: number, documentoId: number): void {
    this.spinner.show('Cargando documento');
    this.parametrosService
      .cargarParametros({
        tipoComponente: 'DE',
        proyectoId,
        modoDocumento: 't',
        idDocumento: documentoId,
        urlRegreso: 'Proveedores/CotizacionesCompra'
      })
      .toPromise()
      .then(() => {
        this.router
          .navigate(['Proveedores/DatosEquipos'])
          .then(() => this.spinner.hide());
      });
  }
}

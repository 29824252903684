import { last } from '@angular/router/src/utils/collection';
export class Direccion {
  id: number;
  paisId: number;
  paisDescripcion: string;
  estadoDescripcion: string;
  ciudadDescripcion: string;
  estadoId: number;
  ciudadId: number;
  calle: string;
  numeroExterior: string;
  numeroInterior: string;
  colonia: string;
  codigoPostal: string;
  referencias: string;
  activo: boolean;
  esDestino: boolean;
  esOrigen: boolean;
  ubicacion: string;
  clienteId: number;
  socionombrecomercial: string;
  contactoOrigen: string;
  contactoDestino: string;
  establecimiento: string;
  direccionCompleta: string;
  lat: number;
  lng: number;
}

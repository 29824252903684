import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DivGridComponent } from './components/div-grid.component';
import { ContainerDirective } from './directives/container.directive';

@NgModule({
  declarations: [DivGridComponent, ContainerDirective],
  imports: [CommonModule, FlexLayoutModule],
  exports: [DivGridComponent, ContainerDirective]
})
export class DivGridModule {}

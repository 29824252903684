import { HttpClient } from "@angular/common/http";
import { EstadosProyectos } from "../entidades/estado-proyecto";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export class RepositorioEstadosProyectos extends RepositorioCatalogo<EstadosProyectos> {

    constructor(http: HttpClient) {
        super(http, "EstadosProyectos");
    }

    // activar(id: number): Observable<void>{
    //     let ruta = `${this.Ruta}/Activar/${id}`;
    //     return this.ClienteHttp.put(ruta, null)
    //     .pipe(map(() => null));
    // }

    // desactivar(id: number): Observable<void>{
    //     let ruta = `${this.Ruta}/Desactivar/${id}`;
    //     return this.ClienteHttp.put(ruta, null)
    //     .pipe(map(() => null));
    // }
}

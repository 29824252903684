import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Camion } from '../entidades/camion';
import { AsignacionCamion } from '../entidades/asignacion-camion';
import { CfdiEmisor } from '../entidades/cfdi-emisor';
import { RepositorioCatalogo } from './repositorio-catalogo';

export class RepositorioCfdiEmisor extends RepositorioCatalogo<CfdiEmisor> {
  constructor(http: HttpClient) {
    super(http, 'CfdiEmisor');
  }


}

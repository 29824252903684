export class Usuario {
  id: number;
  nombre: string;
  activo: boolean;
  autorizado: boolean;
  email: string;
  tipoUsuarioId: number;
  tipoUsuarioDescripcion: string;
  rolId: number;
  rolNombre: string;
  contrasena: string;
  confirmacionContrasena: string;
  socioId: number;

  // id:number;
  // rfc: string;
  // nombre: string;
  // activo: boolean;
  // autorizo: boolean;
  // email: string;
  // tipoUsuarioId:number
  // tipoUsuarioDescripcion: string;
  // rolId: number;
  // rolNombre: string;
  // contrasena: string;
  // confirmacionContrasena: string;
  // empresaId: number;
  // empresa: string;
  // empresaRFC: string;
}

import { Component, OnInit, OnDestroy, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { flatMap } from 'rxjs/operators';
import { AsignacionClienteDireccion } from 'src/app/entidades/AsignacionClienteDireccion';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { LocalidesSocio } from 'src/app/entidades/localidades-socio';
import { AsignacionLocalidadesSocio } from 'src/app/entidades/asignacion-localidades-socio';
 
 



@AutoUnsubscribe()
@Component({
  selector: 'cat-forma-asigdirecciones-prov',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAsignacionDireccionesServicioProveedoresComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
 
 
  proveedorSeleccionado: number;
 

  fuenteDatos: MatTableDataSource<LocalidesSocio> = new MatTableDataSource([]);
  columnasMostradas = [
    'paisDescripcion',
    'estadoDescripcion',
    'esOrigen',
    'asignacion'
  ];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  direccionesCliente: LocalidesSocio[];


  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
 
    private alerta: ServicioAlerta,
  ) { }

  ngOnInit() {
    this.cargando = false;
  }

  cargarDireccionesOrigen(proveedor : number): void {
    this.proveedorSeleccionado = proveedor;
    this.cargando = true;
    this.ctx.localidadesSocio
      .obtenerLocalidadesSocioCliente(this.proveedorSeleccionado)
      .pipe(
        flatMap(direcciones => {
          this.direccionesCliente = direcciones;
          return this.ctx.localidadesSocio.obtenerTodosOrigenes();
        })
      )
      .subscribe(direcciones => {
        this.fuenteDatos = new MatTableDataSource(direcciones);
       
       
        this.cargando = false;
      });
  }

  cambiarEstado(seleccionado: boolean, idDireccion: number): void {
    if (seleccionado) {
      this.asignar(idDireccion);
    } else {
      this.desasignar(idDireccion);
    }
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  asignar(idDireccionServicio: number): void {
    const asignacion = new AsignacionLocalidadesSocio();
    asignacion.clienteId = this.proveedorSeleccionado;
    asignacion.localidadesSocioId = idDireccionServicio;

    this.ctx.localidadesSocio
      .asignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación guardada');
        },
        error => {
          this.alerta.mostrarError('Error al asignar dirección');
        }
      );
  }

  desasignar(idDireccionServicio: number): void {
    const asignacion = new AsignacionLocalidadesSocio();
    asignacion.clienteId = this.proveedorSeleccionado;
    asignacion.localidadesSocioId = idDireccionServicio;

    this.ctx.localidadesSocio
      .desasignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación eliminada');
        },
        error => {
          this.alerta.mostrarError('Error al quitar la asignación la dirección');
        }
      );
  }

  direccionAsignada(idDireccion: number): boolean {
    const direccion = this.direccionesCliente.find(d => d.id === idDireccion);
 
    if (direccion) {
      return true;
    } else {
      return false;
    }
  }


}

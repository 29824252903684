import { Injectable } from '@angular/core';
import {
    Observable,
    of,
    Subscription,
    BehaviorSubject,
    Subject,
    forkJoin,
    EMPTY,
} from 'rxjs';
import { tap, flatMap, map } from 'rxjs/operators';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Frontera } from 'src/app/entidades/frontera';
import { Aduana } from 'src/app/entidades/aduana';
import { TipoCarga } from 'src/app/entidades/tipo-carga';
import { UnidadesLongitud } from 'src/app/entidades/unidades-longitud';
import { UnidadesPeso } from 'src/app/entidades/unidades-peso';
import { MedidaLegal } from 'src/app/entidades/medida-legal';
import { Impuesto } from 'src/app/entidades/impuesto';
import { Articulo } from 'src/app/entidades/articulo';
import { Contexto } from 'src/app/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Moneda } from 'src/app/entidades/moneda';
import { Operador } from 'src/app/entidades/operador';
import { Trailer } from 'src/app/entidades/trailer';
import { Camion } from 'src/app/entidades/camion';
import { ParametrosComponentes, ArgumentosComponentes } from '../parametros';
import { TipoComponente } from 'src/app/entidades/tipo-componente';
import { Empleado } from 'src/app/entidades/empleado';
import { Documento } from 'src/app/entidades/documento';
import { TipoCambio } from 'src/app/entidades/tipo-cambio';
import { DocumentoDatosEquipo } from 'src/app/entidades/documento-datos-equipo';
import { Servicios } from 'src/app/entidades/servicios';
import { TiposServicios } from 'src/app/entidades/tipos-servicios';
import { PerfilesProyecto } from 'src/app/entidades/perfil-proyecto';

@AutoUnsubscribe()
@Injectable({
    providedIn: 'root',
})
export class ComponentesService {
    private _parametros: ParametrosComponentes;
    public get parametros(): ParametrosComponentes {
        return this._parametros;
    }
    subsTipoCambio: Subscription;
    subsImportTipoCambio: Subscription;

    private _idMonedaSeleccionada: number;
    public get idMonedaDeleccionada() {
        return this._idMonedaSeleccionada;
    }

    private fechaDocumentoSeleccionada: Date;
    public get fechaDocumento() {
        return this.fechaDocumentoSeleccionada;
    }

    public get datos(): ParametrosComponentes {
        return this._parametros;
    }
    importeTipoCambio: number;
    constructor(private ctx: Contexto) {
        this.importeTipoCambio = 0;
    }

    cargarParametros(args: ArgumentosComponentes): Observable<{}> {
        args.modoDocumento = args.modoDocumento ? args.modoDocumento : 'e';
        if (args.proyecto) {
            return this.cargarDatos(args);
        }
        return this.ctx.proyectos
            .obtener(args.proyectoId)
            .pipe(
                flatMap((proyecto) => this.cargarDatos({ ...args, proyecto }))
            );
    }

    private cargarDatos(args: ArgumentosComponentes): Observable<{}> {
        this._parametros = {
            tipoCambio: new Subject(),
            origenes$: new BehaviorSubject<Direccion[]>([]),
            destinos$: new BehaviorSubject<Direccion[]>([]),
            cotizacionesVentas$: new BehaviorSubject<Documento[]>([]),
            cotizacionesFoliosVentas$: new BehaviorSubject<string[]>([])
        } as ParametrosComponentes;
        this._parametros.documentoComparacion = args.documentoComparacion;
        Object.assign(this._parametros, args);
        switch (args.tipoComponente) {
            case 'SV':
                return this.cargarParametrosSV(args.idDocumento, args.proyecto.clienteId, args.resultado);
            case 'SC':
                return this.cargarParametrosCC(args.idDocumento);
            case 'CC':
                return this.cargarParametrosCC(args.idDocumento);
            case 'CV':
                return this.cargarParametrosCV(args.idDocumento, args.proyecto.clienteId);
            case 'DE':
                return this.cargarParametrosDE(args.idDocumento);
        }

        return of({});
    }

    private cargarParametrosCV(idDocumento: number, clienteId: number): Observable<{}> {
        return this.cargarVenta(idDocumento).pipe(
            flatMap(() =>
                forkJoin([
                    this.generarFolioVenta(idDocumento),
                    this.cargarEmpleados(),
                    this.cargarMonedas(),
                    this.cargarOrigenes(),
                    this.cargarDestinos(),
                    this.cargarFolioCotizacionesVenta(clienteId, idDocumento),
                    this.cargarFronteras(),
                    this.cargarAduanas(),
                    this.cargarTiposCarga(),
                    this.cargarUnidadesLongitud(),
                    this.cargarUnidadesPeso(),
                    this.cargarMedidasLegales(),
                    this.cargarTrasladosSolicitudVenta(),
                    this.cargarRetenciones(),
                    this.cargarArticulos(),
                    this.cargarServicios(),
                    this.cargarTiposServicios(),
                ])
            )
        );
    }

    private cargarParametrosSV(idDocumento: number, clienteId: number, resultado: boolean): Observable<{}> {
        return this.cargarVenta(idDocumento, resultado).pipe(
            flatMap(() =>
                forkJoin([
                    this.generarFolioVenta(idDocumento),
                    this.cargarEmpleadosSV(),
                    this.cargarMonedas(),
                    this.cargarOrigenes(),
                    this.cargarDestinos(),
                    this.cargarFolioCotizacionesVenta(clienteId, idDocumento),
                    this.cargarFronteras(),
                    this.cargarAduanas(),
                    this.cargarTiposCarga(),
                    this.cargarUnidadesLongitud(),
                    this.cargarUnidadesPeso(),
                    this.cargarMedidasLegales(),
                    this.cargarRetenciones(),
                    this.cargarTrasladosSolicitudVenta(),
                    this.cargarArticulos(),
                    this.cargarServicios(),
                    this.cargarTiposServicios(),
                ])
            )

        );
    }

    private cargarParametrosCC(idDocumento: number): Observable<{}> {
        return this.cargarCompra(idDocumento).pipe(
            flatMap(() =>
                forkJoin([
                    this.generarFolioCompra(idDocumento),
                    this.cargarMonedas(),
                    this.cargarOrigenes(),
                    this.cargarDestinos(),
                    this.cargarFronteras(),
                    this.cargarAduanas(),
                    this.cargarTiposCarga(),
                    this.cargarUnidadesLongitud(),
                    this.cargarUnidadesPeso(),
                    this.cargarMedidasLegales(),
                    this.cargarTasladosCotizacionVenta(),
                    this.cargarRetenciones(),
                    this.cargarArticulos(),
                    this.cargarOperadores(),
                    this.cargarOperadoresPorProveedor(),
                    this.cargarCamiones(),
                    this.cargarTrailers(),
                    this.cargarServicios(),
                    this.cargarTiposServicios(),
                ])
            )
        );
    }

    private cargarParametrosDE(idDocumento: number): Observable<{}> {
        return this.cargarDocumentoEquipo(idDocumento).pipe(
            flatMap(() =>
                forkJoin([
                    // this.parametros.documentoEquipo.proveedorId
                    //     ? of([])
                    //     : this.cargarProveedores(),
                    this.cargarOperadoresPorProveedor(),
                    this.cargarTrailers(),
                    this.cargarCamiones(),
                ])
            )
        );
    }

    cargarProveedores() {
        return this.ctx.socios
            .obtenerProveedores()
            .pipe(
                tap(
                    (proveedores) => (this.parametros.proveedores = proveedores)
                )
            );
    }

    cargarEmpleados(): Observable<Empleado[]> {
        return this.ctx.empleados
            .obtenerTodos()
            .pipe(tap((empleados) => (this._parametros.empleados = empleados)));
    }

    cargarEmpleadosSV(): Observable<Empleado[]> {
        return this.ctx.empleados
            .obtenerTodosActivos()
            .pipe(tap((empleados) => (this._parametros.empleados = empleados)));
    }

    cargarVenta(idDocumento?: number, resultado?: boolean): Observable<Documento> {
        if (idDocumento) {
            
            return this.ctx.documentosVenta
                .obtenerVentaDocumento(idDocumento)
                .pipe(
                    tap((documento) => {
                        this._parametros.documento = documento;
                        this._parametros.idDocumento = idDocumento;
                    })
                );
        } else {
            this._parametros.nuevaCotizacion = resultado;
            return of(undefined);
        }
    }

    cargarCompra(idDocumento?: number): Observable<Documento> {
        if (idDocumento) {
            return this.ctx.documentosCompra.obtener(idDocumento).pipe(
                tap((documento) => {
                    this._parametros.documento = documento;
                    this._parametros.idDocumento = idDocumento;
                })
            );
        } else {
            return of(undefined);
        }
    }

    cargarDocumentoEquipo(
        idDocumento?: number
    ): Observable<DocumentoDatosEquipo> {
        if (idDocumento) {
            return this.ctx.documentosVenta
                .obtenerDocumentoDatosEquipo(idDocumento)
                .pipe(
                    tap((documento) => {
                        this._parametros.documentoEquipo = documento;
                        this._parametros.idDocumento = idDocumento;
                    })
                );
        } else {
            return of(undefined);
        }
    }

    cargarMonedas(): Observable<Moneda[]> {
        return this.ctx.monedas
            .obtenerTodos()
            .pipe(tap((monedas) => (this._parametros.monedas = monedas)));
    }

    cargarOrigenes(): Observable<Direccion[]> {
        return this.ctx.direccionesServicio
            .obtenerOrigenesCliente(this._parametros.proyecto.clienteId)
            .pipe(
                tap((origenes) => {
                    this._parametros.origenes = origenes;
                    this._parametros.origenes$.next(origenes);
                })
            );
    }

    cargarDestinos(): Observable<Direccion[]> {
        return this.ctx.direccionesServicio
            .obtenerDestinosCliente(this._parametros.proyecto.clienteId)
            .pipe(
                tap((destinos) => {
                    this._parametros.destinos = destinos;
                    this._parametros.destinos$.next(destinos);
                })
            );
    }

    cargarFolioCotizacionesVenta(clienteId: number, documentoVentaId?: number): Observable<string[]> {
        return this.ctx.documentosVenta
            .obtenerFoliosCotizacionesVenta(clienteId, documentoVentaId)
            .pipe(
                tap((cotizacionesVentas) => {
                    this._parametros.cotizacionesFoliosVentas = cotizacionesVentas;
                    this._parametros.cotizacionesFoliosVentas$.next(cotizacionesVentas);
                })
            );
    }

    cargarFronteras(): Observable<Frontera[]> {
        //if (this._parametros.proyecto.esInternacional) {
        return this.ctx.fronteras
            .obtenerTodos()
            .pipe(
                tap((fronteras) => (this._parametros.fronteras = fronteras))
            );
        // } else {
        //     return of([]);
        // }
    }

    cargarAduanas(): Observable<Aduana[]> {
        // if (this._parametros.proyecto.esInternacional) {
        return this.ctx.aduanas
            .obtenerTodos()
            .pipe(tap((aduanas) => (this._parametros.aduanas = aduanas)));
        // } else {
        //     return of([]);
        // }
    }

    cargarTiposCarga(): Observable<TipoCarga[]> {
        return this.ctx.tiposCarga
            .obtenerTodos()
            .pipe(
                tap((tiposCarga) => (this._parametros.tiposCarga = tiposCarga))
            );
    }

    cargarUnidadesLongitud(): Observable<UnidadesLongitud[]> {
        return this.ctx.unidadesLongitud
            .obtenerTodos()
            .pipe(
                tap(
                    (unidades) => (this._parametros.unidadesLongitud = unidades)
                )
            );
    }

    cargarUnidadesPeso(): Observable<UnidadesPeso[]> {
        return this.ctx.unidadesPeso
            .obtenerTodos()
            .pipe(
                tap((unidades) => (this._parametros.unidadesPeso = unidades))
            );
    }

    cargarMedidasLegales(): Observable<MedidaLegal[]> {
        return this.ctx.medidasLegales
            .obtenerTodos()
            .pipe(
                tap((medidas) => (this._parametros.medidasLegales = medidas))
            );
    }

    cargarTraslados(): Observable<Impuesto[]> {
        return this.ctx.impuestos
            .obtenerTodos()
            .pipe(tap((impuestos) => (this._parametros.traslados = impuestos)));
    }

    cargarTrasladosSolicitudVenta(): Observable<Impuesto[]> {
        return this.ctx.impuestos
            .obtenerImpuestosSolicitudVenta()
            .pipe(tap((impuestos) => (this._parametros.traslados = impuestos)));
    }

    cargarTasladosCotizacionVenta(): Observable<Impuesto[]> {
        return this.ctx.impuestos
            .obtenerImpuestosCotizacionVenta()
            .pipe(tap((impuestos) => (this._parametros.traslados = impuestos)));
    }

    cargarArticulos(): Observable<Articulo[]> {
        return this.ctx.articulos
            .obtenerTodos()
            .pipe(tap((articulos) => (this._parametros.articulos = articulos)));
    }

    cargarOperadores(): Observable<Operador[]> {
        if (false) {
            // this.parametros.proyecto.perfilProyectoId === 1
            return this.ctx.operadores
                .obtenerTodoPorPorProveedor(
                    this.parametros.documentoEquipo.proveedorId
                )
                .pipe(
                    tap(
                        (operadores) =>
                            (this._parametros.operadores = operadores)
                    )
                );
        } else {
            return this.ctx.operadores
                .obtenerTodos()
                .pipe(
                    tap(
                        (operadores) =>
                            (this._parametros.operadores = operadores)
                    )
                );
        }
    }

    cargarOperadoresPorProveedor(): Observable<Operador[]> {
        if (
            this.parametros.proyecto.perfilProyectoId ==
            PerfilesProyecto.Logistica &&
            this.parametros.documentoEquipo &&
            this.parametros.documentoEquipo.proveedorId
        ) {
            return this.ctx.operadores
                .obtenerTodoPorPorProveedor(
                    this.parametros.documentoEquipo.proveedorId
                )
                .pipe(
                    tap(
                        (operadores) =>
                            (this._parametros.operadoresPorProveedor = operadores)
                    )
                );
        } else if (
            this.parametros.proyecto.perfilProyectoId ==
            PerfilesProyecto.Transportista
        ) {
            return this.ctx.operadores
                .obtenerTodos()
                .pipe(
                    tap(
                        (operadores) =>
                            (this._parametros.operadoresPorProveedor = operadores)
                    )
                );
        } else {
            return of([]);
        }
    }

    cargarCamiones(): Observable<Camion[]> {
        return this.ctx.camiones
            .obtenerTodos()
            .pipe(tap((camiones) => (this._parametros.camiones = camiones)));
    }

    cargarServicios(): Observable<Servicios[]> {
        return this.ctx.servicios
            .obtenerTodos()
            .pipe(tap((servicios) => (this._parametros.servicios = servicios)));
    }

    cargarTiposServicios(): Observable<TiposServicios[]> {
        return this.ctx.tiposServicios
            .obtenerTodos()
            .pipe(
                tap(
                    (tiposServicios) =>
                        (this._parametros.tiposServicios = tiposServicios)
                )
            );
    }

    cargarTrailers(): Observable<Trailer[]> {
        return this.ctx.trailers
            .obtenerTodos()
            .pipe(tap((trailers) => (this._parametros.trailers = trailers)));
    }

    cargarRetenciones(): Observable<Impuesto[]> {
        return this.ctx.impuestos
            .obtenerTodos()
            .pipe(
                tap(
                    (impuestos: Impuesto[]) =>
                    (this._parametros.retenciones = impuestos.filter(
                        (e) => e.esRetencion === true
                    ))
                )
            );
    }

    generarFolioCompra(idCompra: number): Observable<string> {
        if (!idCompra) {
            return this.ctx.documentosCompra.generarFolio();
        } else {
            return of('');
        }
    }

    generarFolioVenta(idVenta?: number): Observable<string> {
        if (!idVenta) {
            return this.ctx.documentosVenta.generarFolio();
        } else {
            return of('');
        }
    }

    public async cargarTipoCambio(
        idMoneda?: number,
        fechaDocumento?: Date
    ): Promise<void> {
        let fecha: string;
        if (fechaDocumento) {
            fecha =
                fechaDocumento instanceof Date
                    ? fechaDocumento.toString()
                    : <string>fechaDocumento;
            this.fechaDocumentoSeleccionada = fechaDocumento;
            fecha = new Date(this.fechaDocumentoSeleccionada)
                .toISOString()
                .substring(0, 10);
        } else if (this.fechaDocumentoSeleccionada) {
            fecha = new Date(this.fechaDocumentoSeleccionada)
                .toISOString()
                .substring(0, 10);
        } else {
            fecha = '';
        }

        let moneda: number;
        if (idMoneda) {
            moneda = idMoneda;
            this._idMonedaSeleccionada = moneda;
        } else if (this._idMonedaSeleccionada) {
            moneda = this._idMonedaSeleccionada;
        } else {
            moneda = 0;
        }

        const tipoCamb = await this.ctx.tiposCambio
            .obtenerTipoCambio(moneda, fecha)
            .toPromise();
        if (tipoCamb) {
            this.datos.tipoCambio.next(tipoCamb.valor);
        } else {
            this.datos.tipoCambio.next(0);
        }
    }

    actualizarTipoCambio(idMoneda?: number, fechaDocumento?: Date): void {
        this.cargarTipoCambio(idMoneda, fechaDocumento);
    }
}

import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
    ChangeDetectorRef
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatTableDataSource,
    MatDialog,
    MatOption,
} from '@angular/material';
import {
    FormGroup,
    Validators,
    FormBuilder,
    AbstractControl,
    ValidatorFn,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { Socio } from 'src/app/entidades/socio';
import { Pais } from 'src/app/entidades/pais';
import { Estado } from 'src/app/entidades/estado';
import { Ciudad } from 'src/app/entidades/ciudad';
import { FormaCiudadComponent } from '../../ciudades/forma/forma.component';
import { MapsAPILoader } from '@agm/core';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

declare var google: any;

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaDireccionServicioComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    direccion: Direccion;
    idSeleccionado = 0;
    cargando = false;
    subsGuardar: Subscription;
    guardado: EventEmitter<void> = new EventEmitter<void>();
    idCliente: number;
    estadoSeleccionado: number;
    // coordenadas motion si
    accion = 1;
    lat;
    lng;
    geoCoder: any;

    get f() {
        return this.forma.controls;
    }
    clientes: MatTableDataSource<Socio> = new MatTableDataSource([]);
    selectedValue: string;

    paises: MatTableDataSource<Pais> = new MatTableDataSource([]);
    estados: MatTableDataSource<Estado> = new MatTableDataSource([]);
    ciudades: MatTableDataSource<Ciudad> = new MatTableDataSource([]);

    disableEstado: boolean;
    disableCiudad: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: number,
        private ventana: MatDialogRef<FormaDireccionServicioComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private autenticacion: Autenticacion,
        public ventanaDocumento: MatDialog,
        public ventanaCatalogo: MatDialog,
        private mapLoader: MapsAPILoader,
        private ref: ChangeDetectorRef
    ) {
        if (id && id > 0) {
            this.accion = 2;
            this.idSeleccionado = id;

            this.ctx.direccionesServicio.obtener(id).subscribe((a) => {
                this.direccion = a;
                this.direccion.clienteId = this.idCliente;
                Object.assign(this.forma.value, this.direccion);
                this.forma.patchValue(this.forma.value);

                this.ctx.pais
                    .obtener(this.direccion.paisId)
                    .subscribe((pais) => {
                        if (
                            pais.descripcion === 'Mexico' ||
                            pais.descripcion === 'México'
                        ) {
                            this.f['codigoPostal'].setValidators(
                                Validators.maxLength(5)
                            );
                        }
                    });
                this.ctx.estado
                    .obtenerEstadosPais(this.direccion.paisId)
                    .subscribe((estados) => {
                        this.estados.data = estados;
                    });
                this.ctx.ciudad
                    .obtenerEstadosCiudad(this.direccion.estadoId)
                    .subscribe((ciudades) => {
                        this.ciudades.data = ciudades;
                    });
                if (this.direccion.lat && this.direccion.lng) {
                    this.lat = Number(this.direccion.lat);
                    this.lng = Number(this.direccion.lng);
                } else {
                  this.actualizarGeolocalizacion();
                }
            });
            this.cargarSocios();
        } else {
            this.accion = 1;
            this.idSeleccionado = 0;
            this.direccion = new Direccion();
            this.cargarSocios();
            this.disableEstado = true;
            this.disableCiudad = true;
        }
    }

    ngOnInit() {
        this.mapLoader.load()
        .then((success)=> {
            this.geoCoder = new google.maps.Geocoder();
        });
        // let perfil = this.autenticacion.perfil;
        // this.idCliente = perfil.id;
        this.forma = this.formBuilder.group({
            paisId: [0, Validators.required],
            estadoId: [0, Validators.required],
            ciudadId: [0, Validators.required],
            calle: ['', Validators.nullValidator],
            numeroExterior: ['', Validators.required],
            numeroInterior: [''],
            colonia: ['', Validators.nullValidator],
            establecimiento: ['', Validators.required],
            codigoPostal: ['', Validators.nullValidator],
            referencias: [''],
            esDestino: [false, Validators.nullValidator],
            esOrigen: [true, Validators.nullValidator],
            ubicacion: ['https://', Validators.required],
            contactoDestino: [''],
            contactoOrigen: [''],
            lat:[false, Validators.nullValidator],
            lng:[false, Validators.nullValidator]
        });
        // this.clientes.subscribe(socios=>{console.log(socios)});
        if (this.accion === 1) {
          this.onChanges();
        }
    }

    onChanges(): void {
      this.forma.get('ciudadId').valueChanges.subscribe(val => {
        this.actualizarGeolocalizacion();
      });
      this.forma.get('establecimiento').valueChanges.subscribe(val => {
        this.actualizarGeolocalizacion();
      });
      this.forma.get('calle').valueChanges.subscribe(val => {
        this.actualizarGeolocalizacion();
      });
      this.forma.get('numeroExterior').valueChanges.subscribe(val => {
        this.actualizarGeolocalizacion();
      });
      this.forma.get('codigoPostal').valueChanges.subscribe(val => {
        this.actualizarGeolocalizacion();
      });
      this.forma.get('colonia').valueChanges.subscribe(val => {
        this.actualizarGeolocalizacion();
      });
    }

    cargarSocios(): void {
        this.ctx.socios.obtenerClientes().subscribe((socio) => {
            this.clientes.data = socio;
            this.cargarPaises();
        });
    }

    limpiar(): void {
        this.forma.reset();

        this.f['esDestino'].setValue(false);
        this.f['esOrigen'].setValue(true);
        this.f['contactoDestino'].setValue('');
        this.f['contactoOrigen'].setValue('');

        // this.forma.get('clienteId').setValue(this.idCliente);
    }

    actualizarGeolocalizacion() {
      var address;
      Object.assign(this.direccion, this.forma.value);
      if (!this.idSeleccionado) {
        let ciudad = this.ciudades.data.find(mCiudad => mCiudad.id === this.direccion.ciudadId);
        let pais = this.estados.data.find(mEstado => mEstado.id === this.direccion.estadoId);
        let estado = this.paises.data.find(mPais => mPais.id === this.direccion.paisId);
        address= `${this.direccion.establecimiento? this.direccion.establecimiento: ''} ${this.direccion.calle ? this.direccion.calle : ''}  ${this.direccion.numeroExterior ? this.direccion.numeroExterior : ''} ${this.direccion.colonia ? this.direccion.colonia : ''} ${ciudad ? ciudad.descripcion : ''} ${estado ? estado.descripcion : ''} ${ciudad ? ciudad.descripcion: ''}`;
      } else {
        address= `${this.direccion.establecimiento? this.direccion.establecimiento: ''} ${this.direccion.calle ? this.direccion.calle : ''}  ${this.direccion.numeroExterior ? this.direccion.numeroExterior : ''} ${this.direccion.colonia ? this.direccion.colonia : ''} ${this.direccion.ciudadDescripcion ? this.direccion.ciudadDescripcion : ''} ${this.direccion.estadoDescripcion ? this.direccion.estadoDescripcion : ''} ${this.direccion.paisDescripcion ? this.direccion.paisDescripcion: ''}`;
      }
      this.geoCoder.geocode({ 'address': address }, (results, status) => {
          if (status === 'OK') {
              this.lat = results[0].geometry.location.lat();
              this.lng = results[0].geometry.location.lng();
              this.forma.controls["lat"].setValue(this.lat);
              this.forma.controls["lng"].setValue(this.lng);
          }
          this.ref.detectChanges();
      });
    }

    actualizarCoordenadas(event: any) {
      this.lat = event.coords.lat;
      this.lng = event.coords.lng;
      this.forma.controls["lat"].setValue(this.lat);
      this.forma.controls["lng"].setValue(this.lng);
  }

    actualizarLatLng() {
      this.forma.value.lat
      if (this.forma.value.lat && this.forma.value.lng) {
          this.lat = Number(this.forma.value.lat);
          this.lng = Number(this.forma.value.lng);
      }

    }



    guardar(): void {
        console.log('valid-->', this.forma.valid)
        if (this.forma.valid) {
            this.cargando = true;
            Object.assign(this.direccion, this.forma.value);
            let observable: Observable<Direccion>;
            let mensaje = 'Dirección guardada.';
            let guardar = true;
            this.direccion.lat = this.lat;
            this.direccion.lng = this.lng;
            if (this.idSeleccionado == 0) {
                observable = this.ctx.direccionesServicio.guardar(
                    this.direccion
                );
            } else {
                guardar = false;
                mensaje = 'Dirección actualizada';
                observable = this.ctx.direccionesServicio.actualizar(
                    this.idSeleccionado.toString(),
                    this.direccion
                );
            }

            this.subsGuardar = observable.subscribe(
                () => {
                    this.guardado.emit();
                    this.alerta.mostrarExito(mensaje);
                    this.cargando = false;
                    this.limpiar();
                    if (!guardar) {
                        this.ventana.close();
                    }
                },
                () => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                }
            );
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    cargarPaises(): void {
        this.ctx.pais.obtenerTodos().subscribe((a) => {
            this.paises.data = a;
        });
    }

    cargarEstadosPais(event) {
        let texto = (event.source.selected as MatOption).viewValue;
        console.log(texto);
        if (texto === 'Mexico' || texto === 'México') {
            this.f['codigoPostal'].setValidators([
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(5),
            ]);
        } else {
            this.f['codigoPostal'].setValidators([
                Validators.required,
                Validators.minLength(5),
            ]);
        }

        const idPais = event.value;
        this.disableEstado = false;
        if (idPais > 0) {
            this.ctx.estado.obtenerEstadosPais(idPais).subscribe((estados) => {
                this.estados.data = estados;
            });
        }
    }

    cargarEstado(): void {
        this.ctx.estado.obtenerTodos().subscribe((a) => {
            this.estados.data = a;
        });
    }

    cargarCiudadesEstado(event) {
        const idEstado = event.value;
        this.estadoSeleccionado = idEstado;
        this.disableCiudad = false;
        if (idEstado > 0) {
            this.ctx.ciudad
                .obtenerEstadosCiudad(idEstado)
                .subscribe((ciudades) => {
                    this.ciudades.data = ciudades;
                });
        }
    }
    cargarCiudad(): void {
        this.ctx.ciudad.obtenerTodos().subscribe((a) => {
            this.ciudades.data = a;
        });
    }

    cerrar(): void {
        this.ventana.close();
    }

    ValidarContacto(tipo: 'origen' | 'destino') {
        // return (control: AbstractControl) => {
        //   let resultado = { contactoInvalido: true };
        //   if (!this.forma) {
        //     resultado = null;
        //   }
        //   else if (tipo == 'origen') {
        //     let origenSeleccionado: boolean = this.forma.get('esOrigen').value;
        //     if (!origenSeleccionado || control.value) {
        //       resultado = null;
        //     }
        //   }
        //   else if (tipo == 'destino') {
        //     let destinoSeleccionado: boolean = this.forma.get('esDestino').value;
        //     if (!destinoSeleccionado || control.value) {
        //       resultado = null;
        //     }
        //   }
        //   return resultado;
        // };
        if (tipo === 'origen') {
            this.f['contactoOrigen'].setValidators;
            this.f['contactoDestino'].clearValidators;
        } else if (tipo === 'destino') {
            this.f['contactoOrigen'].clearValidators;
            this.f['contactoDestino'].setValidators;
        }
    }

    irA(): void {
        let url: string = this.forma.get('ubicacion').value;
        url =
            url.toUpperCase().includes('HTTP://') ||
            url.toUpperCase().includes('HTTPS://')
                ? url
                : 'http://' + url;
        window.open(url, '_blank');
    }

    abrirCatalogo(tipo: String): void {
        if (tipo === 'Ciudades') {
            this.ventanaCatalogo
                .open(FormaCiudadComponent, {
                    data: {},
                    panelClass: 'form-container',
                })
                .afterClosed()
                .subscribe(() => {
                    this.ctx.ciudad
                        .obtenerEstadosCiudad(this.estadoSeleccionado)
                        .subscribe((ciudades) => {
                            this.ciudades.data = ciudades;
                        });
                });
        }
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
    }
}

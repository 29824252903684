import { Injectable } from '@angular/core';
import { Autenticacion } from './autenticacion.service';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { ServicioAlerta } from '../utilerias/alerta.service';
import { TiposUsuario } from '../entidades/tipos-usuario';

@Injectable({
    providedIn: 'root',
})
export class GuardiaAcceso implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;

    constructor(private autenticacion: Autenticacion) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const url: string = state.url;

        const autorizado = this.autenticacion.ingreso;
        if (!autorizado) {
            this.autenticacion.rutaRedireccion = url;
            window.location.href = '/Login';
            return false;
        }
        return true;
    }
}

@Injectable({
    providedIn: 'root',
})
export class GuardiaPerfil implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;

    constructor(
        private autenticacion: Autenticacion,
        private router: Router,
        private alerta: ServicioAlerta
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        let puedePasar = false;
        const tipoPerfil: TiposUsuario = next.data['perfil'];
        puedePasar = this.autenticacion.puedePasar(tipoPerfil);

        if (this.router.url === '/_' && !puedePasar) {
            this.router.navigateByUrl('');
        } else if (!puedePasar) {
            if (tipoPerfil === TiposUsuario.Cliente) {
                this.alerta.mostrarAdvertencia(
                    'Seleccione un perfil de cliente'
                );
            } else if (tipoPerfil === TiposUsuario.Proveedor) {
                this.alerta.mostrarAdvertencia(
                    'Seleccione un perfil de proveedor'
                );
            } else {
                this.alerta.mostrarAdvertencia(
                    'Debe seleccionar un perfil cliente o proveedor'
                );
            }
        }
        return puedePasar;
    }
}

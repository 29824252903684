import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoOpcion } from '../entidades/tipoOpcion';

export class RepositorioTipoOpcion extends RepositorioCatalogo<TipoOpcion>{
    constructor(http: HttpClient){
        super(http, 'TipoOpcion');
    }

    tipoOpcionTodos(): Observable<TipoOpcion[]>{
        let route = `${this.ruta}/TipoOpcionTodos`;
        return this.clienteHttp.get(route)
            .pipe(map((data: TipoOpcion[]) => {
                return data;
            }))
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { Contexto } from 'src/app/api/contexto.service';
import { Pareto } from 'src/app/entidades/pareto';
import { MatTableDataSource } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';

@Component({
  selector: 'app-tabla-pareto',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.scss']
})
@AutoUnsubscribe()
export class TablaParetoComponent implements OnInit {
  @Input() pareto: Observable<Pareto[]>;
  pareto$: Subscription;
  fuenteDatos: MatTableDataSource<Pareto> = new MatTableDataSource([]);

  columnasMostradas = [
    'nombreCliente',
    'ventas',
    'ranking',
    'ventaAcumulado',
    'valorPorcentaje',
    'porcentajeAcumulado'
  ];

  constructor() {}

  ngOnInit() {
    this.pareto$ = this.pareto.subscribe(pareto => {
      this.fuenteDatos = new MatTableDataSource(pareto);
    });
  }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { GastoOperacion } from '../entidades/gasto-operacion';
import { Observable } from 'rxjs';
import { GastoFactura } from '../entidades/gasto-factura';

export class RepositorioGastoOperacion extends RepositorioCatalogo<GastoOperacion> {
    constructor(http: HttpClient) {
        super(http, 'GastoOperacion');
    }

    prorratearCostos(anio: number, mes: number, clienteId : number): Observable<void> {
        const ruta = `${this.ruta}/ProrratearGastoOperaciones/${anio}/${mes}/${clienteId}`;
        return this.clienteHttp.post<void>(ruta, null);
    }

    obtenerProrrateo(anio: number, mes: number, clienteId: number): Observable<GastoFactura[]> {
        const ruta = `${this.ruta}/ObtenerProrrateo/${anio}/${mes}/${clienteId}`;
        return this.clienteHttp.get<GastoFactura[]>(ruta);
    }

    obtenerProrrateoExcel(anio: number, mes: number, clienteId: number): Observable<Blob> {
        const ruta = `${this.ruta}/ObtenerProrrateoExcel/${anio}/${mes}/${clienteId}`;
        return this.clienteHttp.get(ruta, {
            responseType: 'blob',
        });
    }
}

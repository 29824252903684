import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaEstadosProyectosComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { EstadosProyectos } from 'src/app/entidades/estado-proyecto';

@Component({
  selector: 'catalogo-proyecto',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoEstadosProyectosComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<EstadosProyectos> = new MatTableDataSource([]);
  columnasMostradas = ['clave', 'descripcion', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  subsEstadosProyectos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarEstados();
  }

  cargarEstados(): void {
    this.cargando = true;
    this.ctx.estadosProyecto
      .obtenerTodos()
      .subscribe(EstadosProyectos => {
        this.fuenteDatos = new MatTableDataSource(EstadosProyectos);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaEstadosProyectosComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarEstados();
      });
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx
      .estadosProyecto.eliminar(id.toString())
      .subscribe(() => {
        this.alerta.mostrarError('Registro eliminado');
        this.cargarEstados();
      },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subsEstadosProyectos) { this.subsEstadosProyectos.unsubscribe(); }
    if (this.subsActivar) { this.subsActivar.unsubscribe(); }
    if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
  }
}
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Empleado } from 'src/app/entidades/empleado';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaEmpleadoComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  empleado: Empleado;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  color = '#101010';
  jefeId = 0;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  jefeArea: MatTableDataSource<Empleado> = new MatTableDataSource([]);
  get f() {
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaEmpleadoComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {
    if (id > 0) {
      this.idSeleccionado = id;
      this.ctx.empleados.obtener(id).subscribe(a => {
        this.empleado = a;
        Object.assign(this.forma.value, this.empleado);
        this.forma.reset(this.forma.value);
        this.color = this.empleado.color;
        this.jefeId = this.empleado.jefeId;
      });
    } else {
      this.idSeleccionado = 0;
      this.empleado = new Empleado();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.required],
      clave: ['', Validators.required],
      rfc: ['', [Validators.required, Validators.pattern('^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$')]],
      nombre: ['', Validators.required],
      telefono: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      color: ['', Validators.nullValidator],
      contrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]],
      confirmacionContrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]],
      esJefe: [false, Validators.nullValidator],
      jefeId: [0, Validators.required],
    });

    this.ctx.empleados.obtenerJefes().subscribe(jefes =>{
      this.jefeArea.data = jefes;
    });
  }

  limpiar(): void {
    this.forma.reset();
    this.color = '#101010';
    this.f['color'].setValue("#101010");
    this.f['id'].setValue(0);
  }

  guardar(): void {
    if (this.idSeleccionado > 0) {
      if (this.forma.controls['contrasena'].value !== null && this.forma.controls['contrasena'].value !== '') {// && this.forma.get('contrasena').value !==""){
        this.forma.get('contrasena').setValidators([Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]);
        this.forma.get('contrasena').updateValueAndValidity();
        this.forma.get('confirmacionContrasena').setValidators([Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]);
        this.forma.get('confirmacionContrasena').updateValueAndValidity();
      }
      else {
        this.forma.get('contrasena').clearValidators();
        this.forma.get('contrasena').updateValueAndValidity();
        this.forma.get('confirmacionContrasena').clearValidators();
        this.forma.get('confirmacionContrasena').updateValueAndValidity();
      }
    }

    if (this.forma.valid) {

      if (this.empleado.contrasena != this.empleado.confirmacionContrasena) {
        this.alerta.mostrarError('¡Error, la contraseña no coincide!');
        this.cargando = false;
        return;
      }

      this.cargando = true;
      Object.assign(this.empleado, this.forma.value);
      if (this.idSeleccionado > 0) {
        this.empleado.contrasena = "SinCambioContraseña";
        this.empleado.confirmacionContrasena = "SinCambioContraseña";
      }
      this.empleado.color = this.color;
      let observable: Observable<Empleado>;
      let mensaje = 'Empleado guardada.';
      let guardar = true;
      if (this.idSeleccionado === 0) {
        observable = this.ctx.empleados.guardar(this.empleado);
      } else {
        guardar = false;
        mensaje = 'Empleado actualizada';
        observable = this.ctx.empleados.actualizar(
          this.idSeleccionado.toString(),
          this.empleado
        );
      }

      this.subsGuardar = observable.subscribe(
        () => {
          this.guardado.emit();
          this.alerta.mostrarExito(mensaje);
          this.cargando = false;
          this.limpiar();
          if (!guardar) {
            this.ventana.close();
          }
        },
        error => {
          if (error.status == 515) {//error == "Clave Duplicada") {
            this.alerta.mostrarError('¡Error Clave Duplicada!');
            this.cargando = false;
          }
          else if (error.error == "YaExiste") {
            console.log(error);
            this.alerta.mostrarAdvertencia('¡Ya se encuentra un empleado asignado con ese correo o RFC!');
            this.cargando = false;
            return;
          } else {
            this.alerta.mostrarError('¡Error al guardar!');
            this.cargando = false;
          }
        });

    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cambiarEsJefe(activo): void {
    if (!activo) {
      this.forma.get('categoriaEmpresaId').setValue(null);
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  verContrasena(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  verContrasenaConfirmacion(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

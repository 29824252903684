import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';

import { FormaCorreoComponent } from '../forma/forma.component';
import { Contexto } from '../../../api/contexto.service';
import { ServicioAlerta } from '../../../utilerias/alerta.service';
import { Correo } from '../../../entidades/correos';

@Component({
    selector: 'catalogo-tipoPersona',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss']
})

export class CatalogoCorreoComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    fuenteDatos: MatTableDataSource<Correo> = new MatTableDataSource([]);
    columnasMostradas = ['direccion', 'contrasena', 'servidor', 'puerto', 'usaSSL', 'activo', 'opciones'];
    cargando = true;

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
            return 50;
        }
        return 100;
    }

    subsTipoPersonas: Subscription;
    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

    ngOnInit() {
        this.cargarCorreos();
    }

    cargarCorreos(): void {
        this.cargando = true;
        this.ctx.correos
            .obtenerTodos()
            .subscribe(tipop => {
                this.fuenteDatos = new MatTableDataSource(tipop);
                this.fuenteDatos.paginator = this.paginador;
                this.fuenteDatos.sort = this.ordenador;
                this.cargando = false;
            });
    }


    abrirForma(id?: number): void {
        let dato = id ? id : null;
        let forma = this.ventana.open(FormaCorreoComponent,
            { data: dato, panelClass: 'form-container' });

        this.subsGuardar = forma.componentInstance.guardado
            .subscribe(() => {
                this.cargarCorreos();
            });
    }


    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    cambiarEstado(activo: boolean, id: number): void {
        if (activo) {
            this.activar(id);
        }
        else {
            this.desactivar(id);
        }

    }

    cambiarEstadoSSL(activo: boolean, id: number): void {
        if (activo) {
            this.activarSSL(id);
        }
        else {
            this.desactivarSSL(id);
        }

    }

    activar(id: number): void {
        this.subsActivar = this.ctx
            .correos.activar(id)
            .subscribe(() => {
                this.cargarCorreos();
            },
                error => {
                    this.alerta.mostrarError('Error al activar');
                }
            );

    }

    desactivar(id: number): void {
        this.subsActivar = this.ctx
            .correos.desactivar(id)
            .subscribe(() => { },
                error => {
                    this.alerta.mostrarError('Error al desactivar');
                }
            );
    }

    activarSSL(id: number): void {
        this.subsActivar = this.ctx
            .correos.activarSSL(id)
            .subscribe(() => { },
                error => {
                    this.alerta.mostrarError('Error al activar');
                }
            );
    }

    desactivarSSL(id: number): void {
        this.subsActivar = this.ctx
            .correos.desactivarSSL(id)
            .subscribe(() => { },
                error => {
                    this.alerta.mostrarError('Error al desactivar');
                }
            );

    }



    eliminar(id: number): void {
        this.subsActivar = this.ctx
            .correos.eliminar(id.toString())
            .subscribe(() => {
                this.alerta.mostrarError('Registro eliminado');
                this.cargarCorreos();
            },
                error => {
                    this.alerta.mostrarError('Error al eliminar');
                }
            );
    }

    ngOnDestroy(): void {
        if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
        if (this.subsTipoPersonas) { this.subsTipoPersonas.unsubscribe(); }
        if (this.subsActivar) { this.subsActivar.unsubscribe(); }
        if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
        if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
    }
}
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
export interface Archivo {
  id: number;
  guia: string;
  nombre: string;
  extension: string;
  datos: Blob;
  archivoBase64: string;
}
@Component({
  selector: 'app-control-archivo',
  templateUrl: './control-archivo.component.html',
  styleUrls: ['./control-archivo.component.scss']
})
export class ControlArchivosComponent implements OnInit {
  @Output() verArchivoSeleccionado = new EventEmitter<Archivo>();
  @Output() archivosAgregados = new EventEmitter<File[]>();
  @Output() archivoNuevoEliminado = new EventEmitter<number>();
  @Output() archivoExistenteEliminado = new EventEmitter<number>();
  @Input() editable = true;
  zonaActiva = false;
  lector = new FileReader();
  lectorListo = false;

  private _archivosNuevos: File[] = [];
  public get archivosNuevos(): File[] {
    return this._archivosNuevos;
  }

  private _archivosExistentes: Archivo[] = [];
  public get archivosExistentes(): Archivo[] {
    return this._archivosExistentes;
  }
  public set archivosExistentes(v: Archivo[]) {
    this._archivosExistentes = v;
  }

  controlArchivo = new FormControl();

  constructor() {
    this.lector.onloadend = () => {
      this.lectorListo = true;
    };
  }

  ngOnInit() {}

  public async leerArchivoComoURL(archivo: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const lector = new FileReader();
      lector.onload = () => resolve(lector.result.toString());
      lector.onerror = () =>
        reject(new Error(`Error leyendo: ${archivo.name}`));
      lector.readAsDataURL(archivo);
    });
  }

  public async obtenerArchivosNuevos(): Promise<Archivo[]> {
    const arreglo: Archivo[] = [];
    const promesasArchivo = await this.archivosNuevos.map(async (a) => {
      const dataUrl = await this.leerArchivoComoURL(a);
      const nombre = a.name.substring(0, a.name.lastIndexOf('.'));
      const extension = a.name.substring(
        a.name.lastIndexOf('.'),
        a.name.length
      );

      const archivo: Archivo = {
        id: 0,
        guia: '1',
        nombre: nombre,
        extension: extension,
        datos: null,
        archivoBase64: dataUrl
      };
      return archivo;
    });

    for (let i = 0; i < promesasArchivo.length; i++) {
      const promesaArchivo = promesasArchivo[i];
      const archivo = await promesaArchivo;
      arreglo.push(archivo);
    }

    return arreglo;
  }

  public async obtenerArchivoNuevo(indice: number): Promise<Archivo> {
    const archivo = this.archivosNuevos[indice];
    const dataUrl = await this.leerArchivoComoURL(archivo);
    const nombre = archivo.name.substring(0, archivo.name.lastIndexOf('.'));
    const extension = archivo.name.substring(
      archivo.name.lastIndexOf('.'),
      archivo.name.length
    );

    const resultado: Archivo = {
      id: 0,
      guia: '',
      nombre: nombre,
      extension: extension,
      datos: null,
      archivoBase64: dataUrl
    };
    return resultado;
  }

  archivosSeleccionados(listaArchivos: FileList): void {
    for (let i = 0; i < listaArchivos.length; i++) {
      this._archivosNuevos.push(listaArchivos.item(i));
    }
    this.archivosAgregados.emit(Array.from(listaArchivos));
  }

  archivosEncima($event: boolean): void {
    this.zonaActiva = $event;
  }

  agregarArchivo(evento: Event) {
    const inputArchivo = <HTMLInputElement>evento.target;
    if (inputArchivo.files) {
      for (let i = 0; i < inputArchivo.files.length; i++) {
        console.log(inputArchivo.files.item(i));
        this._archivosNuevos.push(inputArchivo.files.item(i));
      }
      this.archivosAgregados.emit(Array.from(inputArchivo.files));
    }
  }

  removerArchivo(indice: number): void {
    this._archivosNuevos.splice(indice, 1);
    this.archivoNuevoEliminado.emit(indice);
  }

  removerArchivoExistente(indice: number): void {
    this._archivosExistentes.splice(indice, 1);
    this.archivoExistenteEliminado.emit(indice);
  }

  verArchivo(archivo: Archivo): void {
    this.verArchivoSeleccionado.emit(archivo);
  }

  limpiar(): void {
    this._archivosNuevos = [];
    this.archivosExistentes = [];
    this.controlArchivo.reset();
  }
}

import { SubservicioCompra } from './subservicio-compra';
import { OrigenServicioCompra } from './origen-servicio-compra';
import { DestinoServicioCompra } from './destino-servicio-compra';
import { ArchivoServicioCompra } from './archivo-servicio-compra';
import { DatosEquipo } from './datos-equipo';

export class ServicioCompra {
  id: number;
  linea: string;
  descripcion: string;
  fechaSolicitud: Date | string;
  fechaRequerida: Date | string;
  fechaComprometida: Date | string = new Date;
  contactoOrigen: string;
  contactoDestino: string;
  peso: number;
  largo: number;
  ancho: number;
  alto: number;
  importe: number;
  importeCambio: number;
  unidadId: number;
  unidadAbreviatura: string;
  tipoCargaId: number;
  tipoCargaDescripcion: string;
  medidaLegalId: number;
  medidaLegalDescripcion: string;
  estadoId: number;
  estadoDescripcion: string;
  compraId: number;
  aduanaId: number;
  fronteraId: number;
  descuento: number;
  total: number;
  origenId: number;
  origenDireccion: string;
  destinoId: number;
  destinoDireccion: string;
  trasladoId: number;
  retencionId: number;
  montoTraslado: number;
  montoRetencion: number;
  trasladoDescripcion: string;
  aplicaRetencion: boolean;
  origenes: OrigenServicioCompra[] = [];
  destinos: DestinoServicioCompra[] = [];
  archivos: ArchivoServicioCompra[] = [];
  subservicios: SubservicioCompra[] = [];
  bollc: '';
  camionId: number;
  trailerId: number;
  fullId: number;
  noEconomicoCamion: string;
  noEconomicoPlataforma: string;
  noEconomicoFull: string;
  placasCamion: string;
  placasPlataforma: string;
  placasFull: string;
  operadorId: number;
  equipoId: number;
}

import { Permiso } from "../entidades/permiso";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class RepositorioPermiso extends RepositorioCatalogo<Permiso> {

    constructor(http: HttpClient) {
        super(http, "Permisos");
    }

    ObtenerPermisosConDetalle(): Observable<Permiso[]> {
        let ruta = this.ruta + "/ObtenerPermisoConDetalle";
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Permiso[]) => {
                return data;
            }));
    }

}

import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Rastreo } from '../entidades/rastreo';
import { FiltroRastreo } from '../entidades/filtro-rastreo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ubicacion } from '../entidades/ubicacion';
import { EstatusServicios } from '../entidades/estatus-servicios';

export class RepositorioRastreo extends RepositorioCatalogo<Rastreo> {

  constructor(http: HttpClient) {
    super(http, "Rastreo");
  }

  filtrarRastreo(rastreo: FiltroRastreo): Observable<Rastreo> {
    let ruta = `${this.ruta}/FiltroRastreo`;
    return this.clienteHttp.post(ruta, rastreo)
      .pipe(map((r: Rastreo) => r));
  }


  obtenerFotoOperador(operadorId: number): Observable<Blob> {
    let ruta = `${this.ruta}/ObtenerFotoOperador/${operadorId}`;
    return this.clienteHttp.get(ruta, { responseType: 'blob' })
      .pipe(map((img: Blob) => img));
  }



  obtenerUltimasUbicaciones(): Observable<Ubicacion[]> {
    let ruta = `${this.ruta}/ObtenerUltimasUbicaciones`;
    return this.clienteHttp.get(ruta)
      .pipe(map((r: Ubicacion[]) => r));
  }


  obtenerUltimasUbicacionesPorRastreo(id: number): Observable<Ubicacion> {
    const ruta = `${this.ruta}/ObtenerUltimasUbicacionesPorRastreo/${id}`;
    return this.clienteHttp.get(ruta).pipe(
      map((ubi: Ubicacion) => {
        return ubi;
      })
    );
  }

  descargarEvidencia(id: number): Observable<Blob> {
    let ruta = `${this.ruta}/Evidencias/Descargar/${id}`;
    return this.clienteHttp.get(ruta, { responseType: 'blob' })
      .pipe(map((img: Blob) => img));

  }


  obtenerEstatusServicios(): Observable<EstatusServicios []> {
    const ruta = `${this.ruta}/ObtenerServicios`;
    return this.clienteHttp.get(ruta).pipe(
      map((ubi: EstatusServicios []) => {
        return ubi;
      })
    );
  }
}


// asdasd

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RepositorioReporteSeguimiento } from "./repositori-reporte-seguimiento";



@Injectable({
    providedIn: 'root',
})
export class ContextoPortalOnMonitor {


  private repoReporteSeguimiento: RepositorioReporteSeguimiento;
  public get ReporteSeguimientos(): RepositorioReporteSeguimiento {
    return this.repoReporteSeguimiento;
  }
    constructor(private cliente: HttpClient) {
      this.repoReporteSeguimiento = new RepositorioReporteSeguimiento(cliente);
    }
}

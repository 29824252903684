import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Venta, SolicitudVenta } from '../entidades/venta';
import { Compra } from '../entidades/compra';
import { DocumentoDatosEquipo } from '../entidades/documento-datos-equipo';
import { DocumentoDuplicar } from '../entidades/documento-duplicar';
import { AnalisisVentas } from '../entidades/analisis-ventas';
import { VentasEmpleado } from '../entidades/ventas-empleado';
import { VentasCanceladas } from '../entidades/ventas-canceladas';
import { EstatusVentas } from '../entidades/estatus-ventas';
import { Documento } from '../entidades/documento';
import { Socio } from '../entidades/socio';

export class RepositorioVenta extends RepositorioCatalogo<Venta> {
  constructor(http: HttpClient) {
    super(http, 'Ventas');
  }



  guardarDocumentoVenta(documento: Documento): Observable<Documento> {
    const ruta = `${this.ruta}/GuardarVenta`;
    return this.clienteHttp.post(ruta, documento).pipe(
      map((documento: Documento) => {
        return documento;
      })
    );
  }

  ////////////////////////////////////////////////////////////LO VIEJOOOOOO//////////////////////////////////////////////////////////////////////////////////////////
  obtenerVentaCompleta(idSolicitud: number): Observable<SolicitudVenta> {
    const ruta = `${this.ruta}/ObtenerVentaCompleta/${idSolicitud}`;
    console.log('ruta-->', ruta);
    return this.clienteHttp.get(ruta).pipe(
      map((solicitud: SolicitudVenta) => {
        return solicitud;
      })
    );
  }

  obtenerVentaDocumento(idDocumento: number): Observable<Documento> {
    const ruta = `${this.ruta}/ObtenerVentaDocumento/${idDocumento}`;
    console.log('ruta-->', ruta);
    return this.clienteHttp.get<Documento>(ruta);
  }

  guardarVenta(solicitudes: FormData): Observable<Venta[]> {
    const ruta = `${this.ruta}/GuardarVenta`;
    return this.clienteHttp.post(ruta, solicitudes).pipe(
      map((ventas: Venta[]) => {
        return ventas;
      })
    );
  }

  actualizarVenta(solicitudes: FormData): Observable<Venta[]> {
    const ruta = `${this.ruta}/ActualizarVenta`;
    return this.clienteHttp.post(ruta, solicitudes).pipe(
      map((ventas: Venta[]) => {
        return ventas;
      })
    );
  }

  descargarArchivoCliente(idArhivo: number): Observable<Blob> {
    const ruta = `${this.ruta}/ArchivosCliente/Descargar/${idArhivo}`;
    return this.clienteHttp.get(ruta, {
      responseType: 'blob'
    }).pipe(map((pdf: Blob) => pdf));
  }
  crearCompra(idVenta: number): Observable<Compra> {
    const ruta = `${this.ruta}/CrearCompra/${idVenta}`;
    return this.clienteHttp.get(ruta).pipe(
      map((compra: Compra) => {
        return compra;
      })
    );
  }

  enviarVentaCliente(idVenta: number): Observable<boolean> {
    const ruta = `${this.ruta}/EnviarVentaCliente/${idVenta}`;
    return this.clienteHttp.put(ruta, null).pipe(
      map((enviada: boolean) => {
        return enviada;
      })
    );
  }

  autorizarVentaCliente(idVenta: number): Observable<boolean> {
    const ruta = `${this.ruta}/AutorizarVenta/${idVenta}`;
    return this.clienteHttp.put(ruta, null).pipe(
      map((enviada: boolean) => {
        return enviada;
      })
    );
  }

  generarFolio(): Observable<string> {
    const ruta = `${this.ruta}/GenerarFolioVenta`;
    return this.clienteHttp.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  ObtenerVentasPorEnviar(): Observable<Venta[]> {
    const ruta = `${this.ruta}/VentasPorEnviar/`;
    return this.clienteHttp.get(ruta).pipe(
      map((ventas: Venta[]) => {
        return ventas;
      })
    );
  }

  GuardarDatosEquipo(
    documentoDatosEquipo: DocumentoDatosEquipo
  ): Observable<Venta> {
    const ruta = `${this.ruta}/DatosEquipo/`;
    return this.clienteHttp.put<Venta>(ruta, documentoDatosEquipo);
  }

  cancelar(idVenta: number, idMotivo: number): Observable<void> {
    const ruta = `${this.ruta}/CancelarDocumento/${idVenta}/${idMotivo}`;
    return this.clienteHttp.get<void>(ruta);
  }

  duplicar(documento: DocumentoDuplicar): Observable<Venta> {
    const ruta = `${this.ruta}/Duplicar`;
    return this.clienteHttp.post<Venta>(ruta, documento);
  }

  obtenerAnalisisVentas(): Observable<AnalisisVentas[]> {
    const ruta = `${this.ruta}/ObtenerAnalisisVentas`;
    return this.clienteHttp.get<AnalisisVentas[]>(ruta);
  }

  obtenerFlujo(proyectoId: number): Observable<Documento[]> {
    const ruta = `${this.ruta}/ObtenerFlujo/${proyectoId}`;
    return this.clienteHttp.get<Documento[]>(ruta);
  }

  obtenerVentasEmpleado(): Observable<VentasEmpleado[]> {
    const ruta = `${this.ruta}/ObtenerVentasEmpleado`;
    return this.clienteHttp.get<VentasEmpleado[]>(ruta);
  }

  obtenerVentasCanceladas(): Observable<VentasCanceladas[]> {
    const ruta = `${this.ruta}/ObtenerVentasCanceladas`;
    return this.clienteHttp.get<VentasCanceladas[]>(ruta);
  }

  obtenerEstatusVentas(): Observable<EstatusVentas> {
    const ruta = `${this.ruta}/ObtenerEstatusVentas`;
    return this.clienteHttp.get<EstatusVentas>(ruta);
  }
}

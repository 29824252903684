import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    MatPaginator,
    MatSort,
    MatTableDataSource,
    MatDialog,
} from '@angular/material';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { Documento } from 'src/app/entidades/documento';
import { ParametrosDocumentoFacturaService } from '../../forma/parametros.service';
import { DocumentoFacturaComponent } from '../../forma/documento-factura.component';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@Component({
    selector: 'app-compras-por-enviar',
    templateUrl: './compras-por-enviar.component.html',
    styleUrls: ['./compras-por-enviar.component.scss'],
})
export class ComprasPorEnviarComponent implements OnInit {
    @Input() set compras(v: Documento[]) {
        this.fuenteDatosCompra = new MatTableDataSource(v);
        this.fuenteDatosCompra.paginator = this.paginador;
        this.fuenteDatosCompra.sort = this.ordenador;
        this.cargando = false;
    }
    banderaGeneral = false;
    renglon = 0;
    @Input() banderaCompra = true;

    @Output() facturaAbierta = new EventEmitter();

    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    fuenteDatosCompra: MatTableDataSource<Documento> = new MatTableDataSource(
        []
    );
    columnasMostradas = [
        'proyectoFolio',
        'folioRe',
        'folio',
        'folioSv',
        'fechaDocumento',
        'proveedorNombreCorto',
        'subtotal',
        'monedaNombre',
        'opciones',
    ];
    cargando = true;

    public get diametro(): number {
        if (
            !this.fuenteDatosCompra ||
            this.fuenteDatosCompra.data.length === 0
        ) {
            return 50;
        }
        return 100;
    }

    constructor(
        private ventana: MatDialog,
        private overlay: ServicioOverlayCargando,
        private parametros: ParametrosDocumentoFacturaService,
        private alerta: ServicioAlerta
    ) { }

    ngOnInit() { }

    llenarDatos(documento: Documento): void {
        if (documento.autorizado) {
            this.overlay.abrirCargando();
            this.banderaGeneral = true;
            this.renglon = documento.id;
            this.parametros
                .cargarParametros(documento, 'compra')
                .toPromise()
                .then((compra) => {
                    this.abrirFactura();
                    this.overlay.cerrarCargando();
                });
        } else {
            this.alerta.mostrarError(
                'Este documento no tiene autorización para enviarse'
            );
        }
    }

    abrirFactura(): void {
        this.ventana
            .open(DocumentoFacturaComponent, {
                panelClass: 'form-container-large',
            })
            .afterClosed()
            .toPromise()
            .then(() => {
                this.facturaAbierta.emit();
                this.banderaGeneral = this.banderaCompra;
            });
    }

    filtrar(filterValue: string) {
        this.fuenteDatosCompra.filter = filterValue.trim().toLowerCase();
    }
}

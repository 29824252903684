import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog, MatSort } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
    selector: 'app-rentabilidad-mes',
    templateUrl: './cierre-despacho-operativo-mes.component.html',
    styleUrls: ['./cierre-despacho-operativo-mes.component.scss']
})
export class CierreDespachoOperativoMesMesComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['cliente', 'numServicios', 'enviadosTiempo', 'fueraTiempo', 'diasDesfase', 'porcentaFueraTiempo'];
    diasDeTolerancia: number;

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], objetivo: number, messNum: number, yearF: number, diasTolerancia: number },
        public ventana: MatDialog) {
    }

    nomMes: string;

    ngOnInit(): void {
        this.diasDeTolerancia = this.object.diasTolerancia;
        const groupMonths = _.chain(this.object.rastreos)
                .groupBy(c => moment(c.fechaEntrega).format('MM'))
                .map((x, y) => {
                    return {
                        monthNumber: y,
                        monthName: `${moment(y, 'MM').locale('Es-MX').format('MMMM')}`,
                        data: x
                    }
                }).value();


        const selectedMonth = groupMonths.filter(c => c.monthNumber == String(this.object.messNum));

        let selectedMonthData = [];

        if (selectedMonth.length > 0) {
            selectedMonthData = selectedMonth[0].data;
        }

        const months = _.chain(selectedMonthData)
            .groupBy(c => c.cliente)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    cli: x[0].cliente,
                    data: x
                }
            })
            .sortBy(c => Number(c.cli)).value();
        
        const nombreMes = moment(this.object.messNum, 'MM').locale('es-mx').format('MMMM');    
            
        // const filtroAño = this.object.rastreos.filter(c => moment(c.fechaCompromiso).format('YYYY') == String(this.object.yearF));
        // const filtroMes = filtroAño.filter(c => Number(moment(c.fechaCompromiso).format('MM')) == this.object.messNum);
        // const months = _.chain(filtroMes)
        //     .groupBy(c => c.cliente)
        //     .map((x, y) => {
        //         return {
        //             monthNumber: y,
        //             cli: x[0].cliente,
        //             data: x
        //         }
        //     })
        //     .sortBy(c => Number(c.cli)).value();

        const monthsModel = _.chain(months).map((month, key) => {
            let desf = month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia)
            let diasDif = desf.reduce((total, e) => {
                let diff = moment(e.fechaEnvioSAP).diff(e.fechaEntrega, 'days');
                if (diff > this.diasDeTolerancia) {
                    return total + diff;
                }
                return total;
            }, 0);
            
            return {
                'cliente': month.cli,
                'numServicios': month.data.length,
                'enviadosTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') <= this.diasDeTolerancia).length,
                'fueraTiempo': month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length,
                'diasDesfase': diasDif,
                'porcentaFueraTiempo': `${((month.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length / month.data.length) * 100).toFixed(2)} %`,

            };
        }).value();

        this.nomMes = nombreMes;

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;

    }


}
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { Contexto } from '../../../api/contexto.service';
import { ServicioAlerta } from '../../../utilerias/alerta.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Usuario } from '../../../entidades/usuario';
import { FormaUsuarioComponent } from '../forma-nuevo/forma.component';
import { Autenticacion } from '../../../autenticacion/autenticacion.service';

@Component({
    selector: 'catalogo-usuarios',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss']
})

export class CatalogoUsuarioComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    formaTabla: FormGroup;
    fuenteDatos: MatTableDataSource<Usuario> = new MatTableDataSource([]);
    columnasMostradas = ['nombre', 'email', 'activo', 'autorizado', 'opciones'];
    cargando = true;

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
            return 50;
        }
        return 100;
    }

    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta, private autenticacion: Autenticacion, private formBuilder: FormBuilder) { }

    ngOnInit() {

        this.formaTabla = this.formBuilder.group(
            {
                buscar: ['']
            }
        );
        this.cargarUsuarios();
    }

    cargarUsuarios(): void {
        if (this.autenticacion.perfil != undefined) {
                this.cargando = true;
                this.ctx.usuarios
                    .obtenerUsuariosPorSocio(this.autenticacion.perfil.id)
                    .subscribe(tipop => {
                        this.fuenteDatos = new MatTableDataSource(tipop);
                        this.fuenteDatos.paginator = this.paginador;
                        this.fuenteDatos.sort = this.ordenador;
                        this.cargando = false;
                    });
        } else {
            this.cargando = true;
            this.ctx.usuarios
                .obtenerUsuariosAdministradores()
                .subscribe(tipop => {
                    this.fuenteDatos = new MatTableDataSource(tipop);
                    this.fuenteDatos.paginator = this.paginador;
                    this.fuenteDatos.sort = this.ordenador;
                    this.cargando = false;
                });
        }
    }


    abrirForma(id?: number): void {
        let dato = id ? id : null;
        let forma = this.ventana.open(FormaUsuarioComponent,
            { data: dato, panelClass: 'form-container' });

        this.subsGuardar = forma.componentInstance.guardado
            .subscribe(() => {
                this.cargarUsuarios();
            });
    }

    abrirNuevoForma(id?: number): void {
        let dato = id ? id : null;
        let forma = this.ventana.open(FormaUsuarioComponent,
            { data: dato, panelClass: 'form-container' });

        this.subsGuardar = forma.componentInstance.guardado
            .subscribe(() => {
                this.cargarUsuarios();
            });
    }

    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    limpiar(): void {
        this.formaTabla.reset();
        this.cargarUsuarios();
    }
    cambiarEstado(activo: boolean, id: number): void {
        if (activo) {
            this.activar(id);
        }
        else {
            this.desactivar(id);
        }
    }

    cambiarAuto(activo: boolean, id: number): void {
        if (activo) {
            this.autorizar(id);
        }
        else {
            this.desautorizar(id);
        }
    }
    activar(id: number): void {
        this.subsActivar = this.ctx
            .usuarios.activar(id)
            .subscribe(() => { },
                error => {
                    this.alerta.mostrarError('Error al activar');
                }
            );
    }

    desactivar(id: number): void {
        this.subsActivar = this.ctx
            .usuarios.desactivar(id)
            .subscribe(() => { },
                error => {
                    this.alerta.mostrarError('Error al desactivar');
                }
            );
    }


    cambiarAutorizacion(activo: boolean, id: number): void {
        if (activo) {
            this.autorizar(id);
        }
        else {
            this.desautorizar(id);
        }
    }

    autorizar(id: number): void {
        this.subsActivar = this.ctx
            .usuarios.autorizar(id)
            .subscribe(() => {
                this.alerta.mostrarExito("Usuario autorizado");
            },
                error => {
                    this.alerta.mostrarError('Error!');
                }
            );
    }

    desautorizar(id: number): void {
        this.subsActivar = this.ctx
            .usuarios.desautorizar(id)
            .subscribe(() => {
                this.alerta.mostrarExito("Usuario sin autorización");
            },
                error => {
                    this.alerta.mostrarError('Error!');
                }
            );
    }


    eliminar(id: number): void {
        this.subsActivar = this.ctx
            .usuarios.eliminar(id.toString())
            .subscribe(() => {
                this.alerta.mostrarExito('Registro eliminado');
                this.cargarUsuarios();
            },
                error => {
                    this.alerta.mostrarError('Error al eliminar');
                }
            );
    }

    ngOnDestroy(): void {
        if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
        if (this.subsActivar) { this.subsActivar.unsubscribe(); }
        if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
        if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
    }


}


// asdjaskdjkasjdk skj
import {
  trigger,
  animate,
  transition,
  style,
  query,
  state,
  animation,
  animateChild,
  group
} from '@angular/animations';

// export const fadeAnimation = trigger('fadeAnimation', [
//   transition('* => *', [
//     query(':enter', [style({ opacity: 0 })], { optional: true }),

//     query(
//       ':leave',
//       [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))],
//       { optional: true }
//     ),

//     query(
//       ':enter',
//       [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))],
//       { optional: true }
//     )
//   ])
// ]);

export const animacionTexto = trigger('animacionTexto', [
  state('shown', style({ opacity: 1 })),
  state('hidden', style({ opacity: 0 })),
  transition('shown => hidden', animate('300ms')),
  transition('hidden => shown', animate('300ms'))
]);

export const animacionTabla = trigger('detailExpand', [
  state(
    'collapsed, void',
    style({ height: '0px', minHeight: '0', display: 'none' })
  ),
  state('expanded', style({ height: '*' })),
  transition(
    'expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
  ),
  transition(
    'expanded <=> void',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
  )
]);

export const fadeInOut = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0
    })
  ),
  transition('void <=> *', animate(1000))
]);

// export const transAnimation = animation([
//   style({
//     height: '{{ height }}',
//     opacity: '{{ opacity }}',
//     backgroundColor: '{{ backgroundColor }}'
//   }),
//   animate('{{ time }}')
// ]);

// Routable animations
export const animations = trigger('routeAnimations', [
  transition('HomePage <=> AboutPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('300ms ease-out', style({ left: '100%' }))]),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('* <=> FilterPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('200ms ease-out', style({ left: '100%' }))]),
      query(':enter', [animate('300ms ease-out', style({ left: '0%' }))])
    ]),
    query(':enter', animateChild())
  ])
]);

import { Component, OnInit, Inject, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from '../../../utilerias/alerta.service';
import { Contexto } from '../../../api/contexto.service';
import { Rol } from '../../../entidades/rol';
import { Permiso } from '../../../entidades/permiso';
import { startWith, map } from 'rxjs/operators';
import { Socio } from '../../../entidades/socio';
import { RolEspecifico } from '../../../entidades/rol-especifico';
import { TiposUsuario } from '../../../entidades/tipos-usuario';



@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaPermisoComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  rol: Rol = new Rol();
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  permisos: Permiso[] = null;
  rolesEspecificos: RolEspecifico[] = [];
  tipoUsuarios: MatTableDataSource<TiposUsuario> = new MatTableDataSource([]);

  filtroEmpresa = new FormControl();
  subsEmpresa: Subscription;
  sociosFiltrados: Observable<Socio[]>;
  sociosSinFiltrar: Socio[];
  socioId: number;
  verEmpresas: boolean = false;
  socios: Socio[] = [];

  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<Socio> = new MatTableDataSource([]);
  columnasMostradas = ['nombreComercial', 'opciones'];

  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaPermisoComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {


    this.rol.detallesPermiso = [];

    if (id > 0) {
      this.idSeleccionado = id;
      this.cargarTipoUsuarioPorRolId(id);
      this.cargarPermisosPorRol(id);
      this.cargarTipoUsuarios();
      this.cargarEmpresas();

    }
    else {
      this.cargarPermisos();
      this.cargarTipoUsuarios();
      this.cargarEmpresas();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        nombre: ['', Validators.required],
        tipoUsuarioId: [null, Validators.required],
        socioId: [''],
        esEspecifico: [false]
      }
    );

  }

  cargarTipoUsuarioPorRolId(id: number) {
    this.ctx.roles.obtenerTipoUsuarioPorIdRol(id)
      .subscribe(tipo => {
        this.f['tipoUsuarioId'].setValue(tipo);
      });
  }




  cargarTipoUsuarios() {
    this.ctx.tipoUsuario.obtenerTodos()
      .subscribe(tipos => {
        this.tipoUsuarios.data = tipos
      });
  }


  empresaSeleccionada(socio: Socio) {
    let emp = this.socios.find(e => e.id == socio.id);
    if (emp == null) {
      this.socios.push(socio);
      this.fuenteDatos.data = this.socios;
      this.f['socioId'].setValue(0);
      this.filtroEmpresa.setValue('');
    }
  }


  esRolEspecifico(event) {
    if (event.checked) {
      this.verEmpresas = true;
      this.f['esEspecifico'].setValue(true);

    }
    else {
      this.verEmpresas = false;
      this.f['esEspecifico'].setValue(false);
    }
  }

  cargarPermisos(): void {
    this.ctx.permisos.ObtenerPermisosConDetalle()
      .subscribe(permisos => {
        this.permisos = permisos.map(a => {
          a.detallesPermiso = a.detallesPermiso.map(e => {
            let detalle = this.rol.detallesPermiso.find(c => c.id == e.id)
            if (!detalle) {
              e.seleccionado = false;
            }
            else {
              e.seleccionado = true;
            }
            return e;
          });
          return a;
        });
      });
  }


  cargarPermisosPorRol(id: number): void {
    this.ctx.roles.ObtenerRolConDetalleId(id)
      .subscribe(rol => {
        this.rol = rol;
        Object.assign(this.forma.value, this.rol);
        this.forma.reset(this.forma.value);
        this.cargarPermisos();
        if (this.rol.esEspecifico) {
          this.verEmpresas = true;
          this.f['esEspecifico'].setValue(true);
        }
        if (this.idSeleccionado > 0) {
          this.ctx.roles.obtenerRolEspecificoEmpresa(this.idSeleccionado)
            .subscribe(tipos => {
              this.rolesEspecificos = tipos;
              this.socios.length = 0;
              this.rolesEspecificos.forEach(element => {
                let emp = new Socio();
                emp.id = element.socioId,
                  emp.nombreComercial = element.nombreComercial;
                this.socios.push(emp);
              });
              this.fuenteDatos.data = this.socios;
            });
        }
      });
  }


  cargarEmpresas() {
    this.subsEmpresa = this.ctx.socios.obtenerSinAdminSociosActivas()
      .subscribe(empresas => {
        this.sociosSinFiltrar = empresas;

        this.sociosFiltrados = this.filtroEmpresa.valueChanges
          .pipe(
            startWith<string | Socio>(''),
            map(empresa => typeof empresa === 'string' ? empresa : empresa == null ? '' : empresa.nombreComercial),
            map(empresa => this.filtrar(empresa))

          );
      });
  }

  campoMostrar(empresa: Socio) {
    return empresa ? empresa.nombreComercial : '';
  }

  private filtrar(nombre: string): Socio[] {
    const valorFiltro = nombre.toLowerCase();
    let empresas = this.sociosSinFiltrar.filter(empresas => empresas.nombreComercial.toLowerCase().indexOf(valorFiltro) === 0);

    return empresas;
  }


  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    let detalle = this.permisos.map(a => a.detallesPermiso).reduce((a, b) => a.concat(b)).filter(e => e.seleccionado);

    if (this.forma.valid) {
      this.cargando = true;

      this.rol.detallesPermiso = [];
      this.rol.rolesEspecifico = [];
      this.socioId = null;

      Object.assign(this.rol, this.forma.value);
      Object.assign(this.rol.detallesPermiso, detalle);
      if (this.rol.esEspecifico) {

        if (this.socios.length == 0) {
          this.alerta.mostrarAdvertencia('¡Seleccione al menos una empresa!');
          this.cargando = false;
          return;
        }
        this.rol.socios = this.socios;
        // Object.assign(this.rol.empresas, this.empresas);
      }

      let observable: Observable<Rol>;
      let mensaje = 'Rol guardado.';
      let guardar = true;
      if (this.idSeleccionado == 0) {
        observable = this.ctx.roles.guardar(this.rol);
      } else {
        guardar = false;
        mensaje = 'Rol actualizado';
        observable = this.ctx.roles.actualizar(this.idSeleccionado.toString(), this.rol);
      }

      this.subsGuardar = observable.subscribe(() => {
        this.guardado.emit();
        this.alerta.mostrarExito(mensaje);
        this.cargando = false;
        this.limpiar();
        if (!guardar) {
          this.ventana.close();
        }
      },
        error => {
          if (error.error == "YaExiste") {
            console.log(error);
            this.alerta.mostrarAdvertencia('¡El registro ya existe!');
            this.cargando = false;
          } else {
            this.alerta.mostrarError('¡Error al guardar!');
            this.cargando = false;
          }
        });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  eliminar(id: number): void {

    let emp = this.socios.find(e => e.id == id);
    let index = this.socios.indexOf(emp, 0);
    if (index > -1) {
      this.socios.splice(index, 1);
      this.fuenteDatos.data = this.socios;
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
    if (this.subsEmpresa) { this.subsEmpresa.unsubscribe(); }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'underscore';
import * as moment from 'moment';
import { RentabilidadSelectionAdicionalComponent } from '../rentabilidad-selection-ad/rentabilidad-selection-ad.component';

@Component({
    selector: 'app-cierre-despacho-operativo-selection',
    templateUrl: './rentabilidad-selection.component.html',
    styleUrls: ['./rentabilidad-selection.component.scss']
})
export class RentabilidadSelectionComponent implements OnInit {
    objetivoN: number;
    rastre: RastreoClienteModel[];
    numCol: number;
    mes: string;

    public barChartOptions: ChartOptions = {

        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                ticks: {
                    max: 100,
                    callback: (value, index, values) => {
                        return `${value}%`;
                    }
                }
            }],
            xAxes: [{
                barPercentage: 1.0,
            }]
        },
        legend: {
            position: 'bottom'
        },
        plugins: {

        },
    };

    public barChartLabels = [];
    public barChartData: ChartDataSets[] = [
        { data: [], label: 'Objetivo', type: 'line', datalabels: { display: false }, fill: false, pointRadius: 0 },
        { data: [], label: 'Rentabilidad', type: 'bar', datalabels: { display: false } },
    ];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
    public barChartColors = [
        {
            backgroundColor: []
        }
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], objetivo: number, colNum: number, columnName: string },
        private ventana: MatDialogRef<RentabilidadSelectionComponent>, public ventana2: MatDialog) {
    }



    ngOnInit(): void {
        this.mes = this.object.columnName;
        this.objetivoN = this.object.objetivo;
        const months = _.chain(this.object.rastreos)
            .groupBy(c => moment(c.fechaCompromiso).week())
            .map((x, y) => {
                return {
                    weekNumber: `Semana: ${Number(y) + 1}`,
                    data: x,
                };
            })
            .sortBy(c => Number(c.weekNumber)).value();

        const monthValues = _.chain(months.map(c => c.data)).map(c => {
            const venta = c.reduce((s, f) => s + f.importeVenta, 0);
            const costo = c.reduce((s, f) => s + f.importeCosto, 0);
            const rentabilidad = venta - costo;
            return ((rentabilidad / costo) * 100).toFixed(2);

        }).value();

        const monthKeys = months.map(c => c.weekNumber);

        this.barChartData.find(c => c.label == 'Rentabilidad').data = monthValues.map(c => Number(c));
        //this.objetivo = response.objetivos.find(c => c.year == Number(moment(new Date()).format('YYYY'))).valor;
        this.barChartData.find(c => c.label == 'Objetivo').data = Array(monthKeys.length).fill(this.objetivoN);
        this.barChartLabels = monthKeys;
        // this.barChartColors[0].backgroundColor = monthKeys.map(v => 'rgba(30, 120, 152, 1)');
        this.barChartData.find(c => c.label == 'Rentabilidad').backgroundColor = '#5B9BD5';

        this.barChartData.find(c => c.label == 'Objetivo').backgroundColor = '#00B050';
        this.barChartData.find(c => c.label == 'Objetivo').borderColor = '#00B050';
    }

    abrirForma2(): void {
        const forma = this.ventana2.open(RentabilidadSelectionAdicionalComponent, {
            data: { rastreos: this.object.rastreos, colNum: this.object.colNum, columnName: this.object.columnName },
            panelClass: 'form-container',
            width: '35vw'
        });
    }

}
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MedidaLegal } from "../entidades/medida-legal";

export class RepositorioMedidaLegal extends RepositorioCatalogo<MedidaLegal> {

    constructor(http: HttpClient) {
        super(http, "MedidasLegales");
    }

    activar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

}

import { Component, OnInit, Input, Output } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { first } from 'rxjs/operators';
import { FormaMetaEmpleadoComponent } from '../forma-meta/forma.component';
import { MetaEmpleado } from 'src/app/entidades/meta-empleado';

@Component({
  selector: 'app-meta-empleados',
  templateUrl: './meta-empleados.component.html',
  styleUrls: ['./meta-empleados.component.scss']
})
@AutoUnsubscribe()
export class MetaEmpleadoComponent implements OnInit {
  @Input() set metas(valor: MetaEmpleado[]) {
    this.metas$.next(valor);
  }

  metas$ = new BehaviorSubject<MetaEmpleado[]>([]);

  fuenteDatos: MatTableDataSource<MetaEmpleado> = new MatTableDataSource([]);
  columnasMostradas = ['ano', 'mes', 'meta', 'opciones'];

  constructor(
    public overlay: ServicioOverlayCargando,
    private ventana: MatDialog
  ) {}

  ngOnInit() {
    this.metas$.pipe(first()).subscribe(metas => {
      metas = this.ordenarVentas(metas);
      this.fuenteDatos = new MatTableDataSource(metas);
    });
  }

  ordenarVentas(metas: MetaEmpleado[]): MetaEmpleado[] {
    return metas.sort((a, b) => {
      if (a.ano > b.ano) {
        return 1;
      } else if (a.ano < b.ano) {
        return -1;
      } else if (a.mes > b.mes) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  editar(metaId: number): void {
    this.ventana
      .open(FormaMetaEmpleadoComponent, {
        data: metaId,
        panelClass: 'form-container'
      })
      .afterClosed()
      .pipe(first())
      .subscribe(meta => {
        const metas = this.metas$.value;
        const indice = this.metas$.value.findIndex(m => m.id === meta.id);
        metas[indice] = meta;
        this.metas$.next(metas);
      });
  }
}

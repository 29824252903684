import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { EstatusVentas } from 'src/app/entidades/estatus-ventas';
import { first } from 'rxjs/operators';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import * as _ from 'underscore';
import { FacturacionPendiente } from 'src/app/entidades/facturacionPendiente';
import { FacturacionPendienteData } from './facturacion-pendiente-data/facturacion-pendiente-data.component';
import { FacturacionPendienteSelection } from './facturacion-pendiente-mo/facturacion-pendiente-mo.component';
import * as Chart from 'chart.js';
import { VentasPorEnviar } from 'src/app/entidades/VentasPorEnviar'; 


@Component({
    selector: 'app-grafica-facturacionP',
    templateUrl: './facturacionP.component.html',
    styleUrls: ['./facturacionP.component.scss'],
})
export class FacturacionPComponent implements OnInit {
    @Input() year: number = new Date().getFullYear();

    estatusVentas: EstatusVentas;
    facturacion: FacturacionPendiente[];
    facturacionF: FacturacionPendiente[];
    facturacionActual: FacturacionPendiente[];
    rastreos: any[];
    groupedData: any;
    filteredData: [];
    IVentasEntregada: VentasPorEnviar[];
    maped: {
        importeMonedaDocumento: number;
        monedaCompra: string;
        fechaDocumento: string | Date;
    }[];
    promedioAnterior: number;
    objetivo: number;
    days = ['1 A 7 DÍAS', '8 A 15 DÍAS', '+15 DÍAS'];
    isLoaded: boolean = false;

    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            xAxes: [
                {   
                    barPercentage: 0.5,
                    display: true,
                    scaleLabel: {
                        display: true,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        display: false,
                        max: 5000000,
                    },
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
        legend: {
            display: true,
            position: 'top',
            labels:{
                usePointStyle: true,
                boxWidth: 8,
                generateLabels: function(chart) {
                    const labels = Chart.defaults.global.legend.labels.generateLabels(chart);
                    labels.forEach(label => {
                        label.pointStyle = 'rect';
                    });
                    return labels;
                }
            }
        },
        plugins: {
            datalabels: {
                formatter: function(value, context) {
                    return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                },
                align: 'end',
                anchor: 'end',
                rotation: 270,
            },
        },
        
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return Number(tooltipItem.yLabel).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                }
              }
        },
        onClick: (event, active) => this.chartClicked({ event, active }),
    };

    public barChartLabels = [this.days[0], this.days[1], this.days[2]];
    public barChartData: ChartDataSets[] = [
        {
            data: [],
            label: 'USD',
            backgroundColor: 'rgba(146, 208, 80)',
            borderColor: 'rgba(146, 208, 80)',
            borderWidth: 1
        },
        {
            data: [],
            label: 'MXN',
            backgroundColor: 'rgba(68, 114, 196)',
            borderColor: 'rgba(68, 114, 196)',
            borderWidth: 1,
        },
    ];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
    public barChartColors = [
        {
            backgroundColor: [],
        },
    ];

    constructor(
        private ctx: Contexto,
        public overlay: ServicioOverlayCargando,
        public autenticacion: Autenticacion,
        public ventana: MatDialog
    ) {
        this.cargarDatos();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`Child OnChages: ${this.year}`);
        if (this.facturacion) {
            this.actualizarDatos();
        }
    }

    ngOnInit() {}



    public chartClicked({
        event,
        active,
    }: {
        event: MouseEvent;
        active: {}[];
    }): void {
        console.log(event, active);
        if (active.length > 0) {
            const chart = active[0]['_chart'];
            const columNumber = active[0]['_index'];
            const columnName = chart.data.labels[columNumber];

            const activePoint = chart.getElementAtEvent(event)[0];
            const data = activePoint['_chart'].config.data;
            let datasetIndex = activePoint['_datasetIndex'];
            let columnCurrency = data.datasets[datasetIndex].label;

            let periodKey;

            if(columnName == this.days[0]){
                periodKey = 'data1a7';
            }else if(columnName == this.days[1]){
                periodKey = 'data8a14';
            }else if(columnName == this.days[2]){
                periodKey = 'data15mas';
            }

            if(columnCurrency == 'MXN')
                columnCurrency = 'MXP';

            const rastreos = _.chain(this.groupedData)
            .find(c => c.clase === columnCurrency)
            .get(periodKey)
            .value();


            this.ventana.open(FacturacionPendienteSelection, {
                data: { rastreos: rastreos, columna: columnName },
                panelClass: 'form-container',
                width: '40vw',
            });
        }
    }

    public chartHovered({
        event,
        active,
    }: {
        event: MouseEvent;
        active: {}[];
    }): void {
        console.log(event, active);
    }

    cargarDatos(): void {
        this.ctx.documentosVenta
            .ObtenerOperacionResumenFacturacionPendiente()
            .pipe(first())
            .subscribe((response) => {
                this.IVentasEntregada = response.ventasPorEnviarData;
                this.processSalesData();
                this.actualizarDatos();
                this.isLoaded = true;
            });
    }

    processSalesData(): void {
        this.groupedData = _.chain(this.IVentasEntregada)
        .filter((c) => c.estatusXmonitor == "Enviada")
        .groupBy((c) => c.monedaVenta)
        .map((x, y) => {
            const data1a7 = x.filter((item) => {
                const diff = moment().diff(
                    moment(item.fechaComprometida),
                    'days'
                );
                return diff >= 0 && diff <= 7;
            });
            const data8a14 = x.filter((item) => {
                const diff = moment().diff(
                    moment(item.fechaComprometida),
                    'days'
                );
                return diff >= 8 && diff <= 14;
            });
            const data15mas = x.filter((item) => {
                const diff = moment().diff(
                    moment(item.fechaComprometida),
                    'days'
                );
                if(item.ctFechaEnvioSap == null){
                    const diff = moment().diff(
                        moment(item.fechaComprometida),
                        'days'
                    );
                    return diff >= 15;
                }
                return diff >= 15;
            });
            return {
                clase: y,
                data1a7,
                data8a14,
                data15mas,
            };
        })
        .value();
    }

    actualizarDatos() {
        const clas3 = this.groupedData;

        //Sumamos los datos de cada intervalo

        const suma1a7 = clas3.map((item) => {
            const sumaCompra = item.data1a7.reduce((sum, value) => {
                return sum + value.importeMonedaDocumento;
            }, 0);
            return {
                clase: item.clase,
                sumaCompra: sumaCompra,
            };
        });

        const suma8a14 = clas3.map((item) => {
            const sumaCompra = item.data8a14.reduce((sum, value) => {
                return sum + value.importeMonedaDocumento;
            }, 0);
            return {
                clase: item.clase,
                sumaCompra: sumaCompra,
            };
        });

        const suma15mas = clas3.map((item) => {
            const sumaCompra = item.data15mas.reduce((sum, value) => {
                return sum + value.importeMonedaDocumento;
            }, 0);
            return {
                clase: item.clase,
                sumaCompra: sumaCompra,
            };
        });

        //Almacenamos nuestras sumas de clase pesos en un arreglo
        const sumasMXN = [
            suma1a7.find((item) => item.clase === 'MXP'),
            suma8a14.find((item) => item.clase === 'MXP'),
            suma15mas.find((item) => item.clase === 'MXP'),
        ];

        const sumasUSD = [
            suma1a7.find((item) => item.clase === 'USD'),
            suma8a14.find((item) => item.clase === 'USD'),
            suma15mas.find((item) => item.clase === 'USD'),
        ];

        this.barChartData.find((c) => c.label == 'USD').data = [
            sumasUSD[0].sumaCompra,
            sumasUSD[1].sumaCompra,
            sumasUSD[2].sumaCompra,
        ];
        this.barChartData.find((c) => c.label == 'MXN').data = [
            sumasMXN[0].sumaCompra,
            sumasMXN[1].sumaCompra,
            sumasMXN[2].sumaCompra,
        ];

        this.barChartData.find((c) => c.label == 'USD').backgroundColor = 'rgba(146, 208, 80)';
        this.barChartData.find((c) => c.label == 'USD').borderColor = 'rgba(146, 208, 80)';

        this.barChartData.find((c) => c.label == 'MXN').backgroundColor = 'rgba(68, 114, 196)';
        this.barChartData.find((c) => c.label == 'MXN').borderColor = 'rgba(68, 114, 196)';

        /*
        const suma = clas.map(item => {
            const sumaCompra = item.data.reduce((sum, value) => {
                return sum + value.importeMonedaDocumento;
            }, 0);
            return{
                clase: item.clase,
                sumaCompra: sumaCompra
            }
        })

        const suma2 = clas2.map(item => {
            const sumaCompra = item.data.reduce((sum, value) => {
                return sum + value.importeMonedaDocumento;
            }, 0);
            return{
                clase: item.clase,
                sumaCompra: sumaCompra
            }
        })
        console.log(suma);
        console.log(suma2);
        */
        /*
        const groups = _.chain(this.facturacion).groupBy(c => moment(c.aFecha).year());

        const clas = _.chain(this.facturacion).filter(c => moment(c.fechaDocumento).format('YYYY') == String(this.year))
            .groupBy(c => c.clasificacion)
            .map((x, y) => {
                return {
                    clase: y,
                    labelClas: x[0].labelClas,
                    data: x
                }
            })
            .sortBy(c => Number(c.clase)).value();


        this.facturacionF = (this.facturacion).filter(c => moment(c.fechaDocumento).format('YYYY') == String(this.year))

        const monthValues = _.chain(clas.map(c => c.data)).map(c => {
            return `${(c.reduce((s, f) => s + Number(f.venta), 0).toFixed(2))}`;
        }).value();

        this.objetivo = clas.length * 100;

        const monthKeys = clas.map(c => c.labelClas);

        //this.barChartData.find(c => c.label == '%').data = Array(monthKeys.length).fill(this.objetivo);
        this.barChartLabels = monthKeys;

        this.barChartData.find(c => c.label == 'Cantidad').data = monthValues.map(c => Number(c));
        this.barChartData.find(c => c.label == 'Cantidad').backgroundColor = '#5B9BD5';

        this.barChartData.find(c => c.label == '%').data = monthValues.map(c => Number(c));
        this.barChartData.find(c => c.label == '%').backgroundColor = '#FEC200';
        this.barChartData.find(c => c.label == '%').borderColor = '#FEC200';
        // this.barChartColors[1].backgroundColor = monthKeys.map(v => 'rgba(226, 245, 39, 0.8)');*/
    }

    abrirForma(): void {
        const forma = this.ventana.open(FacturacionPendienteData, {
            data: { rastreos: this.IVentasEntregada },
            panelClass: 'form-container',
            width: '50vw',
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlArchivosComponent } from './componentes/control-archivo/control-archivo.component';
import {
    MatIconModule,
    MatChipsModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDividerModule,
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { DivGridModule } from '../createga/div-grid/div-grid.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LigaSeguraPipe } from './componentes/liga-segura.pipe';
import { FileDropDirective } from './componentes/control-archivo/file-drop.directive';
import { VisorArchivoComponent } from 'src/app/utilerias/visor-archivo/visor-archivo.component';

@NgModule({
    declarations: [
        ControlArchivosComponent,
        FileDropDirective,
        VisorArchivoComponent,
        LigaSeguraPipe,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatChipsModule,
        MatCardModule,
        DivGridModule,
        MatDialogModule,
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatInputModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatDividerModule,
        MatButtonModule,
        MatDialogModule,
    ],
    exports: [ControlArchivosComponent, VisorArchivoComponent],
    entryComponents: [VisorArchivoComponent],
})
export class ControlArchivosModule {}

import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { GeneradorReportes } from '../entidades/generador-reportes';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Reporteador } from '../entidades/reporteador';
import { ReporteadorGlobal } from '../entidades/reporteador-global';
import { ReporteServicioTransporteCliente } from "../entidades/reporte-servicio-transporte-cliente";
import { ReporteadorAdicional } from "../entidades/reporteadorAdicional";
import { ReportePenasquitoAcumulado } from "../entidades/reporte-penasquito-acumulado";
import { ReportePenasquitoEstatusDiarioFriedrich } from "../entidades/reporte-penasquito-estatus-diario-driedrich";

export class RepositorioGeneradorReportes extends RepositorioCatalogo<GeneradorReportes> {

    constructor(http: HttpClient) {
        super(http, "GeneradorReportes");
    }

    obtenerReporte(reporte: GeneradorReportes): Observable<Reporteador[]> {
        const ruta = `${this.ruta}/ObtenerReporte`;
        return this.clienteHttp.post(ruta, reporte).pipe(
            map((solicitud: Reporteador[]) => {
                return solicitud;
            })
        );
    }

    obtenerReporteReporteador(reporte: GeneradorReportes,conTipoServicio : boolean): Observable<Reporteador[]> {
      const ruta = `${this.ruta}/ObtenerReporteReporteador/${conTipoServicio}`;
      return this.clienteHttp.post(ruta, reporte).pipe(
          map((solicitud: Reporteador[]) => {                
                return solicitud;
          })
      );
    }

    obtenerReporteReporteadorAdicional(reporte: GeneradorReportes,conTipoServicio : boolean): Observable<ReporteadorAdicional[]> {
        const ruta = `${this.ruta}/ObtenerReporteReporteadorAdicional/${conTipoServicio}`;
        return this.clienteHttp.post(ruta, reporte).pipe(
            map((solicitud: ReporteadorAdicional[]) => {                
                  return solicitud;
            })
        );
      }

    obtenerReporteReporteadorAuditoriaVentas(reporte: GeneradorReportes, estatusCompra: Number): Observable<Reporteador[]> {
      const ruta = `${this.ruta}/ObtenerReporteReporteadorAuditoriaVentas/${estatusCompra}`;
      return this.clienteHttp.post(ruta, reporte).pipe(
          map((solicitud: Reporteador[]) => {
              return solicitud;
          })
      );
    }


    obtenerReporteGlobal(reporte: GeneradorReportes): Observable<ReporteadorGlobal[]> {
        const ruta = `${this.ruta}/ObtenerReporteGlobal`;
        return this.clienteHttp.post(ruta, reporte).pipe(
            map((solicitud: ReporteadorGlobal[]) => {
                return solicitud;
            })
        );
    }

    obtenerReporteHistorial(reporte: GeneradorReportes): Observable<Reporteador[]> {
        const ruta = `${this.ruta}/ObtenerReporteHistorial`;
        return this.clienteHttp.post(ruta, reporte).pipe(
            map((solicitud: Reporteador[]) => {
                return solicitud;
            })
        );
    }

    ObtenerReportePenasquitoAcumuladoExcel(reporte: ReportePenasquitoAcumulado): Observable<any> {
        const ruta = `${this.ruta}/ObtenerReportePenasquitoAcumuladoExcel/`;
        return this.clienteHttp.post(ruta, reporte);
    }

    ObtenerReportePenasquitoEstatusDiarioFriedrich(reporte: ReportePenasquitoEstatusDiarioFriedrich): Observable<any> {
        const ruta = `${this.ruta}/ObtenerReportePenasquitoEstatusDiarioFriedrich/`;
        return this.clienteHttp.post(ruta, reporte);
    }

    obtenerReporteExcel(reporte: GeneradorReportes,conTipoServicio : boolean): Observable<Blob> {
        const ruta = `${this.ruta}/ObtenerReporteExcel/${conTipoServicio}`;
        return this.clienteHttp.post(ruta, reporte, { responseType: 'blob' as 'blob' }).pipe(
            map((res: Blob) => { return res; })
        );
    }

    obtenerReporteExcelAuditoriaVentas(reporte: GeneradorReportes, estatusCompra: Number): Observable<Blob> {
      const ruta = `${this.ruta}/ObtenerReporteExcelAuditoriaVentas/${estatusCompra}`;
      return this.clienteHttp.post(ruta, reporte, { responseType: 'blob' as 'blob' }).pipe(
          map((res: Blob) => { return res; })
      );
    }

    obtenerReporteGlobalExcel(reporte: GeneradorReportes): Observable<Blob> {
        const ruta = `${this.ruta}/ObtenerReporteGlobalExcel`;
        return this.clienteHttp.post(ruta, reporte, { responseType: 'blob' as 'blob' }).pipe(
            map((res: Blob) => { return res; })
        );
    }

    obtenerReporteHistorialExcel(reporte: GeneradorReportes): Observable<Blob> {
        const ruta = `${this.ruta}/ObtenerReporteHistorialExcel`;
        return this.clienteHttp.post(ruta, reporte, { responseType: 'blob' as 'blob' }).pipe(
            map((res: Blob) => { return res; })
        );
    }

    obtenerReporteServicioTransporteExcel(reporte: ReporteServicioTransporteCliente): Observable<Blob> {
      const ruta = `${this.ruta}/ObtenerReportServicioClienteExcel`;
      return this.clienteHttp.post(ruta, reporte, { responseType: 'blob' as 'blob' }).pipe(
          map((res: Blob) => { return res; })
      );
  }



}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { Factura } from '../entidades/factura';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

export class RepositorioFacturaCompra extends RepositorioCatalogo<Factura> {
  constructor(http: HttpClient) {
    super(http, 'FacturasCompra');
  }

  procesarDocumento(idDocumento: number): Observable<Factura[]> {
    const ruta = `${this.ruta}/Procesar/${idDocumento}`;
    return this.clienteHttp.put(ruta, {}).pipe(
      map((facturas: Factura[]) => {
        return facturas;
      })
    );
  }

  obtenerSinProcesar(): Observable<Factura[]> {
    const ruta = `${this.ruta}/ObtenerSinProcesar`;
    return this.clienteHttp.get(ruta).pipe(
      map((facturas: Factura[]) => {
        return facturas;
      })
    );
  }

  DocSeEncuentraEnSAP(documentoId: number): Observable<boolean> {
    const ruta = `${this.ruta}/DocSeEncuentraEnSAP/${documentoId}`;
    return this.clienteHttp.get(ruta).pipe(
      map((respuesta: boolean) => {
        return respuesta;
      })
    );
  }

  obtenerProcesadas(): Observable<Factura[]> {
    const ruta = `${this.ruta}/ObtenerProcesadas`;
    return this.clienteHttp.get(ruta).pipe(
      map((facturas: Factura[]) => {
        return facturas;
      })
    );
  }
}

import { HttpClient } from "@angular/common/http";
import { TipoCarga } from "../entidades/tipo-carga";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { EspecificacionCarga } from '../entidades/especificacion-carga';
import { AsignacionEspecificacionCarga } from '../entidades/asignacion-especificacion-carga';
import { AplicaProveedorMina } from '../entidades/aplica-proveedor-mina';
import { AsignacionAplicaproveedoMina } from '../entidades/asignacion-aplica-proveedor-mina';

export class RepositorioAplicaProveedorMina extends RepositorioCatalogo<AplicaProveedorMina> {

    constructor(http: HttpClient) {
        super(http, "AplicaProoveedorMina");
    }

    activar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    obtenerTodosActivos(): Observable<AplicaProveedorMina[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<AplicaProveedorMina[]>(ruta);

    }

    asignar(asignacion: AsignacionAplicaproveedoMina): Observable<AsignacionAplicaproveedoMina> {
        const ruta = `${this.ruta}/Asignar`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionAplicaproveedoMina) => asig)
        );
    }

    desasignar(asignacion: AsignacionAplicaproveedoMina): Observable<void> {
        const ruta = `${this.ruta}/Desasignar/${asignacion.aplicaProveedorMinaId}/${asignacion.clienteId}`;
        return this.clienteHttp.delete(ruta).pipe(map(() => null));
    }

    obtenerAplicaProveedorMinaCliente(IdCliente: number): Observable<AplicaProveedorMina[]> {
        const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
        return this.clienteHttp.get<AplicaProveedorMina[]>(ruta);
    }
}



import { HttpClient } from "@angular/common/http";
import { TipoCarga } from "../entidades/tipo-carga";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { EspecificacionCarga } from '../entidades/especificacion-carga';
import { AsignacionEspecificacionCarga } from '../entidades/asignacion-especificacion-carga';

export class RepositorioEspecificacionCarga extends RepositorioCatalogo<EspecificacionCarga> {

    constructor(http: HttpClient) {
        super(http, "EspecificacionCarga");
    }

    activar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    obtenerTodosActivos(): Observable<EspecificacionCarga[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<EspecificacionCarga[]>(ruta);

    }


    asignar(asignacion: AsignacionEspecificacionCarga): Observable<AsignacionEspecificacionCarga> {
        const ruta = `${this.ruta}/Asignar`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionEspecificacionCarga) => asig)
        );
    }

    desasignar(asignacion: AsignacionEspecificacionCarga): Observable<void> {
        const ruta = `${this.ruta}/Desasignar/${asignacion.especificacionCargaId}/${asignacion.clienteId}`;
        return this.clienteHttp.delete(ruta).pipe(map(() => null));
    }

    obtenerEspecificacionCargaCliente(IdCliente: number): Observable<EspecificacionCarga[]> {
        const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
        return this.clienteHttp.get<EspecificacionCarga[]>(ruta);
    }
}



import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatTableDataSource,
    MatDialog,
} from '@angular/material';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { TiposDocumentos } from 'src/app/entidades/tipos-documentos';
import { VentanaConfirmacionComponent } from 'src/app/modulos/operaciones/componentes/ventana-confirmacion/ventana-confirmacion.component';

@AutoUnsubscribe()
@Component({
    selector: 'app-modal-tipos-documentos-no-facturar',
    templateUrl: './modal-tipos-documento-no-facturar.component.html',
    styleUrls: ['./modal-tipos-documento-no-facturar.component.scss'],
})
export class ModalTiposDocumentosNoFacturarComponent implements OnInit {
    columnasMostradas = [
      'descripcion',
      'documento',
      'comentario',
    ];
    fuenteDatos: MatTableDataSource<TiposDocumentos> = new MatTableDataSource([]);
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public parametros: {
            proyectoId: number;
            documentoId: number;
            folio: string;
        },
        private ventana: MatDialogRef<ModalTiposDocumentosNoFacturarComponent>,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private ventanaModal: MatDialog
    ) {}

    ngOnInit() {

      this.cargarTiposDocumentos();
    }

    cargarTiposDocumentos() {
        this.ctx.tiposDocumentos.obtenerTodos().subscribe((respuesta) => {
            this.fuenteDatos = new MatTableDataSource(
                respuesta.map((e) => {
                    return {
                        ...e,
                        docSeleccionado: false,
                    };
                })
            );
        });
    }

    agregarComentario(tipo: TiposDocumentos, comentario:string){
      const tipoDoc = this.fuenteDatos.data.find((e) => e.id == tipo.id);
      tipoDoc.comentario = comentario;
    }


    seleccionaDocumento(e: any, tipo: TiposDocumentos){
      const tipoDoc = this.fuenteDatos.data.find((e) => e.id == tipo.id);
      if(e != undefined)
      {
        const file = (e.target as HTMLInputElement).files[0];
        const reader = new FileReader();
        reader.onload = () => {
          let archivoDatos = reader.result as string;
          tipoDoc.archivo = archivoDatos.split('base64,')[1]
          tipoDoc.tipoArchivo = archivoDatos.split(',')[0] + ","
          tipoDoc.extension = "." + tipoDoc.tipoArchivo.split('/')[1].split(";")[0]
          console.log(archivoDatos);
        }
        reader.readAsDataURL(file);
      }
      tipoDoc.docSeleccionado = true;
      console.log(tipoDoc);
    }

    guardar(): void {
      try {

          let existenDocsSinCargar = this.fuenteDatos.data.filter(e => e.docSeleccionado == false);
          if(existenDocsSinCargar.length > 0)
          {
            existenDocsSinCargar.map((e) => (e.remarcar = true));
            this.alerta.mostrarAdvertencia("Aun faltan cargar documentos!");
            return;
          }

          let existenComentariosVacios = this.fuenteDatos.data.filter(e => e.comentario == undefined || e.comentario == "");
          if(existenComentariosVacios.length > 0)
          {
            existenComentariosVacios.map((e) => (e.remarcar = true));
            this.alerta.mostrarAdvertencia("Aun faltan agregar comentarios!");
            return;
          }



          this.ventanaModal
              .open(VentanaConfirmacionComponent, {
                  data: {
                      titulo: '¿Desea guardar los siguientes documentos y asignarlos?',
                      botonVerdad: 'Si',
                      botonFalso: 'No',
                  },
              })
              .afterClosed()
              .toPromise()
              .then(async (respuesta) => {
                  if (respuesta) {
                      let resultado = await this.ctx.documentosVenta.asignarDocumentosNoFacturados(+this.parametros.folio, this.fuenteDatos.data).toPromise();
                      if (resultado.ok) {
                          this.alerta.mostrarExito(resultado.mensaje);
                          this.ventana.close(true)
                      } else {
                          this.alerta.mostrarError(resultado.mensaje);
                      }
                  }
              });
      } catch (error) {
          console.log(error);
      }
  }

  cerrar(): void {
    this.ventana.close(false);
}



}

import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { FiltroReporte } from '../entidades/filtro-reporte';
import { Observable } from 'rxjs';

export class RepositorioFiltroReporte extends RepositorioCatalogo<FiltroReporte> {
    constructor(http: HttpClient) {
        super(http, "FiltroReporte");
    }

    obtenerTodosActivos(): Observable<FiltroReporte[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<FiltroReporte[]>(ruta);
    }

    obtenerFiltroTipoReporte(tipoReporteId: number): Observable<FiltroReporte[]> {
        const ruta = `${this.ruta}/ObtenerFiltroTipoReporte/${tipoReporteId}`;
        return this.clienteHttp.get<FiltroReporte[]>(ruta);
    }
}

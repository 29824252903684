import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Clientes } from 'src/app/entidades/clientes';
import { Empleados } from 'src/app/entidades/empleados';
import { FiltroReporteSeguimientoLiberado } from 'src/app/entidades/filtro-reporte-seguimientos-liberados';
import { ReporteSeguimientos } from 'src/app/entidades/reporte-seguimientos';
import { RepositorioOnMonitor } from './repo-onmonitor';


export class RepositorioReporteSeguimiento extends RepositorioOnMonitor<ReporteSeguimientos> {
  constructor(http: HttpClient) {
    super(http, 'ReportesSeguimientos');
  }

  ServiciosEntregados(): Observable<ReporteSeguimientos[]> {
    const ruta = `${this.ruta}/Serviciosentregados`;
    return this.cliente.get<ReporteSeguimientos[]>(ruta);
  }

  obtenerExcel(model: FiltroReporteSeguimientoLiberado): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcel`;
    return this.cliente.post(ruta, model, {
      responseType: 'blob' as 'blob',
    }).pipe(map((res: Blob) => res));
  }

  obtenerExcelCliente(model: ReporteSeguimientos[]): Observable<Blob> {
    const ruta = `${this.ruta}/DescargarExcelCliente`;
    return this.cliente.post(ruta, model, {
      responseType: 'blob' as 'blob',
    }).pipe(map((res: Blob) => res));
  }

  obtenerClientes(): Observable<Clientes[]> {
    const ruta = `${this.ruta}/Clientes`;
    return this.cliente.get<Clientes[]>(ruta);
  }

  obtenerClientesId(nombreCliente: string): Observable<number> {
    const ruta = `${this.ruta}/ClientePorId/${nombreCliente}`;
    return this.cliente.get<number>(ruta);
  }

  tipoTransporte(): Observable<string[]> {
    const ruta = `${this.ruta}/TipoTransporte`;
    return this.cliente.get<string[]>(ruta);
  }

  obtenerEmpleador(): Observable<Empleados[]> {
    const ruta = `${this.ruta}/EmpleadosOperaciones`;
    return this.cliente.get<Empleados[]>(ruta);
  }

  obtenerProveedores(): Observable<string[]> {
    const ruta = `${this.ruta}/Proveedores`;
    return this.cliente.get<string[]>(ruta);
  }

  filtroProveedores(modelo: FiltroReporteSeguimientoLiberado): Observable<ReporteSeguimientos[]> {
    const ruta = `${this.ruta}/ReporteFiltrado`;
    return this.cliente.post<ReporteSeguimientos[]>(ruta, modelo);
  }

  filtroVistaReporte(modelo: FiltroReporteSeguimientoLiberado): Observable<ReporteSeguimientos[]> {
    const ruta = `${this.ruta}/ReporteFiltrado`;
    return this.cliente.post<ReporteSeguimientos[]>(ruta, modelo);
  }

  filtroVistaReporteEntregados(
    modelo: FiltroReporteSeguimientoLiberado
  ): Observable<ReporteSeguimientos[]> {
    const ruta = `${this.ruta}/ReporteEntregados`;
    return this.cliente.post<ReporteSeguimientos[]>(ruta, modelo);
  }

  filtroMaster(modelo: FiltroReporteSeguimientoLiberado): Observable<ReporteSeguimientos[]> {
    const ruta = `${this.ruta}/ReporteMaster`;
    return this.cliente.post<ReporteSeguimientos[]>(ruta, modelo);
  }
}

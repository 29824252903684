import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { filter } from 'lodash';
import { Objetivo } from 'src/app/entidades/objetivo';

@Component({
    selector: 'app-rentabilidad-data',
    templateUrl: './rentabilidad-data-mo.component.html',
    styleUrls: ['./rentabilidad-data-mo.component.scss']
})
export class RentabilidadDataMoComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['proyecto', 'cliente', 'fechaCompromiso', 'venta', 'costo', 'rentabilidad', 'rojo'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], promedioAnterior: number, objetivo: number },
        private ventana: MatDialogRef<RentabilidadDataMoComponent>) {
    }

    ngOnInit(): void {
        const months = _.chain(this.object.rastreos)
            .groupBy(c => c.proyecto)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    proy: x[0].proyecto,
                    cli: x[0].cliente,
                    fe: x[0].fechaCompromiso,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();

        const remarcar = false;

        const monthsModel = _.chain(months).map((month, key) => {
            const venta = month.data.reduce((s, f) => s + f.importeVenta, 0);
            const costo = month.data.reduce((s, f) => s + f.importeCosto, 0);
            const rentabilidad = ((venta - costo) / costo) * 100;
            return {
                'proyecto': month.proy,
                'cliente': month.cli,
                'fechaCompromiso': month.fe,
                'periodo': month.monthName,
                'venta': venta,
                'costo': costo,
                'rentabilidad': `${rentabilidad.toFixed(2)} %`,
                'renta': rentabilidad.toFixed(2),
                'rojo': rentabilidad < 0 ? true : false,
            };
        }).value();

        const debajo = monthsModel.filter(c => Number(c.renta) < this.object.objetivo)

        this.fuenteDatos = new MatTableDataSource(debajo);
        this.fuenteDatos.sort = this.ordenador;

    }

}
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaMonedasComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Moneda } from 'src/app/entidades/moneda';

@Component({
  selector: 'catalogo-moneda',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoMonedaComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<Moneda> = new MatTableDataSource([]);
  columnasMostradas = ['nombre', 'abreviatura', 'activo', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  subsMonedas: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarMonedas();
  }

  cargarMonedas(): void {
    this.cargando = true;
    this.ctx.monedas
      .obtenerTodos()
      .subscribe(monedas => {
        this.fuenteDatos = new MatTableDataSource(monedas);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaMonedasComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarMonedas();
      });
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    }
    else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {
    this.subsActivar = this.ctx
      .monedas.activar(id)
      .subscribe(() => { },
        error => {
          this.alerta.mostrarError('Error al activar');
        }
      );
  }

  desactivar(id: number): void {
    this.subsActivar = this.ctx
      .monedas.desactivar(id)
      .subscribe(() => { },
        error => {
          this.alerta.mostrarError('Error al desactivar');
        }
      );
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx
      .monedas.eliminar(id.toString())
      .subscribe(() => {
        this.alerta.mostrarError('Registro eliminado');
        this.cargarMonedas();
      },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subsMonedas) { this.subsMonedas.unsubscribe(); }
    if (this.subsActivar) { this.subsActivar.unsubscribe(); }
    if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
  }
}
export class Servicios {
    id: number;
    clave: string;
    descripcion: string;
    conSeguimiento: boolean;
    activo: boolean;
    imagen: string;
    mostrarEnMapa: boolean;
    tipoServicioId: number;
    aplicaSeguimiento: boolean;
    diasTolerancia: number;
}

import { DetalleAlerta } from "../entidades/detalle-alertas";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class RepositorioDetalleAlerta extends RepositorioCatalogo<DetalleAlerta> {
    constructor(http: HttpClient) {
        super(http, "DetallesAlertas");
    }

    obtenerTodosPorAlerta(idAlerta: number): Observable<DetalleAlerta[]> {
        let ruta = `${this.ruta}/PorAlerta/${idAlerta}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: DetalleAlerta[]) => {
                return data;
            }));
    }
}

import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { TiposUsuario } from "../entidades/tipos-usuario";

export class RepositorioTipoUsuario extends RepositorioCatalogo<TiposUsuario> {

    constructor(http: HttpClient) {
        super(http, "TipoUsuario");
    }
}

import { Component, EventEmitter, OnInit, Output, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { EstatusVentas } from 'src/app/entidades/estatus-ventas';
import { first } from 'rxjs/operators';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { PerfectOrderDataComponent } from './perfect-order-data/perfect-order-data.component';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Objetivo } from 'src/app/entidades/objetivo';

@Component({
    selector: 'app-grafica-perfect-order',
    templateUrl: './perfect-order.component.html',
    styleUrls: ['./perfect-order.component.scss']
})
export class PerfectOrderComponent implements OnInit {
    @Input() year: number = (new Date()).getFullYear();
    @Output() public perfectOrderEvent = new EventEmitter<RastreoClienteModel[]>();

    estatusVentas: EstatusVentas;
    rastreos: RastreoClienteModel[];
    rastreosPrev: RastreoClienteModel[];

    rastreosActual: RastreoClienteModel[];
    promedioAnterior: number;
    objetivo: number;
    objetivos: Objetivo[];

    // AnnoFiltro:number=2024;



    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                ticks: {
                    max: 100,
                    callback: (value, index, values) => {
                        return `${value}%`;
                    }
                }
            }]
        },
        legend: {
            position: 'bottom',
            labels: {
                filter: function name(item, chart) {
                    return !item.text.includes('Servicios');
                },
            }
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return `${Number(tooltipItem.yLabel).toFixed(2)} %`;
                }
            }
        }
    };

    public barChartLabels = [];
    public barChartData: ChartDataSets[] = [
        { data: [], label: `Prom ${this.year - 1}`, type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 0 },
        { data: [], label: `Prom ${this.year}`, type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 0 },
        { data: [], label: 'Objetivo', type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 0 },
        { data: [], label: 'Servicios', type: 'bar', datalabels: { display: false }, hideInLegendAndTooltip: false, hidden: false },
    ];
    
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
    public barChartColors = [
        {
            backgroundColor: []
        }
    ];

    constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando, public autenticacion: Autenticacion, public ventana: MatDialog) {
        this.cargarDatos();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`Child OnChages: ${this.year}`);
        if (this.rastreos) {
            this.actualizarDatos();
        }
    }

    ngOnInit() { }

    // events
    public chartClicked({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    public chartHovered({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    cargarDatos(): void {
        this.ctx.documentosVenta
            // .obtenerPerfectOrderFiltrado(this.AnnoFiltro)
            .obtenerPerfectOrder()
            .pipe(first())
            .subscribe((response) => {
                this.rastreos = response.servicios;
                this.objetivos = response.objetivos;
                this.actualizarDatos();
            });
    }


    actualizarDatos() {

        let yearAnbterior = (this.year - 1)
        this.rastreos = this.rastreos.filter(c => moment(c.fechaCompromiso).format('YYYY'));
        this.rastreosActual = this.rastreos.filter(c => moment(c.fechaCompromiso).format('YYYY') == String((this.year)));
        this.rastreosPrev = this.rastreos.filter(c => moment(c.fechaCompromiso).format('YYYY') == String(yearAnbterior));

        const groups = _.chain(this.rastreos).groupBy(c => moment(c.fechaCompromiso).format('MM'));

        const months = _.chain(this.rastreosActual)
            .groupBy(c => moment(c.fechaCompromiso).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber));

        const monthsPRev = _.chain(this.rastreosPrev)
            .groupBy(c => moment(c.fechaCompromiso).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber));

        const monthValues = months.map(c => c.data)
            .map(c => {
                return (c.filter(d => d.fechaEntrega < d.fechaCompromiso).length / c.length) * 100;
            }).value();

        const monthValuesPrev = monthsPRev.map(c => c.data)
            .map(c => {
                return (c.filter(d => d.fechaEntrega < d.fechaCompromiso).length / c.length) * 100;
            }).value();

        const monthKeys = months.map(c => c.monthName).value();

        const lastMonth = months.map(c => c.data).last();

        this.perfectOrderEvent.emit(lastMonth.value());

        const lastMonthWeeksGroups = lastMonth
            .map(c => c)
            .groupBy(c => moment(c.fechaCompromiso).week())
            .map((x, y) => {
                return {
                    weekNumber: `${Number(y)}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.weekNumber));

        const lastMonthValues = lastMonthWeeksGroups
            .map(c => c.data)
            .map(c => (this.division(c.length, c.filter(d => d.fechaEntrega < d.fechaCompromiso).length) * 100)).value();
        const lastMonthKeys = lastMonthWeeksGroups.map(c => `W${c.weekNumber}`).value();

        monthValues.pop();
        monthKeys.pop();

        const actYear = this.year;
        const actLabel = `Prom ${actYear}`;
        const prevLabel = `Prom ${Number(actYear) - 1}`;

        const prevPromLabel = `Prom ${_.chain(this.barChartData).filter(c => c.label.includes('Prom')).map(c => Number(c.label.substring(5))).sortBy(x => x).first().value()}`;
        const actPromLabel = `Prom ${_.chain(this.barChartData).filter(c => c.label.includes('Prom')).map(c => Number(c.label.substring(5))).sortBy(x => x).last().value()}`;

        this.barChartData.find(c => c.label == prevPromLabel).label = prevLabel;
        this.barChartData.find(c => c.label == actPromLabel).label = actLabel;

        const promAct = months
            .map(c => c.data)
            .map(c => {
                return (this.division(c.length, c.filter(d => d.fechaEntrega < d.fechaCompromiso).length)) * 100;
            }).value();

        // const promPrev = _.chain(response.servicios
        //     // .filter(c => Number(moment(c.fechaCompromiso).format('YYYY')) == this.AnnoFiltro- 1))
        //     .filter(c => Number(moment(c.fechaCompromiso).format('YYYY')) == Number(moment(new Date()).format('YYYY')) - 1))
        //     .sortBy(c => c)
        //     .groupBy(c => moment(c.fechaCompromiso).format('MM'))
        //     .map(c => (this.division(c.length, c.filter(d => moment(d.fechaEntrega).isAfter(moment(d.fechaCompromiso))).length)) * 100);

        promAct.pop();
        this.barChartData.find(c => c.label == 'Servicios').data = promAct.concat(lastMonthValues);

        const numeroColumnas = monthKeys.concat(lastMonthKeys).length;

        // this.objetivo = response.objetivos.find(c => c.year == this.AnnoFiltro).valor;
        this.objetivo = this.objetivos.find(c => c.year == Number(this.year)).valor;
        this.barChartData.find(c => c.label == actLabel).data = monthValues.map(c => Number(c));
        this.barChartData.find(c => c.label == prevLabel).data = monthValuesPrev.map(c => Number(c));
        this.barChartData.find(c => c.label == 'Objetivo').data = Array(numeroColumnas).fill(this.objetivo);

        this.barChartLabels = monthKeys.concat(lastMonthKeys);
        // this.barChartColors[3].backgroundColor = monthKeys.map(c => 'rgba(100, 109, 113, 1)').concat(lastMonthKeys.map(v => 'rgba(30, 120, 152, 1)'))
        this.barChartData.find(c => c.label == 'Servicios').backgroundColor = monthKeys.map(c => 'rgba(128, 128, 128, 1)').concat(lastMonthKeys.map(v => 'rgba(91, 155, 213, 1)'));
        this.barChartData.find(c => c.label == prevLabel).backgroundColor = '#FEC200';
        this.barChartData.find(c => c.label == prevLabel).borderColor = '#FEC200';
        this.barChartData.find(c => c.label == actLabel).backgroundColor = '#4472C4';
        this.barChartData.find(c => c.label == actLabel).borderColor = '#4472C4';
        this.barChartData.find(c => c.label == 'Objetivo').backgroundColor = '#00B050';
        this.barChartData.find(c => c.label == 'Objetivo').borderColor = '#00B050';
    }

    division(a: number, b: number): number {
        if (a == 0) {
            return 0;
        }
        else {
            return b / a;
        }
    }

    abrirForma(): void {
        const forma = this.ventana.open(PerfectOrderDataComponent, {
            data: { rastreos: this.rastreosActual, rastreospre: this.rastreosPrev, objetivo: this.objetivo, yearF: this.year },
            panelClass: 'form-container',
            width: '50vw'
        });
    }
}
import { Injectable, Component } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class ServicioOverlayCargando {
  referencia: OverlayRef;
  // Inject overlay service
  constructor(private overlay: Overlay) {}

  abrirCargando() {
    Promise.resolve().then(() => {
      const overlayConfig = new OverlayConfig({
        hasBackdrop: true,
        positionStrategy: this.overlay
          .position()
          .global()
          .centerHorizontally()
          .centerVertically(),
        scrollStrategy: this.overlay.scrollStrategies.block()
      });

      this.referencia = this.overlay.create(overlayConfig);
      const filePreviewPortal = new ComponentPortal(CargandoComponent);
      this.referencia.attach(filePreviewPortal);
    });
  }

  cerrarCargando(): void {
    if (this.referencia) {
      this.referencia.dispose();
    }
  }
}

@Component({
  template: `
    <mat-spinner></mat-spinner>
  `
})
export class CargandoComponent {}

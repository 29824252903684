import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DocumentoOperador } from 'src/app/entidades/documento-operador';
import { Subscription } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaDocumentoOperadorComponent } from '../forma-documento/forma.component';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.scss']
})
export class VisorComponent implements OnInit, OnDestroy {
  rutaArchivo: string = '';
  subsDocumento: Subscription;
  subsSubirDocumentos: Subscription;
  subsNuevoDocumento: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public documento: DocumentoOperador, private ctx: Contexto,  public ventanaDocumento: MatDialog) { }

  ngOnInit() {
   this.cargarArchivo();
  }

  cargarArchivo(): void {
    this.subsDocumento = this.ctx
    .operadores
    .descargarDocumento(this.documento.id)
    .subscribe(
      archivo => {
          let tipo = 'octet/stream';
          let extension = this.documento.extension;
          extension = extension.replace('.','');
          if(extension === 'pdf'){
            tipo = 'application/pdf';
          }
          else{
            tipo = 'image/' + extension;
          }
          let blob = new Blob([archivo], { type: tipo });
          this.rutaArchivo = window.URL.createObjectURL(blob);
      }
    );
  }

  obtenerDocumento(): void {
    this.subsNuevoDocumento = this.ctx
    .operadores
    .obtenerDocumento(this.documento.id)
    .subscribe(documento => {
      this.documento = documento;
      this.cargarArchivo();
    });
  }

  abrirVentaDocumentos(): void {
    let ventana = this.ventanaDocumento.open(FormaDocumentoOperadorComponent,
      { data: this.documento.operadorId, panelClass: 'form-container' });
    
    ventana.componentInstance
    .cerrado
    .subscribe(
      (guardo: boolean) => {
        if(guardo){
          this.obtenerDocumento();
        }
      });
  }

  async descargarDocumento(): Promise<void> {
    let archivo = await this.ctx.operadores.descargarDocumento(this.documento.id).toPromise();
    let nombreArchivo = `${this.documento.operadorNombre}_${this.documento.tipoDocumentoDescripcion}${this.documento.extension}`;

    let a = document.createElement("a");
    let blob = new Blob([archivo], { type: "octet/stream" });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = nombreArchivo;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      a.remove();
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.subsDocumento) { this.subsDocumento.unsubscribe(); }
    if (this.subsSubirDocumentos) { this.subsSubirDocumentos.unsubscribe(); }
    if (this.subsNuevoDocumento) { this.subsNuevoDocumento.unsubscribe(); }
  }
}

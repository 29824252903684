import { Component, OnInit } from '@angular/core';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-grafica-canceladas',
  templateUrl: './grafica-canceladas.component.html',
  styleUrls: ['./grafica-canceladas.component.scss']
})
export class GraficaVentasCanceladasComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [{ ticks: { beginAtZero: true } }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public barChartLabels: Label[] = ['Motivo'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartColors = [
    {
      backgroundColor: ['rgba(0,0,255,0.85)']
    }
  ];

  public barChartData: ChartDataSets[] = [{ data: [0], label: '' }];

  constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando) {
    this.cargarDatos();
  }

  ngOnInit() {}

  // events
  public chartClicked({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  cargarDatos(): void {
    this.ctx.documentosVenta
      .obtenerVentasCanceladas()
      .pipe(first())
      .subscribe((ventas) => {
        this.barChartData = ventas.map((v) => {
          return {
            data: [v.ventas.length],
            label: v.motivoCancelacion ? v.motivoCancelacion.descripcion : ''
          };
        });
      });
  }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Compra, SolicitudCompra, CotizacionCompra } from '../entidades/compra';
import { ArchivoProveedorCompra } from '../entidades/archivo-proveedor-compra';
import { Venta } from '../entidades/venta';
import { DatosEquipo } from '../entidades/datos-equipo';
import { DocumentoDuplicar } from '../entidades/documento-duplicar';
import { Documento } from '../entidades/documento';
import { Socio } from '../entidades/socio';

export class RepositorioCompra extends RepositorioCatalogo<Compra> {
  constructor(http: HttpClient) {
    super(http, 'Compras');
  }

  obtenerCompraDocumento(idDocumento: number): Observable<Documento> {
    const ruta = `${this.ruta}/ObtenerCompraDocumento/${idDocumento}`;
    return this.clienteHttp.get<Documento>(ruta);
  }

  //#region Solicitudes cotización
  obtenerSolicitudCotizacion(idSolicitud: number): Observable<SolicitudCompra> {
    const ruta = `${this.ruta}/SolicitudesCotizacion/${idSolicitud}`;
    return this.clienteHttp.get(ruta).pipe(
      map((solicitud: SolicitudCompra) => {
        return solicitud;
      })
    );
  }

  guardarSoliditudesCotizacion(solicitudes: FormData): Observable<Compra[]> {
    const ruta = `${this.ruta}/GuardarSolicitudesCotizacion`;
    return this.clienteHttp.post(ruta, solicitudes).pipe(
      map((compras: Compra[]) => {
        return compras;
      })
    );
  }

  guardarDatosEquipo(datosEquipos: DatosEquipo[]): Observable<void> {
    const ruta = `${this.ruta}/DatosEquipo`;
    return this.clienteHttp.put(ruta, datosEquipos).pipe(map(() => null));
  }

  actualizarSoliditudesCotizacion(solicitud: FormData): Observable<Compra> {
    const ruta = `${this.ruta}/SolicitudesCotizacion`;
    return this.clienteHttp.put(ruta, solicitud).pipe(
      map((compra: Compra) => {
        return compra;
      })
    );
  }

  crearEnviarCostos(idSolicitud: number): Observable<boolean> {
    const ruta = `${this.ruta}/CrearEnviarCostos/${idSolicitud}`;
    return this.clienteHttp.post(ruta, {}).pipe(
      map((enviada: boolean) => {
        return enviada;
      })
    );
  }

  reenviarEnviarCostos(idCostos: number): Observable<boolean> {
    const ruta = `${this.ruta}/ReenviarEnviarCostos/${idCostos}`;
    return this.clienteHttp.get(ruta).pipe(
      map((enviada: boolean) => {
        return enviada;
      })
    );
  }
  //#endregion

  //#region Archivos
  descargarArchivoServicio(idArhivo: number): Observable<Blob> {
    const ruta = `${this.ruta}/ArchivosServicios/Descargar/${idArhivo}`;
    return this.clienteHttp.get(ruta, {
      responseType: 'blob'
    }).pipe(map((pdf: Blob) => pdf));
  }

  descargarArchivoProveedor(idArhivo: number): Observable<Blob> {
    const ruta = `${this.ruta}/ArchivosProveedor/Descargar/${idArhivo}`;
    return this.clienteHttp.get(ruta, {
      responseType: 'blob'
    }).pipe(map((pdf: Blob) => pdf));
  }
  //#endregion

  //#region Cotizaciones
  obtenerCotizacion(idCotizacion: number): Observable<CotizacionCompra> {
    const ruta = `${this.ruta}/Cotizaciones/${idCotizacion}`;
    return this.clienteHttp.get(ruta).pipe(
      map((cotizacion: CotizacionCompra) => {
        return cotizacion;
      })
    );
  }

  obtenerCotizaciones(idProveedor: number): Observable<CotizacionCompra[]> {
    const ruta = `${this.ruta}/Cotizaciones/PorProveedor/${idProveedor}`;
    return this.clienteHttp.get(ruta).pipe(
      map((cotizaciones: CotizacionCompra[]) => {
        return cotizaciones;
      })
    );
  }

  obtenerCotizacionesDatosEquipo(
    idProveedor: number
  ): Observable<CotizacionCompra[]> {
    const ruta = `${this.ruta}/DatosEquipo/PorProveedor/${idProveedor}`;
    return this.clienteHttp.get(ruta).pipe(
      map((cotizaciones: CotizacionCompra[]) => {
        return cotizaciones;
      })
    );
  }

  generarCotizacion(idCostos: number): Observable<Compra[]> {
    const ruta = `${this.ruta}/Cotizaciones/${idCostos}`;
    return this.clienteHttp.post(ruta, {}).pipe(
      map((compras: Compra[]) => {
        return compras;
      })
    );
  }

  guardarCotizacion(cotizacion: CotizacionCompra): Observable<Compra[]> {
    const ruta = `${this.ruta}/Costos`;
    return this.clienteHttp.put(ruta, cotizacion).pipe(
      map((compras: Compra[]) => {
        return compras;
      })
    );
  }

  actualizarCotizacion(cotizacion: FormData): Observable<Compra> {
    const ruta = `${this.ruta}/Cotizaciones`;
    return this.clienteHttp.put(ruta, cotizacion).pipe(
      map((compra: Compra) => {
        return compra;
      })
    );
  }

  //#endregion

  //#region  Compra
  guardarCompra(listadoCompras: {
    compras: Compra[];
    archivosProveedor: ArchivoProveedorCompra[];
  }): Observable<Compra[]> {
    const ruta = `${this.ruta}/CotizacionesCompra`;
    return this.clienteHttp.post(ruta, listadoCompras).pipe(
      map((compras: Compra[]) => {
        return compras;
      })
    );
  }

  actualizarCompra(compra: Compra): Observable<Compra> {
    const ruta = `${this.ruta}/CotizacionesCompra`;
    return this.clienteHttp.put(ruta, compra).pipe(
      map((resultadoCompra: Compra) => {
        return resultadoCompra;
      })
    );
  }

  crearVenta(idCompra: number): Observable<Venta> {
    const ruta = `${this.ruta}/CrearVenta/${idCompra}`;
    return this.clienteHttp.post(ruta, null).pipe(
      map((venta: Venta) => {
        return venta;
      })
    );
  }

  cerrarCotizacion(idCotizacion: number): Observable<Compra> {
    const ruta = `${this.ruta}/CerrarCotizacion/${idCotizacion}`;
    return this.clienteHttp.put(ruta, null).pipe(
      map((compra: Compra) => {
        return compra;
      })
    );
  }

  //#endregion

  enviarDatosEquipo(idCotizacion: number): Observable<boolean> {
    const ruta = `${this.ruta}/EnviarDatosEquipo/${idCotizacion}`;
    return this.clienteHttp.get(ruta).pipe(
      map((enviada: boolean) => {
        return enviada;
      })
    );
  }

  ObtenerComprasPorEnviar(): Observable<Compra[]> {
    const ruta = `${this.ruta}/ComprasPorEnviar/`;
    return this.clienteHttp.get(ruta).pipe(
      map((compras: Compra[]) => {
        return compras;
      })
    );
  }

  generarFolio(): Observable<string> {
    const ruta = `${this.ruta}/GenerarFolio`;
    return this.clienteHttp.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }

  cancelar(idCompra: number, idMotivo: number): Observable<void> {
    const ruta = `${this.ruta}/CancelarDocumento/${idCompra}/${idMotivo}`;
    return this.clienteHttp.get<void>(ruta);
  }

  duplicar(documento: DocumentoDuplicar): Observable<Compra> {
    const ruta = `${this.ruta}/Duplicar`;
    return this.clienteHttp.post<Compra>(ruta, documento);
  }
}

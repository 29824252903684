import { Directive, HostBinding } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Directive({
  selector: '[creContainer]'
})
export class ContainerDirective {
  width = '1100px';

  @HostBinding('style.flex-basis') basis = this.width;
  @HostBinding('style.flex-grow') grow = 0;
  @HostBinding('style.flex-shrink') shrink = 1;
  @HostBinding('style.margin') margin = '1rem';
  @HostBinding('style.max-width') maxWidth = this.width;
  @HostBinding('class.mat-elevation-z0') elevation0: boolean;

  constructor(public mediaObserver: MediaObserver) {
    mediaObserver.media$.subscribe(({ mqAlias }) => {
      switch (mqAlias) {
        case 'xs':
          this.margin = '0';
          this.elevation0 = true;
          break;
        default:
          this.margin = '1rem';
          this.elevation0 = false;
          break;
      }
    });
  }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { ModoTransporte } from '../entidades/modo-transporte';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoProveedor } from '../entidades/tipo-proveedor';
import { AsignacionTipoProveedor } from '../entidades/asignacion-tipo-proveedot';
import { TipoCamion } from '../entidades/tipo-camion';
import { AsignacionTipoCamion } from '../entidades/asignacion-tipo-camion';

export class RepositorioTipoCamion extends RepositorioCatalogo<TipoCamion> {
  constructor(http: HttpClient) {
    super(http, 'TipoCamion');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);

  }


  obtenerTodosActivos(): Observable<TipoCamion[]> {
    const ruta = `${this.ruta}/ObtenerTodosActivos`;
    return this.clienteHttp.get<TipoCamion[]>(ruta);

  }


  asignar(asignacion: AsignacionTipoCamion): Observable<AsignacionTipoCamion> {
    const ruta = `${this.ruta}/Asignar`;
    return this.clienteHttp.post(ruta, asignacion).pipe(
      map((asig: AsignacionTipoCamion) => asig)
    );
  }

  desasignar(asignacion: AsignacionTipoCamion): Observable<void> {
    const ruta = `${this.ruta}/Desasignar/${asignacion.tipoCamionId}/${asignacion.clienteId}`;
    return this.clienteHttp.delete(ruta).pipe(map(() => null));
  }



  obtenerTipoCamionCliente(IdCliente: number): Observable<TipoCamion[]> {
    const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
    return this.clienteHttp.get<TipoCamion[]>(ruta);
  }
}

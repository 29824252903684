import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable } from 'rxjs';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SolicitudEmbarque } from 'src/app/entidades/solicitud-embarque';
import { startWith, map } from 'rxjs/operators';
import { AsignacionEmbarque } from 'src/app/entidades/asignacion-embarque';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@Component({
    selector: 'app-asignar-solicitud-embarque',
    templateUrl: './asignar-solicitud-embarque.component.html',
    styleUrls: ['./asignar-solicitud-embarque.component.scss'],
})
export class AsignarEmbarqueComponent implements OnInit, OnDestroy {
    title = 'Cotización Venta';
    folio: string;
    forma: FormGroup;

    get f() { return this.forma.controls; }

    get ventanaNormal(): boolean {
        return this.parametros.tipo === 'normal';
    }

    folios: SolicitudEmbarque[] = [];
    foliosFiltrados: Observable<SolicitudEmbarque[]> = new Observable<SolicitudEmbarque[]>();
    filtroFolio = new FormControl();

    constructor(
        @Inject(MAT_DIALOG_DATA) public parametros: { idDocumento: number; tipo: 'normal' | 'confirmacion'; },
        public dialogRef: MatDialogRef<AsignarEmbarqueComponent>,
        private ctx: Contexto,
        public autenticacion: Autenticacion,
        public ventanaDocumento: MatDialog,
        private formBuilder: FormBuilder,
        public alerta: ServicioAlerta
    ) {
    }

    ngOnInit() {
        this.forma = this.formBuilder.group({
            idDocumento: [this.parametros.idDocumento, Validators.required],
            idEmbarque: [null, Validators.required],
            cliente: [this.autenticacion.perfil.razonSocial, Validators.required]
        });
        this.cargarFolios();
    }

    ngOnDestroy(): void { }

    guardar() {
        if (this.forma.valid) {
            const model = this.forma.value as AsignacionEmbarque;
            this.ctx.solicitudesEmbarque.asignarSolicitudEmbarque(model).subscribe(result => {
                if (result.idDocumento) {
                    this.alerta.mostrarExito(`Asignacion de documento realizada con exito`);
                    this.dialogRef.close(true);
                } else {
                    this.alerta.mostrarError(`La asignacion de documento genero un error inesperado`);
                }
            });
        }
    }

    cargarFolios() {
        this.ctx.solicitudesEmbarque.obtenerSolicitudesEmbarque(this.autenticacion.perfil.id).subscribe(result => {
            this.folios = result;
            this.foliosFiltrados = this.filtroFolio.valueChanges
                .pipe(
                    startWith<string | SolicitudEmbarque>(''),
                    map((t) => typeof t === 'string' ? t : t == null ? '' : t.folio),
                    map((t) => t ? this.filtrarFolio(t) : this.folios.slice())
                );
        }, error => console.error(error));
    }

    private filtrarFolio(folio: string): SolicitudEmbarque[] {
        const folioFiltro = folio.toString().toLowerCase();
        return this.folios.filter(t => t.folio.toString().toLowerCase().includes(folioFiltro));
    }

    folioSeleccionado(solicitudEmbarque: SolicitudEmbarque) {
        this.f['idEmbarque'].setValue(solicitudEmbarque.id);
        this.filtroFolio.setValue(solicitudEmbarque && solicitudEmbarque.folio ?
            `${solicitudEmbarque.folio} - ${solicitudEmbarque.referencia}` : '');
    }
}

import {
    Component,
    OnInit,
} from '@angular/core';
import {
    MatDialog,
} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contexto } from '../../api/contexto.service';
import { ServicioAlerta } from '../../utilerias/alerta.service';
import { Autenticacion } from '../../autenticacion/autenticacion.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FiltroExportacion } from 'src/app/entidades/filtro-exportacion';
import { Proveedor } from 'src/app/entidades/proveedor';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { CompraExportacion } from 'src/app/entidades/compra-exportacion';

@AutoUnsubscribe()
@Component({
    selector: 'exportacion-informacion',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoExportacionInfoComponent implements OnInit {
    formaExportacion: FormGroup;
    filtro: FiltroExportacion;
    cargando: boolean = false;
    proveedores: Proveedor[] = [];
    documentoCompra: CompraExportacion[] = [];
    get f() {
        return this.formaExportacion.controls;
    }
    constructor(
        public ventana: MatDialog,
        private spinner: LoadingService,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private autenticacion: Autenticacion,
        private formBuilder: FormBuilder,
        private dialog: MatDialog
    ) {}

    async ngOnInit() {
        this.formaExportacion = this.formBuilder.group({
            fechaInicial: [new Date(), Validators.nullValidator],
            fechaFinal: [new Date(), Validators.nullValidator],
        });
    }

    async exportarProveedores() {
        try {
            this.spinner.show('Enviando lista de proveedores');
            this.proveedores = await this.ctx.socios
                .obtenerProveedoresMigracion()
                .toPromise();
            await this.ctx.exportacion
                .migracionProveedores(this.proveedores)
                .toPromise()
                .then((proveedores) => {
                    this.alerta.mostrarExito(
                        'Proveedores exportados con éxito'
                    );
                    this.spinner.hide();
                })
                .catch((e) => {
                    this.spinner.hide();

                    console.log(e);
                    this.alerta.mostrarError('Error al importar proveedores');
                });

        } catch (error) {
            this.spinner.hide();

            console.log(error);
            this.alerta.mostrarError('Error interno');
        }
    }

    async exportarOrdenesCompra() {
        try {
            this.spinner.show('Enviando lista de cotizaciones de compra');
            this.documentoCompra = await this.ctx.documentosCompra
                .obtenerDocumentosCompraMigracion()
                .toPromise();
            await this.ctx.exportacion
                .migracionOrdenesCompra(this.documentoCompra)
                .toPromise()
                .then((compras) => {
                    this.alerta.mostrarExito(
                        'Ordenes de compra exportados con éxito'
                    );
                    this.spinner.hide();
                })
                .catch((e) => {
                    this.spinner.hide();

                    console.log(e);
                    this.alerta.mostrarError(
                        'Error al importar las ordenes de compra'
                    );
                });
        } catch (error) {
            this.spinner.hide();

            console.log(error);
            this.alerta.mostrarError('Error interno!');
        }
    }
}

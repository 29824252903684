import { Component, OnInit, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { GeneradorReportes } from 'src/app/entidades/generador-reportes';
import { Reporteador } from 'src/app/entidades/reporteador';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';

@Component({
    selector: 'catalogo-reporte-tabla',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoReporteTablaComponent implements OnInit {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    fuenteDatos: MatTableDataSource<Reporteador> = new MatTableDataSource([]);
    columnasPorMostrar = [
        ['proyecto', 'Folio del proyecto', true],
        ['cliente', 'Cliente', true],
        ['proyectoDescripcion', 'Descripción del proyecto', true],
        ['proyectoSAP', 'Proyecto SAP', true],
        ['descripcionProyectoSAP', 'Descripción del Proyecto SAP', true],
        ['tipoProyectoDescripcion', 'Tipo de proyecto', true],
        ['fechaProyecto', 'Fecha del proyecto', true],
        ['estatusProyecto', 'Estatus del proyecto', true],
        ['origenDocumento', 'Origen del documento', true],
        ['estadoDocumento', 'Estado de documento', true],
        ['asociadaEn', 'Facturada en', false],
        ['documentoCancelado', 'Cancelado', true],
        ['enviadasSAP', 'Enviado a SAP', true],
        ['sv', 'Solicitud de venta', true],
        ['ct', 'Costos', true],
        ['cv', 'Cotización de venta', true],
        ['tipoServicioDescripcion', 'Tipo de servicio', false],
        ['servicioDescripcion', 'Modo', false],
        ['cantidadServicios', '# Servicios', false],
        ['fechaDocumento', 'Fecha de documento', true],
        ['fechaRequerida', 'Fecha requerida', false],
        ['fechaCompromiso', 'Fecha compromiso', false],
        ['fechaEnvioSap', 'Fecha de envío a SAP', true],
        ['facturaFechaPago', 'Fecha de pago SAP', false],
        ['numeroFactura', 'Número de factura/Orden SAP', true],
        ['fechaFactura', 'Fecha factura SAP', false],
        ['estatusFactura', 'Estatus de factura', true],
        ['importeMonedaOrigenCompra', 'Importe moneda de compra', true],
        ['importeMonedaOrigenVenta', 'Importe moneda de venta', true],
        ['moneda', 'Moneda', true],
        ['subTotal', 'Subtotal', true],
        ['importeIVA', 'IVA', true],
        ['aplicoRetencion', 'Retención', true],
        ['total', 'Total', true],
        ['tipoCambio', 'Tipo de cambio', true],
        ['empleado', 'Emleado', true],
        ['proveedor', 'Proveedor', true],
        ['distancia', 'Distancia', false],
        ['numeroCarga', 'No. Carga', false],
        ['origenes', 'Origenes', true],
        ['destinos', 'Destinos', true],
        ['motivoNoEnvio', 'Motivo no envio', true],
        ['motivoNoFacturar', 'Motivo no facturar', true],
    ];

    get columnasSeleccionadas() {
        return this.columnasPorMostrar.filter((c) => c[2]).map((c) => c[0]);
    }

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
            return 10;
        }
        return 100;
    }
    constructor(
        public ventana: MatDialog,
        private ctx: Contexto,
        public spinner: LoadingService
    ) {}

    ngOnInit() {}

    crearReporte(reporteador: GeneradorReportes, conTipoServicio: boolean): void {
      this.spinner.show('Buscando...');
      this.ctx.reporteador
          .obtenerReporteReporteador(reporteador, conTipoServicio)
          .toPromise()
          .then((r) => {
              console.log('crearReporteDetallado-->',r);
              // if(conTipoServicio){
              this.columnasPorMostrar.find(c => c[0] == 'tipoServicioDescripcion')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'servicioDescripcion')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'cantidadServicios')[2] = true;

              this.columnasPorMostrar.find(c => c[0] == 'fechaFactura')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'asociadaEn')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'numeroCarga')[2] = true;

              this.columnasPorMostrar.find(c => c[0] == 'fechaRequerida')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'fechaCompromiso')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'facturaFechaPago')[2] = true;

              this.columnasPorMostrar.find(c => c[0] == 'distancia')[2] = true;

              this.columnasPorMostrar.find(c => c[0] == 'proyectoSAP')[2] = !conTipoServicio;
              this.columnasPorMostrar.find(c => c[0] == 'descripcionProyectoSAP')[2] = !conTipoServicio;

              this.columnasPorMostrar.find(c => c[0] == 'descripcionProyectoSAP')[2] = !conTipoServicio;

              //   }else{
              //     this.columnasPorMostrar.find(c => c[0] == 'tipoServicioDescripcion')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'servicioDescripcion')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'cantidadServicios')[2] = true;

              //     this.columnasPorMostrar.find(c => c[0] == 'fechaFactura')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'asociadaEn')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'numeroCarga')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'fechaRequerida')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'fechaCompromiso')[2] = true;
              //   }
              this.fuenteDatos = new MatTableDataSource(r);
              this.fuenteDatos.paginator = this.paginador;
              this.fuenteDatos.sort = this.ordenador;
              console.log('fuenteDatos-->', this.fuenteDatos);
              this.spinner.hide();
          })
          .catch((e) => {
              console.log('e-->', e);
              this.spinner.hide();
          });
    }

    crearReporteAuditoriaVentas(reporteador: GeneradorReportes,estatusCompra: Number): void {
      this.spinner.show('Buscando...');
      this.ctx.reporteador
          .obtenerReporteReporteadorAuditoriaVentas(reporteador,estatusCompra)
          .toPromise()
          .then((r) => {
              // if(conTipoServicio){
              this.columnasPorMostrar.find(c => c[0] == 'tipoServicioDescripcion')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'servicioDescripcion')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'cantidadServicios')[2] = true;

              this.columnasPorMostrar.find(c => c[0] == 'fechaFactura')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'asociadaEn')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'numeroCarga')[2] = true;

              this.columnasPorMostrar.find(c => c[0] == 'fechaRequerida')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'fechaCompromiso')[2] = true;
              this.columnasPorMostrar.find(c => c[0] == 'facturaFechaPago')[2] = true;

              //   }else{
              //     this.columnasPorMostrar.find(c => c[0] == 'tipoServicioDescripcion')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'servicioDescripcion')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'cantidadServicios')[2] = true;

              //     this.columnasPorMostrar.find(c => c[0] == 'fechaFactura')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'asociadaEn')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'numeroCarga')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'fechaRequerida')[2] = true;
              //     this.columnasPorMostrar.find(c => c[0] == 'fechaCompromiso')[2] = true;
              //   }
              this.fuenteDatos = new MatTableDataSource(r);
              this.fuenteDatos.paginator = this.paginador;
              this.fuenteDatos.sort = this.ordenador;
              console.log('fuenteDatos-->', this.fuenteDatos);
              this.spinner.hide();
          })
          .catch((e) => {
              console.log('e-->', e);
              this.spinner.hide();
          });
    }

    limpiarTabla(): void {}

    exportarExcel(reporteador: GeneradorReportes, conTipoServicio: boolean): void {
        this.spinner.show('Exportando a excel...');
        const fechaActual = new Date();
        const nombreArchivo =
            'Reporte_Detallado_hasta_el_día_' + fechaActual.toLocaleString();
        this.ctx.reporteador
            .obtenerReporteExcel(reporteador, conTipoServicio)
            .toPromise()
            .then((respuesta) => {
                this.spinner.hide();
                this.llamarExcel(respuesta, nombreArchivo);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    exportarExcelAuditoriaVentas(reporteador: GeneradorReportes, estatusCompra: Number): void {
      this.spinner.show('Exportando a excel...');
      const fechaActual = new Date();
      const nombreArchivo =
          'Reporte_Auditoria_ Ventas_hasta_el_día_' + fechaActual.toLocaleString();
      this.ctx.reporteador
          .obtenerReporteExcelAuditoriaVentas(reporteador,estatusCompra)
          .toPromise()
          .then((respuesta) => {
              this.spinner.hide();
              this.llamarExcel(respuesta, nombreArchivo);
          })
          .catch((e) => {});
    }


    llamarExcel(respuesta, nombreArchivo) {
        const blob = new Blob([respuesta], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = nombreArchivo;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    seleccionarColumna(indice: number) {
        const columna = this.columnasPorMostrar[indice];
        columna[2] = !columna[2];
    }

    seleccionarTodasLasColumnas() {
        this.columnasPorMostrar.forEach((c) => (c[2] = true));
    }
}

import { ContactoSocio } from './contacto-socio';
import { DireccionSocio } from './direccion-socio';

export class Socio {
    id: number;
    nombreCorto: string;
    razonSocial: string;
    nombreComercial: string;
    rfc: string;
    representante: string;
    esProveedor: boolean;
    esCliente: boolean;
    activo: boolean;
    categoriaEmpresaId: number;
    contactos: ContactoSocio[];
    direccionesSocio: DireccionSocio[];
    codigoCliente: string;
    codigoProveedor: string;
    imagen: string;
    extension: string;
    servicio: string;
    vatidUnCmp: string;
}

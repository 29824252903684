import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { Unidad } from "../entidades/unidad";

export class RepositorioUnidad extends RepositorioCatalogo<Unidad> {

    constructor(http: HttpClient) {
        super(http, "Unidades");
    }

}

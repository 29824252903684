import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { flatMap } from 'rxjs/operators';
import { AsignacionClienteDireccion } from 'src/app/entidades/AsignacionClienteDireccion';

@Component({
  selector: 'catalogo-camion',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class AsignacionDireccionesServicioComponent
  implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<Direccion> = new MatTableDataSource([]);
  columnasMostradas = [
    'paisDescripcion',
    'estadoDescripcion',
    'ciudadDescripcion',
    'direccion',
    'esOrigen',
    'esDestino',
    'asignacion'
  ];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  direccionesCliente: Direccion[];
  subsDirecciones: Subscription;
  subsAsignar: Subscription;
  subsDesasignar: Subscription;
  idCliente: number;

  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private autenticacion: Autenticacion,
    private alerta: ServicioAlerta
  ) {}

  ngOnInit() {
    this.idCliente = this.autenticacion.perfil.id;
    this.cargarDirecciones();
  }

  cargarDirecciones(): void {
    this.cargando = true;
    this.subsDirecciones = this.ctx.direccionesServicio
      .obtenerTodosCliente(this.idCliente)
      .pipe(
        flatMap(direcciones => {
          this.direccionesCliente = direcciones;
          return this.ctx.direccionesServicio.obtenerTodasDirecciones();
        })
      )
      .subscribe(direcciones => {
        this.fuenteDatos = new MatTableDataSource(direcciones);
        this.cargando = false;
      });
  }

  cambiarEstado(seleccionado: boolean, idDireccion: number): void {
    if (seleccionado) {
      this.asignar(idDireccion);
    } else {
      this.desasignar(idDireccion);
    }
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  asignar(idDireccionServicio: number): void {
    const asignacion = new AsignacionClienteDireccion();
    asignacion.clienteId = this.idCliente;
    asignacion.direccionServicioId = idDireccionServicio;

    this.subsAsignar = this.ctx.direccionesServicio
      .asignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación guardada');
        },
        error => {
          this.alerta.mostrarError('Error al asignar dirección');
        }
      );
  }

  desasignar(idDireccionServicio: number): void {
    const asignacion = new AsignacionClienteDireccion();
    asignacion.clienteId = this.idCliente;
    asignacion.direccionServicioId = idDireccionServicio;

    this.subsDesasignar = this.ctx.direccionesServicio
      .desasignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación eliminada');
        },
        error => {
          this.alerta.mostrarError('Error al desasignar la dirección');
        }
      );
  }

  direccionAsignada(idDireccion: number): boolean {
    const direccion = this.direccionesCliente.find(d => d.id === idDireccion);
    console.log(direccion);
    if (direccion) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) {
      this.fuenteDatos.disconnect();
    }
    if (this.subsAsignar) {
      this.subsAsignar.unsubscribe();
    }
    if (this.subsAsignar) {
      this.subsAsignar.unsubscribe();
    }
    if (this.subsDirecciones) {
      this.subsDirecciones.unsubscribe();
    }
  }
}

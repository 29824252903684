import { HttpClient } from "@angular/common/http";
import { Impuesto } from "../entidades/impuesto";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export class RepositorioImpuesto extends RepositorioCatalogo<Impuesto> {

    constructor(http: HttpClient) {
        super(http, "Impuestos");
    }

    activar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    obtenerImpuestosCotizacionVenta(): Observable<Impuesto[]> {
        let ruta = `${this.ruta}/ObtenerImpuestosCotizacionVenta`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Impuesto[]) => {
                return data;
            }));
    }

    obtenerImpuestosSolicitudVenta(): Observable<Impuesto[]> {
        let ruta = `${this.ruta}/ObtenerImpuestosSolicitudVenta`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Impuesto[]) => {
                return data;
            }));
    }
}

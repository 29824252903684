import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RepoBaseOnMonitor } from './repo-base-onmonitor';


export abstract class RepositorioOnMonitor<T> extends RepoBaseOnMonitor<T> {

  constructor(http: HttpClient, controlador: string) {
    super(http, controlador);
  }



}

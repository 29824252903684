import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asignar-fecha-cv',
  templateUrl: './asignar-fecha-cv.component.html',
  styleUrls: ['./asignar-fecha-cv.component.scss']
})
export class AsignarFechaCvComponent implements OnInit {
  asignarFecha = new Date();
  constructor() {}

  ngOnInit() {}
}

import { MetaEmpleado } from './meta-empleado';

export class Empleado {
  id: number;
  clave: string;
  rfc: string;
  nombre: string;
  correo: string;
  telefono: string;
  color: string;
  contrasena: string;
  confirmacionContrasena: string;
  metas: MetaEmpleado[];
  esJefe: Boolean;
  jefeId: number;
  activo: Boolean;
}

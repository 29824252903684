import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
 
import { Contexto } from '../../../api/contexto.service';
import { ServicioAlerta } from '../../../utilerias/alerta.service';
import { Correo } from '../../../entidades/correos';


@Component({
    selector: 'forma-correo',
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss']
})
export class FormaCorreoComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    correo: Correo;
    idSeleccionado: number = 0;
    cargando: boolean = false;
    subsGuardar: Subscription;
    guardado: EventEmitter<void> = new EventEmitter<void>();
    get f() { return this.forma.controls; }

    constructor(private formBuilder: FormBuilder, private ctx: Contexto, private alerta: ServicioAlerta) {}

    ngOnInit() {
        this.generarForma();
        this.cargarCorreo();
    }

    generarForma():void {
        this.forma = this.formBuilder.group(
            {
                direccion: ['', [Validators.required, Validators.email]],
                contrasena: ['', Validators.required],
                servidor: ['', Validators.required],
                puerto: ['', Validators.required],
                usaSSL: [null, Validators.nullValidator]
            }
        );
    }

    cargarCorreo(): void {
        this.ctx
        .correos
        .obtenerCorreoUnico()
        .subscribe(
            correo => {
                this.correo = correo;
                Object.assign(this.forma.value, this.correo);
                this.forma.reset(this.forma.value);
            });
    }

    limpiar(): void {
        this.forma.reset();
    }

    guardar(): void {
        if (this.forma.valid) {
            this.cargando = true;
            Object.assign(this.correo, this.forma.value);

            let mensaje = 'correo actualizado';
            let observable = this.ctx.correos.actualizar(this.idSeleccionado.toString(), this.correo);

            this.subsGuardar = observable.subscribe(() => {
                this.guardado.emit();
                this.alerta.mostrarExito(mensaje);
                this.cargando = false;    
            },
                () => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                });
        }
        else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
    }
}

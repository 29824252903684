import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { filter } from 'lodash';

@Component({
    selector: 'app-perfect-order-data',
    templateUrl: './perfect-order-data.component.html',
    styleUrls: ['./perfect-order-data.component.scss']
})
export class PerfectOrderDataComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['periodo', 'promPrev', 'serviciosActivos', 'aTiempo', 'objetivo', 'promAct'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], rastreospre: RastreoClienteModel[], objetivo: number, yearF: number },
        private ventana: MatDialogRef<PerfectOrderDataComponent>) {
    }


    ngOnInit(): void {

        //this.object.rastreospre = filter(c => Number(moment(c.fechaCompromiso).format('YYYY')) == Number(moment(new Date()).format('YYYY'))) - 1;

        const months = _.chain(this.object.rastreos)
            .groupBy(c => moment(c.fechaCompromiso).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    monthNumberStr: String(Number(y) - 1),
                    numeroMes: Number(y),
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();

        const monthsPre = _.chain(this.object.rastreospre)
            //.filter(c => Number(moment(c.fechaCompromiso).format('YYYY')) == this.object.yearF)
            .groupBy(c => moment(c.fechaCompromiso).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();

        const monthValuesPrev = _.chain(monthsPre.map(c => c.data))
            .map(c => {
                return (c.filter(d => d.fechaEntrega < d.fechaCompromiso).length / c.length) * 100;
            }).value();

        const avarageMonths = _.chain(monthsPre.map(c => c.data)).sortBy(d => d).map(c => {
            return (c.filter(d => d.fechaEntrega < d.fechaCompromiso).length / c.length) * 100;
        }).value();

        const avarageMonth = _.reduce(avarageMonths, (memo, v) => memo + v, 0) / avarageMonths.length;
        const i = 0;
        const monthsModel = _.chain(months).map((month, key) => {
            return {
                'periodo': month.monthName,
                'promAct': `${((month.data.filter(d => d.fechaEntrega < d.fechaCompromiso).length / month.data.length) * 100).toFixed(2)} %`,
                'promPrev': `${Number(month.numeroMes) > monthValuesPrev.length ? '' : monthValuesPrev[month.monthNumberStr].toFixed(2)} %`,
                'serviciosActivos': month.data.length,
                'aTiempo': month.data.filter(d => d.fechaEntrega < d.fechaCompromiso).length,
                //'nivelServicio': `${((month.data.filter(d => d.fechaEntrega < d.fechaCompromiso).length / month.data.length) * 100).toFixed(2)} %`,
                'objetivo': `${this.object.objetivo.toFixed(2)} %`
            };
        }).value();


        monthsModel.pop();

        const lastMonth = _.chain(months).last().value();
        const weeks = _.chain(lastMonth.data)
            .groupBy(c => moment(c.fechaCompromiso).week())
            .map((x, y) => {
                return {
                    weekNumber: `${Number(y) + 1}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.weekNumber)).value();

        months.pop();

        const weeksModel = _.chain(weeks).map(month => {
            return {
                'periodo': `W${month.weekNumber}`,
                'promPrev': '0.00',
                'serviciosActivos': month.data.length,
                'aTiempo': month.data.filter(d => d.fechaEntrega < d.fechaCompromiso).length,
                //'nivelServicio': `${((month.data.filter(d => d.fechaEntrega < d.fechaCompromiso).length / month.data.length) * 100).toFixed(2)} %`,
                'objetivo': `${this.object.objetivo.toFixed(2)} %`,
                'promAct': `${((month.data.filter(d => d.fechaEntrega < d.fechaCompromiso).length / month.data.length) * 100).toFixed(2)} %`,
            };
        }).value();



        this.fuenteDatos = new MatTableDataSource(monthsModel.concat(weeksModel));
        this.fuenteDatos.sort = this.ordenador;

    }

    division(a: number, b: number): number {
        if (a == 0) {
            return 0;
        }
        else {
            return b / a;
        }
    }
}
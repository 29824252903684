import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, MatDialogRef } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contexto } from '../../api/contexto.service';
import { ServicioAlerta } from '../../utilerias/alerta.service';
import { Autenticacion } from '../../autenticacion/autenticacion.service';

import { Usuario } from '../../entidades/usuario';
import { Socio } from 'src/app/entidades/socio';


@Component({
    selector: 'cuenta-usuario',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss']
})

export class CatalogoCuentaUsuarioComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    formaCuentaUsuario: FormGroup;
    socio: Socio;
    usuario: Usuario;
    nombreUsuario: string;
    cargando: boolean = false;
   

    guardado: EventEmitter<void> = new EventEmitter<void>();
    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    get f() { return this.formaCuentaUsuario.controls; }
    constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta, private autenticacion: Autenticacion, private formBuilder: FormBuilder, private dialog: MatDialog) {

    }

    async ngOnInit() {
        this.formaCuentaUsuario = this.formBuilder.group(
            {
                usuarioId: [''],
                nombre: ['', Validators.required],
                contrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]],
                confirmacionContrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]],
            }
        );
        await this.cargarDatosEmpresa();
    }

    guardar(): void {
        if (this.formaCuentaUsuario.valid) {
            this.cargando = true;
            Object.assign(this.usuario, this.formaCuentaUsuario.value);
            if (this.usuario.contrasena != this.usuario.confirmacionContrasena) {
                this.alerta.mostrarError('¡Error, la contraceña no coincide!');
                this.cargando = false;
                return;
            }
            let observable: Observable<Usuario>;
            let mensaje = 'Cuenta actualizada con éxito.';
            observable = this.ctx.usuarios.actualizarPasswordUsuario(this.usuario);
            this.subsGuardar = observable.subscribe(() => {
                this.guardado.emit();
                this.alerta.mostrarExito(mensaje);
                this.cargando = false;
            },
                error => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                });
        }
        else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }


    async cargarDatosEmpresa() {
        let a = await this.ctx.usuarios.obtenerSocioUsuario(this.autenticacion.credencial.idUsuario).toPromise();
        this.usuario = a;
        if (this.usuario == null) {
            this.usuario = new Usuario();
        }
        Object.assign(this.formaCuentaUsuario.value, this.usuario);
        this.formaCuentaUsuario.reset(this.formaCuentaUsuario.value);
        this.nombreUsuario = this.autenticacion.credencial.nombreUsuario;
        this.f['nombre'].setValue(this.autenticacion.credencial.nombreUsuario);
        this.f['usuarioId'].setValue(this.autenticacion.credencial.idUsuario);
        // this.f['contrasena'].setValue("***********");
        // this.f['confirmacioncontrasena'].setValue("***********");
    }

    verContrasena(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
    }

    verContrasenaConfirmacion(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
    }


    ngOnDestroy(): void {
        if (this.subsActivar) { this.subsActivar.unsubscribe(); }
        if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
        if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
    }
}
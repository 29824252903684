import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Venta } from 'src/app/entidades/venta';
import { MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { first } from 'rxjs/operators';
import { Empleado } from 'src/app/entidades/empleado';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { FlujoService } from 'src/app/modulos/flujo-ventas/servicios/flujo.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-analisis',
    templateUrl: './analisis.component.html',
    styleUrls: ['./analisis.component.scss'],
})
export class AnalisisComponent implements OnInit {
    fuenteDatos: MatTableDataSource<Venta> = new MatTableDataSource([]);
    empleados: Empleado[];
    ventas: Venta[];

    @Input() cv: number;
    @Output() closeEvent = new EventEmitter<boolean>();
    esEspecifico: boolean = false;

    columnasMostradas = [
        'proyectoFolio',
        'folio',
        'clienteNombreCorto',
        'procesado',
        'estadoDescripcion',
        'fechaDocumento',
        'monedaAbreviatura',
        'subtotal',
    ];

    forma: FormGroup;

    get f() {
        return this.forma.controls;
    }
    constructor(
        private ctx: Contexto,
        public overlay: ServicioOverlayCargando,
        private formBuilder: FormBuilder,
        private alerta: ServicioAlerta,
        private spinner: LoadingService,
        private servicioFlujo: FlujoService,
        private webapi: Contexto,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.esEspecifico = !!this.cv;
        this.forma = this.formBuilder.group({
            folio: ['', Validators.nullValidator],
        });
        if (this.esEspecifico) {
            this.obtenerEspecifico();
        } else {
            this.tabla();
        }
    }

    obtenerEspecifico() {
        this.ctx.documentosVenta
            .obtenerAnalisisVentasEspecifico(this.cv)
            .pipe(first())
            .subscribe((analisis) => {
                this.ventas = analisis
                    .map((a) => a.ventas)
                    .reduce((a, b) => a.concat(b));
                this.empleados = analisis.map((a) => a.empleado);
                this.mostrarTodo();
            });
    }

    public tabla(): void {
        this.ctx.documentosVenta
            .obtenerAnalisisVentas()
            .pipe(first())
            .subscribe((analisis) => {
                this.ventas = analisis
                    .map((a) => a.ventas)
                    .reduce((a, b) => a.concat(b));
                this.empleados = analisis.map((a) => a.empleado);
                this.mostrarTodo();
            });
    }

    private cargarVentas(ventas: Venta[]): void {
        this.fuenteDatos = new MatTableDataSource(ventas);
    }

    mostrarVentasEmpleado(empleadoId: number): void {
        const ventas = this.ventas.filter((v) => v.empleadoId === empleadoId);
        this.cargarVentas(ventas);
    }

    mostrarTodo(): void {
        this.cargarVentas(this.ventas);
    }

    irAlFlujo(venta: Venta) {
        this.spinner.show('Cargando el flujo de documentos...');
        forkJoin(
            this.webapi.documentosVenta.ObtenerSV(venta.id),
            this.webapi.proyectos.obtener(venta.proyectoId)
        )
            .toPromise()
            .then((objetos) => {
                if (this.esEspecifico) {
                    this.closeEvent.emit(this.esEspecifico);
                }
                this.servicioFlujo
                    .cargarParametros(objetos[1], true, false, objetos[0].id)
                    .toPromise()
                    .then(() => {
                        this.router
                            .navigate(['Empresa/FlujoVentas'])
                            .then(() => this.spinner.hide());
                    })
            });
    }

    buscar(): void {
        if (this.f['folio'].value !== '') {
            const ventas = this.ventas.filter(
                (v) => v.folio === this.f['folio'].value
            );
            this.cargarVentas(ventas);
        } else {
            this.alerta.mostrarAdvertencia('Ingrese un número de folío');
            return;
        }
    }

    limpiar(): void {
        this.f['folio'].setValue('');
        this.tabla();
    }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog, MatSort } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { FacturacionPendiente } from 'src/app/entidades/facturacionPendiente';
import { FacturacionPendienteSelection } from '../facturacion-pendiente-mo/facturacion-pendiente-mo.component';
import { FacturacionPendienteGrupoSelection } from '../facturacion-pendiente-mo-gru/facturacion-pendiente-mo-gru.component';
import { VentasPorEnviar } from 'src/app/entidades/VentasPorEnviar'; 

@Component({
    selector: 'app-perfect-order-data',
    templateUrl: './facturacion-pendiente-data.component.html',
    styleUrls: ['./facturacion-pendiente-data.component.scss']
})
export class FacturacionPendienteData implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['jefeProyecto', 'unoSiete', 'ochoCatorce', 'quince'];
    facturacion: FacturacionPendiente[];
    IVentasEntregada: VentasPorEnviar[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: VentasPorEnviar[] },
        public ventana: MatDialogRef<FacturacionPendienteData>,
        public ventana2: MatDialog) {
    }

    ngOnInit(): void {
        console.log(this.object.rastreos);


        const clas = _.chain(this.object.rastreos)
            .filter((c) => c.estatusXmonitor == 'Enviada')
            .groupBy((c) => c.nombreJefe)
            .map((value, key) => {
                const periodCounts = _.countBy(value, (c) => {
                    const days = moment().diff(
                            moment(c.fechaComprometida), 
                            'days'
                        );
                    if (days <= 7) return '1 a 7';
                    if (days <= 14) return '8 a 14';
                    return '+15';
                });
                return {
                    jefeProyecto: key,
                    unoSiete: periodCounts['1 a 7'] || 0,
                    ochoCatorce: periodCounts['8 a 14'] || 0,
                    quince: periodCounts['+15'] || 0
                };
            })
            .value();

            this.fuenteDatos = new MatTableDataSource(clas);
            this.fuenteDatos.sort = this.ordenador;

            console.log(clas);
        // const clas = _.chain(this.object.rastreos)
        //             .groupBy(c => c.jefeProyecto)
        //             .map((x, y) => {
        //                 return {
        //                     clase: y,
        //                     labelClas: x[0].labelClas,
        //                     data: x
        //                 }
        //             })
        //             .sortBy(c => Number(c.clase)).value();
 
        // const unoSie = _.chain(clas).map((info, key) => {
        //     const unsi = info.data.filter(c => c.clasificacion == 1);
        //     const occa = info.data.filter(c => c.clasificacion == 2);
        //     const quce = info.data.filter(c => c.clasificacion == 3);

        //     return {
        //         'jefeProyecto': info.clase,
        //         'unoSiete': unsi.length,
        //         'ochoCatorce': occa.length,
        //         'quince': quce.length

        //     };
        // }).value();

        // this.fuenteDatos = new MatTableDataSource(unoSie);
        // this.fuenteDatos.sort = this.ordenador;

    }
}
import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  ViewChildren,
  ViewChild,
  QueryList
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Contexto } from 'src/app/api/contexto.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ServicioCompra } from 'src/app/entidades/servicio-compra';
import {
  ControlArchivosComponent,
  Archivo
} from 'src/app/utilerias/control-archivos/control-archivo.component';
import { SolicitudCompra, Compra } from 'src/app/entidades/compra';
import { Subscription, of, combineLatest } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Proyecto } from 'src/app/entidades/proyecto';
import { ServicioSolicitudCotizacionCompraComponent } from './servicio-solicitud-cotizacion-compra/servicio-solicitud-cotizacion-compra.component';
import { Socio } from 'src/app/entidades/socio';
import { Moneda } from 'src/app/entidades/moneda';
import { Frontera } from 'src/app/entidades/frontera';
import { Aduana } from 'src/app/entidades/aduana';
import { TipoCarga } from 'src/app/entidades/tipo-carga';
import { UnidadesLongitud } from 'src/app/entidades/unidades-longitud';
import { UnidadesPeso } from 'src/app/entidades/unidades-peso';
import { tap, flatMap } from 'rxjs/operators';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { DestinoServicioCompra } from 'src/app/entidades/destino-servicio-compra';
import { OrigenServicioCompra } from 'src/app/entidades/origen-servicio-compra';
import { MedidaLegal } from 'src/app/entidades/medida-legal';
import {
  CatalogoServicio,
  DatosSolicitudCompra,
  ProveedorSolicitud
} from './servicio-solicitud-cotizacion-compra/clases';
import { ValidadorFormas } from 'src/app/utilerias/validadorFormas';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import {
  ServicioOverlayCargando,
  CargandoComponent
} from 'src/app/utilerias/overlay-cargando.service';
import { ArchivoProveedorCompra } from 'src/app/entidades/archivo-proveedor-compra';
import { ArchivoServicioCompra } from 'src/app/entidades/archivo-servicio-compra';
import { VisorArchivoComponent } from 'src/app/utilerias/visor-archivo/visor-archivo.component';
import { TipoCambioService } from 'src/app/utilerias/tipo-cambio.service';

@Component({
  selector: 'app-cotizacion-compra',
  templateUrl: './solicitud-cotizacion-compra.component.html',
  styleUrls: ['./solicitud-cotizacion-compra.component.scss']
})
@AutoUnsubscribe()
export class SolicitudCotizacionCompraComponent implements OnInit {
  @ViewChild(ControlArchivosComponent)
  componenteArchivosProveedor: ControlArchivosComponent;
  @ViewChildren(ServicioSolicitudCotizacionCompraComponent)
  componentesServiciosCompra: QueryList<
    ServicioSolicitudCotizacionCompraComponent
  >;

  cargando = false;
  modificando: boolean;

  forma = this.crearFormaSolicitud();

  solicitudCompra: SolicitudCompra = new SolicitudCompra();
  proyecto: Proyecto = new Proyecto();
  catalogoServicio = new CatalogoServicio();

  proveedores: Socio[];
  monedas: Moneda[];
  destinos: Direccion[];
  origenes: Direccion[];
  fronteras: Frontera[];
  aduanas: Aduana[];
  tiposCarga: TipoCarga[];
  unidadesLongitud: UnidadesLongitud[];
  unidadesPeso: UnidadesPeso[];
  medidasLegales: MedidaLegal[];

  subsGuardar: Subscription;
  subsSolicitud: Subscription;
  subsCatalogos: Subscription;
  subsArchivos: Subscription;

  cargaInicial: Subscription;

  solicitudGuardada = new EventEmitter<Compra[]>();
  solicitudActualizada = new EventEmitter<Compra>();

  get noProveedores(): number {
    return (<FormArray>this.forma.get('proveedores')).controls.length;
  }
  get noServicios(): number {
    return (<FormArray>this.forma.get('servicios')).controls.length;
  }
  get formasProveedores(): FormArray {
    return <FormArray>this.forma.get('proveedores');
  }
  get formasServicios(): FormArray {
    return <FormArray>this.forma.get('servicios');
  }
  get esInternacional(): boolean {
    return this.proyecto.esInternacional;
  }
  get aduanaValida(): boolean {
    let resultado = true;
    if (this.esInternacional) {
      const aduanaSeleccionada = this.formasServicios.controls.find(
        c => c.get('aduanaId').value != null
      );
      const aduanasTocadas = this.formasServicios.controls.find(
        c => c.get('aduanaId').touched
      );
      resultado =
        aduanaSeleccionada || (!aduanaSeleccionada && !aduanasTocadas)
          ? true
          : false;
    }
    return resultado;
  }
  get fronteraValida(): boolean {
    let resultado = true;
    if (this.esInternacional) {
      const fronteraSeleccionada = this.formasServicios.controls.find(
        c => c.get('fronteraId').value != null
      );
      const fronterasTocadas = this.formasServicios.controls.find(
        c => c.get('fronteraId').touched
      );
      resultado =
        fronteraSeleccionada || (!fronteraSeleccionada && !fronterasTocadas)
          ? true
          : false;
    }
    return resultado;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public datos: DatosSolicitudCompra,
    private ctx: Contexto,
    private modal: MatDialogRef<SolicitudCotizacionCompraComponent>,
    private formBuilder: FormBuilder,
    public ventanaArchivos: MatDialog,
    private alerta: ServicioAlerta,
    private validadorForma: ValidadorFormas,
    private overlay: ServicioOverlayCargando,
    private servicioTipoCambio: TipoCambioService
  ) {}

  ngOnInit() {
    this.modificando = this.datos.idCompra > 0;
    this.cargarDatos();
  }

  //#region  Definicion de Formas
  crearFormaSolicitud(): FormGroup {
    return this.formBuilder.group({
      id: [0, Validators.nullValidator],
      folio: ['', Validators.nullValidator],
      fechaDocumento: [new Date(), Validators.required],
      comentarioEmpresa: ['', Validators.required],
      proveedores: this.formBuilder.array([]),
      servicios: this.formBuilder.array([]),
      proyectoId: [0, Validators.required],
      clienteNombreComercial: ['', Validators.nullValidator],
      estadoId: [1, Validators.nullValidator]
    });
  }

  crearFormaProveedor(): FormGroup {
    return this.formBuilder.group({
      proveedorId: [null, Validators.required],
      monedaId: [null, Validators.required],
      socioNombreComercial: ['', Validators.nullValidator]
    });
  }

  crearFormaServicio(): FormGroup {
    return this.formBuilder.group({
      id: [0, Validators.nullValidator],
      compraId: [0, Validators.nullValidator],
      linea: [0, Validators.nullValidator],
      descripcion: ['', Validators.required],
      fechaSolicitud: new Date(),
      fechaRequerida: [new Date(), Validators.required],
      peso: ['', Validators.required],
      largo: ['', Validators.required],
      ancho: ['', Validators.required],
      alto: ['', Validators.required],
      importe: [0, Validators.nullValidator],
      unidadPesoId: [null, Validators.required],
      unidadPesoAbreviatura: ['', Validators.nullValidator],
      unidadLongitudId: [null, Validators.required],
      unidadLongitudAbreviatura: ['', Validators.nullValidator],
      tipoCargaId: [null, Validators.required],
      tipoCargaDescripcion: ['', Validators.nullValidator],
      medidaLegalId: [null, Validators.required],
      medidaLegalDescripcion: ['', Validators.nullValidator],
      equipoId: [null, Validators.nullValidator],
      equipoDescripcion: ['', Validators.nullValidator],
      estadoId: [1, Validators.nullValidator],
      estadoDescripcion: ['', Validators.nullValidator],
      fronteraId: [null, Validators.nullValidator],
      aduanaId: [null, Validators.nullValidator],
      origenes: this.formBuilder.array([]),
      destinos: this.formBuilder.array([]),
      archivos: this.formBuilder.array([])
    });
  }

  crearFormaOrigen(): FormGroup {
    return this.formBuilder.group({
      id: [0, Validators.nullValidator],
      origenId: [null, Validators.required],
      contacto: ['', Validators.required],
      servicioCompraId: [0, Validators.nullValidator]
    });
  }

  crearFormaDestino(): FormGroup {
    return this.formBuilder.group({
      id: [0, Validators.nullValidator],
      destinoId: [null, Validators.required],
      contacto: ['', Validators.required],
      servicioCompraId: [0, Validators.nullValidator]
    });
  }
  //#endregion

  cargarDatos(): void {
    this.overlay.abrirCargando();
    this.subsCatalogos = this.ctx.proyectos
      .obtener(this.datos.idProyecto)
      .pipe(
        flatMap(proyecto => {
          this.proyecto = proyecto;
          return combineLatest(
            this.ctx.direccionesServicio
              .obtenerDestinosCliente(this.proyecto.clienteId)
              .pipe(
                tap(
                  (destinos: Direccion[]) =>
                    (this.catalogoServicio.destinos = destinos)
                )
              ),
            this.ctx.direccionesServicio
              .obtenerOrigenesCliente(this.proyecto.clienteId)
              .pipe(
                tap(
                  (origenes: Direccion[]) =>
                    (this.catalogoServicio.origenes = origenes)
                )
              ),
            this.ctx.socios
              .obtenerProveedores()
              .pipe(
                tap((proveedores: Socio[]) => (this.proveedores = proveedores))
              ),
            this.ctx.monedas
              .obtenerTodos()
              .pipe(tap((monedas: Moneda[]) => (this.monedas = monedas))),
            this.ctx.fronteras
              .obtenerTodos()
              .pipe(
                tap(
                  (fronteras: Frontera[]) =>
                    (this.catalogoServicio.fronteras = fronteras)
                )
              ),
            this.ctx.aduanas
              .obtenerTodos()
              .pipe(
                tap(
                  (aduanas: Aduana[]) =>
                    (this.catalogoServicio.aduanas = aduanas)
                )
              ),
            this.ctx.tiposCarga
              .obtenerTodos()
              .pipe(
                tap(
                  (tiposCarga: TipoCarga[]) =>
                    (this.catalogoServicio.tiposCarga = tiposCarga)
                )
              ),
            this.ctx.unidadesLongitud
              .obtenerTodos()
              .pipe(
                tap(
                  (unidades: UnidadesLongitud[]) =>
                    (this.catalogoServicio.unidadesLongitud = unidades)
                )
              ),
            this.ctx.unidadesPeso
              .obtenerTodos()
              .pipe(
                tap(
                  (unidades: UnidadesPeso[]) =>
                    (this.catalogoServicio.unidadesPeso = unidades)
                )
              ),
            this.ctx.medidasLegales
              .obtenerTodos()
              .pipe(
                tap(
                  (medidas: MedidaLegal[]) =>
                    (this.catalogoServicio.medidasLegales = medidas)
                )
              )
          );
        })
      )
      .subscribe(() => {
        this.forma = this.crearFormaSolicitud();
        this.forma
          .get('clienteNombreComercial')
          .setValue(this.proyecto.clienteNombreComercial);
        this.forma.get('proyectoId').setValue(this.proyecto.id);

        if (this.modificando) {
          this.cargarSolicitud();
        } else {
          // this.generarFolioVenta();
          this.agregarProveedor();
          this.agregarServicio();
          this.overlay.cerrarCargando();
        }
      });
  }

  cargarSolicitud(): void {
    this.subsSolicitud = this.ctx.compras
      .obtenerSolicitudCotizacion(this.datos.idCompra)
      .subscribe(solicitud => {
        this.solicitudCompra = solicitud;

        this.forma.get('proyectoId').setValue(solicitud.proyectoId);
        this.forma
          .get('comentarioEmpresa')
          .setValue(solicitud.comentarioEmpresa);
        this.forma.get('fechaDocumento').setValue(solicitud.fechaDocumento);

        this.agregarProveedor({
          proveedorId: solicitud.proveedorId,
          monedaId: solicitud.monedaId
        });

        this.componenteArchivosProveedor.archivosExistentes = solicitud.archivosProveedor.map(
          archivoProveedor => {
            const archivo: Archivo = {
              id: archivoProveedor.id,
              extension: archivoProveedor.extension,
              guia: archivoProveedor.guia,
              nombre: archivoProveedor.nombre,
              datos: null,
              archivoBase64: ''
            };
            return archivo;
          }
        );

        solicitud.servicios.forEach(servicio => {
          this.agregarServicio(servicio);
        });

        this.overlay.cerrarCargando();
      });
  }

  agregarProveedor(proveedor: ProveedorSolicitud = null): void {
    const formaProvedor = this.crearFormaProveedor();
    if (proveedor != null) {
      formaProvedor.reset(proveedor);
    }
    this.formasProveedores.push(formaProvedor);
  }

  removerProveedor(indice: number): void {
    this.formasProveedores.removeAt(indice);
  }

  agregarServicio(servicio: ServicioCompra = null): void {
    const formaServicio = this.crearFormaServicio();
    if (servicio != null) {
      formaServicio.reset(servicio);

      servicio.origenes.forEach(origen => {
        this.agregarOrigen(formaServicio, origen);
      });

      servicio.destinos.forEach(destino => {
        this.agregarDestino(formaServicio, destino);
      });

      const formasArchivo = <FormArray>formaServicio.get('archivos');
      servicio.archivos.forEach(archivo => {
        formasArchivo.push(this.formBuilder.group(archivo));
      });
    } else {
      this.agregarOrigen(formaServicio);
      this.agregarDestino(formaServicio);
    }

    this.formasServicios.push(formaServicio);
  }

  agregarNuevoServicio(): void {
    const nuevoServicio = new ServicioCompra();
    this.solicitudCompra.servicios.push(nuevoServicio);
    this.agregarServicio();
  }

  removerServicio(indice: number): void {
    this.formasServicios.removeAt(indice);
  }

  agregarOrigen(
    formaServicio: FormGroup,
    origen: OrigenServicioCompra = null
  ): void {
    const origenes = <FormArray>formaServicio.get('origenes');
    const formaOrigen = this.crearFormaOrigen();
    if (origen != null) {
      formaOrigen.reset(origen);
    }
    origenes.push(formaOrigen);
  }

  removerOrigen(indiceServicio: number, indiceOrigen: number): void {
    const servicio = this.formasServicios.controls[indiceServicio];
    const origenes = <FormArray>servicio.get('origenes');
    origenes.removeAt(indiceOrigen);
  }

  agregarDestino(
    formaServicio: FormGroup,
    destino: DestinoServicioCompra = null
  ): void {
    const destinos = <FormArray>formaServicio.get('destinos');
    const formaDestino = this.crearFormaDestino();
    if (destino != null) {
      formaDestino.reset(destino);
    }
    destinos.push(formaDestino);
  }

  removerDestino(indiceServicio: number, indiceDestino: number): void {
    const servicio = this.formasServicios.controls[indiceServicio];
    const destinos = <FormArray>servicio.get('destinos');
    destinos.removeAt(indiceDestino);
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      const fechaSolicitud = this.forma.get('fechaDocumento').value as Date;
      this.servicioTipoCambio
        .validarTipoCambio(this.formasProveedores, fechaSolicitud)
        .then(cambioCorrecto => {
          if (!cambioCorrecto) {
            this.cargando = false;
            this.alerta.mostrarAdvertencia('No se encontró el tipo de cambio');
          } else {
            const formaSolicitudes = new FormData();

            const solicitudesPorGuardar: SolicitudCompra[] = [];
            const solicitudBase = new SolicitudCompra();

            solicitudBase.proyectoId = this.forma.get('proyectoId').value;
            solicitudBase.comentarioEmpresa = this.forma.get(
              'comentarioEmpresa'
            ).value;
            solicitudBase.fechaDocumento = this.forma.get(
              'fechaDocumento'
            ).value;
            solicitudBase.estadoId = this.forma.get('estadoId').value;

            const archivosProveedores = this.componenteArchivosProveedor
              .archivosNuevos;
            for (
              let indiceArchivo = 0;
              indiceArchivo < archivosProveedores.length;
              indiceArchivo++
            ) {
              const a = archivosProveedores[indiceArchivo];
              const archivoProveedor: ArchivoProveedorCompra = {
                compraId: 0,
                extension: a.name.substring(
                  a.name.lastIndexOf('.'),
                  a.name.length
                ),
                guia: indiceArchivo.toString(),
                id: 0,
                nombre: a.name,
                arhivoBase64: ''
              };
              solicitudBase.archivosProveedor.push(archivoProveedor);
              formaSolicitudes.append('archivosProveedores', a);
            }

            const archivosProveedorExistentes = this.componenteArchivosProveedor.archivosExistentes.map(
              a => {
                const archivoExistente: ArchivoProveedorCompra = {
                  compraId: this.datos.idCompra,
                  extension: a.extension,
                  guia: a.guia,
                  id: a.id,
                  nombre: a.nombre,
                  arhivoBase64: ''
                };
                return archivoExistente;
              }
            );
            solicitudBase.archivosProveedor.push(
              ...archivosProveedorExistentes
            );

            let contadorArchivos = 0;
            for (
              let indiceServicio = 0;
              indiceServicio < this.formasServicios.controls.length;
              indiceServicio++
            ) {
              const formaServicio = this.formasServicios.controls[
                indiceServicio
              ].value;
              const servicio: ServicioCompra = new ServicioCompra();
              const componenteArchivo = this.componentesServiciosCompra.toArray()[
                indiceServicio
              ];
              const archivos = componenteArchivo.archivos;

              Object.assign(servicio, formaServicio);
              servicio.archivos = [];
              solicitudBase.servicios.push(servicio);
              // Arhivos de servicio recien seleccionados.
              // La guia debe corresponer al indice, ya que por medio de ese valor se enlaza en el servidor
              for (
                let indiceArchivo = 0;
                indiceArchivo < archivos.length;
                indiceArchivo++
              ) {
                const a = archivos[indiceArchivo];

                const archivoServicio: ArchivoServicioCompra = {
                  servicioCompraId: servicio.id,
                  extension: a.name.substring(
                    a.name.lastIndexOf('.'),
                    a.name.length
                  ),
                  guia: contadorArchivos.toString(),
                  id: 0,
                  nombre: a.name,
                  archivoBase64: ''
                };
                servicio.archivos.push(archivoServicio);
                formaSolicitudes.append('archivosServicios', a);
                contadorArchivos++;
              }

              // Archivos de servicio que ya existian en la base de datos y que aun se necesitan (solo cuando se actualiza)
              const archivosServicioExistentes = componenteArchivo.archivosExistentes.map(
                a => {
                  const archivoExistente: ArchivoServicioCompra = {
                    servicioCompraId: servicio.id,
                    extension: a.extension,
                    guia: a.guia,
                    id: a.id,
                    nombre: a.nombre,
                    archivoBase64: ''
                  };
                  return archivoExistente;
                }
              );
              servicio.archivos.push(...archivosServicioExistentes);
            }

            for (
              let indiceFormaProveedor = 0;
              indiceFormaProveedor < this.formasProveedores.controls.length;
              indiceFormaProveedor++
            ) {
              const formaProveedor = <FormGroup>(
                this.formasProveedores.controls[indiceFormaProveedor].value
              );
              const solicitudCotizacion: SolicitudCompra = new SolicitudCompra();
              Object.assign(solicitudCotizacion, solicitudBase);
              Object.assign(solicitudCotizacion, formaProveedor);
              solicitudesPorGuardar.push(solicitudCotizacion);
            }

            if (!this.modificando) {
              formaSolicitudes.append(
                'solicitudesCompraTexto',
                JSON.stringify(solicitudesPorGuardar)
              );
              this.subsGuardar = this.ctx.compras
                .guardarSoliditudesCotizacion(formaSolicitudes)
                .subscribe(
                  solicituds => {
                    this.limpiar();
                    this.cerrar(true);
                    this.solicitudGuardada.emit(solicituds);
                    this.alerta.mostrarExito('Solicitud guardada');
                  },
                  () => {
                    this.alerta.mostrarError('¡Error al guardar!');
                  }
                );
            } else {
              solicitudesPorGuardar[0].id = this.datos.idCompra;
              formaSolicitudes.append(
                'solicitudCompraTexto',
                JSON.stringify(solicitudesPorGuardar[0])
              );
              this.subsGuardar = this.ctx.compras
                .actualizarSoliditudesCotizacion(formaSolicitudes)
                .subscribe(
                  solicitud => {
                    this.limpiar();
                    this.cerrar(true);
                    this.solicitudActualizada.emit(solicitud);
                    this.alerta.mostrarExito('Solicitud actualizada');
                  },
                  () => {
                    this.alerta.mostrarError('¡Error al actualizar!');
                  }
                );
            }
            this.cargando = false;
          }
        });
    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
      this.validadorForma.marcarComoTocado(this.forma);
    }
  }

  generarFolioVenta(): void {
    this.ctx.compras.generarFolio().subscribe(folio => {
      this.forma.get('folio').setValue(`SC-${folio}`);
    });
  }

  limpiar(): void {
    this.forma.reset();
    this.componenteArchivosProveedor.limpiar();
    // this.componentesServiciosCompra.forEach((s) => {
    //   s.limpiarArchivos();
    // })
  }

  cerrar(guardo: boolean): void {
    this.modal.close(guardo);
  }
}

import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable } from 'rxjs';
import { TiposServicios } from '../entidades/tipos-servicios';

export class RepositorioTiposServicios extends RepositorioCatalogo<TiposServicios> {

    constructor(http: HttpClient) {
        super(http, "TiposServicios");
    }

    activar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put<void>(ruta, null);
    }

    desactivar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put<void>(ruta, null);

    }

}

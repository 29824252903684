import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Usuario } from "../entidades/usuario";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class RepositorioUsuario extends RepositorioCatalogo<Usuario> {

    constructor(http: HttpClient) {
        super(http, "Usuarios");
    }

    public obtenerUsuario(id: number): Observable<Usuario> {
        return this.clienteHttp.get(`${this.ruta}/${id}`)
            .pipe(map((data: Usuario) => {
                return data;
            }));
    }

    activar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    autorizar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Autorizar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desautorizar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desautorizar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    obtenerSocioUsuario(id: number): Observable<Usuario> {
        let ruta = `${this.ruta}/ObtenerSocioUsuario/${id}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Usuario) => {
                return data;
            }));
    }

    obtenerUsuariosPorSocio(id: number): Observable<Usuario[]> {
        let ruta = `${this.ruta}/ObtenerUsuariosPorSocio/${id}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Usuario[]) => {
                return data;
            }));
    }

    obtenerUsuariosAdministradores(): Observable<Usuario[]> {
        let ruta = `${this.ruta}/ObtenerUsuariosAdministradores`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Usuario[]) => {
                return data;
            }));
    }



    actualizarPasswordUsuario(usuario: Usuario): Observable<Usuario> {
        let ruta = `${this.ruta}/ActualizarPasswordUsuario`;
        return this.clienteHttp.post(ruta, usuario)
            .pipe(map((data: Usuario) => {
                return data;
            }));
    }


    obtenerUsuariosDetalleSocio(id: number): Observable<Usuario> {
        let ruta = `${this.ruta}/ObtenerUsuariosDetalleSocio/${id}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Usuario) => {
                return data;
            }));
    }


}

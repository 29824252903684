import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DetalleFactura, DetalleCostosAdicionales } from 'src/app/entidades/detalle-factura';
import { Factura } from 'src/app/entidades/factura';

@Injectable()
export class ConstructorFormaFactura {
  constructor(private fb: FormBuilder) { }

  public crearFormaFactura(factura?: Factura): FormGroup {
    const formaFactura = this.fb.group({
      id: [0, Validators.nullValidator],
      documentoId: [0, Validators.required],
      socioNombreCorto: ['', Validators.nullValidator],
      monedaAbreviatura: ['', Validators.nullValidator],
      proyectoExternoId: [null, Validators.required],
      estatusFacturacion: [null, Validators.nullValidator],
      descripcion: ['', Validators.nullValidator],
      detalles: this.fb.array([])
    });

    if (factura) {
      this.asignarFacturaAForma(formaFactura, factura);
    }

    return formaFactura;
  }

  asignarFacturaAForma(formaFactura: FormGroup, factura: Factura): void {
    formaFactura.patchValue(factura);
    formaFactura.setControl(
      'detalles',
      this.crearArregloDetalle(factura.detalles)
    );
  }

  crearArregloDetalle(detallesFactura: DetalleFactura[]): FormArray {
    return this.fb.array([
      ...detallesFactura.map(d => this.crearFormaDetalleFactura(d))
    ]);
  }

  crearArregloCostos(detallesCostos: DetalleCostosAdicionales[]): FormArray {
    return this.fb.array([
      ...detallesCostos.map(d => this.costosAdicionales(d))
    ]);
  }

  public costosAdicionales(costosAdiciones?: DetalleCostosAdicionales):FormGroup{//DetalleFactura['costos']
    
    const formaCostoAdicional = this.fb.group({
      cantidad:[0, Validators.nullValidator],
      articuloId:[null, Validators.nullValidator],
      articuloDescripcion:['', Validators.nullValidator],
      precio:[0, Validators.nullValidator],
      total: [0, Validators.nullValidator]
    });
    if(costosAdiciones){
      formaCostoAdicional.patchValue(costosAdiciones);
    }
    return formaCostoAdicional;
    // return this.fb.group({
    //   cantidad:[0, Validators.nullValidator],
    //   articuloId:[null, Validators.nullValidator],
    //   articuloDescripcion:['', Validators.nullValidator],
    //   precio:[0, Validators.nullValidator],
    //   total: [0, Validators.nullValidator]
    //   })
  }

  public crearFormaDetalleFactura(detalleFactura?: DetalleFactura): FormGroup {
    
    const formaDetalle = this.fb.group({
      id: [0, Validators.nullValidator],
      bollc: [null, Validators.nullValidator],
      articuloId: [null, Validators.required],
      descripcionArticulo: ['', Validators.nullValidator],
      texto: [null, Validators.required],
      facturaId: [0, Validators.nullValidator],
      total: [0, Validators.required],
      trasladoId: [null, Validators.required],
      retencionId: [null, Validators.nullValidator],
      importeMoneda: [0, Validators.nullValidator],
      cantidad: [0, Validators.nullValidator],
      tieneCostos:[true, Validators.nullValidator],
      costos: this.fb.array([])//this.costosAdicionales(detalleFactura.costos)
    });

    if (detalleFactura) {
      formaDetalle.patchValue(detalleFactura);
      formaDetalle.setControl(
        'costos',
        this.crearArregloCostos(detalleFactura.costos)
      );
    }
    return formaDetalle;
  }
}

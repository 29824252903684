import { Component, OnInit, OnDestroy, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { flatMap } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { AplicaProveedorMina } from 'src/app/entidades/aplica-proveedor-mina';
import { AsignacionAplicaproveedoMina } from 'src/app/entidades/asignacion-aplica-proveedor-mina';
 



@AutoUnsubscribe()
@Component({
  selector: 'asignacion-aplica-proveedor-mina',
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAsignacionAplicaProveedoMinaComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
   
  proveedorSeleccionado: number;
  
  fuenteDatos: MatTableDataSource<AplicaProveedorMina> = new MatTableDataSource([]);
  columnasMostradas = [
    'clave',
    'descripcion',
    'asignacion'
  ];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  tabla: AplicaProveedorMina[];
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
  ) { }

  ngOnInit() {
    this.cargando = false;
  }

  public cargarTabla(proveedor: number): void {
    this.proveedorSeleccionado = proveedor;
    this.cargando = true;
    this.ctx.aplicaProveedorMina
      .obtenerAplicaProveedorMinaCliente(this.proveedorSeleccionado)
      .pipe(
        flatMap(direcciones => {
          this.tabla = direcciones;
          return this.ctx.aplicaProveedorMina.obtenerTodosActivos();
        })
      )
      .subscribe(direcciones => {
        this.fuenteDatos = new MatTableDataSource(direcciones);
        this.cargando = false;
      });
  }

  cambiarEstado(seleccionado: boolean, idDireccion: number): void {
    if (seleccionado) {
      this.asignar(idDireccion);
    } else {
      this.desasignar(idDireccion);
    }
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  asignar(id: number): void {
    const asignacion = new AsignacionAplicaproveedoMina();
    asignacion.clienteId = this.proveedorSeleccionado;
    asignacion.aplicaProveedorMinaId = id;

    this.ctx.aplicaProveedorMina
      .asignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación guardada');
        },
        error => {
          this.alerta.mostrarError('Error al asignar dirección');
        }
      );
  }

  desasignar(id: number): void {
    const asignacion = new AsignacionAplicaproveedoMina();
    asignacion.clienteId = this.proveedorSeleccionado;
    asignacion.aplicaProveedorMinaId = id;

    this.ctx.aplicaProveedorMina
      .desasignar(asignacion)
      .subscribe(
        () => {
          this.alerta.mostrarExito('Asignación eliminada');
        },
        error => {
          this.alerta.mostrarError('Error al quitar la asignación la dirección');
        }
      );
  }

  modoAsignada(id: number): boolean {
    const direccion = this.tabla.find(d => d.id === id);

    if (direccion) {
      return true;
    } else {
      return false;
    }
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { LayoutModule } from '@angular/cdk/layout';
import {
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatGridListModule,
    MatTooltipModule,
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatRadioModule,
    MatExpansionModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MAT_DATE_LOCALE,
    MatRippleModule,
    MatProgressBarModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';
import { ColorPickerModule } from 'ngx-color-picker';
import { ChartsModule } from 'ng2-charts';

import { Router } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { DashboardComponent } from './paginas/dashboard/dashboard.component';
import { RolesComponent } from './paginas/roles/roles.component';
import { CatalogoArticuloComponent } from './paginas/articulos/catalogo/catalogo.component';
import { FormaArticuloComponent } from './paginas/articulos/forma/forma.component';
import { CatalogoCamionComponent } from './paginas/camiones/catalogo/catalogo.component';
import { FormaCamionComponent } from './paginas/camiones/forma/forma.component';
import { CatalogoPermisoComponent } from './paginas/permisos/catalogo/catalogo.component';
import { FormaPermisoComponent } from './paginas/permisos/forma/forma.component';
import { CatalogoSocioComponent } from './paginas/socios/catalogo/catalogo.component';
import { FormaSociosComponent } from './paginas/socios/forma/forma.component';
import { CatalogoOperadorComponent } from './paginas/operadores/catalogo/catalogo.component';
import { FormaOperadorComponent } from './paginas/operadores/forma/forma.component';
import { FormaDocumentoOperadorComponent } from './paginas/operadores/forma-documento/forma.component';
import { CatalogoUsuarioComponent } from './paginas/usuarios/catalogo/catalogo.component';
import { FormatoSocio } from './utilerias/formato-socio.pipe';
import { CatalogoMonedaComponent } from './paginas/monedas/catalogo/catalogo.component';
import { FormaMonedasComponent } from './paginas/monedas/forma/forma.component';
import { CatalogoTextoComponent } from './paginas/textos/catalogo/catalogo.component';
import { FormaTextosComponent } from './paginas/textos/forma/forma.component';
import { CatalogoUnidadesLongitudComponent } from './paginas/unidad-longitud/catalogo/catalogo.component';
import { FormaUnidadesLongitudComponent } from './paginas/unidad-longitud/forma/forma.component';
import { CatalogoUnidadesPesoComponent } from './paginas/unidad-peso/catalogo/catalogo.component';
import { FormaUnidadesPesoComponent } from './paginas/unidad-peso/forma/forma.component';
import { CatalogoTipoDocumentoComponent } from './paginas/tipos-documento/catalogo/catalogo.component';
import { FormaTipoDocumentoComponent } from './paginas/tipos-documento/forma/forma.component';
import { EncabezadoPipe } from './utilerias/titulo-encabezado-tabla.pipe';
import { VisorComponent } from './paginas/operadores/visor/visor.component';
import { LigaSeguraPipe } from './utilerias/liga-segura.pipe';
import { CatalogoDireccionServicioComponent } from './paginas/direcciones-entregas/catalogo/catalogo.component';
import { FormaDireccionServicioComponent } from './paginas/direcciones-entregas/forma/forma.component';
import { CatalogoTipoCargaComponent } from './paginas/tipos-carga/catalogo/catalogo.component';
import { FormaTiposCargaComponent } from './paginas/tipos-carga/forma/forma.component';
import { CatalogoTrailersComponent } from './paginas/trailers/catalogo/catalogo.component';
import { FormaTrailersComponent } from './paginas/trailers/forma/forma.component';
import { CatalogoEstadosProyectosComponent } from './paginas/estados-proyectos/catalogo/catalogo.component';
import { FormaEstadosProyectosComponent } from './paginas/estados-proyectos/forma/forma.component';
import { CatalogoProyectosComponent } from './paginas/proyectos/catalogo/catalogo.component';
import { FormaProyectosComponent } from './paginas/proyectos/forma/forma.component';
import { FormaContactosSocioComponent } from './paginas/socios/forma-contactos/forma.component';
import { FormaDireccionesSocioComponent } from './paginas/socios/forma-direcciones/forma.component';
import { ParametrizacionComponent } from './paginas/parametrizacion/parametrizacion.component';
import { FormaCorreoComponent } from './paginas/correos/forma/forma.component';
import { CatalogoCorreoComponent } from './paginas/correos/catalogo/catalogo.component';

import { FormaMedidaLegalComponent } from './paginas/medida-legal/forma/forma.component';
import { CatalogoMedidaLegalComponent } from './paginas/medida-legal/catalogo/catalogo.component';
import { CatalogoImpuestosComponent } from './paginas/impuestos/catalogo/catalogo.component';
import { FormaImpuestosComponent } from './paginas/impuestos/forma/forma.component';
import { CatalogoAlertasComponent } from './paginas/alertas/catalogo/catalogo.component';
import { FormaAlertasComponent } from './paginas/alertas/forma/forma.component';
import { FormaListasComponent } from './paginas/alertas/forma-lista/forma.component';
import { AsignacionDireccionesServicioComponent } from './paginas/AsignacionDireccionesServicio/catalogo/catalogo.component';
import { CatalogoAduanaComponent } from './paginas/aduanas/catalogo/catalogo.component';
import { FormaAduanaComponent } from './paginas/aduanas/forma/forma.component';
import { CatalogoFronteraComponent } from './paginas/fronteras/catalogo/catalogo.component';
import { FormaFronteraComponent } from './paginas/fronteras/forma/forma.component';
import { CatalogoTipoUnidadComponent } from './paginas/tipo-unidad/catalogo/catalogo.component';
import { CatalogoUnidadComponent } from './paginas/unidades/catalogo/catalogo.component';
import { FormaTipoUnidadComponent } from './paginas/tipo-unidad/forma/forma.component';
import { FormaUnidadComponent } from './paginas/unidades/forma/forma.component';
import { SolicitudCotizacionCompraComponent } from './paginas/solicitud-cotizacion-compra/solicitud-cotizacion-compra.component';
// tslint:disable-next-line: max-line-length
import { ProveedorSolicitudCotizacionCompraComponent } from './paginas/solicitud-cotizacion-compra/proveedor-solicitud-cotizacion-compra/proveedor-solicitud-cotizacion-compra.component';
// tslint:disable-next-line: max-line-length
import { ServicioSolicitudCotizacionCompraComponent } from './paginas/solicitud-cotizacion-compra/servicio-solicitud-cotizacion-compra/servicio-solicitud-cotizacion-compra.component';
// tslint:disable-next-line: max-line-length
import { OrigenServicioSolicitudCotizacionCompraComponent } from './paginas/solicitud-cotizacion-compra/servicio-solicitud-cotizacion-compra/origen-servicio-solicitud-cotizacion-compra/origen-servicio-solicitud-cotizacion-compra.component';
// tslint:disable-next-line: max-line-length
import { DestinoServicioSolicitudCotizacionCompraComponent } from './paginas/solicitud-cotizacion-compra/servicio-solicitud-cotizacion-compra/destino-servicio-solicitud-cotizacion-compra/destino-servicio-solicitud-cotizacion-compra.component';
import { CargandoComponent } from './utilerias/overlay-cargando.service';
import { CotizacionesCompraComponent } from './paginas/cotizaciones-compra/cotizaciones-compra.component';
import { FormaTipoCambioComponent } from './paginas/tipos-cambio/forma/forma.component';
import { CatalogoTipoCambioComponent } from './paginas/tipos-cambio/catalogo/catalogo.component';
import { CotizacionPDFComponent } from './paginas/reportes/cotizacion/cotizacion';
import { BOLPDF } from './paginas/reportes/bol/bol';
import { LCPDF } from './paginas/reportes/lc/lc';
import { CatalogoTipoTextoComponent } from './paginas/tipo-textos/catalogo/catalogo.component';
import { FormaTipoTextosComponent } from './paginas/tipo-textos/forma/forma.component';
import { EnBlancoComponent } from './paginas/en-blanco/en-blanco.component';
import { SincronizacionComponent } from './paginas/sincronizacion/sincronizacion.component';
import { PanelControlComponent } from './paginas/panel-control/panel/panel.component';
import { AgmCoreModule } from '@agm/core';

import { FacturaCompraComponent } from './paginas/facturas-compra/facturas.component';
import { DetalleFacturaCompraComponent } from './paginas/facturas-compra/detalle/detalle.component';
import { DetallesfacturaCompraComponent } from './paginas/facturas-compra/detalles/detalles.component';
import { CatalogoFacturaCompraComponent } from './paginas/facturas-compra/catalogo/catalogo.component';
import { FacturaVentaComponent } from './paginas/facturas-venta/facturas.component';
import { DetalleFacturaVentaComponent } from './paginas/facturas-venta/detalle/detalle.component';
import { DetallesfacturaVentaComponent } from './paginas/facturas-venta/detalles/detalles.component';
import { CatalogoFacturaVentaComponent } from './paginas/facturas-venta/catalogo/catalogo.component';
import { DocumentosFacturaPorEnviarComponent } from './paginas/documentos-por-enviar/catalogo/documentos-por-enviar.component';
import { DocumentoFacturaComponent } from './paginas/documentos-por-enviar/forma/documento-factura.component';
import { DetallesDocumentoFacturaComponent } from './paginas/documentos-por-enviar/forma/detalles/detalles.component';
import { DetalleDocumentoFacturaComponent } from './paginas/documentos-por-enviar/forma/detalle/detalle.component';
import { CatalogoEvidenciasComponent } from './paginas/panel-control/catalogo-evidencias/catalogo-evidencias.component';
import { CatalogoUbicacionesComponent } from './paginas/panel-control/catalogo-ubicaciones/catalogo-ubicaciones.component';
import { VisorEvidenciasComponent } from './paginas/panel-control/visor-evidencias/visor-evidencias.component';
import { ControlPanelComponent } from './paginas/panel-control/control-panel/control-panel.component';
import { CatalogoEstatusServiciosComponent } from './paginas/panel-control/estatus-ubicaciones/catalogo/catalogo.component';
import { CatalogoCuentaUsuarioComponent } from './paginas/cuenta-usuario/catalogo.component';
import { FormaUsuarioComponent } from './paginas/usuarios/forma-nuevo/forma.component';
import { CotizacionDatosEquipoComponent } from './paginas/datos-equipo/datos-equipo.component';
import { CatalogoEmpleadoComponent } from './paginas/empleados/catalogo/catalogo.component';
import { FormaEmpleadoComponent } from './paginas/empleados/forma/forma.component';
import { AnalisisComponent } from './paginas/dashboard/ventas-prospectadas/analisis/analisis.component';
import { PerfilComponent } from './inicio/perfil/perfil.component';
import { PruebaFormularioComponent } from './paginas/prueba-formulario/prueba-formulario.component';
import { DuplicadoComponent } from './paginas/proyectos/duplicado/duplicado.component';
import { GraficaVentaComponent } from './paginas/dashboard/ventas-prospectadas/grafica/grafica-venta.component';
import { GraficaVentasCanceladasComponent } from './paginas/dashboard/ventas-prospectadas/grafica-canceladas/grafica-canceladas.component';
import { VentasProspectadasComponent } from './paginas/dashboard/ventas-prospectadas/ventas-prospectadas.component';
import { VentasEmpleadoComponent } from './paginas/dashboard/ventas-prospectadas/ventas-por-empleado/ventas-empleado.component';
import { CatalogoMotivoCancelacionComponent } from './paginas/motivos-cancelacion/catalogo/catalogo.component';
import { FormaMotivoCancelacionComponent } from './paginas/motivos-cancelacion/forma/forma.component';
import { ConfirmacionCancelacionComponent } from './paginas/proyectos/confirmacion-cancelacion/confirmacion-cancelacion.component';
import { MetaEmpleadoComponent } from './paginas/empleados/metas/meta-empleados.component';
import { FormaMetaEmpleadoComponent } from './paginas/empleados/forma-meta/forma.component';
import { GridCardComponent } from './utilerias/contenedor/contenedor.component';
import { routing } from './app.routing';
import { DivGridModule } from './modulos/createga/div-grid/div-grid.module';
import { FormaDatosProveedoresComponent } from './modulos/operaciones/componentes/datos-proveedores/datos-proveedores.component';
import { FormaAsignacionDireccionesServicioComponent } from './paginas/AsignacionDireccionesServicio/forma/forma.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingModule } from './modulos/loading/loading.module';
import { BotonProgresoModule } from './modulos/controles/boton-progreso/boton-progreso.module';
import { TablaParetoComponent } from './paginas/dashboard/pareto/tabla/tabla.component';
import { ParetoComponent } from './paginas/dashboard/pareto/pareto.component';
import { GraficaParetoComponent } from './paginas/dashboard/pareto/grafica/grafica.component';
import { CurrencyPipe, DecimalPipe, DatePipe } from '@angular/common';
import { ComprasPorEnviarComponent } from './paginas/documentos-por-enviar/catalogo/compras-por-enviar/compras-por-enviar.component';
import { VentasPorEnviarComponent } from './paginas/documentos-por-enviar/catalogo/ventas-por-enviar/ventas-por-enviar.component';
import { ControlArchivosModule } from './modulos/control-archivos/control-archivos.module';
import { DuplicadoProyectoModule } from './modulos/duplicado-proyecto/duplicado-proyecto.module';
import { FormaStatus } from './paginas/panel-control/forma-status/forma.component';
import { CatalogoComisionVentaComponent } from './paginas/comisiones-venta/catalogo/catalogo.component';
import { CatalogoCiudadesComponent } from './paginas/ciudades/catalogo/catalogo.component';
import { FormaCiudadComponent } from './paginas/ciudades/forma/forma.component';
// tslint:disable-next-line: max-line-length
import { CaracteristicasProveedorComponent } from './paginas/asignacion-caracteristicas-proveedor/caracteristicas-proveedor/caracteristicas-proveedor.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionDireccionesServicioProveedoresComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-direcciones-servicio-prov/forma/forma.component';
import { FormaModoTransporteComponent } from './paginas/modo-transporte/forma/forma.component';
import { CatalogoModoTransporteComponent } from './paginas/modo-transporte/catalogo/catalogo.component';
import { CatalogoTipoProveedorComponent } from './paginas/tipo-proveedor/catalogo/catalogo.component';
import { FormaTipoProveedorComponent } from './paginas/tipo-proveedor/forma/forma.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionTipoProveedorComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-tipo-proveedor/forma/forma.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionModoTransporteComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-modo-transporte/forma/forma.component';
import { CatalogoTipoServicioComponent } from './paginas/tipo-servicio/catalogo/catalogo.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionTipoServicioComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-tipo-servicio/forma/forma.component';
import { FormaTipoDeServicioComponent } from './paginas/tipo-servicio/forma/forma.component';
import { CatalogoTipoCamionComponent } from './paginas/tipo-camiones/catalogo/catalogo.component';
import { FormaTipoCamionComponent } from './paginas/tipo-camiones/forma/forma.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionTipoCamionesComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-tipo-camion/forma/forma.component';
import { FormaAsignacionCamionesComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-camion/forma/forma.component';
import { FormaAsignacionTrailersComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-trailer/forma/forma.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionTipoCargaComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-tipo-carga/forma/forma.component';
import { FormaEspecificacionCargaComponent } from './paginas/especificacion-carga/forma/forma.component';
import { CatalogoEspecificacionCargaComponent } from './paginas/especificacion-carga/catalogo/catalogo.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionEspecificacionCargaComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-especificacion-carga/forma/forma.component';
import { FormaAplicaProveedoMinaComponent } from './paginas/aplica-proveedor-mina/forma/forma.component';
import { CatalogoAplicaProveedorMinaComponent } from './paginas/aplica-proveedor-mina/catalogo/catalogo.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionAplicaProveedoMinaComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-aplica-proveedor-mina/forma/forma.component';
import { CatalogoLocalidadesSocioComponent } from './paginas/localidades-socio/catalogo/catalogo.component';
import { FormaLocalidadesSocioComponent } from './paginas/localidades-socio/forma/forma.component';
import { CatalogoProyectosCerradosComponent } from './paginas/proyectos/catalogo-proyectos-cerrados/catalogo-proyectos-cerrados';
import { CatalogoGeneradorReportesComponent } from './paginas/generador-reportes/catalogo/catalogo.component';
import { CatalogoTipoReporteComponent } from './paginas/tipo-reporte/catalogo/catalogo.component';
import { FormaTipoReporteComponent } from './paginas/tipo-reporte/forma/forma.component';
import { FormaFiltroReporteComponent } from './paginas/filtro-reporte/forma/forma.component';
import { CatalogoFiltroReporteComponent } from './paginas/filtro-reporte/catalogo/catalogo.component';
import { FormaAsignacionFiltroReporteComponent } from './paginas/asignacion-filtro-reporte/forma/forma.component';
import { FormaFiltrosComponent } from './paginas/generador-reportes/filtros/forma.component';
import { CatalogoReporteTablaComponent } from './paginas/generador-reportes/reporte/catalogo.component';
import { ExcelService } from './servicios/excel-service.service';
import { UtilidadProyectoComponent } from './paginas/proyectos/utilidad-proyectos/utilidad-proyecto.component';
import { CatalogoReporteGlobalTablaComponent } from './paginas/generador-reportes/reporte-global/catalogo.component';
import { CatalogoReporteHistorialTablaComponent } from './paginas/generador-reportes/reporte-historial/catalogo.component';
import { FormaMensajeGlobalComponent } from './paginas/pop-up-mensaje-global/form-mensaje.component';
import { FormaTiposServiciosComponent } from './paginas/tipos-servicios/forma/forma.component';
import { CatalogoTiposServiciosComponent } from './paginas/tipos-servicios/catalogo/catalogo.component';
import { FormaServiciosComponent } from './paginas/servicios/forma/forma.component';
import { CatalogoServiciosComponent } from './paginas/servicios/catalogo/catalogo.component';
import { CatalogoPDFCotizacionGlobalComponent } from './paginas/proyectos/pdf-cotizacion-global/catalogo/catalogo.component';
import { DescargarPDFCotizacionGlobalComponent } from './paginas/proyectos/descargar-pdf-cotizacion-global/cotizacion';
// tslint:disable-next-line: max-line-length
import { CatalogoPDFCotizacionGlobalSVComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/pdf-cotizacion-global-sv/catalogo/catalogo.component';
import { CatalogoExportacionInfoComponent } from './paginas/exportacion-info/catalogo.component';
import { ControlArchivosComponent } from './utilerias/control-archivos/control-archivo.component';
// tslint:disable-next-line: max-line-length
import { FormaAsignacionDireccionesDestinosSociosComponent } from './paginas/asignacion-caracteristicas-proveedor/asignacion-direcciones-servicio-prov/forma-destinos-socios/forma.component';
import { FileDropDirective } from './utilerias/file-drop.directive';
import { ServicioTipoTransporteClienteComponent } from './paginas/reporte-tipotransporte-cliente/catalogo.component';
import { AlertaFaltaFacturaComponent } from './paginas/proyectos/alerta-falta-factura/alerta-falta-factura.component';
import { CfdiComponent } from './paginas/cfdi/catalogo.component';
import { CatalogoTiposMotivosComponent } from './paginas/tipos-motivos/catalogo/catalogo.component';
import { FormaTiposDocumentosComponent } from './paginas/tipos-documentos/forma/forma.component';
import { FormaTiposMotivosComponent } from './paginas/tipos-motivos/forma/forma.component';
import { CatalogoTiposDocumentosComponent } from './paginas/tipos-documentos/catalogo/catalogo.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ReporteSeguimientoComponent } from './paginas/reporte-servicios-liberados/reporteseguimiento.component';
import { EmbarqueSelectorComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/selector/embarque-selector';
// tslint:disable-next-line: max-line-length
import { AsignarEmbarqueComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/asignar-solicitud-embarque/asignar-solicitud-embarque.component';
// tslint:disable-next-line: max-line-length
import { SolicitudEmbarqueComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/nueva-solicitud-embarque/solicitud-embarque.component';
// tslint:disable-next-line: max-line-length
import { BuscadorCatalogoComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/buscador-catalogo/buscador-catalogo.component';
// tslint:disable-next-line: max-line-length
import { RegistroManualMercanciaComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/registro-manual-mercancia/registro-manual-mercancia.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { DescargaSolicitudEmbarqueComponent } from './modulos/flujo-ventas/componentes/solicitud-embarque/descarga-solicitud/descarga-solicitud.componente';
import { CatalogoListaComprasVentasComponent } from './paginas/proyectos/lista-compras-ventas/catalogo.component';
import { ModalTiposDocumentosNoFacturarComponent } from './modulos/flujo-ventas/componentes/modal-tipos-documento-no-facturar/modal-tipos-documento-no-facturar.component';
import { CatalogoMotivoCierreComponent } from './paginas/motivos-cierre/catalogo/catalogo.component';
import { FormaMotivoCierreComponent } from './paginas/motivos-cierre/forma/forma.component';
import { VentanaConfirmacionComponent } from './modulos/operaciones/componentes/ventana-confirmacion/ventana-confirmacion.component';
import { ModelSeleccionClienteComponent } from './paginas/socios/modal-seleccion-cliente/model-seleccion-cliente.component';
import { NgMatSearchBarModule } from 'ng-mat-search-bar';
import { BuscadorCvComponent } from './inicio/buscador-cv/buscador-cv.component';
import { ObjetivosComponent } from './paginas/objetivos/objetivos.component';
import { DashboardPrincipalComponent } from './paginas/dashboard/principal/principal.component';
import { PerfectOrderComponent } from './paginas/dashboard/principal/perfect-order.component/perfect-order.component';
import { PerfectOrderDataComponent } from './paginas/dashboard/principal/perfect-order.component/perfect-order-data/perfect-order-data.component';
import { CierreDespachoOperativoDataComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-data/cierre-despacho-operativo-data.component';
import { CierreDespachoOperativoComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo.component';
import { RentabilidadComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad.component';
import { RentabilidadDataComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-data/rentabilidad-data.component';
import { FueraTiempoComponent } from './paginas/dashboard/principal/fuera-tiempo/fuera-tiempo.component';
import { CierreDespachoOperativoSelectionComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-selection/cierre-despacho-operativo-selection-ad.component';
import { RelacionAsignacionDocumentoComponent } from './paginas/proyectos/relacion-asignacion-documento/relacion-asignacion-documento.component';
import { CatalogoActualizaClienteProyectoComponent } from './paginas/proyectos/actualizacionClienteProyecto/catalogo.component';
import { ReporteCostoKmComponent } from './paginas/reporte-costo-km/reporte-costo-km/reporte-costo-km.component';
import { CatalogoReporteAdicionalTablaComponent } from './paginas/generador-reportes/reporte-adicional/catalogo.component';
import { VentanaConfirmacionNuevaComponent } from './modulos/operaciones/componentes/ventana-confirmacion-nueva/ventana-confirmacion.component';
import { CatalogoPenasquitoReportesComponent } from './paginas/penasquito/catalogo/catalogo.component';
import { CatalogoTipoEstatusClienteComponent } from './paginas/tipos-estatus-clientes/catalogo/catalogo.component';
import { FormaTipoEstatusClienteComponent } from './paginas/tipos-estatus-clientes/forma/forma.component';
import { AsignarFechaCvComponent } from './modulos/flujo-ventas/componentes/asignar-fecha-cv/asignar-fecha-cv.component';
import { FormaFechaComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaFecha/forma.component';
import { FormaUbicacionComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaUbicacion/forma.component';
import { FormaAsignarEstatusCVComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaEstatus/forma.component';
import { FacturacionPComponent } from './paginas/dashboard/principal/facturacion-pendiente/facturacionP.component';
import { FacturacionPendienteData } from './paginas/dashboard/principal/facturacion-pendiente/facturacion-pendiente-data/facturacion-pendiente-data.component';
import { RentabilidadSelectionComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-selection/rentabilidad-selection.component';
import { RentabilidadDataMoComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-data-mo/rentabilidad-data-mo.component';
import { FacturacionPendienteSelection } from './paginas/dashboard/principal/facturacion-pendiente/facturacion-pendiente-mo/facturacion-pendiente-mo.component';
import { FacturacionPendienteGrupoSelection } from './paginas/dashboard/principal/facturacion-pendiente/facturacion-pendiente-mo-gru/facturacion-pendiente-mo-gru.component';
import { RentabilidadSelectionAdicionalComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-selection-ad/rentabilidad-selection-ad.component';
import { CierreDespachoOperativoSelectionAdComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-selection-ad/cierre-despacho-operativo-selection-ad.component';
import { PartidasCerradasComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas.component';
import { RentabilidadMesComponent } from './paginas/dashboard/principal/rentabilidad/rentabilidad-mes/rentabilidad-mes.component';
import { CierreDespachoOperativoMesMesComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-mes/cierre-despacho-operativo-mes.component';
import { CierreDespachoOperativoEmpleadoComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-empleado/cierre-despacho-operativo-empledo.component';
import { PartidasCerradasDataComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-data/partidas-cerradas-data.component';
import { PartidasCerradasEmpleadoComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-empleado/partidas-cerradas-empleado.component';
import { PartidasCerradasSelecComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-selec/partidas-cerradas-selec.component';
import { PartidasCerradasclienteComponent } from './paginas/dashboard/principal/partidas-cerradas/partidas-cerradas-cliente/partidas-cerradas-cliente.component';
import { ProveeduriaComponent } from './paginas/proveeduria/proveeduria.component';
import { FormaUbicacionObligatorioComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaUbicacionObligatorio/forma.component';
import { CatalogoEstadosFacturacionComponent } from './paginas/estados-facturacion/catalogo/catalogo.component';
import { FormaEstadoFacturacionComponent } from './paginas/estados-facturacion/forma/forma.component';
import { VentasSAPComponent } from './paginas/dashboard/principal/Ventas-SAP/Ventas-SAP.component';
import { VentasSAPDataComponent } from './paginas/dashboard/principal/Ventas-SAP/Ventas-SAP-data/Ventas-SAP-data.component';
import { ComprasSAPComponent } from './paginas/dashboard/principal/Compras-SAP/Compras-SAP.component';
import { ComprasSAPDataComponent } from './paginas/dashboard/principal/Compras-SAP/Compras-SAP-data/Compras-SAP-data.component';
import { EstatusAsignadosComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/formaEstatus/estatus-asignados/estatus-asignados.component';
import { ConfirmacionCancelacionSVComponent } from './modulos/flujo-ventas/componentes/confirmacion-cancelacion-sv/confirmacion-cancelacion-sv.component';
import { RecargaDirective } from './directives/recarga.directive';
import { EstatusDiarioFriedrichComponent } from './paginas/penasquito/estatus-diario-friedrich/estatus-diario-friedrich.component';
import { HotTableModule } from '@handsontable/angular';
import { FormaAutorizacionesComponent } from './paginas/Autorizaciones/forma/forma.component';
import { CatalogoAutorizacionesComponent } from './paginas/Autorizaciones/catalogo/catalogo.component';
import { VentasSAPEntregadasComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sap-entregadas/ventas-sap-entregadas.component';
import { VentasSAPTotalParcialComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sap-total-parcial/ventas-sap-total-parcial.component';
import { VentasSAPFiltroComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sapfiltro-component/ventas-sapfiltro-component.component';
import { FormaTipoOpcionComponent } from './paginas/tipo-opcion/forma/forma.component';
import { CatalogoTipoOpcionComponent } from './paginas/tipo-opcion/catalogo/catalogo.component';
import { CierreDespachoOperativoFilterComponent } from './paginas/dashboard/principal/cierre-despacho-operativo/cierre-despacho-operativo-filter/cierre-despacho-operativo-filter.component';
import { ProyectoDetalleComponent } from './paginas/proyectos/catalogo/proyecto-detalle/proyecto-detalle.component';
import { VentasSinEnviarSAPComponent } from './paginas/dashboard/principal/Ventas-SAP/ventas-sin-enviar-SAP/ventas-sin-enviar-SAP.component';
import { ModalMotivosNoFacturarComponent } from './modulos/motivo-documentos-enviados/componentes/modal-motivos-no-facturar/modal-motivos-no-facturar.component';
import { ComprasSinEnviarSAPComponent } from './paginas/dashboard/principal/Compras-SAP/compras-sin-enviar-SAP/compras-sin-enviar-SAP.component';

@NgModule({
    declarations: [
        ModalMotivosNoFacturarComponent,
        AppComponent,
        FacturacionPendienteData,
        ConfirmacionCancelacionSVComponent,
        FacturacionPComponent,
        FormaAsignarEstatusCVComponent,
        FormaUbicacionComponent,
        FormaUbicacionObligatorioComponent,
        AsignarFechaCvComponent,
        FormaFechaComponent,
        CatalogoTipoEstatusClienteComponent,
        FormaTipoEstatusClienteComponent,
        VentanaConfirmacionNuevaComponent,
        CatalogoPenasquitoReportesComponent,
        FormatoSocio,
        InicioComponent,
        DashboardComponent,
        RolesComponent,
        FormaArticuloComponent,
        CatalogoArticuloComponent,
        FormaArticuloComponent,
        CatalogoCamionComponent,
        FormaCamionComponent,
        CatalogoSocioComponent,
        PerfilComponent,
        FormaSociosComponent,
        CatalogoPermisoComponent,
        FormaPermisoComponent,
        CatalogoSocioComponent,
        FormaSociosComponent,
        CatalogoOperadorComponent,
        FormaOperadorComponent,
        FormaDocumentoOperadorComponent,
        FormaSociosComponent,
        FormaUsuarioComponent,
        CatalogoUsuarioComponent,
        CatalogoMonedaComponent,
        FormaMonedasComponent,
        CatalogoTextoComponent,
        FormaTextosComponent,
        CatalogoUnidadesLongitudComponent,
        FormaUnidadesLongitudComponent,
        CatalogoUnidadesPesoComponent,
        FormaUnidadesPesoComponent,
        CatalogoTipoDocumentoComponent,
        FormaTipoDocumentoComponent,
        CatalogoDireccionServicioComponent,
        FormaDireccionServicioComponent,
        CatalogoTipoCargaComponent,
        FormaTiposCargaComponent,
        CatalogoTrailersComponent,
        FormaTrailersComponent,
        CatalogoEstadosProyectosComponent,
        FormaEstadosProyectosComponent,
        CatalogoProyectosComponent,
        FormaProyectosComponent,
        FormaTrailersComponent,
        FormaDireccionesSocioComponent,
        FormaContactosSocioComponent,
        CatalogoCorreoComponent,
        FormaCorreoComponent,
        EncabezadoPipe,
        VisorComponent,
        LigaSeguraPipe,
        ParametrizacionComponent,
        CatalogoImpuestosComponent,
        FormaImpuestosComponent,
        CatalogoAlertasComponent,
        FormaAlertasComponent,
        FormaListasComponent,
        FormaMedidaLegalComponent,
        CatalogoMedidaLegalComponent,
        AsignacionDireccionesServicioComponent,
        CatalogoAduanaComponent,
        FormaAduanaComponent,
        CatalogoFronteraComponent,
        FormaFronteraComponent,
        CatalogoUnidadComponent,
        FormaUnidadComponent,
        CatalogoTipoUnidadComponent,
        FormaTipoUnidadComponent,
        SolicitudCotizacionCompraComponent,
        ProveedorSolicitudCotizacionCompraComponent,
        ServicioSolicitudCotizacionCompraComponent,
        OrigenServicioSolicitudCotizacionCompraComponent,
        DestinoServicioSolicitudCotizacionCompraComponent,
        CargandoComponent,
        CotizacionesCompraComponent,
        CatalogoTipoCambioComponent,
        FormaTipoCambioComponent,
        CotizacionPDFComponent,
        BOLPDF,
        LCPDF,
        FormaTipoCambioComponent,
        CatalogoTipoTextoComponent,
        FormaTipoTextosComponent,
        LCPDF,
        FormaTipoCambioComponent,
        EnBlancoComponent,
        SincronizacionComponent,
        PanelControlComponent,
        CatalogoUbicacionesComponent,
        DocumentosFacturaPorEnviarComponent,
        DocumentoFacturaComponent,
        DetallesDocumentoFacturaComponent,
        DetalleDocumentoFacturaComponent,
        FacturaCompraComponent,
        DetalleFacturaCompraComponent,
        DetallesfacturaCompraComponent,
        CatalogoFacturaCompraComponent,
        FacturaVentaComponent,
        DetalleFacturaVentaComponent,
        DetallesfacturaVentaComponent,
        CatalogoFacturaVentaComponent,
        CatalogoEvidenciasComponent,
        VisorEvidenciasComponent,
        ControlPanelComponent,
        CatalogoEstatusServiciosComponent,
        CatalogoCuentaUsuarioComponent,
        CotizacionDatosEquipoComponent,
        CatalogoEmpleadoComponent,
        FormaEmpleadoComponent,
        CatalogoAutorizacionesComponent,
        FormaAutorizacionesComponent,
        AnalisisComponent,
        EstatusAsignadosComponent,
        PruebaFormularioComponent,
        DuplicadoComponent,
        GraficaVentaComponent,
        GraficaVentasCanceladasComponent,
        VentasProspectadasComponent,
        VentasEmpleadoComponent,
        CatalogoMotivoCancelacionComponent,
        CatalogoEstadosFacturacionComponent,
        FormaEstadoFacturacionComponent,
        FormaMotivoCancelacionComponent,
        ConfirmacionCancelacionComponent,
        MetaEmpleadoComponent,
        FormaMetaEmpleadoComponent,
        GridCardComponent,
        FormaDatosProveedoresComponent,
        FormaAsignacionDireccionesServicioComponent,
        TablaParetoComponent,
        ParetoComponent,
        GraficaParetoComponent,
        ComprasPorEnviarComponent,
        VentasPorEnviarComponent,
        FormaStatus,
        CatalogoComisionVentaComponent,
        CatalogoCiudadesComponent,
        FormaCiudadComponent,
        CaracteristicasProveedorComponent,
        FormaAsignacionDireccionesServicioProveedoresComponent,
        FormaModoTransporteComponent,
        CatalogoModoTransporteComponent,
        FormaAsignacionModoTransporteComponent,
        CatalogoTipoProveedorComponent,
        FormaTipoProveedorComponent,
        FormaAsignacionTipoProveedorComponent,
        CatalogoTipoServicioComponent,
        FormaTipoDeServicioComponent,
        FormaAsignacionTipoServicioComponent,
        RentabilidadSelectionComponent,
        RentabilidadDataMoComponent,
        CatalogoTipoCamionComponent,
        FormaTipoCamionComponent,
        FormaAsignacionTipoCamionesComponent,
        FormaAsignacionCamionesComponent,
        FormaAsignacionTrailersComponent,
        FormaAsignacionTipoCargaComponent,
        CatalogoEspecificacionCargaComponent,
        FormaEspecificacionCargaComponent,
        FormaAsignacionEspecificacionCargaComponent,
        CatalogoAplicaProveedorMinaComponent,
        FormaAplicaProveedoMinaComponent,
        FormaAsignacionAplicaProveedoMinaComponent,
        CierreDespachoOperativoMesMesComponent,
        CierreDespachoOperativoEmpleadoComponent,
        CatalogoLocalidadesSocioComponent,
        FormaLocalidadesSocioComponent,
        CatalogoProyectosCerradosComponent,
        CatalogoGeneradorReportesComponent,
        CatalogoTipoReporteComponent,
        FormaTipoReporteComponent,
        CatalogoFiltroReporteComponent,
        FormaFiltroReporteComponent,
        FormaAsignacionFiltroReporteComponent,
        FormaFiltrosComponent,
        CatalogoReporteTablaComponent,
        UtilidadProyectoComponent,
        CatalogoReporteGlobalTablaComponent,
        CatalogoReporteAdicionalTablaComponent,
        CatalogoReporteHistorialTablaComponent,
        FormaMensajeGlobalComponent,
        CatalogoTiposServiciosComponent,
        PartidasCerradasSelecComponent,
        FormaTiposServiciosComponent,
        CatalogoServiciosComponent,
        FormaServiciosComponent,
        CatalogoPDFCotizacionGlobalComponent,
        PartidasCerradasclienteComponent,
        DescargarPDFCotizacionGlobalComponent,
        CatalogoPDFCotizacionGlobalSVComponent,
        CatalogoExportacionInfoComponent,
        ServicioTipoTransporteClienteComponent,
        PartidasCerradasDataComponent,
        PartidasCerradasEmpleadoComponent,
        FacturacionPendienteGrupoSelection,
        FacturacionPendienteSelection,
        ControlArchivosComponent,
        FormaAsignacionDireccionesDestinosSociosComponent,
        FileDropDirective,
        AlertaFaltaFacturaComponent,
        CfdiComponent,
        CatalogoTiposDocumentosComponent,
        RentabilidadSelectionAdicionalComponent,
        CierreDespachoOperativoSelectionAdComponent,
        CatalogoTiposMotivosComponent,
        FormaTiposDocumentosComponent,
        FormaTiposMotivosComponent,
        ReporteSeguimientoComponent,
        EmbarqueSelectorComponent,
        AsignarEmbarqueComponent,
        BuscadorCatalogoComponent,
        SolicitudEmbarqueComponent,
        RegistroManualMercanciaComponent,
        DescargaSolicitudEmbarqueComponent,
        CatalogoListaComprasVentasComponent,
        ModalTiposDocumentosNoFacturarComponent,
        RentabilidadMesComponent,
        CatalogoMotivoCierreComponent,
        FormaMotivoCierreComponent,
        VentanaConfirmacionComponent,
        BuscadorCvComponent,
        PartidasCerradasComponent,
        ModelSeleccionClienteComponent,
        ObjetivosComponent,
        DashboardPrincipalComponent,
        PerfectOrderComponent,
        PerfectOrderDataComponent,
        CierreDespachoOperativoComponent,
        VentasSAPComponent,
        VentasSAPDataComponent,
        ComprasSAPComponent,
        ComprasSAPDataComponent,
        CierreDespachoOperativoDataComponent,
        CierreDespachoOperativoSelectionComponent,
        RentabilidadComponent,
        RentabilidadDataComponent,
        FueraTiempoComponent,
        RelacionAsignacionDocumentoComponent,
        CatalogoActualizaClienteProyectoComponent,
        ReporteCostoKmComponent,
        ProveeduriaComponent,
        RecargaDirective,
        EstatusDiarioFriedrichComponent,
        VentasSAPEntregadasComponent,
        VentasSAPTotalParcialComponent,
        FormaTipoOpcionComponent,
        CatalogoTipoOpcionComponent,
        CierreDespachoOperativoFilterComponent,
        ProyectoDetalleComponent,
        VentasSAPFiltroComponent,
        VentasSinEnviarSAPComponent,
        ComprasSinEnviarSAPComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AgmCoreModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBbRe_jx6FaIYeDXc8N00Sz9CYvGrqlogA',
            libraries: ['geometry']
        }),
        LayoutModule,
        FlexLayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        BrowserAnimationsModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        routing,
        HotTableModule.forRoot(),
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatFormFieldModule,
        MatTabsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatRippleModule,
        FormsModule,
        TextMaskModule,
        ColorPickerModule,
        ChartsModule,
        DivGridModule,
        NgxSpinnerModule,
        LoadingModule,
        BotonProgresoModule,
        ControlArchivosModule,
        DuplicadoProyectoModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgMatSearchBarModule
    ],
    entryComponents: [
        ModalMotivosNoFacturarComponent,
        VentasSinEnviarSAPComponent,
        ComprasSinEnviarSAPComponent,
        EstatusAsignadosComponent,
        FacturacionPendienteData,
        RentabilidadSelectionComponent,
        VentanaConfirmacionNuevaComponent,
        FormaAsignarEstatusCVComponent,
        FormaUbicacionComponent,
        FormaUbicacionObligatorioComponent,
        ConfirmacionCancelacionSVComponent,
        FormaEstadoFacturacionComponent,
        AsignarFechaCvComponent,
        FormaFechaComponent,
        FormaTipoEstatusClienteComponent,
        CatalogoTipoEstatusClienteComponent,
        CatalogoPenasquitoReportesComponent,
        CatalogoServiciosComponent,
        FormaArticuloComponent,
        FormaCamionComponent,
        FormaPermisoComponent,
        FormaSociosComponent,
        FormaOperadorComponent,
        FormaDocumentoOperadorComponent,
        FormaOperadorComponent,
        FormaSociosComponent,
        FormaUsuarioComponent,
        FormaMonedasComponent,
        FormaTextosComponent,
        FormaUnidadesPesoComponent,
        FormaUnidadesLongitudComponent,
        FormaTipoDocumentoComponent,
        FormaDireccionServicioComponent,
        FacturacionPendienteGrupoSelection,
        RentabilidadSelectionAdicionalComponent,
        FormaTiposCargaComponent,
        FormaTrailersComponent,
        VisorComponent,
        FormaEstadosProyectosComponent,
        FormaProyectosComponent,
        FormaDireccionesSocioComponent,
        RentabilidadDataMoComponent,
        FacturacionPendienteSelection,
        FormaContactosSocioComponent,
        FormaImpuestosComponent,
        FormaAlertasComponent,
        PartidasCerradasEmpleadoComponent,
        PartidasCerradasSelecComponent,
        FormaListasComponent,
        FormaMedidaLegalComponent,
        FormaAduanaComponent,
        FormaFronteraComponent,
        FormaUnidadComponent,
        FormaTipoUnidadComponent,
        SolicitudCotizacionCompraComponent,
        CierreDespachoOperativoSelectionAdComponent,
        CargandoComponent,
        FormaTipoCambioComponent,
        CotizacionPDFComponent,
        RentabilidadMesComponent,
        CierreDespachoOperativoMesMesComponent,
        BOLPDF,
        LCPDF,
        FormaTipoCambioComponent,
        FormaTipoTextosComponent,
        PartidasCerradasComponent,
        SincronizacionComponent,
        PanelControlComponent,
        SincronizacionComponent,
        FacturaCompraComponent,
        FacturaVentaComponent,
        DocumentoFacturaComponent,
        VisorEvidenciasComponent,
        CatalogoEstatusServiciosComponent,
        CatalogoCuentaUsuarioComponent,
        FormaEmpleadoComponent,
        FormaAutorizacionesComponent,
        DuplicadoComponent,
        FormaMotivoCancelacionComponent,
        ConfirmacionCancelacionComponent,
        FormaMetaEmpleadoComponent,
        FormaDatosProveedoresComponent,
        FormaAsignacionDireccionesServicioComponent,
        FormaStatus,
        CatalogoComisionVentaComponent,
        FormaCiudadComponent,
        CaracteristicasProveedorComponent,
        FormaAsignacionDireccionesServicioProveedoresComponent,
        FormaModoTransporteComponent,
        FormaAsignacionModoTransporteComponent,
        FormaTipoProveedorComponent,
        FormaAsignacionTipoProveedorComponent,
        FormaTipoDeServicioComponent,
        FormaAsignacionDireccionesServicioComponent,
        FormaTipoCamionComponent,
        FormaAsignacionTipoCamionesComponent,
        FormaAsignacionCamionesComponent,
        FormaAsignacionTrailersComponent,
        FormaAsignacionTipoCargaComponent,
        FormaEspecificacionCargaComponent,
        FormaAsignacionEspecificacionCargaComponent,
        FormaAplicaProveedoMinaComponent,
        FormaAsignacionAplicaProveedoMinaComponent,
        FormaLocalidadesSocioComponent,
        CatalogoGeneradorReportesComponent,
        FormaTipoReporteComponent,
        FormaFiltroReporteComponent,
        FormaAsignacionFiltroReporteComponent,
        FormaFiltrosComponent,
        CatalogoReporteTablaComponent,
        UtilidadProyectoComponent,
        CatalogoReporteGlobalTablaComponent,
        CatalogoReporteAdicionalTablaComponent,
        CatalogoReporteHistorialTablaComponent,
        FormaMensajeGlobalComponent,
        FormaTiposServiciosComponent,
        FormaServiciosComponent,
        CatalogoPDFCotizacionGlobalComponent,
        DescargarPDFCotizacionGlobalComponent,
        CatalogoPDFCotizacionGlobalSVComponent,
        CierreDespachoOperativoEmpleadoComponent,
        CatalogoExportacionInfoComponent,
        PartidasCerradasclienteComponent,
        PartidasCerradasDataComponent,
        AlertaFaltaFacturaComponent,
        FormaTiposDocumentosComponent,
        FormaTiposMotivosComponent,
        ReporteSeguimientoComponent,
        EmbarqueSelectorComponent,
        AsignarEmbarqueComponent,
        BuscadorCatalogoComponent,
        SolicitudEmbarqueComponent,
        RegistroManualMercanciaComponent,
        DescargaSolicitudEmbarqueComponent,
        CatalogoListaComprasVentasComponent,
        ModalTiposDocumentosNoFacturarComponent,
        FormaMotivoCierreComponent,
        VentanaConfirmacionComponent,
        BuscadorCvComponent,
        ObjetivosComponent,
        DashboardPrincipalComponent,
        PerfectOrderComponent,
        PerfectOrderDataComponent,
        VentanaConfirmacionComponent,
        ModelSeleccionClienteComponent,
        CierreDespachoOperativoComponent,
        CierreDespachoOperativoDataComponent,
        CierreDespachoOperativoSelectionComponent,
        RentabilidadComponent,
        RentabilidadDataComponent,
        FueraTiempoComponent,
        RelacionAsignacionDocumentoComponent,
        CatalogoActualizaClienteProyectoComponent,
        VentasSAPDataComponent,
        ComprasSAPDataComponent,
        VentasSAPEntregadasComponent,
        VentasSAPTotalParcialComponent,
        FormaTipoOpcionComponent,
        CatalogoTipoOpcionComponent,
        CierreDespachoOperativoFilterComponent,
        CatalogoTipoOpcionComponent,
        ProyectoDetalleComponent,
        VentasSAPFiltroComponent
    ],
    providers: [
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                closeOnNavigation: true,
                disableClose: true,
                autoFocus: true,
            },
        },
        ExcelService,
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 2500, verticalPosition: 'top' },
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(public router: Router) { }
}

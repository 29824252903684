import { HttpClient } from '@angular/common/http';
import { Socio } from '../entidades/socio';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Proveedor } from '../entidades/proveedor';

export class RepositorioSocio extends RepositorioCatalogo<Socio> {
  constructor(http: HttpClient) {
    super(http, 'Socios');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  obtenerClientes(): Observable<Socio[]> {
    const ruta = `${this.ruta}/Clientes`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Socio[]) => {
        return data;
      })
    );
  }

  obtenerProveedores(): Observable<Socio[]> {
    const ruta = `${this.ruta}/Proveedores`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Socio[]) => {
        return data;
      })
    );
  }

  obtenerSinAdminSociosActivas(): Observable<Socio[]> {
    let ruta = `${this.ruta}/ObtenerSinAdminSociosActivas`;
    return this.clienteHttp.get(ruta)
      .pipe(map((data: Socio[]) => {
        return data;
      }));
  }


  obtenerSociosActivos(): Observable<Socio[]> {
    let ruta = `${this.ruta}/ObtenerSociosActivos`;
    return this.clienteHttp.get(ruta)
      .pipe(map((data: Socio[]) => {
        return data;
      }));
  }


  obtenerProveedoresMigracion(): Observable<Proveedor[]> {
    let ruta = `${this.ruta}/ObtenerProveedoresMigracion`;
    return this.clienteHttp.get(ruta)
      .pipe(map((data: Proveedor[]) => {
        return data;
      }));
  }

  migrarCliente(clienteFuenteId: number, clienteDestinoId: number): Observable<void>{
    let ruta = `${this.ruta}/MigrarCliente/${clienteFuenteId}/${clienteDestinoId}`;
    return this.clienteHttp.get<void>(ruta);
  }

}











import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { filter } from 'lodash';
import { Partidas } from 'src/app/entidades/partidas';
import { PartidasCerradasEmpleadoComponent } from '../partidas-cerradas-empleado/partidas-cerradas-empleado.component';
import { PartidasCerradasclienteComponent } from '../partidas-cerradas-cliente/partidas-cerradas-cliente.component';

@Component({
    selector: 'app-partidas-cerradas-Selec',
    templateUrl: './partidas-cerradas-Selec.component.html',
    styleUrls: ['./partidas-cerradas-Selec.component.scss']
})
export class PartidasCerradasSelecComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['cliente',  'ganadas', 'importeG', 'perdidas', 'importeP',  ];
    rastreosNuevos: Partidas[];
    mes: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: Partidas[], objetivo: number, columnName: string },
        private ventana: MatDialog) {
    }

    ngOnInit(): void {

        this.mes = this.object.columnName;

        this.rastreosNuevos = this.object.rastreos;

        const months = _.chain(this.object.rastreos)
            .groupBy(c => c.cliente)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: x[0].cliente,
                    data: x
                }
            })
            .sortBy(c => c.monthName).value();


        const monthsModel = _.chain(months).map((month, key) => {
            const Perdidaimp = month.data.filter(e => e.estatus == 'Perdida').reduce((s, f) => s + f.importe, 0);
            const Ganadaimp = month.data.filter(e => e.estatus == 'Ganada').reduce((s, f) => s + f.importe, 0);

            return {
                'cliente': month.monthName,
                'perdidas': month.data.filter(d => d.estatus == 'Perdida').length,
                'ganadas': month.data.filter(d => d.estatus == 'Ganada').length,
                'importeP': Perdidaimp,
                'importeG': Ganadaimp,

            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;
    }


    abrirFormaEmpleadoP(cliente): void {
        const forma = this.ventana.open(PartidasCerradasclienteComponent, {
            data: { rastreos: this.rastreosNuevos, cliente: cliente, filtro: 'Perdida' },
            panelClass: 'form-container',
            width: '40vw'
        });
    }

    abrirFormaEmpleadoG(cliente): void {
        const forma = this.ventana.open(PartidasCerradasclienteComponent, {
            data: { rastreos: this.rastreosNuevos, cliente: cliente,  filtro: 'Ganada' },
            panelClass: 'form-container',
            width: '40vw'
        });
    }

}
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatosCalculo } from '../entidades/datos-calculo';
import { share } from 'rxjs/operators';

export class CalculoApi {
    private readonly ruta: string;

    constructor(private http: HttpClient) {
        this.ruta = '/api/Calculo';
    }

    calcular(datos: DatosCalculo): Observable<DatosCalculo> {
        return this.http.post<DatosCalculo>(this.ruta, datos);
    }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { FormaEspecificacionCargaComponent } from '../forma/forma.component';
import { EspecificacionCarga } from 'src/app/entidades/especificacion-carga';
 

@Component({
  selector: 'catalogo-especificacion-carga',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoEspecificacionCargaComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<EspecificacionCarga> = new MatTableDataSource([]);
  columnasMostradas = ['clave','descripcion','activo', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

 
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.tabla();
  }

  tabla(): void {
    this.ctx.especificacionCarga.obtenerTodos().toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;

    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al cargar las especificacion de carga');
      });
  }

  abrirForma(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaEspecificacionCargaComponent, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {

    this.ctx.especificacionCarga.activar(id).toPromise().then(() => {
      this.alerta.mostrarExito('Registro activado');
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al activar');
      });

  }

  desactivar(id: number): void {
    this.ctx.especificacionCarga.desactivar(id).toPromise().then(() => {
      this.alerta.mostrarExito('Registro inactivo');
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al inactivar');
      });
  }


  eliminar(id: number): void {
    this.ctx.especificacionCarga.eliminar(id.toString()).toPromise().then(() => {
      this.alerta.mostrarExito('Registro eliminado');
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al eliminar');
      });
  }

  limpiar() {
    this.fuenteDatos.filter = '';
  }
}
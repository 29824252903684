import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
    MAT_DIALOG_DATA,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Proyecto } from 'src/app/entidades/proyecto';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { Router } from '@angular/router';
import { FlujoService } from 'src/app/modulos/flujo-ventas/servicios/flujo.service';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { ComponentesService } from 'src/app/modulos/operaciones/servicios/componentes.service';
import { Compra } from 'src/app/entidades/compra';
import { Venta } from 'src/app/entidades/venta';

@Component({
    selector: 'catalogo-lista-compras-ventas',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
    animations: [animacionTabla],
})
@AutoUnsubscribe()
export class CatalogoListaComprasVentasComponent implements OnInit {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    fuenteDatosCompras: MatTableDataSource<Compra> = new MatTableDataSource([]);
    columnasMostradas = [
        'folio',
    ];

    fuenteDatosVentas: MatTableDataSource<Venta> = new MatTableDataSource([]);

    constructor(
      @Inject(MAT_DIALOG_DATA)
        public proyecto: Proyecto,
        public ventana: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private autenticacion: Autenticacion,
        private parametrosService: ComponentesService,
        private servicioFlujo: FlujoService,
        private router: Router,
        private spinner: LoadingService
    ) {}

    ngOnInit() {
      this.fuenteDatosCompras = new MatTableDataSource(this.proyecto.compras);
      this.fuenteDatosVentas = new MatTableDataSource(this.proyecto.ventas);

    }

    cancelarTodo(){

    }


}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaMotivoCancelacionComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { MotivoCancelacion } from 'src/app/entidades/motivo-cancelacion';

@Component({
  selector: 'catalogo-motivo-cancelacion',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoMotivoCancelacionComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<MotivoCancelacion> = new MatTableDataSource(
    []
  );
  columnasMostradas = ['clave', 'descripcion', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  subsArticulos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {}

  ngOnInit() {
    this.cargarArticulos();
  }

  cargarArticulos(): void {
    this.cargando = true;
    this.ctx.motivosCancelacion.obtenerTodos().subscribe(motivosCancelacion => {
      this.fuenteDatos = new MatTableDataSource(motivosCancelacion);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }

  abrirForma(id?: number): void {
    const dato = id ? id : null;
    const forma = this.ventana.open(FormaMotivoCancelacionComponent, {
      data: dato,
      panelClass: 'form-container'
    });

    this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
      this.cargarArticulos();
    });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx.motivosCancelacion
      .eliminar(id.toString())
      .subscribe(
        () => {
          this.alerta.mostrarError('Registro eliminado');
          this.cargarArticulos();
        },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) {
      this.fuenteDatos.disconnect();
    }
    if (this.subsArticulos) {
      this.subsArticulos.unsubscribe();
    }
    if (this.subsActivar) {
      this.subsActivar.unsubscribe();
    }
    if (this.subsDesactivar) {
      this.subsDesactivar.unsubscribe();
    }
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { Socio } from 'src/app/entidades/socio';
import { Observable } from 'rxjs';
import { Moneda } from 'src/app/entidades/moneda';
import { map, startWith } from 'rxjs/operators';
import { ProveedorSolicitud } from '../servicio-solicitud-cotizacion-compra/clases';

@Component({
  selector: 'proveedor-solicitud-compra',
  templateUrl: './proveedor-solicitud-cotizacion-compra.component.html',
  styleUrls: ['./proveedor-solicitud-cotizacion-compra.component.scss']
})
export class ProveedorSolicitudCotizacionCompraComponent implements OnInit, OnDestroy {
  @Input() forma: FormGroup;
  @Input() proveedor: ProveedorSolicitud;
  @Input() proveedores: Socio[];
  @Input() monedas: Moneda[];

  filtroProveedores = new FormControl(null, this.validadorProveedor);

  provedoresFiltrados: Observable<Socio[]>;

  constructor() { }

  ngOnInit() {
    this.sustituirFuncionTocado();

    this.provedoresFiltrados = this.filtroProveedores.valueChanges
      .pipe(
        startWith<string | Socio>(''),
        map(socio => typeof socio === 'string' ? socio : socio == null ? '' : socio.nombreComercial),
        map(socio => this.filtrar(socio))
      );

    if (this.forma.get('proveedorId').value && !this.filtroProveedores.value) {
      let idProveedor = this.forma.get('proveedorId').value;
      let proveedor = this.proveedores.find(p => p.id == idProveedor);
      if (proveedor) {
        this.filtroProveedores.setValue(proveedor);
      }
    }

  }

  sustituirFuncionTocado(): void {
    let funcionOriginal = this.forma.get('proveedorId').markAsTouched;
    this.forma.get('proveedorId').markAsTouched = (opts: { onlySelf: true }) => {
      let funcionEnlazada = funcionOriginal.bind(this.forma.get('proveedorId'));
      funcionEnlazada(opts);
      this.filtroProveedores.markAsTouched(opts);
    }
  }

  marcarComoTocado(): void {
    this.forma.get('proveedorId').markAsTouched({ onlySelf: true });
  }

  campoMostrar(socio: Socio) {
    return socio ? socio.nombreComercial : '';
  }

  private filtrar(nombre: string): Socio[] {
    if (!nombre) {
      nombre = '';
    }
    const valorFiltro = nombre.toLowerCase();
    let proveedores = this.proveedores
      .filter(socio => socio.nombreComercial.toLowerCase().indexOf(valorFiltro) === 0 ||
        socio.rfc.toLowerCase().indexOf(valorFiltro) === 0);

    return proveedores;
  }

  validadorProveedor(): ValidatorFn {
    return (control: FormControl) => {
      let errorProveedor = this.forma.get('proveedorId').errors;
      if (errorProveedor) {
        return { errorProveedor: true };
      }
      return null;
    }
  }

  ngOnDestroy(): void {
  }

}

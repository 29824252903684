import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Impuesto } from 'src/app/entidades/impuesto';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaImpuestosComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  impuesto: Impuesto;
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaImpuestosComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx
        .impuestos
        .obtener(id)
        .subscribe(
          a => {
            this.impuesto = a;
            Object.assign(this.forma.value,this.impuesto);
            this.forma.reset(this.forma.value);
            
          });
    }
    else {
      this.idSeleccionado = 0;
      this.impuesto = new Impuesto();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        clave: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(10)])],
        descripcion: ['', Validators.required],
        valor: ['', Validators.required],
        esPorcentaje: [false, Validators.nullValidator],
        esRetencion: [false, Validators.nullValidator],
        cotizacionVenta: [false, Validators.nullValidator],
        solicitudVenta: [false, Validators.nullValidator],

      }
    );
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.impuesto, this.forma.value);

      let observable: Observable<Impuesto>;
      let mensaje = 'Impuesto guardado.';
      let guardar = true;
      if (this.idSeleccionado == 0) {
        observable = this.ctx
          .impuestos
          .guardar(this.impuesto);
      } else {
        guardar = false;
        mensaje = 'Impuesto actualizado';
        observable = this.ctx
          .impuestos
          .actualizar(this.idSeleccionado.toString(), this.impuesto);
      }

      this.subsGuardar = observable.subscribe(() => {
        this.guardado.emit();
        this.alerta.mostrarExito(mensaje);
        this.cargando = false;
        this.limpiar();
        if(!guardar){
          this.ventana.close();
        }
      },
      error => {
        //console.log(err);
        if (error.status == 515) {//error == "Clave Duplicada") {
          this.alerta.mostrarError('¡Error Clave Duplicada!');
          this.cargando = false;
        }
        else {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        }
      });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

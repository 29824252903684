import { HttpClient } from '@angular/common/http';

export abstract class RepoBaseReferi<T> {
    private readonly _ruta: string;
    get ruta() {
        return this._ruta;
    }
    get cliente() {
        return this.http;
    }

    constructor(private http: HttpClient, controlador: string) {
         //this._ruta = 'https://referi.grupo-motion.com/api/' + controlador; // prod
        //this._ruta = 'http://localhost:1025/api/' + controlador; //referi local ejecutandose
        this._ruta = 'https://referipruebas.grupo-motion.com/api/' + controlador; //Calidad
    }
}
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Archivo } from '../control-archivos/control-archivo.component';

@Component({
  selector: 'visor-archivo',
  templateUrl: './visor-archivo.component.html',
  styleUrls: ['./visor-archivo.component.scss']
})
export class VisorArchivoComponent implements OnInit, OnDestroy {
  rutaArchivo = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public documento: Archivo,
    private ctx: Contexto,
    public ventanaDocumento: MatDialog
  ) {}

  ngOnInit() {
    this.cargarArchivo();
  }

  cargarArchivo(): void {
    if (this.documento.datos) {
      let tipo = 'octet/stream';
      let extension = this.documento.extension;
      extension = extension.replace('.', '');
      if (extension === 'pdf') {
        tipo = 'application/pdf';
      } else {
        tipo = 'image/' + extension;
      }
      const blob = new Blob([this.documento.datos], { type: tipo });
      this.rutaArchivo = window.URL.createObjectURL(blob);
    } else {
      console.error('No se proporciono ningún archivo');
    }
  }

  async descargarDocumento(): Promise<void> {
    const nombreArchivo = `${this.documento.nombre}${this.documento.extension}`;

    const a = document.createElement('a');
    const blob = new Blob([this.documento.datos], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = nombreArchivo;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      a.remove();
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  ngOnDestroy(): void {}
}

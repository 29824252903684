import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DireccionSocio } from "../entidades/direccion-socio";

export class RepositorioDireccionSocio extends RepositorioCatalogo<DireccionSocio> {

    constructor(http: HttpClient) {
        super(http, "DireccionesSocio");
    }

    obtenerTodosPorSocio(idSocio: number): Observable<DireccionSocio[]> {
        let ruta = `${this.ruta}/PorSocio/${idSocio}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: DireccionSocio[]) => {
                return data;
            }));
    }

}

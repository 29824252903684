import { Component } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Component({
    selector: 'app-div-grid',
    templateUrl: './div-grid.component.html',
    styleUrls: ['./div-grid.component.scss'],
})
export class DivGridComponent {
    columns: string;
    gutter = '1rem';
    mqAlias: string;

    constructor(public mediaObserver: MediaObserver) {
        mediaObserver.media$.subscribe(({ mqAlias }) => {
            this.mqAlias = mqAlias;
            switch (mqAlias) {
                case 'xs':
                    this.columns = 'repeat(4, 1fr)';
                    break;
                case 'sm':
                    this.columns = 'repeat(6, 1fr)';
                    break;
                case 'md':
                    this.columns = 'repeat(8, 1fr)';
                    break;
                case 'lg':
                    this.columns = 'repeat(12, 1fr)';
                    break;
                case 'xl':
                    this.columns = 'repeat(12, 1fr)';
                    break;
                default:
                    break;
            }
        });
    }
}

import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Socio } from 'src/app/entidades/socio';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaDireccionesSocioComponent } from '../forma-direcciones/forma.component';
import { FormaContactosSocioComponent } from '../forma-contactos/forma.component';
import { DescendienteSocio } from '../catalogo/catalogo.component';
import { PerfilService } from 'src/app/inicio/perfil/perfil.service';

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaSociosComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    socio: Socio;
    cargando = false;
    subsGuardar: Subscription;
    subsDirecciones: Subscription;
    subsContactos: Subscription;
    cerrado: EventEmitter<boolean> = new EventEmitter<boolean>();
    guardoAlgo = false;
    get f() {
        return this.forma.controls;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) private idSeleccionado: number,
        private ventana: MatDialogRef<FormaSociosComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private ventanaDirecciones: MatDialog,
        private ventanaContactos: MatDialog,
        private servicioPerfil: PerfilService
    ) {}

    ngOnInit() {
        this.cargarForma();

        if (this.idSeleccionado > 0) {
            this.ctx.socios.obtener(this.idSeleccionado).subscribe((a) => {
                this.socio = a;
                this.forma.patchValue(this.socio);
                if (this.socio.esProveedor) {
                    this.f.categoriaEmpresaId.enable();
                } else {
                    this.f.categoriaEmpresaId.disable();
                }

                this.forma.reset(this.forma.value);
            });
        } else {
            this.idSeleccionado = 0;
            this.socio = new Socio();
            this.f.categoriaEmpresaId.disable();
        }
    }

    cargarForma(): void {
        this.forma = this.formBuilder.group({
            rfc: ['', Validators.required],
            nombreCorto: ['', Validators.required],
            razonSocial: ['', Validators.required],
            nombreComercial: ['', Validators.required],
            representante: ['', Validators.required],
            esProveedor: [false, Validators.nullValidator],
            esCliente: [false, Validators.nullValidator],
            categoriaEmpresaId: [null, Validators.nullValidator],
            codigoCliente: ['CL000000', Validators.nullValidator],
            codigoProveedor: ['PR000000', Validators.nullValidator],
            imagen: [null, Validators.nullValidator],
            extension: [null, Validators.nullValidator],
            servicio: ['', Validators.nullValidator],
            encuesta: [false, Validators.nullValidator]
        });
    }

    limpiar(): void {
        this.cargarForma();
    }

    guardar(): void {
        console.log("forma-->", this.forma);
        if (this.forma.valid) {
            this.cargando = true;
            Object.assign(this.socio, this.forma.value);
            console.log('socioGuardar-->', this.socio);
            let observable: Observable<Socio>;
            let mensaje = 'Socio guardado.';
            let guardar = true;
            if (this.idSeleccionado === 0) {
                observable = this.ctx.socios.guardar(this.socio);
            } else {
                guardar = false;
                mensaje = 'Socio actualizado';
                observable = this.ctx.socios.actualizar(
                    this.idSeleccionado.toString(),
                    this.socio
                );
            }

            this.subsGuardar = observable.subscribe(
                (socio) => {
                    this.servicioPerfil.cargarSocios();
                    this.alerta.mostrarExito(mensaje);
                    this.cargando = false;
                    this.guardoAlgo = true;
                    if (!guardar) {
                        this.cerrar();
                    } else {
                        const datosDireccion: DescendienteSocio = {
                            idDescendiente: 0,
                            idSocio: socio.id,
                        };

                        const ventanaDirecciones = this.ventanaDirecciones.open(
                            FormaDireccionesSocioComponent,
                            {
                                data: datosDireccion,
                                panelClass: 'form-container',
                            }
                        );

                        this.subsDirecciones =
                            ventanaDirecciones.componentInstance.cerrado.subscribe(
                                () => {
                                    const datosContacto: DescendienteSocio = {
                                        idDescendiente: 0,
                                        idSocio: socio.id,
                                    };

                                    const ventanaContactos =
                                        this.ventanaContactos.open(
                                            FormaContactosSocioComponent,
                                            {
                                                data: datosContacto,
                                                panelClass: 'form-container',
                                            }
                                        );

                                    this.subsContactos =
                                        ventanaContactos.componentInstance.cerrado.subscribe(
                                            () => {
                                                this.limpiar();
                                            }
                                        );
                                }
                            );
                    }
                },
                () => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                }
            );
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    cerrar(): void {
        this.cerrado.emit(this.guardoAlgo);
        this.ventana.close();
    }

    cambiarEsCliente(activo): void {
        if (!activo) {
            this.forma.get('categoriaEmpresaId').setValue(null);
        }
    }

    cambiarEncuesta(activo): void {
      console.log('activo-->',activo);
    }

    cambiarEsProveedor(activo) {
        if (activo) {
            this.f.categoriaEmpresaId.enable();
        } else {
            this.f.categoriaEmpresaId.disable();
        }
    }

    obtenerImg(event): void {
        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                // let extencion = event.target.files[i].name.split('.')[1];
                let obtenerBase64 = event.target.files[i].type;
                let nombre = event.target.files[i].name;
                if (
                    obtenerBase64 == 'image/jpeg' ||
                    obtenerBase64 == 'image/png'
                ) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        let docBs64 = event.target.result.split(',')[1];
                        let tipo = event.target.result.split(',')[0];
                        this.forma.get('imagen').setValue(docBs64);
                        this.forma.get('extension').setValue(tipo);
                    };
                    reader.readAsDataURL(event.target.files[i]);
                } else {
                }
            }
        }
    }

    quitar() {
        this.forma.get('imagen').setValue(null);
        this.forma.get('extension').setValue(null);
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
        if (this.subsDirecciones) {
            this.subsDirecciones.unsubscribe();
        }
        if (this.subsContactos) {
            this.subsContactos.unsubscribe();
        }
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotonProgresoComponent } from './components/boton-progreso/boton-progreso';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [BotonProgresoComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [BotonProgresoComponent]
})
export class BotonProgresoModule {}

import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[FileDrop]'
})
export class FileDropDirective {
  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() filesHovered = new EventEmitter();

  constructor() {}

  @HostListener('drop', ['$event'])
  ondrop($event) {
    $event.preventDefault();
    $event.stopPropagation();
    const transfer = $event.dataTransfer;
    this.filesDropped.emit(transfer.files);
    this.filesHovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  ondragleave($event) {
    $event.stopPropagation();
    this.filesHovered.emit(false);
  }
}

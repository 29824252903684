import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DireccionSocio } from 'src/app/entidades/direccion-socio';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { DescendienteSocio } from '../catalogo/catalogo.component';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaDireccionesSocioComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  direccion: DireccionSocio;
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  cerrado: EventEmitter<boolean> = new EventEmitter<boolean>();
  guardoAlgo = false;
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public datos: DescendienteSocio, private ventana: MatDialogRef<FormaDireccionesSocioComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (datos.idDescendiente > 0) {
      this.idSeleccionado = datos.idDescendiente;

      this.ctx
        .direccionesSocio
        .obtener(this.idSeleccionado)
        .subscribe(
          a => {
            this.direccion = a;
            Object.assign(this.forma.value,this.direccion);
            this.forma.reset(this.forma.value);
          });
    }
    else {
      this.idSeleccionado = 0;
      this.direccion = new DireccionSocio();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        calle: ['', Validators.required],
        numeroExterior: ['', Validators.required],
        numeroInterior: [''],
        colonia: ['', Validators.required],
        codigoPostal: ['', Validators.required],
        ciudad: ['', Validators.required],
        estado: ['', Validators.required],
        pais: ['', Validators.required]
      }
    );
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.direccion, this.forma.value);
      this.direccion.socioId = this.datos.idSocio;

      let observable: Observable<DireccionSocio>;
      let mensaje = 'Dirección guardada.';
      let guardar = true;
      if (this.idSeleccionado == 0) {
        observable = this.ctx
          .direccionesSocio
          .guardar(this.direccion);
      } else {
        guardar = false;
        mensaje = 'Dirección actualizada';
        observable = this.ctx
          .direccionesSocio
          .actualizar(this.idSeleccionado.toString(), this.direccion);
      }

      this.subsGuardar = observable.subscribe(() => {
        this.alerta.mostrarExito(mensaje);
        this.cargando = false;
        this.limpiar();
        this.guardoAlgo = true;
        if(!guardar){
          this.cerrar();
        }
      },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.cerrado.emit(this.guardoAlgo);
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

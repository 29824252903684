import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@Component({
  selector: 'catalogo-comision-venta',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComisionVentaComponent implements OnInit {

  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {

  }

  abrirForma(){

  }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ContactoSocio } from 'src/app/entidades/contacto-socio';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { DescendienteSocio } from '../catalogo/catalogo.component';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaContactosSocioComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  contactosSocio: ContactoSocio;
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  cerrado: EventEmitter<boolean> = new EventEmitter<boolean>();
  guardoAlgo = false;
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public datos: DescendienteSocio, private ventana: MatDialogRef<FormaContactosSocioComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (datos.idDescendiente > 0) {
      this.idSeleccionado = this.datos.idDescendiente;

      this.ctx
        .contactosSocio
        .obtener(this.idSeleccionado)
        .subscribe(
          contacto => {
            this.contactosSocio = contacto;
            Object.assign(this.forma.value, this.contactosSocio);
            this.forma.reset(this.forma.value);
          });
    }
    else {
      this.idSeleccionado = 0;
      this.contactosSocio = new ContactoSocio();
    }
  }

  ngOnInit() {
   this.cargarForma();
  }

  cargarForma(): void {
    this.forma = this.formBuilder.group(
      {
        nombre: ['', Validators.required],
        telefono: ['', Validators.required],
        correo: ['', [Validators.required, Validators.email]],
      }
    );
  }

  limpiar(): void {
    this.cargarForma();
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.contactosSocio, this.forma.value);
      this.contactosSocio.socioid = this.datos.idSocio;

      let observable: Observable<ContactoSocio>;
      let mensaje = 'Contacto guardado.';
      let guardar = true;
      if (this.idSeleccionado == 0) {
        observable = this.ctx
          .contactosSocio
          .guardar(this.contactosSocio);
      } else {
        guardar = false;
        mensaje = 'Contacto actualizado';
        observable = this.ctx
          .contactosSocio
          .actualizar(this.idSeleccionado.toString(), this.contactosSocio);
      }

      this.subsGuardar = observable.subscribe(() => {
        this.alerta.mostrarExito(mensaje);
        this.cargando = false;
        this.limpiar();
        this.guardoAlgo = true;
        if (!guardar) {
          this.cerrar();
        }
      },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.cerrado.emit(this.guardoAlgo);
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

export class Impuesto {
  id: number;
  clave: string;
  descripcion: string;
  valor: number;
  esPorcentaje: boolean;
  esRetencion: boolean;
  activo: boolean;
  cotizacionVenta: boolean;
  solicitudVenta: boolean;
}

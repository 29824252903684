export class LocalidesSocio {
    id: number;
    socioDescripcion: string;
    socioId: number;
    esOrigen: boolean;
    esDestino: boolean;
    estadoDescripcion: string;
    estadoId: number;
    paisDescripcion: string;
    paisId: number;
  }
  
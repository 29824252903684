import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Unidad } from 'src/app/entidades/unidad';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { TipoUnidad } from 'src/app/entidades/tipo-unidad';
import { EstadoRastreo } from 'src/app/entidades/estado-rastreo';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaStatus implements OnInit, OnDestroy {
  forma: FormGroup;
  estados: Observable<EstadoRastreo[]>;

  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  

  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }


  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaStatus>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id > 0) {
      this.idSeleccionado = id;


    }
    else {
      this.idSeleccionado = 0;

    }
  }

  ngOnInit() {
    this.estados = this.ctx.estadoRastreo.obtenerTodos();

    this.forma = this.formBuilder.group(
      {
        estadoId: [0, Validators.required],
      },
    );
  }


  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {

  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Empleado } from '../entidades/empleado';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioEmpleado extends RepositorioCatalogo<Empleado> {
  constructor(http: HttpClient) {
    super(http, 'Empleados');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }
}


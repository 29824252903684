import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { EnvioPagoFactura } from '../entidades/envio-pago-factura';
import { Observable } from 'rxjs/internal/Observable';
import { ReporteFacturasProyecto } from '../entidades/reporte-proyectos-factura';

export class RepositorioEnvioPagoFactura extends RepositorioCatalogo<EnvioPagoFactura> {
    constructor(http: HttpClient) {
        super(http, 'EnvioPagoFactura');
    }

    prepararEnvioPagoFacturaSap(envio: ReporteFacturasProyecto): Observable<boolean> {
        return this.clienteHttp.post<boolean>(
            `${this.ruta}/PrepararParaEnvio`, envio
        );
    }
}

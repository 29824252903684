import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { filter } from 'lodash';
import { Partidas } from 'src/app/entidades/partidas';
import { PartidasCerradasEmpleadoComponent } from '../partidas-cerradas-empleado/partidas-cerradas-empleado.component';

@Component({
    selector: 'app-partidas-cerradas-data',
    templateUrl: './partidas-cerradas-data.component.html',
    styleUrls: ['./partidas-cerradas-data.component.scss']
})
export class PartidasCerradasDataComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['empleado', 'perdidas', 'ganadas'];
    rastreosNuevos: Partidas[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: Partidas[],  objetivo: number },
        private ventana: MatDialog) {
    }

    ngOnInit(): void {
        
        this.rastreosNuevos = this.object.rastreos;

        const months = _.chain(this.object.rastreos)
            .groupBy(c => c.empleado)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: x[0].empleado,
                    data: x
                }
            })
            .sortBy(c => c.monthName).value();

      
        const monthsModel = _.chain(months).map((month, key) => {

            return {
                'empleado': month.monthName,
                'perdidas': month.data.filter(d => d.estatus == 'Perdida' || d.estatus == 'Perdido').length,
                'ganadas':  month.data.filter(d => d.estatus == 'Ganada').length,
            };
        }).value();
 
        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;
    }

    abrirFormaEmpleadoP(empleado): void {
        const forma = this.ventana.open(PartidasCerradasEmpleadoComponent, {
            data: { rastreos: this.rastreosNuevos,  empleado: empleado, filtro: 'Perdida'},
            panelClass: 'form-container',
            width: '50vw'
        });
    }

    abrirFormaEmpleadoG(empleado): void {
        const forma = this.ventana.open(PartidasCerradasEmpleadoComponent, {
            data: { rastreos: this.rastreosNuevos,  empleado: empleado, filtro: 'Ganada'},
            panelClass: 'form-container',
            width: '50vw'
        });
    }

}
import { HttpClient } from '@angular/common/http';
import { Proyecto } from '../entidades/proyecto';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilidadProyectos } from '../entidades/utilidad-proyecto';
import { CotizacionesGlobal } from '../entidades/cotizacion-globales';
import { Respuesta } from '../entidades/respuesta';
import { RelacionAsignacionCVs } from '../entidades/relacion-asignacion-cv';

export class RepositorioProyectos extends RepositorioCatalogo<Proyecto> {
    constructor(http: HttpClient) {
        super(http, 'Proyectos');
    }

    activar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null).pipe(map(() => null));
    }

    desactivar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null).pipe(map(() => null));
    }

    duplicar(proyectoOrigenId: number): Observable<void> {
        const ruta = `${this.ruta}/Duplicar/${proyectoOrigenId}`;
        return this.clienteHttp.post<void>(ruta, null);
    }

    obtenerTodosCliente(idCliente: number): Observable<Proyecto[]> {
        const ruta = `${this.ruta}/Cliente/${idCliente}`;        
        return this.clienteHttp.get(ruta).pipe(
            map((data: Proyecto[]) => { 
                console.log(data)               
                return data;
            })
        );
    }

    obtenerDetalleProyectoPorCliente(idCliente: number, idProyecto: number): Observable<Proyecto[]> {
        const ruta = `${this.ruta}/ObtenerProyectoDetalle/${idCliente}/${idProyecto}`;        
        return this.clienteHttp.get(ruta).pipe(
            map((data: Proyecto[]) => {             
                return data;
            })
        );
    }

    obtenerFoliosProyectosAbiertos(idCliente: number): Observable<Proyecto[]> {
        const ruta = `${this.ruta}/ObtenerFoliosProyectosAbiertos/${idCliente}`;
        return this.clienteHttp.get<Proyecto[]>(ruta);
    }

    obtenerProyectosCerrados(idCliente: number): Observable<Proyecto[]> {
        const ruta = `${this.ruta}/ObtenerProyectosCerrados/${idCliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((data: Proyecto[]) => {
                return data;
            })
        );
    }

    TipoDeProyectoEs(idCliente: number): Observable<string> {
        const ruta = `${this.ruta}/TipoDeProyectoEs/${idCliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((data: string) => {
                return data;
            })
        );
    }

    obtenerUtilidadProyectosCerrados(
        idProyecto: number
    ): Observable<UtilidadProyectos> {
        const ruta = `${this.ruta}/ObtenerUtilidadProyectosCerrados/${idProyecto}`;
        return this.clienteHttp.get(ruta).pipe(
            map((data: UtilidadProyectos) => {
                return data;
            })
        );
    }

    cerrar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Cerrar/${id}`;
        return this.clienteHttp.put(ruta, null).pipe(map(() => null));
    }

    cancelar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Cancelar/${id}`;
        return this.clienteHttp.put(ruta, null).pipe(map(() => null));
    }

    abrirProyectoCerrado(proyectoOrigenId: number): Observable<void> {
        const ruta = `${this.ruta}/AbrirProyectoCerrado/${proyectoOrigenId}`;
        return this.clienteHttp.post<void>(ruta, null);
    }

    obtenerCVGlobales(proyectoId: number): Observable<CotizacionesGlobal> {
        const ruta = `${this.ruta}/ObtenerCVGlobales/${proyectoId}`;
        return this.clienteHttp.get(ruta).pipe(
            map((cotizacion: CotizacionesGlobal) => {
                return cotizacion;
            })
        );
    }

    obtenerDocumentosSinFactura(id: number): Observable<string[]> {
        const ruta = `${this.ruta}/ObtenerDocumentosSinFactura/${id}`;
        return this.clienteHttp.get<string[]>(ruta);
    }

    obtenerPorFolio(folio: string): Observable<Proyecto>{
      const ruta = `${this.ruta}/ObtenerPorFolio/${folio}`;
      return this.clienteHttp.get<Proyecto>(ruta);
    }

    cancelacionProyecto(documentoId: number): Observable<Respuesta<Proyecto>>  {
      const ruta = `${this.ruta}/CancelacionProyecto/${documentoId}`;
      return this.clienteHttp.get<Respuesta<Proyecto>>(ruta);
    }

    obtenerRelacionCvsAsociadas(proyectoId: number): Observable<RelacionAsignacionCVs[]>{
        const ruta = `${this.ruta}/RelacionAsignacionesCV/${proyectoId}`;
        return this.clienteHttp.get<RelacionAsignacionCVs[]>(ruta);
    }

    preActualizarClienteProyecto(proyectoId: string): Promise<any> {
        const ruta = `${this.ruta}/PreActualizacionCliente/${proyectoId}`;                
        return new Promise((resolve, reject) => {
          this.clienteHttp.get(ruta).toPromise()
          .then( (success: any) => {           
            resolve(success);
          })
          .catch((error) => {
            reject(error);
          });
        });
    }

    actualizarClienteProyecto(solicitud: any): Promise<any> {
        const ruta = `${this.ruta}/ActualizacionCliente`;
        return new Promise((resolve, reject) => {
          this.clienteHttp.put(ruta,solicitud).toPromise()
          .then( (success: any) => {           
            resolve(success);
          })
          .catch((error) => {
            reject(error);
          });
        });
    }

}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TipoUnidad } from 'src/app/entidades/tipo-unidad';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { ModoTransporte } from 'src/app/entidades/modo-transporte';
import { TipoProveedor } from 'src/app/entidades/tipo-proveedor';
import { TipoCamion } from 'src/app/entidades/tipo-camion';
import { EspecificacionCarga } from 'src/app/entidades/especificacion-carga';
import { AplicaProveedorMina } from 'src/app/entidades/aplica-proveedor-mina';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAplicaProveedoMinaComponent implements OnInit {
  forma: FormGroup;
  model: AplicaProveedorMina;
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaAplicaProveedoMinaComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id > 0) {
      this.idSeleccionado = id;
      this.ctx.aplicaProveedorMina.obtener(id).toPromise().then(resultado => {
        this.model = resultado;
        Object.assign(this.forma.value, this.model);
        this.forma.reset(this.forma.value);
      }).catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al obtener información');
      });

    }
    else {
      this.idSeleccionado = 0;
      this.model = new AplicaProveedorMina();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        clave: ['', Validators.required],
        descripcion: ['', Validators.required],
        activo: [true, Validators.nullValidator],
      },
    );
  }

  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {

    if (this.forma.valid) {

      const model = this.forma.value as AplicaProveedorMina;

      if (this.idSeleccionado === 0) {
        this.ctx.aplicaProveedorMina.guardar(model).toPromise().then(() => {
          this.alerta.mostrarExito("Aplica proveedor en mina guardado con éxito");
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.alerta.mostrarAdvertencia('¡Ya existe la aplicación de proveedor en mina con esa clave o descripción! ');
          } else if (e.error === 'ErrorGuardar') {
            this.alerta.mostrarAdvertencia('¡Error al guardar la aplicación de proveedor en mina! ');
          }
          else {
            this.alerta.mostrarError('¡Error interno al guardar! ');
          }

        });

      } else {
        this.ctx.aplicaProveedorMina.actualizar(this.idSeleccionado.toString(), model).toPromise().then(() => {
          this.alerta.mostrarExito("Aplica proveedor en mina actualizado con éxito");
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.alerta.mostrarAdvertencia('¡Ya existe la aplicación de proveedor en mina con esa clave o descripción! ');
          } else {
            this.alerta.mostrarError('¡Error interno al guardar! ');
          }
        });
      }
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }
}

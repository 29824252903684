import { Articulo } from '../entidades/articulo';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioArticulo extends RepositorioCatalogo<Articulo> {
    constructor(http: HttpClient) {
        super(http, 'Articulos');
    }

    activar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null).pipe(map(() => null));
    }

    desactivar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null).pipe(map(() => null));
    }
}

import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { TiposMotivos } from 'src/app/entidades/tipos-motivos';

type dataMotivos = {
  id: number;
  datosTabla: TiposMotivos[];
}

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaTiposMotivosComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  modelo: TiposMotivos;
  datosTabla: TiposMotivos[];
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  claveActualizar: string;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public data: dataMotivos, private ventana: MatDialogRef<FormaTiposMotivosComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {

    const { id, datosTabla } = data;
    this.datosTabla = datosTabla;

    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx
        .tiposMotivos
        .obtener(id)
        .subscribe(
          a => {
            this.modelo = a;
            Object.assign(this.forma.value,this.modelo);
            this.forma.reset(this.forma.value);
            this.claveActualizar = this.forma.value.clave;

          });
    }
    else {
      this.idSeleccionado = 0;
      this.modelo = new TiposMotivos();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        clave: ['', Validators.required],
        descripcion: ['', [Validators.required]],
        tipo: ['', Validators.required]
      }
    );
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.modelo, this.forma.value);
      console.log(this.forma.value)
      console.log(this.modelo)

      let observable: Observable<TiposMotivos>;
      let mensaje = 'Tipos de motivos guardado.';
      let guardar = true;
      if (this.idSeleccionado == 0) {
        observable = this.ctx
          .tiposMotivos
          .guardar(this.modelo);
      } else {
        guardar = false;
        mensaje = 'Tipos de motivos actualizado';
        observable = this.ctx
          .tiposMotivos
          .actualizar(this.idSeleccionado.toString(), this.modelo);
      }

      this.subsGuardar = observable.subscribe(() => {
        this.guardado.emit();
        this.alerta.mostrarExito(mensaje);
        this.cargando = false;
        this.limpiar();

        this.ventana.close();

      },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  asignarClave(tipo: string): void {

    let clave = '';

    if(this.idSeleccionado == 0){

      clave = this.cargarClaveNueva(tipo)
  
    }else{

      if( this.forma.value.tipo == this.claveActualizar[0] ){
        clave = this.claveActualizar;
      } else {
        clave = this.cargarClaveNueva(tipo)
      }

    }

    this.forma.patchValue({
      clave
    });
    

  }

  cargarClaveNueva(tipo: string): string {

    let clave = '';

    if(tipo == 'C'){
      const dataCompra = this.datosTabla.filter(data => data.clave.includes('C')).sort((a,b) => {
        if (a.clave < b.clave) {
          return 1;
        }
        if (a.clave > b.clave) {
          return -1;
        }
        return 0;
      })

      if( dataCompra.length <= 0 ){
        clave = 'C0001';
      }else {

        clave = this.generarNuevaClave(dataCompra[0].clave, tipo)
      }
      
      }else {
        
        const dataVentas = this.datosTabla.filter(data => data.clave.includes('V')).sort((a,b) => {
          if (a.clave < b.clave) {
            return 1;
          }
          if (a.clave > b.clave) {
            return -1;
          }
          return 0;
        })

        if( dataVentas.length <= 0 ){
          clave = 'V0001';
        }else {
  
          clave = this.generarNuevaClave(dataVentas[0].clave, tipo)
  
        }
    }
    return clave;
  }

  generarNuevaClave(ultimaClave: string, tipo: string): string {
    const ultimoNumero = this.extraerNumeroDeClave(ultimaClave, tipo);
    const nuevoNumero = ultimoNumero + 1;
    return `${tipo}${nuevoNumero.toString().padStart(4, '0')}`;
  }

  extraerNumeroDeClave(clave: string, tipo: string): number {
    let match;

    if(tipo == 'V'){
      match = clave.match(/V(\d+)/);
    }else {
      match = clave.match(/C(\d+)/);
    }

    if (match && match[1]) {
        return parseInt(match[1], 10);
    }
    throw new Error("Clave invalida");
  }



  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActualizaProveedorCT } from 'src/app/entidades/actualiza-proveedor-CT';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwToolbarMixedModesError } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Socio } from 'src/app/entidades/socio';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import * as _ from 'lodash';


@Component({
    selector: 'catalogo-clientesProyecto',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoActualizaClienteProyectoComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    subsGuardar: Subscription;
    guardado: EventEmitter<void> = new EventEmitter<void>();
    get f() {
        return this.forma.controls;
    }
    modelo: ActualizaProveedorCT;
    clientes: Socio[] = [];
    clienteAnterior;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ventana: MatDialogRef<CatalogoActualizaClienteProyectoComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,        
        private alerta: ServicioAlerta,        
        public ventanaMensaje: MatDialog,
        public spinner: LoadingService,
    ) {}

    ngOnInit() {        
        this.forma = this.formBuilder.group({
            clienteId: [undefined, Validators.required]
        });
        
        this.cargarClientes();
    }

    async guardar(): Promise<void> {  
        try {
            this.spinner.show("Procesando");
            if (this.forma.valid) {  
                
                let clienteActual  = this.clienteAnterior = this.clientes.find(mCliente => String(mCliente.id) === String(this.f['clienteId'].value));                
                let folioArray = String(_.cloneDeep(this.data.folio)).split('-');
                let nuevoFolio = `${folioArray[0]}-${clienteActual.codigoCliente}-${folioArray[2]}`;                
                let solicitud = {
                    proyecto: this.data.proyecto,
                    clienteId: clienteActual.id,
                    folio: nuevoFolio
                };                
                this.ctx.proyectos.actualizarClienteProyecto(solicitud)
                .then(success => {                    
                    this.alerta.mostrarExito("Proyecto Actualizado");
                    this.cerrar();
                })
                .catch(error => {
                    console.log('error-->', error);
                });
            } else {
                this.alerta.mostrarAdvertencia('Los datos son inválidos.');
            }
        } catch (error) {
            this.alerta.mostrarError('Error interno en el sistema.');
        }finally{
            this.spinner.hide();
        }
    }
    
    cargarClientes(): void {
        //this.spinner.show('Cargando clientes...');
        /*
            this.proveedores = socios.filter(
                (e) => e.id != this.documento.proveedorId
            );
        */

        this.ctx.socios.obtenerClientes().subscribe((socios) => { 
            this.clienteAnterior = socios.find(mCliente => String(mCliente.id) === String(this.data.clienteId));            
            this.clientes =socios.filter(
                (e) => e.id != this.data.clienteId
            );
        });
        //this.spinner.hide();

    }

    cerrar(): void {
        this.ventana.close();
    }

    ngOnDestroy(): void { }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Socio } from 'src/app/entidades/socio';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

import { Proyecto } from 'src/app/entidades/proyecto';
import { FiltroReporte } from 'src/app/entidades/filtro-reporte';
import { GeneradorReportes } from 'src/app/entidades/generador-reportes';
import { CatalogoReporteTablaComponent } from '../reporte/catalogo.component';
import { DatePipe } from '@angular/common';
import { CatalogoReporteGlobalTablaComponent } from '../reporte-global/catalogo.component';
import { CatalogoReporteHistorialTablaComponent } from '../reporte-historial/catalogo.component';
import { Empleado } from 'src/app/entidades/empleado';
import { TipoServicio } from 'src/app/entidades/tipo-servicio';
import { CatalogoReporteAdicionalTablaComponent } from '../reporte-adicional/catalogo.component';
@AutoUnsubscribe()
@Component({
    selector: 'forma-filtros',
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaFiltrosComponent implements OnInit {
    @ViewChild(CatalogoReporteTablaComponent)
    reporte: CatalogoReporteTablaComponent;
    @ViewChild(CatalogoReporteAdicionalTablaComponent)
    reporteAdicional: CatalogoReporteAdicionalTablaComponent;
    @ViewChild(CatalogoReporteGlobalTablaComponent)
    reporteadorGlobal: CatalogoReporteGlobalTablaComponent;
    @ViewChild(CatalogoReporteHistorialTablaComponent)
    reporteadorHistorial: CatalogoReporteHistorialTablaComponent;

    proveedoresFiltrados: Observable<Socio[]>;
    proveedoresSinFiltrar: Socio[];
    filtroProveedores = new FormControl();

    clientesFiltrados: Observable<Socio[]>;
    clientesSinFiltrar: Socio[];
    filtroClientes = new FormControl();

    proyectosFiltrados: Observable<Proyecto[]>;
    proyectosSinFiltrar: Proyecto[];
    filtroProyectos = new FormControl();

    tiposServicioFiltrados: Observable<TipoServicio[]>;
    tipoServicioSinFiltrar: TipoServicio[];
    filtroTiposServicio = new FormControl();

    empleadosSinFiltrar: Empleado[] = [];
    empleadosFiltrados: Observable<Empleado[]>;
    filtroEmpleados = new FormControl(null);

    filtroReporte: FiltroReporte[] = [];

    filtrosFecha: any[] = [];

    proveedor = false;
    cliente = false;
    proyecto = false;
    tipoServicio = false;
    fechaProyectoDesde = false;
    fechaProyectoHasta = false;
    estadoDocumento = false;
    categoriaDocumento = false;
    categoriaEmpresa = false;
    categoriaProyecto = false;
    origen = false;
    destino = false;
    numeroDeFolio = false;
    enviadoSAP = false;
    empleado = false;

    reporteDetallado = false;
    reporteGlobal = false;
    reporteHistorial = false;
    reporteDetalladoTipoServicio: boolean;
    reporteAuditoriaVentas = false;
    reporteDetalladoAdicional = false;

    estatusCompra: Number = 1;

    filtrosEstatusCompra = [
        {
            value: 1,
            display: "Todo"
        },
        {
            value: 2,
            display: " Enviada a SAP"
        },
        {
            value: 3,
            display: "Pagada en SAP"
        }
    ];

    reporteador: GeneradorReportes;
    forma: FormGroup;
    get f() {
        return this.forma.controls;
    }

    constructor(
        public ventana: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        this.forma = this.formBuilder.group({
            proveedorId: [0, Validators.nullValidator],
            proveedorDescripcion: ['', Validators.nullValidator],
            clienteId: [0, Validators.nullValidator],
            clienteDescripcion: ['', Validators.nullValidator],
            proyectoId: [0, Validators.nullValidator],
            proyectoDescripcion: ['', Validators.nullValidator],
            proyectoFechaDesde: [null, Validators.nullValidator],
            proyectoFechaHasta: [null, Validators.nullValidator],
            estadoDocumentoId: [0, Validators.nullValidator],
            categoriaDocumentoId: [0, Validators.nullValidator],
            categoriaEmpresaId: [0, Validators.nullValidator],
            tipoProyectoId: [0, Validators.nullValidator],
            numeroFolio: ['', Validators.nullValidator],
            enviadosSAP: [0, Validators.nullValidator],
            empleadoId: [0, Validators.nullValidator],
            tipoServicioId: [0, Validators.nullValidator],
            tipoServicioDescripcion: ['', Validators.nullValidator],
            tipoFiltroFecha: [1, Validators.nullValidator],
        });
        this.cargarProveedores();
        this.cargarClientes();
        this.cargarProyectos();
        this.cargarTiposServicio();
    }

    cargarProveedores() {
        this.ctx.socios
            .obtenerProveedores()
            .toPromise()
            .then((resultado) => {
                this.proveedoresSinFiltrar = resultado;
                this.proveedoresFiltrados =
                    this.filtroProveedores.valueChanges.pipe(
                        startWith<string | Socio>(''),
                        map((t) =>
                            typeof t === 'string'
                                ? t
                                : t == null
                                    ? ''
                                    : t.nombreCorto
                        ),
                        map((t) => this.filtrarProveedores(t))
                    );
            })
            .catch((e) => { });
    }

    private filtrarProveedores(nombre: string): Socio[] {
        const valorFiltro = nombre.toLowerCase();
        const filtro = this.proveedoresSinFiltrar.filter(
            (t) =>
                t.nombreCorto.toLowerCase().indexOf(valorFiltro) === 0 ||
                t.rfc.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    proveedorSeleccionado(tipo: Socio) {
        this.f['proveedorId'].setValue(tipo.id);
        this.f['proveedorDescripcion'].setValue(tipo.nombreCorto);
    }

    cargarClientes() {
        this.ctx.socios
            .obtenerClientes()
            .toPromise()
            .then((resultado) => {
                this.clientesSinFiltrar = resultado;
                this.clientesFiltrados = this.filtroClientes.valueChanges.pipe(
                    startWith<string | Socio>(''),
                    map((t) =>
                        typeof t === 'string'
                            ? t
                            : t == null
                                ? ''
                                : t.nombreCorto
                    ),
                    map((t) => this.filtrarClientes(t))
                );
            })
            .catch((e) => { });
    }

    private filtrarClientes(nombre: string): Socio[] {
        const valorFiltro = nombre.toLowerCase();
        const filtro = this.clientesSinFiltrar.filter(
            (t) =>
                t.nombreCorto.toLowerCase().indexOf(valorFiltro) === 0 ||
                t.rfc.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    clienteSeleccionado(tipo: Socio) {
        this.f['clienteId'].setValue(tipo.id);
        this.f['clienteDescripcion'].setValue(tipo.nombreCorto);
    }

    cargarProyectos() {
        this.ctx.proyectos
            .obtenerTodos()
            .toPromise()
            .then((resultado) => {
                this.proyectosSinFiltrar = resultado;
                this.proyectosFiltrados =
                    this.filtroProyectos.valueChanges.pipe(
                        startWith<string | Proyecto>(''),
                        map((t) =>
                            typeof t === 'string' ? t : t == null ? '' : t.folio
                        ),
                        map((t) => this.filtrarProyectos(t))
                    );
            })
            .catch((e) => { });
    }

    private filtrarProyectos(nombre: string): Proyecto[] {
        const valorFiltro = nombre.toLowerCase();
        const filtro = this.proyectosSinFiltrar.filter(
            (t) =>
                t.folio.includes(valorFiltro) ||
                t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    proyectoSeleccionado(tipo: Proyecto) {
        this.f['proyectoId'].setValue(tipo.id);
        this.f['proyectoDescripcion'].setValue(tipo.descripcion);
    }

    //#region Tipos de servicio
    cargarTiposServicio() {
        this.ctx.tiposServicios
            .obtenerTodos()
            .toPromise()
            .then((resultado) => {
                this.tipoServicioSinFiltrar = resultado;
                this.tiposServicioFiltrados =
                    this.filtroTiposServicio.valueChanges.pipe(
                        startWith<string | TipoServicio>(''),
                        map((t) =>
                            typeof t === 'string' ? t : t == null ? '' : t.clave
                        ),
                        map((t) => this.filtrarTiposServicio(t))
                    );
            })
            .catch((e) => { });
    }

    private filtrarTiposServicio(nombre: string): TipoServicio[] {
        const valorFiltro = nombre ? nombre.toLowerCase() : '';
        const filtro = this.tipoServicioSinFiltrar.filter(
            (t) =>
                t.clave.includes(valorFiltro) ||
                t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    tipoServicioSeleccionado(tipo: TipoServicio | number) {
        if (tipo === 0) {
            this.f['tipoServicioId'].setValue(0);
            this.f['tipoServicioDescripcion'].setValue('Todos');
        } else if (typeof tipo == 'object') {
            this.f['tipoServicioId'].setValue(tipo.id);
            this.f['tipoServicioDescripcion'].setValue(tipo.descripcion);
        } else {
            this.f['tipoServicioId'].setValue(null);
            this.f['tipoServicioDescripcion'].setValue('');
        }
    }
    //#endregion

    private cargarEmpleados() {
        this.ctx.empleados
            .obtenerTodos()
            .toPromise()
            .then((resultado) => {
                this.empleadosSinFiltrar = resultado;
                this.empleadosFiltrados =
                    this.filtroEmpleados.valueChanges.pipe(
                        startWith<string | Empleado>(''),
                        map((t) =>
                            typeof t === 'string'
                                ? t
                                : t == null
                                    ? ''
                                    : t.nombre
                        ),
                        map((t) => this.filtrarEmpleados(t))
                    );
            })
            .catch((e) => { });
    }

    private filtrarEmpleados(nombre: string): Empleado[] {
        if (!nombre) {
            nombre = '';
        }
        const valorFiltro = nombre.toLowerCase();
        const empleado = this.empleadosSinFiltrar.filter(
            (emp) =>
                emp.nombre.toLowerCase().indexOf(valorFiltro) === 0 ||
                emp.rfc.toLowerCase().indexOf(valorFiltro) === 0
        );
        return empleado;
    }

    cargarAsignacionFiltros(tipoReporteId: number, opcionReporte: number): void {
        this.ctx.filtroReporte
            .obtenerFiltroTipoReporte(tipoReporteId)
            .toPromise()
            .then((r) => {
                this.filtroReporte = r;
                this.filtroReporte.forEach((element) => {
                    if (element.descripcion === 'Proveedor') {
                        this.proveedor = true;
                    } else if (element.descripcion === 'Cliente') {
                        this.cliente = true;
                    } else if (element.descripcion === 'Proyecto') {
                        this.proyecto = true;
                    } else if (element.descripcion === 'Fecha Proyecto Desde') {
                        this.fechaProyectoDesde = true;
                    } else if (element.descripcion === 'Fecha Proyecto Hasta') {
                        this.fechaProyectoHasta = true;
                    } else if (element.descripcion === 'Estado Documento') {
                        this.estadoDocumento = true;
                    } else if (element.descripcion === 'Categoria Documento') {
                        this.categoriaDocumento = true;
                    } else if (element.descripcion === 'Folio') {
                        this.numeroDeFolio = true;
                    } else if (element.descripcion === 'Categoria Empresa') {
                        this.categoriaEmpresa = true;
                    } else if (element.descripcion === 'Categoria Proyecto') {
                        this.categoriaProyecto = true;
                    } else if (element.descripcion === 'Origen') {
                        this.origen = true;
                    } else if (element.descripcion === 'Destino') {
                        this.destino = true;
                    } else if (element.descripcion === 'Enviado a SAP') {
                        this.enviadoSAP = true;
                    } else if (element.descripcion === 'Empleado') {
                        this.empleado = true;
                        this.cargarEmpleados();
                    } else if (element.clave === '019' && opcionReporte === 4) {
                        this.tipoServicio = true;
                    }
                });
            });
    }

    campoMostrarEmpleado(empleado: Empleado) {
        return empleado ? empleado.nombre : '';
    }

    limpiarFiltros(): void {
        this.proveedor = false;
        this.cliente = false;
        this.proyecto = false;
        this.fechaProyectoDesde = false;
        this.fechaProyectoHasta = false;
        this.estadoDocumento = false;
        this.categoriaDocumento = false;
        this.categoriaEmpresa = false;
        this.categoriaProyecto = false;
        this.origen = false;
        this.destino = false;
        this.numeroDeFolio = false;
        this.enviadoSAP = false;
        this.empleado = false;
        this.tipoServicio = false;
    }

    limpiar(): void {
        this.f['proveedorId'].setValue(0);
        this.f['proveedorDescripcion'].setValue('');
        this.f['clienteId'].setValue(0);
        this.f['clienteDescripcion'].setValue('');
        this.f['proyectoId'].setValue(0);
        this.f['proyectoDescripcion'].setValue('');
        this.f['proyectoFechaDesde'].setValue(null);
        this.f['proyectoFechaHasta'].setValue(null);
        this.f['estadoDocumentoId'].setValue(0);
        this.f['categoriaDocumentoId'].setValue(0);
        this.f['categoriaEmpresaId'].setValue(0);
        this.f['numeroFolio'].setValue('');
        this.f['tipoProyectoId'].setValue(0);
        this.f['enviadosSAP'].setValue(0);
        this.f['tipoServicioId'].setValue(0);
        this.f['tipoServicioDescripcion'].setValue(null);

        this.reporte.limpiarTabla();
        this.cargarProveedores();
        this.cargarClientes();
        this.cargarProyectos();

        this.reporteador = this.forma.value as GeneradorReportes;
        // this.reporte.crearReporte(this.reporteador);
    }

    buscar(): void {
        this.reporteador = this.forma.value as GeneradorReportes;
        const fProyectoDesde = this.datePipe.transform(
            this.reporteador.proyectoFechaDesde,
            'dd-MM-yyyy'
        );
        const fProyectoHasta = this.datePipe.transform(
            this.reporteador.proyectoFechaHasta,
            'dd-MM-yyyy'
        );

        if (fProyectoDesde !== null || fProyectoHasta !== null) {
            if (
                this.reporteador.proyectoFechaHasta <
                this.reporteador.proyectoFechaDesde
            ) {
                this.alerta.mostrarAdvertencia(
                    'La fecha hasta de proyecto es menor a la fecha desde'
                );
                return;
            }
        }

        if (this.reporteDetallado) {
            console.log('detalle');
            this.reporte.crearReporte(this.reporteador, false);
        } else if (this.reporteGlobal) {
            console.log('Global');
            this.reporteadorGlobal.crearReporte(this.reporteador);
        } else if (this.reporteHistorial) {
            console.log('Historial');
            this.reporteadorHistorial.crearReporte(this.reporteador);
        } else if (this.reporteDetalladoTipoServicio) {
            console.log('Detallado por tipo de servicio');
            this.reporte.crearReporte(this.reporteador, true);
        } else if (this.reporteAuditoriaVentas) {
            console.log('reporteAuditoriaVentas');
            this.reporte.crearReporteAuditoriaVentas(this.reporteador, Number(this.estatusCompra));
        }
        else if (this.reporteDetalladoAdicional) {
            console.log('reporteDetalladoAdicional');
            this.reporteAdicional.crearReporte(this.reporteador, false);
        }
    }

    exportarExcel(): void {
        this.reporteador = this.forma.value as GeneradorReportes;
        if (this.reporteDetallado) {
            this.reporte.exportarExcel(this.reporteador, false);
        } else if (this.reporteGlobal) {
            this.reporteadorGlobal.exportarExcel(this.reporteador);
        } else if (this.reporteHistorial) {
            this.reporteadorHistorial.exportarExcel(this.reporteador);
        } else if (this.reporteDetalladoTipoServicio) {
            this.reporte.exportarExcel(this.reporteador, true);
        } else if (this.reporteAuditoriaVentas) { //AUDITORIA DE PROYECTOS
            this.reporte.exportarExcelAuditoriaVentas(this.reporteador, Number(this.estatusCompra));
        } else if (this.reporteAdicional) { //AUDITORIA DE PROYECTOS
            this.reporteAdicional.exportarExcel(this.reporteador, false);
        }
    }

    seleccionCategoriaProyecto(opcion: number): void {
        this.filtrosFecha = [
            {
                value: "1",
                display: "Proyecto"
            },
            {
                value: "2",
                display: "Documento"
            },
            {
                value: "3",
                display: "Envío"
            }
        ];
        if (opcion === 1) {
            this.reporteDetallado = true;
            this.reporteGlobal = false;
            this.reporteHistorial = false;
            this.reporteDetalladoTipoServicio = false;
            this.reporteAuditoriaVentas = false;
            this.reporteDetalladoAdicional = false;
        } else if (opcion === 2) {
            this.reporteGlobal = true;
            this.reporteDetallado = false;
            this.reporteHistorial = false;
            this.reporteDetalladoTipoServicio = false;
            this.reporteAuditoriaVentas = false;
            this.reporteDetalladoAdicional = false;
        } else if (opcion === 3) {
            this.reporteGlobal = false;
            this.reporteDetallado = false;
            this.reporteHistorial = true;
            this.reporteDetalladoTipoServicio = false;
            this.reporteAuditoriaVentas = false;
            this.reporteDetalladoAdicional = false;
        } else if (opcion === 4) {
            this.reporteDetalladoTipoServicio = true;
            this.reporteGlobal = false;
            this.reporteDetallado = false;
            this.reporteHistorial = false;
            this.reporteAuditoriaVentas = false;
            this.reporteDetalladoAdicional = false;
        } else if (opcion === 5) { //AUDITORIA DE PROYECTOS
            console.log('auditoria ventas ');
            this.reporteDetallado = false;
            this.reporteGlobal = false;
            this.reporteHistorial = false;
            this.reporteDetalladoTipoServicio = false;
            this.reporteAuditoriaVentas = true;
            this.reporteDetalladoAdicional = false;
            this.filtrosFecha = [
                {
                    value: "1",
                    display: "Proyecto"
                }
            ];
        }
        else if (opcion === 6) {
            console.log('Detallado adicional')
            this.reporteDetallado = false;
            this.reporteGlobal = false;
            this.reporteHistorial = false;
            this.reporteDetalladoTipoServicio = false;
            this.reporteAuditoriaVentas = false;
            this.reporteDetalladoAdicional = true;
        }
    }
}

import { Component } from '@angular/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'loading',
  template: `
    <ngx-spinner>
      <p style="font-size: 20px; color: white; font-family:Roboto;">
        {{ spinner.mensaje }}
      </p>
    </ngx-spinner>
  `,
  styleUrls: ['./loading.component.scss']
})
export class LoadComponent {
  constructor(public spinner: LoadingService) {}
}

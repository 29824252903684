import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import * as _ from 'underscore';
import { VentasSinEnviar } from 'src/app/entidades/ventas-sin-enviar';


@Component({
  selector: 'app-ventas-sapfiltro-component',
  templateUrl: './ventas-sapfiltro-component.component.html',
  styleUrls: ['./ventas-sapfiltro-component.component.scss']
})
export class VentasSAPFiltroComponent implements OnInit {
  forma: FormGroup;
  datosAgrupados: _.Dictionary<VentasSinEnviar[]>;
  clientes: string[];


  constructor(
    @Inject(MAT_DIALOG_DATA) public object: {documentosPendientes: VentasSinEnviar[]},
    public ventana: MatDialogRef<VentasSAPFiltroComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) { }

  ngOnInit() {
    console.log(this.object.documentosPendientes);
    this.forma = this.formBuilder.group({
      cliente: ['', Validators.required]
    })
    this.formatData();
  }


  formatData(): void{
    this.datosAgrupados = _.chain(this.object.documentosPendientes)
      .groupBy(c => c.nombreCliente)
      .value();

    this.clientes = _.chain(this.datosAgrupados)
    .keys()
    .sort()
    .value();

    console.log(this.clientes);
  }
  
  cerrarYEnviarDatos(): void {
    if(this.forma.value && Object.keys(this.forma.value).length > 0){
      const datosParaEnviar = this.forma.value;
      this.ventana.close(datosParaEnviar);
    }else{
      this.alerta.mostrarAdvertencia('Selecciona un filtro para continuar')
    }
  }

  cerrarSinEnviar(): void{
    this.ventana.close();
  }

  eliminarFiltros(): void{
    this.ventana.close('none');
  }
}

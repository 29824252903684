import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TipoCambio } from 'src/app/entidades/tipo-cambio';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Moneda } from 'src/app/entidades/moneda';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaTipoCambioComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  tipoCambio: TipoCambio;
  monedas: Observable<Moneda[]>;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  habilitar = false;
  get f() {
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaTipoCambioComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {
    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx.tiposCambio.obtener(id).subscribe(a => {
        this.tipoCambio = a;
        Object.assign(this.forma.value, this.tipoCambio);
        this.forma.reset(this.forma.value);
        this.habilitar = true;
      });
    } else {
      this.idSeleccionado = 0;
      this.tipoCambio = new TipoCambio();
    }
  }

  ngOnInit() {
    this.monedas = this.ctx.monedas.obtenerExtranjeras();

    this.forma = this.formBuilder.group({
      id: [0, Validators.required],
      monedaId: ['', Validators.required],
      valor: ['', [Validators.required, Validators.min(0)]],
      fechaCambio: ['', Validators.required]
    });
  }

  limpiar(): void {
    this.forma.reset({ id: 0 });
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.tipoCambio, this.forma.value);

      let observable: Observable<TipoCambio>;
      let mensaje = 'Tipo de cambio guardado.';
      let guardar = true;
      if (this.idSeleccionado === 0) {
        observable = this.ctx.tiposCambio.guardar(this.tipoCambio);
      } else {
        guardar = false;
        mensaje = 'Tipo de cambio actualizado';
        observable = this.ctx.tiposCambio.actualizar(
          this.idSeleccionado.toString(),
          this.tipoCambio
        );
      }

      this.subsGuardar = observable.subscribe(
        () => {
          this.guardado.emit();
          this.alerta.mostrarExito(mensaje);
          this.cargando = false;
          this.limpiar();
          if (!guardar) {
            this.ventana.close();
          }
        },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        }
      );
    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

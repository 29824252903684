import { Component, OnInit, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable } from 'rxjs';
import { TipoTexto } from 'src/app/entidades/tipo-texto';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FormaTipoReporteComponent } from '../../tipo-reporte/forma/forma.component';
import { TipoReporte } from 'src/app/entidades/tipo-reportes';
import {
    FormControl,
    FormGroup,
    FormBuilder,
    Validators,
} from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { FormaAsignacionFiltroReporteComponent } from '../../asignacion-filtro-reporte/forma/forma.component';
import { FormaFiltrosComponent } from '../filtros/forma.component';

@AutoUnsubscribe()
@Component({
    selector: 'catalogo-generador-reporte',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoGeneradorReportesComponent implements OnInit {
    @ViewChild(FormaFiltrosComponent) filtros: FormaFiltrosComponent;

    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    fuenteDatos: MatTableDataSource<TipoTexto> = new MatTableDataSource([]);
    columnasMostradas = ['clave', 'opciones'];
    cargando = true;
    categoriasReporte: any[] = [];

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
            return 50;
        }
        return 100;
    }

    tipoReporteId: number;
    forma: FormGroup;
    get f() {
        return this.forma.controls;
    }
    tipo: TipoReporte[] = [];
    tipoFiltrados: Observable<TipoReporte[]>;
    tipoSinFiltrar: TipoReporte[];
    filtroTipo = new FormControl();

    constructor(
        private formBuilder: FormBuilder,
        public ventana: MatDialog,
        private ctx: Contexto
    ) {}

    ngOnInit() {
        this.forma = this.formBuilder.group({
            tipoReporteId: [0, Validators.nullValidator],
            tipoReporteDescripcion: ['', Validators.required],
            proveedorId: [0, Validators.nullValidator],
            categoriaReporte: [0, Validators.nullValidator],
        });
        this.cargarTipoReportes();
    }
    cargarTipoReportes() {
        this.ctx.tipoReporte
            .obtenerTodosActivos()
            .toPromise()
            .then((resultado) => {
                this.tipoSinFiltrar = resultado;
                this.tipoFiltrados = this.filtroTipo.valueChanges.pipe(
                    startWith<string | TipoReporte>(''),
                    map((t) =>
                        typeof t === 'string'
                            ? t
                            : t == null
                            ? ''
                            : t.descripcion
                    ),
                    map((t) => this.filtrarTipoReportes(t))
                );
            })
            .catch(() => {});
    }

    private filtrarTipoReportes(nombre: string): TipoReporte[] {
        const valorFiltro = nombre.toLowerCase();
        const filtro = this.tipoSinFiltrar.filter(
            (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
        );
        return filtro;
    }

    reporteSeleccionado(tipo: TipoReporte) {
      console.log('tipoReporte-->', tipo);
      if(String(tipo.clave) === '003') {
        this.categoriasReporte = [
          {
            value: 5,
            display: 'Detallado'
          }
        ]
      } else if (String(tipo.clave) === '001'){
        this.categoriasReporte = [
          {
            value: 1,
            display: 'Detallado'
          },
          {
            value: 2,
            display: 'Global'
          },
          {
            value: 3,
            display: 'Historial'
          },
          {
            value: 4,
            display: 'Detallado por tipo de servicio'
          },
          {
            value: 6,
            display: 'Detallado adicional'
          }
        ]
      }
      else{
            this.categoriasReporte = [
              {
                value: 1,
                display: 'Detallado'
              },
              {
                value: 2,
                display: 'Global'
              },
              {
                value: 3,
                display: 'Historial'
              },
              {
                value: 4,
                display: 'Detallado por tipo de servicio'
              },
            ]
      }

      this.f['tipoReporteDescripcion'].setValue(tipo.descripcion);
      this.f['tipoReporteId'].setValue(tipo.id);
      // this.filtros.cargarAsignacionFiltros(tipo.id);
      this.tipoReporteId = tipo.id;
    }

    limpiarFiltro(): void {
        this.forma.reset();
        this.cargarTipoReportes();
        this.f['tipoReporteDescripcion'].setValue(null);
        this.f['tipoReporteId'].setValue(null);
        this.filtros.limpiarFiltros();
        this.tipoReporteId = null;
    }

    abrirCatalogo(tipo: String): void {
        if (tipo === 'TipoReportes') {
            this.ventana
                .open(FormaTipoReporteComponent, {
                    data: {},
                    panelClass: 'form-container',
                })
                .afterClosed()
                .subscribe(() => {
                    this.cargarTipoReportes();
                });
        } else if (tipo === 'AsignacionFiltroReportes') {
            this.ventana
                .open(FormaAsignacionFiltroReporteComponent, {
                    data: {},
                    panelClass: 'form-container',
                })
                .afterClosed()
                .subscribe(() => {
                    if (this.tipoReporteId > 0) {
                        this.limpiarFiltro();
                        this.filtros.cargarAsignacionFiltros(
                            this.tipoReporteId === 8 ? 1 : this.tipoReporteId,
                            1
                        );
                    }
                });
        }
    }

    buscar(): void {}

    seleccionCategoriaProyecto(opcion: any): void {
        console.log(opcion);
        this.filtros.limpiarFiltros();
        this.filtros.seleccionCategoriaProyecto(opcion);
        this.filtros.cargarAsignacionFiltros(this.tipoReporteId === 8 ? 1 : this.tipoReporteId, opcion);

    }
}

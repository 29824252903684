import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Socio } from 'src/app/entidades/socio';
import { Contexto } from 'src/app/api/contexto.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatTableDataSource,
  MatPaginator,
  MatSort
} from '@angular/material';

@Component({
  selector: 'datos-lista-proveedores',
  templateUrl: './datos-proveedores.component.html',
  styleUrls: ['./datos-proveedores.component.scss']
})
@AutoUnsubscribe()
export class FormaDatosProveedoresComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<Socio> = new MatTableDataSource([]);
  columnasMostradas = ['seleccionado', 'rfc', 'nombreCorto'];
  cargando = true;

  proveedores: Socio[] = [];
  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  constructor(
    private ctx: Contexto,
    @Inject(MAT_DIALOG_DATA) public tipoTexto,
    private modal: MatDialogRef<FormaDatosProveedoresComponent>
  ) {}
  ngOnInit() {
    this.cargarProveedores();
  }

  cargarProveedores(): void {
    this.cargando = true;
    this.ctx.socios.obtenerProveedores().subscribe(proveedores => {
      this.fuenteDatos = new MatTableDataSource(proveedores);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  seleccionarProveedor(activo: boolean, socio: Socio): void {
    if (activo) {
      this.proveedores.push(socio);
    } else {
      let pro = this.proveedores.find(e => e.id === socio.id);
      let index = this.proveedores.indexOf(pro, 0);
      if (index > -1) {
        this.proveedores.splice(index, 1);
      }
    }
  }

  cerrar(): void {
    this.modal.close(this.proveedores);
  }
  seleccionado(proveedores: Socio): void {
    this.modal.close(proveedores);
  }

  ngOnDestroy(): void {}
}

import { HttpClient } from "@angular/common/http";
import { UnidadesPeso } from "../entidades/unidades-peso";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

export class RepositorioUnidadesPeso extends RepositorioCatalogo<UnidadesPeso> {

    constructor(http: HttpClient) {
        super(http, "UnidadesPeso");
    }

    activar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }
}

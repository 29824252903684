import { ServicioCompra } from './servicio-compra';
import { ArchivoProveedorCompra } from './archivo-proveedor-compra';

export class Compra {
  id = 0;
  folio = '';
  fechaDocumento: Date = new Date();
  cancelada = false;
  total = 0;
  montoTraslado: number;
  montoRetencion: number;
  subtotal = 0;
  estadoId = 0;
  estadoDescripcion = '';
  monedaId = 0;
  monedaNombre = '';
  monedaAbreviatura = '';
  proyectoId = 0;
  proyectoFolio = '';
  proveedorId = 0;
  proveedorNombreComercial = '';
  proveedorNombreCorto = '';
  proyectoEsInternacional: false;
  archivosProveedor: ArchivoProveedorCompra[] = [];
  comentarioProveedor = '';
  comentarioEmpresa = '';
  numeroPedimento = '';
  ordenCompra = '';
  numeroContenedor = '';
  adicional = '';
  tipoCompraId = 0;
  tipoCompraDescripcion = '';
  folioOrigen = '';
  folioDestino = '';
  servicios: ServicioCompra[] = [];
}

export class SolicitudCompra extends Compra {
  comentarioEmpresa = '';
  servicios: ServicioCompra[] = [];
}

export class CotizacionCompra extends Compra {
  comentarioProveedor = '';
  comentarioEmpresa = '';
  servicios: ServicioCompra[] = [];
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-alerta-falta-factura',
    templateUrl: './alerta-falta-factura.component.html',
    styleUrls: ['./alerta-falta-factura.component.scss'],
})
export class AlertaFaltaFacturaComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public documentos: string[],
        private ventana: MatDialogRef<AlertaFaltaFacturaComponent>
    ) {}

    ngOnInit() {}
}

import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    MatPaginator,
    MatSort,
    MatTableDataSource,
    MatDialog,
} from '@angular/material';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { Documento } from 'src/app/entidades/documento';
import { Contexto } from 'src/app/api/contexto.service';
//import { ModalNoEnviarComponent } from '../modal-no-enviar/modal-no-enviar.component';
import { first } from 'rxjs/operators';
import { EstadoDocumento } from 'src/app/enumeraciones/enumeraciones';
import { ModalListaMotivosNoFacturarComponent } from 'src/app/modulos/flujo-ventas/componentes/modal-lista-motivos-no-facturar/modal-lista-motivos-no-facturar.component';
import { ModalMotivosNoFacturarComponent } from 'src/app/modulos/motivo-documentos-enviados/componentes/modal-motivos-no-facturar/modal-motivos-no-facturar.component';

@Component({
    selector: 'app-ventas-sin-enviar-SAP',
    templateUrl: './ventas-sin-enviar-SAP.component.html',
    styleUrls: ['./ventas-sin-enviar-SAP.component.scss'],
})
export class VentasSinEnviarSAPComponent implements OnInit {
    banderaGeneral = false;
    renglon = 0;
    @Input() banderaVenta = true;

    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    estadosDocumento: typeof EstadoDocumento = EstadoDocumento;

    fuenteDatosVentas: MatTableDataSource<Documento> = new MatTableDataSource(
        []
    );
    columnasMostradas = [
        'proyectoFolio',
        'folioRe',
        'folio',
        'folioSv',
        'fechaDocumento',
        'clienteNombreCorto',
        'subtotal',
        'estadoDescripcion',
        // 'motivoNoEnvio',
        'opciones',
    ];
    cargando = true;

    public get diametro(): number {
        if (
            !this.fuenteDatosVentas ||
            this.fuenteDatosVentas.data.length === 0
        ) {
            return 50;
        }
        return 100;
    }

    constructor(private webapi: Contexto, private dialog: MatDialog) {}

    ngOnInit() {
        this.cargarVentas();
    }

    cargarVentas(): void {
        this.webapi.documentosVenta
            .obtenerTodosSinProcesar()
            .toPromise()
            .then((documentos) => {
                this.fuenteDatosVentas = new MatTableDataSource(documentos);
                this.fuenteDatosVentas.paginator = this.paginador;
                this.fuenteDatosVentas.sort = this.ordenador;
                this.cargando = false;
            });
    }

    abrirModalMotivos(documento: Documento): void {
        // this.dialog
        //     .open(ModalNoEnviarComponent, {
        //         data: {
        //             documentoId: documento.id,
        //             motivo: documento.motivoNoEnvio,
        //             tipoDocumento: 'v',
        //         },
        //         panelClass: 'form-container',
        //     })
        //     .afterClosed()
        //     .pipe(first())
        //     .subscribe((actualizado) => this.cargarVentas());
        this.dialog
        .open(ModalMotivosNoFacturarComponent, {
            data: {
                proyectoId: documento.proyectoId,
                documentoId: documento.id,
                folio: documento.folio,
                tipo: 'V',
                cancelar: true,
                obtener: 'Y',
                guardar: false
            },
            panelClass: 'form-container',
        })
        .afterClosed()
        .pipe(first())
        .subscribe((actualizado) => this.cargarVentas());


    }

    filtrar(filterValue: string) {
        this.fuenteDatosVentas.filter = filterValue.trim().toLowerCase();
    }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaArticuloComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Articulo } from 'src/app/entidades/articulo';
import * as XLSX from 'xlsx';

@Component({
  selector: 'catalogo-articulo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoArticuloComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<Articulo> = new MatTableDataSource([]);
  columnasMostradas = ['clave', 'descripcion', 'activo', 'compra', 'venta', 'iva', 'retencion', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  subsArticulos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarArticulos();
  }

  cargarArticulos(): void {
    this.cargando = true;
    this.ctx.articulos
      .obtenerTodos()
      .subscribe(articulos => {
        this.fuenteDatos = new MatTableDataSource(articulos);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }

  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaArticuloComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarArticulos();
      });
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    }
    else {
      this.desactivar(id);
    }
  }

  cambiarEstadoStr(valor: string, id: number): void {
    if (valor == 'Y') {
      this.activar(id);
    }
    else {
      this.desactivar(id);
    }
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  activar(id: number): void {
    this.subsActivar = this.ctx
      .articulos.activar(id)
      .subscribe(() => { },
        error => {
          this.alerta.mostrarError('Error al activar');
        }
      );
  }

  desactivar(id: number): void {
    this.subsActivar = this.ctx
      .articulos.desactivar(id)
      .subscribe(() => { },
        error => {
          this.alerta.mostrarError('Error al desactivar');
        }
      );
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx
      .articulos.eliminar(id.toString())
      .subscribe(() => {
        this.alerta.mostrarError('Registro eliminado');
        this.cargarArticulos();
      },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }

  exportarAExcel(): void {
    const data = [...this.fuenteDatos.data];
    const workbook = XLSX.utils.book_new();
    const ws_name = 'Articulos';

    const ws_data = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, ws_data, ws_name);

    // Guardar el archivo Excel
    XLSX.writeFile(workbook, 'articulos_lista.xlsx');
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subsArticulos) { this.subsArticulos.unsubscribe(); }
    if (this.subsActivar) { this.subsActivar.unsubscribe(); }
    if (this.subsDesactivar) { this.subsDesactivar.unsubscribe(); }
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
  }
}
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ServicioAlerta {
  constructor(public snackBar: MatSnackBar) {}

  mostrarExito(message: string): void {
    this.snackBar.open('✔️ ' + message);
  }

  mostrarAdvertencia(message: string): void {
    this.snackBar.open('⚠️' + message);
  }

  mostrarError(message: string): void {
    this.snackBar.open('❌ ' + message);
  }
}

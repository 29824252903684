import { DetalleAlerta } from './detalle-alertas';

export class Alerta {
  id: number;
  clave: string;
  descripcion: string;
  tipo: number;
  documento: string;
  fechaRegistro: Date;
  activo: boolean;
  detallesAlerta: DetalleAlerta[];
}

import { HttpClient } from "@angular/common/http";
import { Operador } from "../entidades/operador"
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Pais } from '../entidades/pais';
import { Estado } from '../entidades/estado';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioEstado extends RepositorioCatalogo<Estado> {

    constructor(http: HttpClient) {
        super(http, "Estado");
    }

    obtenerEstadosPais(id:number): Observable<Estado[]> {
        let ruta = `${this.ruta}/ObtenerEstadosPais/${id}`;
        return this.clienteHttp.get(ruta )
            .pipe(map((data: Estado[]) => {
                return data;
            }));
    }
}



// asdas

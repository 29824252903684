import { HttpClient } from '@angular/common/http';
import { Tablas } from '../entidades/tablas';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioTablas extends RepositorioCatalogo<Tablas> {
  constructor(http: HttpClient) {
    super(http, 'Tablas');
  }

  obtenerTabla(id: number): Observable<Tablas[]> {
    const ruta = `${this.ruta}/ObtenerTabla/${id}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Tablas[]) => {
        return data;
      })
    );
  }

  sincronizarTabla(id: number): Observable<void> {
    const ruta = `${this.ruta}/SincronizarTabla/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }
}

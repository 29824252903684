import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Contexto } from 'src/app/api/contexto.service';
import {
    ControlArchivosComponent,
    Archivo,
} from 'src/app/utilerias/control-archivos/control-archivo.component';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ServicioCompra } from 'src/app/entidades/servicio-compra';
import { MatDialog } from '@angular/material';
import { VisorArchivoComponent } from 'src/app/utilerias/visor-archivo/visor-archivo.component';
import { ArchivoServicioCompra } from 'src/app/entidades/archivo-servicio-compra';
import { CatalogoServicio } from './clases';
import { Subscription } from 'rxjs';

@Component({
    selector: 'servicio-solicitud-cotizacion-compra',
    templateUrl: './servicio-solicitud-cotizacion-compra.component.html',
    styleUrls: ['./servicio-solicitud-cotizacion-compra.component.scss'],
})
@AutoUnsubscribe()
export class ServicioSolicitudCotizacionCompraComponent implements OnInit {
    @Input() forma: FormGroup;
    @Input() linea: number;
    @Input() modificando: boolean;
    @Input() esInternacional: boolean;
    @Input() servicio: ServicioCompra;
    @Input() catalogoServicio: CatalogoServicio;
    @ViewChild(ControlArchivosComponent)
    componentArchivos: ControlArchivosComponent;
    @Output() removerOrigenClic = new EventEmitter<number>();
    @Output() removerDestinoClic = new EventEmitter<number>();
    @Output() agregarOrigenClic = new EventEmitter();
    @Output() agregarDestinoClic = new EventEmitter();

    get formasOrigenes(): FormArray {
        return <FormArray>this.forma.get('origenes');
    }
    get formasDestinos(): FormArray {
        return <FormArray>this.forma.get('destinos');
    }
    get noOrigenes(): number {
        return this.formasOrigenes.controls.length;
    }
    get noDestinos(): number {
        return this.formasDestinos.controls.length;
    }
    get sePuedeAgregarOrigen(): boolean {
        return this.noDestinos < 2;
    }
    get sePuedeAgregarDestino(): boolean {
        return this.noOrigenes < 2;
    }
    get sePuedeEliminarOrigen(): boolean {
        return this.noOrigenes > 1;
    }
    get sePuedeEliminarDestino(): boolean {
        return this.noDestinos > 1;
    }
    get archivos() {
        return this.componentArchivos.archivosNuevos;
    }
    get archivosExistentes() {
        return this.componentArchivos.archivosExistentes;
    }

    subsArchivoServicio: Subscription;

    constructor(private ctx: Contexto, private ventanaArchivo: MatDialog) {}

    ngOnInit() {
        this.componentArchivos.archivosExistentes = (<ArchivoServicioCompra[]>(
            this.forma.get('archivos').value
        )).map((archivoServicio) => {
            const archivo: Archivo = {
                id: archivoServicio.id,
                extension: archivoServicio.extension,
                guia: archivoServicio.guia,
                nombre: archivoServicio.nombre,
                datos: null,
                archivoBase64: '',
            };
            return archivo;
        });
    }

    campoMostrar(direccion: Direccion) {
        let direccionCompleta = '';
        if (direccion) {
            direccionCompleta = `${direccion.calle},  ${direccion.numeroExterior}, `;
            if (direccion.numeroInterior) {
                direccionCompleta += `${direccion.numeroInterior}, `;
            }
            direccionCompleta += `${direccion.colonia}, ${direccion.codigoPostal}, ${direccion.ciudadDescripcion}, ${direccion.estadoDescripcion}, ${direccion.paisDescripcion}`;
        }
        return direccionCompleta;
    }

    verArchivoServicio(archivoSeleccionado: Archivo): void {
        this.subsArchivoServicio = this.ctx.compras
            .descargarArchivoServicio(archivoSeleccionado.id)
            .subscribe((blob) => {
                archivoSeleccionado.datos = blob;
                this.ventanaArchivo.open(VisorArchivoComponent, {
                    data: archivoSeleccionado,
                    panelClass: 'form-container',
                });
            });
    }

    limpiar(): void {
        this.forma.reset();
        this.limpiarArchivos();
    }

    limpiarArchivos(): void {
        this.componentArchivos.limpiar;
    }

    ngOnDestroy(): void {}
}

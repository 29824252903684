import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CatalogoSAT } from 'src/app/entidades/catalogo-sat';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { BuscadorCatalogoComponent } from '../buscador-catalogo/buscador-catalogo.component';

@Component({
  selector: 'app-registro-manual-mercancia',
  templateUrl: './registro-manual-mercancia.component.html',
  styleUrls: ['./registro-manual-mercancia.component.scss']
})
export class RegistroManualMercanciaComponent implements OnInit {
  forma: FormGroup;
  monedas: string[] = ['MXN', 'USD', 'CAN'];
  monedaControl = new FormControl();
  mercancias: FormGroup[] = [] as FormGroup[];

  get f() { return this.forma.controls; }

  @Output() newItemEvent = new EventEmitter<FormGroup>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public parametros: { idDocumento: number; tipo: 'normal' | 'confirmacion'; },
    public dialogRef: MatDialogRef<RegistroManualMercanciaComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private alerta: ServicioAlerta
  ) { }

  initForm() {
    this.forma = this.formBuilder.group({
      id: [0],
      claveProducto: [null, Validators.required],
      descripcionProducto: [null, Validators.required],
      claveUnidad: [null, Validators.required],
      descripcionUnidad: [null, Validators.required],
      claveFraccionArancelaria: [null, Validators.required],
      descripcionFraccionArancelaria: [null, Validators.required],
      claveMaterialPeligroso: [null, Validators.required],
      descripcionMaterialPeligroso: [null, Validators.required],
      claveEmbalaje: [null, Validators.required],
      descripcionEmbalaje: [null, Validators.required],
      cantidad: [null, Validators.required],
      valorUnitario: [null, Validators.required],
      moneda: [null, Validators.required],
      largo: [null, Validators.required],
      ancho: [null, Validators.required],
      alto: [null, Validators.required],
      peso: [null, Validators.required],
      pedimento: [null, Validators.required],
      uuid: [null, Validators.required],
      shipmentId: [0],
    });
  }

  ngOnInit() {
    this.initForm();
  }

  save() {
    if (this.forma.valid) {
      this.mercancias.push(this.forma);
      this.dialogRef.close(this.mercancias);
    } else {
      return;
    }
  }

  monedaSeleccionada(moneda: string) {
    this.f.moneda.setValue(moneda);
  }

  saveContinue() {
    if (this.forma.valid) {
      this.mercancias.push(this.forma);
      this.alerta.mostrarExito('Mercancia añadida');
      this.initForm();
    } else {
      return;
    }
  }

  openDialog(catalogo: string) {
    const dialogRef = this.dialog.open(BuscadorCatalogoComponent, {
      width: '1200px',
      panelClass: 'form-container',
      data: { catalogo: catalogo }
    });

    dialogRef.afterClosed().subscribe((row: CatalogoSAT) => {
      switch (row.discriminator) {
        case 'ClaveProdServCP':
          this.f.claveProducto.setValue(row.key);
          this.f.descripcionProducto.setValue(row.description);
          break;
        case 'ClaveUnidad':
          this.f.claveUnidad.setValue(row.key);
          this.f.descripcionUnidad.setValue(row.description);
          break;
        case 'FraccionArancelaria':
          this.f.claveFraccionArancelaria.setValue(row.key);
          this.f.descripcionFraccionArancelaria.setValue(row.description);
          break;
        case 'MaterialPeligroso':
          this.f.claveMaterialPeligroso.setValue(row.key);
          this.f.descripcionMaterialPeligroso.setValue(row.description);
          break;
        case 'TipoEmbalaje':
          this.f.claveEmbalaje.setValue(row.key);
          this.f.descripcionEmbalaje.setValue(row.description);
          break;
        default:
          break;
      }
    });
  }

  close() {
    this.dialogRef.close(this.mercancias);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encabezado'
})
export class EncabezadoPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    value = value.replace(/([A-Z])/g, ' de $1').toLowerCase();
    value = value.replace(/([_])/g, ' $1').toLowerCase();
    if (args === 'u') {
      value = value.toUpperCase();
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

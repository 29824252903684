import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter } from '@angular/core';
import { LayoutDirective } from '@angular/flex-layout';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Rastreo } from 'src/app/entidades/rastreo';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Ubicacion } from 'src/app/entidades/ubicacion';
import { interval, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
@Component({
  selector: 'panel-control',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  animations: [animacionTabla]
})
 
export class PanelControlComponent implements OnInit, OnDestroy {
  // public iconUrl = 'assets/images/truck.png';
  public iconUrl = { url: 'assets/images/truck.png', scaledSize: {height: 40, width: 40}};

  formaTablaRastreo: FormGroup;
  forma: FormGroup;

  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<Rastreo> = new MatTableDataSource([]);
  columnasMostradas = ['ver_detalle', 'operadorNombre', 'estadoRastreoDescripcion', 'servicioBol', 'opciones'];

  cargando = true;
  mostrarImg: boolean = false;
  seleccionado: boolean = false;
  todos: boolean = false;

  url: string;
  operador: string;
  info: string;
  lngLat: string;
  servDescripcion: string;

  ubis: Ubicacion[] = [];
  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  rowExapandido: Rastreo | null = null;

  get f() { return this.forma.controls; }

  lat: number = 28.6294893;
  lng: number = -106.0699771;
  icargarRastreos: number;
  iverOperadores: number;
  ifiltroOperador: number;
  audio = new Audio('assets/audio/alert.mp3');
  constructor(private ctx: Contexto,  private overlay: ServicioOverlayCargando, private formBuilder: FormBuilder) {
    this.url = "";
    this.mostrarImg = false;
    this.seleccionado = false;
    this.todos = false;

    this.info = "";
    this.operador = "";
    this.lngLat = "";
    this.servDescripcion = "";
  }

  ngOnInit() {
    this.formaTablaRastreo = this.formBuilder.group(
      {
        buscar: ['']
      }
    );

    this.forma = this.formBuilder.group(
      {
        rfc: [''],
        operador: [''],
        clave: [''],
        telefono: [''],
        correo: [''],
      }
    );
    this.cargarRastreos();
    this.verOperadores();


    this.icargarRastreos =  window.setInterval(() => this.cargarRastreos(), 20000);
    this.iverOperadores = window.setInterval(() => this.verOperadores(), 20000);

  }



  cargarRastreos(): void {
    this.cargando = true;
    this.ctx.rastreo
      .obtenerTodos()
      .subscribe(rastreos => {
        this.fuenteDatos = new MatTableDataSource(rastreos);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
        this.playAudio();
      });
  }

  limpiar(): void {
    this.formaTablaRastreo.reset();
    this.cargarRastreos();
  }

  limpiarOperador(): void {
    this.forma.reset();
    this.url = null;
    this.mostrarImg = false;
    this.seleccionado = false;
    this.todos = true;

    this.lat = 28.6294893;
    this.lng = -106.0699771;
    if(this.ifiltroOperador)
    {
      clearInterval(this.ifiltroOperador);
    }

    if(this.icargarRastreos)
    {
      clearInterval(this.icargarRastreos);
    }

    if(this.iverOperadores)
    {
      clearInterval(this.iverOperadores);
    }
    
    this.icargarRastreos =  window.setInterval(() => this.cargarRastreos(), 20000);
    this.iverOperadores = window.setInterval(() => this.verOperadores(), 20000);
   
  }


  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  async verOperador(id: number) {
    if(this.ifiltroOperador)
    {
      clearInterval(this.ifiltroOperador);
    }
    if(this.iverOperadores)
    {
      clearInterval(this.iverOperadores);
    }
    this.overlay.abrirCargando();
    this.operadorFiltro(id)
    this.ifiltroOperador = window.setInterval(() => this.operadorFiltro(id), 10000)
  }


  async operadorFiltro (id: number){
    this.seleccionado = true;
    this.todos = false;
   
    let OperadorId;
    let r = await this.ctx.rastreo.obtenerUltimasUbicacionesPorRastreo(id).toPromise();
    if (r != null) {
      this.overlay.cerrarCargando();
      this.f['rfc'].setValue(r.rfc);
      this.f['operador'].setValue(r.operador);
      this.f['correo'].setValue(r.correo);
      this.f['clave'].setValue(r.clave);
      this.f['telefono'].setValue(r.telefono);
    
      this.lat = parseFloat(r.latitud);
      this.lng = parseFloat(r.longitud);
      this.info = "Dirección: " + r.direccion;
      this.operador = "Operador: " + r.operador;
      this.lngLat = "Latitud:  " + r.latitud + "   Longitud:  " + r.longitud;
      this.servDescripcion = "Servicio: " + r.servicioDescripcion;

      OperadorId = r.operadorId;

     
      
      let a = await this.ctx.rastreo.obtenerFotoOperador(OperadorId).toPromise();
      if (a.size > 0) {
        this.url = window.URL.createObjectURL(a);
        this.mostrarImg = true;
      } else {
        this.mostrarImg = false;
      }
    }
  }




  verOperadores() {
    this.seleccionado = false;
    this.todos = true;
    this.ctx.rastreo.obtenerUltimasUbicaciones()
      .subscribe(e => {
        this.ubis = e;
      
      })
  }

  playAudio() {
    
    this.audio.play();
  }

  ngOnDestroy(): void {
    if(this.ifiltroOperador)
    {
      clearInterval(this.ifiltroOperador);
    }

    if(this.icargarRastreos)
    {
      clearInterval(this.icargarRastreos);
    }

    if(this.iverOperadores)
    {
      clearInterval(this.iverOperadores);
    }
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}

// jskdjkasd
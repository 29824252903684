export enum TiposUsuario {
  Empresa = 1,
  Proveedor,
  Cliente,
  Operador,
  Operaciones,
  ManagerVentas = 6,
  Reporteador = 7
}


// sads
import { Directive, TemplateRef, ViewContainerRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appRecarga]'
})
export class RecargaDirective implements OnChanges{

  @Input() appRecarga !: number;

  constructor(private templateRef: TemplateRef<any>,
    private viewContainterRef: ViewContainerRef) { this.viewContainterRef.createEmbeddedView(templateRef) }


  ngOnChanges(changes: SimpleChanges): void {

    if(changes['appRecarga']){
      this.viewContainterRef.clear();
      this.viewContainterRef.createEmbeddedView(this.templateRef);
    }
  }

}

import { DocumentoOperador } from './documento-operador';

export class Operador {
  id: number;
  clave: string;
  nombre: string;
  rfc: string;
  telefono: string;
  correo: string;
  fechaRegistro: string;
  activo: boolean;
  socioId: number;
  socioNombreComercial: string;
  documentos: DocumentoOperador[];
  contrasena: string;
  confirmacionContrasena: string;
  celular: string;
  esPropio: boolean;
}

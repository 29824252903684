import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Aduana } from '../entidades/aduana';
import { BitacoraCompraCT } from '../entidades/bitacoracompraCT';
import { Observable } from 'rxjs';

export class RepositorioBitacoraCT extends RepositorioCatalogo<Aduana> {
    constructor(http: HttpClient) {
        super(http, 'BitacoraCompraCT');
    }

    ObtenerBitacoraCompra(folioCT: string): Observable<BitacoraCompraCT[]> {
        const ruta = `${this.ruta}/ObtenerBitacoraCompra/${folioCT}`;
        return this.clienteHttp.get<BitacoraCompraCT[]>(ruta);
    }
}

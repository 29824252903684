import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfMake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ReporteLC } from 'src/app/entidades/reportes';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './lc.html',
    styleUrls: ['./lc.scss'],
})
export class LCPDF implements OnInit, OnDestroy {
    rutaArchivo = '';
    title = 'CONFIRMACIÓN DE CARGA';
    pdfMake: any;
    pdfFonts: any;
    index = '';
    pdfObj = null;

    reporte: ReporteLC[] = [];
    subReporte: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public idCotizacion: number,
        private ctx: Contexto,
        public ventanaDocumento: MatDialog
    ) {
        // called first time before the ngOnInit()
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    ngOnInit() {
        // this.cargarArchivo();
        this.cargarInformacion(this.idCotizacion);
    }

    cargarInformacion(id): void {
        this.subReporte = this.ctx.reportes.obtenerLC(id).subscribe((lc) => {
            this.reporte = lc;
            this.generar();
        });
    }

    async generar(): Promise<void> {
        let imagenBase64;

        const toDataURL = (url) =>
            fetch(url)
                .then((response) => response.blob())
                .then(
                    (blob) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        })
                );

        await toDataURL('/images/logo.jpg').then((dataUrl) => {
            imagenBase64 = dataUrl;
        });

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const rr = {
            header: {
                text: '\nCONFIRMACIÓN DE CARGA',
                alignment: 'center',
                bold: true,
                fontSize: 8,
                italics: true,
            },
            pageSize: 'LETTER',
            content: [],
            styles: {
                titulo: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'right',
                },
                subtitulo: {
                    fontSize: 8,
                    color: '#D0D0D0',
                    alignment: 'right',
                },
                tituloTabla: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center',
                    fillColor: '#9B9B9B',
                },
                subTituloTabla00: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center',
                },
                subTituloTabla01: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'right',
                },
                subTituloTabla02: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'left',
                },
                contenido00: {
                    fontSize: 9,
                    bold: false,
                    alignment: 'center',
                },
                contenido01: {
                    fontSize: 9,
                    bold: false,
                    alignment: 'right',
                },
                contenido02: {
                    fontSize: 9,
                    bold: false,
                    alignment: 'left',
                },
                contenido03: {
                    fontSize: 8,
                    bold: true,
                    alignment: 'center',
                },
                importante: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center',
                    fillColor: 'yellow',
                },
            },
        };
        const lineas = this.reporte.length;
        let i = 1;
        this.reporte.forEach((element) => {
            this.index = element.folioDocVenta;
            rr.content.push({
                table: {
                    widths: [200, '*'],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                image: imagenBase64,
                                width: 200,
                                opacity: 0.5,
                            },
                            {
                                border: [false, false, false, false],
                                stack: [
                                    'MOTION SI MÉXICO S.A. DE C.V.',
                                    {
                                        text: 'FPERIFERICO DE LA JUVENTUD  3106',
                                        style: 'subtitulo',
                                    },
                                    {
                                        text: 'HACIENDA SANTA FE, C.P. 31215',
                                        style: 'subtitulo',
                                    },
                                    {
                                        text: 'Chihuahua, Chihuahua, México',
                                        style: 'subtitulo',
                                    },
                                    {
                                        text: 'RFC: MSM141212SS5 ',
                                        style: 'subtitulo',
                                    },
                                ],
                                style: 'titulo',
                            },
                        ],
                    ],
                },
            });
            rr.content.push('\n');
            rr.content.push({
                table: {
                    widths: [4, 39, 119, 24, 36, 36, 36, 59, 89],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                fillColor: '#99190c',
                                text: '',
                                color: 'white',
                                fontSize: 7,
                                margion: [0, 1005, 0, 12],
                                rowSpan: 31,
                            },
                            {
                                border: [true, true, true, true],
                                text: element.fechaDocumento,
                                bold: true,
                                fontSize: 9,
                                alignment: 'center',
                                colSpan: 2,
                            },
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea fin
                        [
                            {},
                            {
                                border: [true, true, true, true],
                                text: 'PARA',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                            {
                                border: [true, true, true, true],
                                text: 'TRANSPORTISTA',
                                style: 'tituloTabla',
                                colSpan: 4,
                            },
                            {},
                            {},
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        [
                            {},
                            {
                                border: [true, true, true, true],
                                text: element.contacto,
                                style: 'contenido00',
                                margin: 5,
                                colSpan: 2,
                            },
                            {},
                            {
                                border: [true, true, true, true],
                                text: element.cliente,
                                style: 'contenido00',
                                margin: 5,
                                colSpan: 4,
                            },
                            {},
                            {},
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea fin
                        [
                            {},
                            {
                                border: [true, true, true, true],
                                text: 'Número de confirmación de carga',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                            {
                                border: [true, true, true, true],
                                text: element.folioDocVenta,
                                style: 'contenido00',
                                colSpan: 4,
                            },
                            {},
                            {},
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea fin
                        [
                            {},
                            {
                                border: [true, true, true, true],
                                text: 'Programado para su recolección',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                            {
                                border: [true, true, true, true],
                                text: element.fechaRecoleccion,
                                bold: true,
                                fontSize: 9,
                                alignment: 'center',
                                colSpan: 4,
                            },
                            {},
                            {},
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea fin
                        [
                            {},
                            {
                                text: 'UNIDAD / EQUIPO',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                            {
                                text: 'Dirección Origen',
                                style: 'tituloTabla',
                                colSpan: 4,
                            },
                            {},
                            {},
                            {},
                            {
                                text: 'Dirección Destino',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                        ],
                        [
                            {},
                            {
                                text: 'Equipo',
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.equipo,
                                style: 'contenido00',
                            },
                            {
                                text: element.origen,
                                style: 'contenido00',
                                colSpan: 4,
                                rowSpan: 4,
                            },
                            {},
                            {},
                            {},
                            {
                                text: element.destino,
                                style: 'contenido00',
                                rowSpan: 4,
                                colSpan: 2,
                            },
                        ],
                        [
                            {},
                            {
                                text: 'Camión',
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.placasCamion,
                                style: 'contenido00',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {},
                            {
                                text: element.trailer,
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.placasTrailer,
                                style: 'contenido00',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {},
                            {
                                text: 'Operador',
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.operador,
                                style: 'contenido00',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        // Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea fin
                        [
                            {},
                            {
                                text: 'Descripción',
                                colSpan: 2,
                                style: 'tituloTabla',
                            },
                            {},
                            {
                                text: 'Dimensiones y Pesos',
                                colSpan: 4,
                                style: 'tituloTabla',
                            },
                            {},
                            {},
                            {},
                            {
                                text: 'Tarifa total en: ' + element.moneda,
                                colSpan: 2,
                                style: 'tituloTabla',
                            },
                            {},
                        ],
                        [
                            {},
                            {
                                text: element.descripcion,
                                colSpan: 2,
                                rowSpan: 7,
                                style: 'contenido00',
                            },
                            {},

                            {
                                text: element.medida,
                                colSpan: 2,
                                style: 'contenido00',
                            },
                            {},
                            {
                                text: element.peso,
                                colSpan: 1,
                                style: 'contenido00',
                            },
                            {
                                text: element.unidadPeso,
                                fontSize: 8,
                                style: 'contenido00',
                            },
                            {
                                text: element.tipoServicio,
                                style: 'contenido03',
                            },
                            { text: element.importe, style: 'contenido01' },
                        ],
                        [
                            {},
                            {},
                            {},
                            {
                                text: 'Tipo Carga',
                                colSpan: 2,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {},
                            {
                                text: element.medida,
                                colSpan: 2,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {},
                            {
                                text:
                                    element.costosAdicionales !== '$0.00'
                                        ? 'Costos adicionales'
                                        : '\n',
                                fontSize: 8,
                                bold: true,
                                style: 'contenido00',
                            },
                            {
                                text:
                                    element.costosAdicionales !== '$0.00'
                                        ? element.costosAdicionales
                                        : '',
                                fontSize: 8,
                                bold: true,
                                style: 'contenido01',
                            },
                        ],
                        [
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {
                                text:
                                    element.descuento !== '$0.00'
                                        ? 'Descuento'
                                        : '\n',
                                fontSize: 8,
                                bold: true,
                                style: 'contenido00',
                            },
                            {
                                text:
                                    element.descuento !== '$0.00'
                                        ? element.descuento
                                        : '',
                                fontSize: 8,
                                bold: true,
                                style: 'contenido01',
                            },
                        ],
                        [
                            {},
                            {},
                            {},
                            { text: 'Largo', rowSpan: 2, style: 'contenido00' },
                            { text: 'Ancho', rowSpan: 2, style: 'contenido00' },
                            { text: 'Alto', rowSpan: 2, style: 'contenido00' },
                            {
                                text: element.unidadLongitud,
                                rowSpan: 4,
                                style: 'contenido00',
                            },
                            { text: 'SubTotal', style: 'contenido03' },
                            { text: element.subtotal, style: 'contenido01' },
                        ],
                        [
                            {},
                            {},
                            {},
                            { text: '', fontSize: 8, style: 'contenido00' },
                            { text: '', fontSize: 8, style: 'contenido00' },
                            { text: '', fontSize: 8, style: 'contenido00' },
                            {},
                            { text: 'I.V.A.', style: 'contenido03' },
                            { text: element.impuesto, style: 'contenido01' },
                        ],
                        [
                            {},
                            {},
                            {},
                            {
                                text: element.largo,
                                rowSpan: 2,
                                style: 'contenido00',
                                fontSize: 7,
                            },
                            {
                                text: element.ancho,
                                rowSpan: 2,
                                style: 'contenido00',
                                fontSize: 7,
                            },
                            {
                                text: element.alto,
                                rowSpan: 2,
                                style: 'contenido00',
                                fontSize: 7,
                            },
                            {},
                            { text: 'Retención', style: 'contenido03' },
                            { text: element.retencion, style: 'contenido01' },
                        ],
                        [
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            { text: 'TOTAL', style: 'contenido03' },
                            { text: element.total, style: 'contenido01' },
                        ],
                        [
                            {},
                            {
                                border: [false, false, false, false],
                                colSpan: 8,
                                text: '',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        // Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        // Salto de linea fin
                        [
                            {},
                            {
                                text: 'Referencias',
                                colSpan: 8,
                                style: 'tituloTabla',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {},
                            {
                                text: 'Pedimento No. ' + element.pedimento,
                                colSpan: 2,
                                rowSpan: 2,
                                style: 'subTituloTabla00',
                            },
                            {},
                            {
                                text: element.ordenCompra,
                                colSpan: 3,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {},
                            {},
                            {
                                text: element.numeroContenedor,
                                colSpan: 2,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {},
                            {
                                text: element.adicional,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                        ],
                        [{}, {}, {}, {}, {}, {}, {}, {}, {}],
                        [
                            {},
                            {
                                text: 'Index',
                                rowSpan: 2,
                                style: 'subTituloTabla00',
                            },
                            {
                                text: element.folioSV,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {
                                text: element.nombreCortoCliente,
                                colSpan: 6,
                                rowSpan: 2,
                                style: 'contenido00',
                                bold: true,
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [{}, {}, {}, {}, {}, {}, {}, {}, {}],
                        [
                            {},
                            {
                                text: 'Proyecto',
                                rowSpan: 1,
                                style: 'subTituloTabla00',
                            },
                            {
                                text: element.referencia,
                                rowSpan: 1,
                                colSpan: 7,
                                style: 'contenido02',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                    ],
                },
            });
            rr.content.push('\n');
            rr.content.push({
                table: {
                    widths: [325, 25, 150],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                text: element.nombreEmpleado, // 'Ivan Morales',
                                fontSize: 8,
                                bold: true,
                                alignment: 'center',
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, false],
                                text: '',
                                fontSize: 8,
                                alignment: 'center',
                            },
                        ],
                        [
                            {
                                border: [false, false, false, false],
                                text: 'Oficina Mex: +52 (614) 483-2156 ', // Móvil Mex: +52 1(614) 488-3662,
                                fontSize: 8,
                                alignment: 'center',
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, false],
                                text: '',
                                fontSize: 8,
                                alignment: 'center',
                            },
                        ],
                        [
                            {
                                border: [false, false, false, true],
                                text: '',
                                fontSize: 9,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, true],
                                text: '',
                                fontSize: 9,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                        ],
                        [
                            {
                                border: [false, false, false, false],
                                text:
                                    'Representantes de Motion SI MÉXICO, S.A. de C.V.',
                                fontSize: 6,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, false],
                                text: 'Carrier Reprensentative',
                                fontSize: 6,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                        ],
                    ],
                },
            });
            rr.content.push('\n\n');
            rr.content.push({
                text:
                    'Para Pago:\n' +
                    '*** CONFIRMACIÓN FIRMADA DEBE ENVIARSE A MOTION SI MÉXICO, S.A. DE C.V.\n' +
                    '*** LOS DATOS FISCALES QUE SE MUESTRAN EN ESTE DOCUMENTO DEBEN SER IGUAL A LOS QUE SE MUESTREN EN LA FACTURA. ',
                fontSize: 7,
                bold: true,
                color: 'black',
            });
            i++;
            if (i <= lineas) {
                rr.content.push({ text: '', pageBreak: 'after' });
            }
        });

        this.pdfObj = pdfMake.createPdf(rr);

        const archivo = pdfMake.createPdf(rr);
        archivo.getBlob((blod) => {
            const blob = new Blob([blod], { type: 'application/pdf' });
            this.rutaArchivo = window.URL.createObjectURL(blob);
        });
    }

    descargar(): void {
        if (this.pdfObj != null) {
            this.pdfObj.download('lc-' + this.index + '.pdf');
        }
    }

    ngOnDestroy(): void {}
}

import { Component, OnInit, Output, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Socio } from 'src/app/entidades/socio';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Contexto } from 'src/app/api/contexto.service';
import { map, startWith } from 'rxjs/operators';
import { FormaDireccionServicioComponent } from '../../direcciones-entregas/forma/forma.component';
import { FormaModoTransporteComponent } from '../../modo-transporte/forma/forma.component';
import { FormaAsignacionDireccionesServicioProveedoresComponent } from '../asignacion-direcciones-servicio-prov/forma/forma.component';
import { FormaAsignacionModoTransporteComponent } from '../asignacion-modo-transporte/forma/forma.component';
import { FormaAsignacionTipoProveedorComponent } from '../asignacion-tipo-proveedor/forma/forma.component';
import { FormaTipoProveedorComponent } from '../../tipo-proveedor/forma/forma.component';
import { FormaAsignacionTipoServicioComponent } from '../asignacion-tipo-servicio/forma/forma.component';
import { FormaTipoDeServicioComponent } from '../../tipo-servicio/forma/forma.component';
import { FormaTipoCamionComponent } from '../../tipo-camiones/forma/forma.component';
import { FormaAsignacionTipoCamionesComponent } from '../asignacion-tipo-camion/forma/forma.component';
import { FormaCamionComponent } from '../../camiones/forma/forma.component';
import { FormaAsignacionCamionesComponent } from '../asignacion-camion/forma/forma.component';
import { FormaAsignacionTrailersComponent } from '../asignacion-trailer/forma/forma.component';
import { FormaTrailersComponent } from '../../trailers/forma/forma.component';
import { FormaAsignacionTipoCargaComponent } from '../asignacion-tipo-carga/forma/forma.component';
import { FormaTiposCargaComponent } from '../../tipos-carga/forma/forma.component';
import { FormaEspecificacionCargaComponent } from '../../especificacion-carga/forma/forma.component';
import { FormaAsignacionEspecificacionCargaComponent } from '../asignacion-especificacion-carga/forma/forma.component';
import { FormaAsignacionAplicaProveedoMinaComponent } from '../asignacion-aplica-proveedor-mina/forma/forma.component';
import { FormaAplicaProveedoMinaComponent } from '../../aplica-proveedor-mina/forma/forma.component';
 

@Component({
  selector: 'app-caracteristicasPro',
  templateUrl: './caracteristicas-proveedor.component.html',
  styleUrls: ['./caracteristicas-proveedor.component.scss']
})
export class CaracteristicasProveedorComponent implements OnInit {
  @ViewChild(FormaAsignacionDireccionesServicioProveedoresComponent) Rutas: FormaAsignacionDireccionesServicioProveedoresComponent;
  @ViewChild(FormaAsignacionModoTransporteComponent) ModoTransporte: FormaAsignacionModoTransporteComponent;
  @ViewChild(FormaAsignacionTipoProveedorComponent) TipoProveedor: FormaAsignacionTipoProveedorComponent;
  @ViewChild(FormaAsignacionTipoServicioComponent) TipoServicio: FormaAsignacionTipoServicioComponent;
  @ViewChild(FormaAsignacionTipoCamionesComponent) TipoCamiones: FormaAsignacionTipoCamionesComponent;
  @ViewChild(FormaAsignacionCamionesComponent) Camiones: FormaAsignacionCamionesComponent;
  @ViewChild(FormaAsignacionTrailersComponent) Trailers: FormaAsignacionTrailersComponent;
  @ViewChild(FormaAsignacionTipoCargaComponent) TipoCarga: FormaAsignacionTipoCargaComponent;
  @ViewChild(FormaAsignacionEspecificacionCargaComponent) EspecificacionCarga: FormaAsignacionEspecificacionCargaComponent;
  @ViewChild(FormaAsignacionAplicaProveedoMinaComponent) AplicaProveedorMina: FormaAsignacionAplicaProveedoMinaComponent;





  proveedorId: number;
  actualiza: boolean;
  forma: FormGroup;
  get f() { return this.forma.controls; }
  proveedores: Socio[] = [];
  proveedoresFiltrados: Observable<Socio[]>;
  proveedoresSinFiltrar: Socio[];
  filtroProveedor = new FormControl();
  constructor(private ctx: Contexto, public ventana: MatDialog, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        proveedorId: [0, Validators.nullValidator],
        proveedorDescripcion: ['', Validators.required],
      },
    );
    this.cargarProveedores();
  }


  cargarProveedores() {
    this.ctx.socios.obtenerProveedores().toPromise().then(resultado => {
      this.proveedoresSinFiltrar = resultado;
      this.proveedoresFiltrados = this.filtroProveedor.valueChanges
        .pipe(
          startWith<string | Socio>(''),
          map(t => typeof t === 'string' ? t : t == null ? '' : t.nombreCorto),
          map(t => this.filtrarProveedor(t))

        );
    }).catch(e => {
       
    });
  }

  private filtrarProveedor(nombre: string): Socio[] {
    const valorFiltro = nombre.toLowerCase();
    let filtro = this.proveedoresSinFiltrar.filter(t => t.nombreCorto.toLowerCase().indexOf(valorFiltro) === 0);
    return filtro;
  }
 

  proveedorSeleccionado(proveedor: Socio) {
    this.f['proveedorDescripcion'].setValue(proveedor.nombreCorto);
    this.f['proveedorId'].setValue(proveedor.id);
    this.proveedorId = proveedor.id;
    this.ModoTransporte.cargarTabla(this.proveedorId);
    this.Rutas.cargarDireccionesOrigen(this.proveedorId);
    this.TipoProveedor.cargarTabla(this.proveedorId);
    this.TipoServicio.cargarTabla(this.proveedorId);
    this.TipoCamiones.cargarTabla(this.proveedorId);
    this.Camiones.cargarTabla(this.proveedorId);
    this.Trailers.cargarTabla(this.proveedorId);
    this.TipoCarga.cargarTabla(this.proveedorId);
    this.EspecificacionCarga.cargarTabla(this.proveedorId);
    this.AplicaProveedorMina.cargarTabla(this.proveedorId);
     

  }

  limpiarFiltro(): void{
    this.forma.reset();
    this.cargarProveedores();
    this.f['proveedorDescripcion'].setValue(null);
    this.f['proveedorId'].setValue(null);
  }


  abrirCatalogo(tipo: String): void {
    if (tipo === 'DestinosOrigenes') {
      this.ventana
        .open(FormaDireccionServicioComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {this.actualiza = true});
    }
    else if (tipo === 'ModosTransporte') {
      this.ventana
        .open(FormaModoTransporteComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.ModoTransporte.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'TipoProveedores') {
      this.ventana
        .open(FormaTipoProveedorComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.TipoProveedor.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'TipoServicio') {
      this.ventana
        .open(FormaTipoDeServicioComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.TipoServicio.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'TipoCamiones') {
      this.ventana
        .open(FormaTipoCamionComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.TipoCamiones.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'Camiones') {
      this.ventana
        .open(FormaCamionComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.Camiones.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'Trailers') {
      this.ventana
        .open(FormaTrailersComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.Trailers.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'TipoCarga') {
      this.ventana
        .open(FormaTiposCargaComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.TipoCarga.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'EspecificacionCarga') {
      this.ventana
        .open(FormaEspecificacionCargaComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.EspecificacionCarga.cargarTabla(this.proveedorId);
        });
    }
    else if (tipo === 'AplicaProveedorMina') {
      this.ventana
        .open(FormaAplicaProveedoMinaComponent, {
          data: {},
          panelClass: 'form-container'
        })
        .afterClosed().subscribe(() => {
          this.AplicaProveedorMina.cargarTabla(this.proveedorId);
        });
    }
  }



  


}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Contexto } from 'src/app/api/contexto.service';
import { Socio } from 'src/app/entidades/socio';
import { TiposUsuario } from 'src/app/entidades/tipos-usuario';
import { PerfilService } from 'src/app/inicio/perfil/perfil.service';

@Component({
  selector: 'app-modal-seleccion-cliente',
  templateUrl: './model-seleccion-cliente.component.html'
})
export class ModelSeleccionClienteComponent implements OnInit {
  @ViewChild(MatMenuTrigger) disparadorMenu: MatMenuTrigger;
  filtroSocio = new FormControl();
  subsSocios: Subscription;
  sociosFiltrados: Observable<Socio[]>;
  sociosSinFiltrar: Socio[];
  socioSeleccionado: Socio = null;

  get clienteSeleccionadoId(): number {
    return this.socioSeleccionado ? this.socioSeleccionado.id : 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private parametros: { clienteFuenteId: number },
    private contexto: Contexto,
    private servicioPerfil: PerfilService
  ) { }
  tipoUsuario: TiposUsuario;

  async ngOnInit() {
    this.cargarSocios();
  }

  cargarSocios() {
    this.subsSocios = this.servicioPerfil.socios$.subscribe((socios) => {
      this.sociosSinFiltrar = socios;
    });

    this.sociosFiltrados = this.filtroSocio.valueChanges.pipe(
      startWith<string | Socio>(''),
      map((socio) =>
        typeof socio === 'string'
          ? socio
          : socio == null
            ? ''
            : socio.nombreComercial
      ),
      map((socio) => this.filtrar(socio))
    );

    this.servicioPerfil.cargarSocios();
  }

  cambiaTipoSocio() {
    this.filtroSocio.reset();
  }

  abrirMenu() {
    if (
      this.tipoUsuario === TiposUsuario.Empresa ||
      this.tipoUsuario === TiposUsuario.Operaciones
    ) {
      this.disparadorMenu.openMenu();
    }
  }

  clienteSeleccionado(socio: Socio) {
    this.socioSeleccionado = socio;
  }

  campoMostrar(socio: Socio) {
    return socio ? socio.nombreComercial : '';
  }

  limpiar() {
    this.filtroSocio.reset();
  }

  private filtrar(nombre: string): Socio[] {
    const valorFiltro = nombre.toLowerCase();

    const socios = this.sociosSinFiltrar.filter(
      (socio) =>
        socio.id != this.parametros.clienteFuenteId &&
        (socio.nombreComercial.toLowerCase().includes(valorFiltro) ||
          (socio.codigoCliente || '')
            .toLowerCase()
            .includes(valorFiltro) ||
          (socio.codigoProveedor || '')
            .toLowerCase()
            .includes(valorFiltro))
    );

    return socios;
  }
}

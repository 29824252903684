import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReporteEvaluacionCliente } from '../entidades/reporte-evaluacion-cliente';
import { ReporteEvaluacionPE } from '../entidades/reporte-evaluacion-pe';

export class RepositorioEvaluacionesPE extends RepositorioCatalogo<
    ReporteEvaluacionPE
> {
    constructor(http: HttpClient) {
        super(http, 'EvaluacionesPE');
    }

    ObtenerReporte(
        proveedorId: number,
        anio: number,
        periodo: number
    ): Observable<ReporteEvaluacionPE[]> {
        const ruta =
            this.ruta + `/ObtenerReporte/${proveedorId}/${anio}/${periodo}`;
        return this.clienteHttp.get<ReporteEvaluacionPE[]>(ruta);
    }

    ObtenerReporteExcel(
        proveedorId: number,
        anio: number,
        periodo: number
    ): Observable<Blob> {
        const ruta =
            this.ruta +
            `/ObtenerReporteExcel/${proveedorId}/${anio}/${periodo}`;
        return this.clienteHttp.get(ruta, {
            responseType: 'blob',
        });
    }

    ObtenerAniosEvaluacion(): Observable<number[]> {
        const ruta = this.ruta + '/AniosEvaluacion';
        return this.clienteHttp.get<number[]>(ruta);
    }
}

import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from '@angular/material';
import { Factura } from 'src/app/entidades/factura';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ParametrosFacturaVentaService } from '../parametros.service';
import { FacturaVentaComponent } from '../facturas.component';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoFacturaVentaComponent implements OnInit {
  @ViewChild('paginatorSinEnviar') paginadorSinEnviar: MatPaginator;
  @ViewChild('paginatorEnviados') paginadorEnviados: MatPaginator;
  @ViewChildren(MatSort) ordenadores: QueryList<MatSort>;

  DatosSinEnviar: MatTableDataSource<Factura> = new MatTableDataSource([]);
  DatosEnviados: MatTableDataSource<Factura> = new MatTableDataSource([]);
  columnasMostradas = [
    'folioProyecto',
    'folioRe',
    'folioDocumento',
    'folioSv',
    'fechaRegistro',
    'socioNombreCorto',
    'total',
    'monedaAbreviatura',
    'procesado',
    'errorMensaje',
    'opciones'
  ];

  cargandoSinEnviar = false;
  cargandoEnviados = false;

  public get diametroSinEnviar(): number {
    if (!this.DatosSinEnviar || this.DatosSinEnviar.data.length === 0) {
      return 50;
    }
    return 100;
  }

  public get diametroProcesados(): number {
    if (!this.DatosEnviados || this.DatosEnviados.data.length === 0) {
      return 50;
    }
    return 100;
  }

  constructor(
    private ctx: Contexto,
    private ventana: MatDialog,
    private overlay: ServicioOverlayCargando,
    private parametros: ParametrosFacturaVentaService,
    private alerta: ServicioAlerta
  ) {}

  ngOnInit() {
    this.cargarFacturasSinEnviar();
    this.cargarFacturasEnviadas();
  }

  cargarFacturasSinEnviar(): void {
    this.cargandoSinEnviar = true;
    this.ctx.facturasVenta
      .obtenerSinProcesar()
      .pipe(first())
      .subscribe(facturas => {
        this.DatosSinEnviar = new MatTableDataSource(facturas);
        this.DatosSinEnviar.paginator = this.paginadorSinEnviar;
        this.DatosSinEnviar.sort = this.ordenadores.toArray[0];
        this.cargandoSinEnviar = false;
      });
  }

  cargarFacturasEnviadas(): void {
    this.cargandoEnviados = true;
    this.ctx.facturasVenta
      .obtenerProcesadas()
      .pipe(first())
      .subscribe(facturas => {
        this.DatosEnviados = new MatTableDataSource(facturas);
        this.DatosEnviados.paginator = this.paginadorEnviados;
        this.DatosEnviados.sort = this.ordenadores.toArray[2];
        this.cargandoEnviados = false;
      });
  }

  verFactura(idFactura: number): void {
    this.overlay.abrirCargando();
    this.parametros
      .cargarParametros(true, undefined, idFactura)
      .pipe(first())
      .subscribe(() => {
        this.abrirFactura();
        this.overlay.cerrarCargando();
      });
  }

  editarFactura(idFactura: number): void {
    this.overlay.abrirCargando();
    this.parametros
      .cargarParametros(false, undefined, idFactura)
      .pipe(first())
      .subscribe(() => {
        this.abrirFactura();
        this.overlay.cerrarCargando();
      });
  }

  abrirFactura(): void {
    this.ventana
      .open(FacturaVentaComponent, {
        panelClass: 'form-container-large'
      })
      .afterClosed()
      .pipe(first())
      .subscribe(() => this.cargarFacturasSinEnviar());
  }

  procesarFactura(idDocumento: number): void {
    this.cargandoSinEnviar = true;
    this.ctx.facturasVenta
      .procesarDocumento(idDocumento)
      .pipe(first())
      .subscribe(() => {
        this.cargarFacturasEnviadas();
        this.cargarFacturasSinEnviar();
        this.alerta.mostrarExito('Documento en proceso de envio');
        this.cargandoSinEnviar = false;
      });
  }

  filtrarSinEnviar(filterValue: string) {
    this.DatosSinEnviar.filter = filterValue.trim().toLowerCase();
  }

  filtrarEnviadas(filterValue: string) {
    this.DatosEnviados.filter = filterValue.trim().toLowerCase();
  }
}

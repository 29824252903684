import { CatalogoMotivoCierreComponent } from './paginas/motivos-cierre/catalogo/catalogo.component';
import { RouterModule, Route, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { GuardiaAcceso, GuardiaPerfil } from './autenticacion/guardias.service';
import { EnBlancoComponent } from './paginas/en-blanco/en-blanco.component';
import { DashboardComponent } from './paginas/dashboard/dashboard.component';
import { ParametrizacionComponent } from './paginas/parametrizacion/parametrizacion.component';
import { TiposUsuario } from './entidades/tipos-usuario';
import { CatalogoOperadorComponent } from './paginas/operadores/catalogo/catalogo.component';
import { CatalogoCamionComponent } from './paginas/camiones/catalogo/catalogo.component';
import { CatalogoTrailersComponent } from './paginas/trailers/catalogo/catalogo.component';
import { CatalogoTipoDocumentoComponent } from './paginas/tipos-documento/catalogo/catalogo.component';
import { CotizacionesCompraComponent } from './paginas/cotizaciones-compra/cotizaciones-compra.component';
import { CotizacionDatosEquipoComponent } from './paginas/datos-equipo/datos-equipo.component';
import { DocumentosFacturaPorEnviarComponent } from './paginas/documentos-por-enviar/catalogo/documentos-por-enviar.component';
import { CatalogoFacturaCompraComponent } from './paginas/facturas-compra/catalogo/catalogo.component';
import { CatalogoFacturaVentaComponent } from './paginas/facturas-venta/catalogo/catalogo.component';
import { CatalogoArticuloComponent } from './paginas/articulos/catalogo/catalogo.component';
import { CatalogoProyectosComponent } from './paginas/proyectos/catalogo/catalogo.component';
import { CatalogoPermisoComponent } from './paginas/permisos/catalogo/catalogo.component';
import { CatalogoEmpleadoComponent } from './paginas/empleados/catalogo/catalogo.component';
import { CatalogoSocioComponent } from './paginas/socios/catalogo/catalogo.component';
import { CatalogoUsuarioComponent } from './paginas/usuarios/catalogo/catalogo.component';
import { CatalogoMonedaComponent } from './paginas/monedas/catalogo/catalogo.component';
import { CatalogoTextoComponent } from './paginas/textos/catalogo/catalogo.component';
import { CatalogoUnidadesLongitudComponent } from './paginas/unidad-longitud/catalogo/catalogo.component';
import { CatalogoUnidadesPesoComponent } from './paginas/unidad-peso/catalogo/catalogo.component';
import { CatalogoTipoCargaComponent } from './paginas/tipos-carga/catalogo/catalogo.component';
import { CatalogoEstadosProyectosComponent } from './paginas/estados-proyectos/catalogo/catalogo.component';
import { CatalogoMedidaLegalComponent } from './paginas/medida-legal/catalogo/catalogo.component';
import { CatalogoImpuestosComponent } from './paginas/impuestos/catalogo/catalogo.component';
import { CatalogoAduanaComponent } from './paginas/aduanas/catalogo/catalogo.component';
import { CatalogoFronteraComponent } from './paginas/fronteras/catalogo/catalogo.component';
import { CatalogoTipoUnidadComponent } from './paginas/tipo-unidad/catalogo/catalogo.component';
import { CatalogoUnidadComponent } from './paginas/unidades/catalogo/catalogo.component';
import { CatalogoTipoCambioComponent } from './paginas/tipos-cambio/catalogo/catalogo.component';
import { CatalogoTipoTextoComponent } from './paginas/tipo-textos/catalogo/catalogo.component';
import { SincronizacionComponent } from './paginas/sincronizacion/sincronizacion.component';
import { ControlPanelComponent } from './paginas/panel-control/control-panel/control-panel.component';
import { CatalogoDireccionServicioComponent } from './paginas/direcciones-entregas/catalogo/catalogo.component';
import { CatalogoMotivoCancelacionComponent } from './paginas/motivos-cancelacion/catalogo/catalogo.component';
import { AsignacionDireccionesServicioComponent } from './paginas/AsignacionDireccionesServicio/catalogo/catalogo.component';
import { CatalogoComisionVentaComponent } from './paginas/comisiones-venta/catalogo/catalogo.component';
import { CatalogoCiudadesComponent } from './paginas/ciudades/catalogo/catalogo.component';
import { CaracteristicasProveedorComponent } from './paginas/asignacion-caracteristicas-proveedor/caracteristicas-proveedor/caracteristicas-proveedor.component';
import { CatalogoModoTransporteComponent } from './paginas/modo-transporte/catalogo/catalogo.component';
import { CatalogoTipoProveedorComponent } from './paginas/tipo-proveedor/catalogo/catalogo.component';
import { CatalogoTipoServicioComponent } from './paginas/tipo-servicio/catalogo/catalogo.component';
import { CatalogoTipoCamionComponent } from './paginas/tipo-camiones/catalogo/catalogo.component';
import { CatalogoEspecificacionCargaComponent } from './paginas/especificacion-carga/catalogo/catalogo.component';
import { CatalogoAplicaProveedorMinaComponent } from './paginas/aplica-proveedor-mina/catalogo/catalogo.component';
import { CatalogoLocalidadesSocioComponent } from './paginas/localidades-socio/catalogo/catalogo.component';
import { CatalogoProyectosCerradosComponent } from './paginas/proyectos/catalogo-proyectos-cerrados/catalogo-proyectos-cerrados';
import { CatalogoGeneradorReportesComponent } from './paginas/generador-reportes/catalogo/catalogo.component';
import { CatalogoTipoReporteComponent } from './paginas/tipo-reporte/catalogo/catalogo.component';
import { CatalogoFiltroReporteComponent } from './paginas/filtro-reporte/catalogo/catalogo.component';
import { FormaAsignacionFiltroReporteComponent } from './paginas/asignacion-filtro-reporte/forma/forma.component';
import { CatalogoPDFCotizacionGlobalComponent } from './paginas/proyectos/pdf-cotizacion-global/catalogo/catalogo.component';
import { CatalogoPDFCotizacionGlobalSVComponent } from './modulos/flujo-ventas/componentes/cotizacion-venta/pdf-cotizacion-global-sv/catalogo/catalogo.component';
import { CatalogoTiposServiciosComponent } from './paginas/tipos-servicios/catalogo/catalogo.component';
import { CatalogoServiciosComponent } from './paginas/servicios/catalogo/catalogo.component';
import { ServicioTipoTransporteClienteComponent } from './paginas/reporte-tipotransporte-cliente/catalogo.component';
import { CatalogoTiposMotivosComponent } from './paginas/tipos-motivos/catalogo/catalogo.component';
import { CatalogoTiposDocumentosComponent } from './paginas/tipos-documentos/catalogo/catalogo.component';
import { ReporteSeguimientoComponent } from './paginas/reporte-servicios-liberados/reporteseguimiento.component';
import { ReporteCostoKmComponent } from './paginas/reporte-costo-km/reporte-costo-km/reporte-costo-km.component';
import { CatalogoPenasquitoReportesComponent } from './paginas/penasquito/catalogo/catalogo.component';
import { CatalogoTipoEstatusClienteComponent } from './paginas/tipos-estatus-clientes/catalogo/catalogo.component';
import { ProveeduriaComponent } from './paginas/proveeduria/proveeduria.component';
import { CatalogoEstadosFacturacionComponent } from './paginas/estados-facturacion/catalogo/catalogo.component';
import { EstatusDiarioFriedrichComponent } from './paginas/penasquito/estatus-diario-friedrich/estatus-diario-friedrich.component';
import { CatalogoTipoOpcionComponent } from './paginas/tipo-opcion/catalogo/catalogo.component';

const rutas: Route[] = [
    {
        path: '',
        canActivate: [GuardiaAcceso],
        children: [
            {
                path: '_',
                component: EnBlancoComponent,
            },
            {
                path: '',
                component: DashboardComponent,
            },
            {
                path: 'AsigProveedores',
                component: CaracteristicasProveedorComponent,
            },
            {
                path: 'ComisionVenta',
                component: CatalogoComisionVentaComponent,
                data: { perfil: TiposUsuario.ManagerVentas },
            },
            {
                path: 'Proveeduria',
                component: ProveeduriaComponent,
            },
            {
                path: 'Parametrizacion',
                component: ParametrizacionComponent,
                canActivate: [GuardiaPerfil],
                data: { perfil: TiposUsuario.Empresa },
            },
            {
                path: 'Reportes',
                component: CatalogoGeneradorReportesComponent,
            },
            {
                path: 'Peñasquito',
                component: CatalogoPenasquitoReportesComponent,
            },
            {
                path: 'PeñasquitoEstatusDiarioFriedrich',
                component: EstatusDiarioFriedrichComponent,
            },
            {
                path: 'ReporteTipoServicioCliente',
                component: ServicioTipoTransporteClienteComponent,
            },
            {
                path: 'ReporteCostoKM',
                component: ReporteCostoKmComponent,
            },
            {
                path: 'ReporteServiciosLiberados',
                component: ReporteSeguimientoComponent,
            },
            {
                path: 'Encuestas',
                loadChildren:
                    './modulos/encuesta/encuesta.module#EncuestaModule',
                pathMatch: 'full',
            },
            {
                path: 'EvaluacionesPE',
                loadChildren:
                    './modulos/evaluacion-pe/evaluacion-pe.module#EvaluacionPeModule',
                pathMatch: 'full',
            },
            {
                path: 'ReporteRentabilidad',
                loadChildren:
                    './modulos/reporte-rentabilidad/reporte-rentabilidad.module#ReporteRentabilidadModule',
                pathMatch: 'full',
            },
            {
                path: 'ReporteUtilidadProyecto',
                loadChildren:
                    './modulos/reporte-comisiones/reporte-comisiones.module#ReporteComisionesModule',
                pathMatch: 'full',
            },
            {
                path: 'TipoReportes',
                component: CatalogoTipoReporteComponent,
            },
            {
                path: 'MotivosDocumentosNoEnviados',
                loadChildren:
                    './modulos/motivo-documentos-enviados/motivo-documentos-enviados.module#MotivoDocumentosEnviadosModule',
                pathMatch: 'full',
            },

            {
                path: 'Proveedores',
                canActivate: [GuardiaPerfil],
                data: { perfil: TiposUsuario.Proveedor },
                children: [
                    {
                        path: 'Operadores',
                        component: CatalogoOperadorComponent,
                    },
                    {
                        path: 'TiposDocumento',
                        component: CatalogoTipoDocumentoComponent,
                    },
                    {
                        path: 'CotizacionesCompra',
                        component: CotizacionesCompraComponent,
                    },
                    {
                        path: 'CotizacionCompra',
                        loadChildren:
                            './modulos/compra/cotizacion-compra.module#CotizacionCompraModule',
                        pathMatch: 'full',
                    },
                    {
                        path: 'DatosEquipos',
                        loadChildren:
                            './modulos/datos-equipo/datos-equipo.module#DatosEquipoModule',
                        pathMatch: 'full',
                    },
                    {
                        path: 'DatosEquipo',
                        component: CotizacionDatosEquipoComponent,
                    },
                ],
            },
            {
                path: 'Empresa',
                canActivate: [GuardiaPerfil],
                data: { perfil: TiposUsuario.Empresa },
                children: [
                    {
                        path: 'CotizacionVenta',
                        loadChildren:
                            './modulos/venta/venta.module#VentaModule',
                        pathMatch: 'full',
                    },
                    {
                        path: 'CotizacionCompra',
                        loadChildren:
                            './modulos/compra/cotizacion-compra.module#CotizacionCompraModule',
                        pathMatch: 'full',
                    },
                    {
                        path: 'FlujoVentas',
                        loadChildren:
                            './modulos/flujo-ventas/flujo-ventas.module#FlujoVentasModule',
                        pathMatch: 'full',
                    },
                    {
                        path: 'DatosEquipos',
                        loadChildren:
                            './modulos/datos-equipo/datos-equipo.module#DatosEquipoModule',
                        pathMatch: 'full',
                    },
                    
                    {
                        path: 'EnvioDocumentos',
                        component: DocumentosFacturaPorEnviarComponent,
                    },
                    {
                        path: 'FacturasCompra',
                        component: CatalogoFacturaCompraComponent,
                    },
                    {
                        path: 'FacturasVenta',
                        component: CatalogoFacturaVentaComponent,
                    },
                    {
                        path: 'Articulos',
                        component: CatalogoArticuloComponent,
                    },
                    {
                        path: 'Proyectos',
                        component: CatalogoProyectosComponent,
                        canActivate: [GuardiaPerfil],
                        data: { perfil: TiposUsuario.Cliente },
                    },
                    {
                        path: 'ProyectosCerrados',
                        component: CatalogoProyectosCerradosComponent,
                        canActivate: [GuardiaPerfil],
                        data: { perfil: TiposUsuario.Cliente },
                    },
                    {
                        path: 'SvCerradas',
                        loadChildren:
                            './modulos/sv-cerradas/sv-cerradas.module#SvCerradasModule',
                        pathMatch: 'full',
                    },
                    {
                        path: 'Roles',
                        component: CatalogoPermisoComponent,
                    },
                    {
                        path: 'Empleados',
                        component: CatalogoEmpleadoComponent,
                    },
                    {
                        path: 'Socios',
                        component: CatalogoSocioComponent,
                    },
                    {
                        path: 'Usuarios',
                        component: CatalogoUsuarioComponent,
                    },
                    {
                        path: 'Monedas',
                        component: CatalogoMonedaComponent,
                    },
                    {
                        path: 'TiposMotivos',
                        component: CatalogoTiposMotivosComponent,
                    },
                    {
                        path: 'TiposDocumentos',
                        component: CatalogoTiposDocumentosComponent,
                    },
                    {
                        path: 'Textos',
                        component: CatalogoTextoComponent,
                    },
                    {
                        path: 'Ciudades',
                        component: CatalogoCiudadesComponent,
                    },
                    {
                        path: 'UnidadLongitud',
                        component: CatalogoUnidadesLongitudComponent,
                    },
                    {
                        path: 'UnidadPeso',
                        component: CatalogoUnidadesPesoComponent,
                    },
                    {
                        path: 'TiposCarga',
                        component: CatalogoTipoCargaComponent,
                    },
                    {
                        path: 'EstadosProyecto',
                        component: CatalogoEstadosProyectosComponent,
                    },
                    {
                        path: 'Parametrizacion',
                        component: ParametrizacionComponent,
                    },
                    {
                        path: 'MedidasLegales',
                        component: CatalogoMedidaLegalComponent,
                    },
                    {
                        path: 'Impuestos',
                        component: CatalogoImpuestosComponent,
                    },
                    {
                        path: 'Aduanas',
                        component: CatalogoAduanaComponent,
                    },
                    {
                        path: 'Fronteras',
                        component: CatalogoFronteraComponent,
                    },
                    {
                        path: 'TiposUnidad',
                        component: CatalogoTipoUnidadComponent,
                    },
                    {
                        path: 'Unidades',
                        component: CatalogoUnidadComponent,
                    },
                    {
                        path: 'TiposCambio',
                        component: CatalogoTipoCambioComponent,
                    },
                    {
                        path: 'TiposEstatusClientes',
                        component: CatalogoTipoEstatusClienteComponent,
                    },
                    {
                        path: 'TipoOpcion',
                        component: CatalogoTipoOpcionComponent,
                    },
                    {
                        path: 'TipoTexto',
                        component: CatalogoTipoTextoComponent,
                    },
                    {
                        path: 'Sincronizacion',
                        component: SincronizacionComponent,
                    },
                    {
                        path: 'Panel',
                        component: ControlPanelComponent,
                    },
                    {
                        path: 'DireccionServicio',
                        component: CatalogoDireccionServicioComponent,
                    },
                    {
                        path: 'MotivosCancelacion',
                        component: CatalogoMotivoCancelacionComponent,
                    }, 
                    {
                        path: 'EstadosFacturacion',
                        component: CatalogoEstadosFacturacionComponent,
                    }, 
                    {
                        path: 'MotivosCierre',
                        component: CatalogoMotivoCierreComponent,
                    },
                    {
                        path: 'Camiones',
                        component: CatalogoCamionComponent,
                    },
                    {
                        path: 'Trailers',
                        component: CatalogoTrailersComponent,
                    },
                    {
                        path: 'ModoTransporte',
                        component: CatalogoModoTransporteComponent,
                    },
                    {
                        path: 'TipoProveedor',
                        component: CatalogoTipoProveedorComponent,
                    },
                    {
                        path: 'TipoServicio',
                        component: CatalogoTipoServicioComponent,
                    },
                    {
                        path: 'TipoCamion',
                        component: CatalogoTipoCamionComponent,
                    },
                    {
                        path: 'EspecificacionCarga',
                        component: CatalogoEspecificacionCargaComponent,
                    },
                    {
                        path: 'AplicaProveedorMina',
                        component: CatalogoAplicaProveedorMinaComponent,
                    },
                    {
                        path: 'LocalidadesSocio',
                        component: CatalogoLocalidadesSocioComponent,
                    },
                    {
                        path: 'FiltroReportes',
                        component: CatalogoFiltroReporteComponent,
                    },
                    {
                        path: 'AsignacionFiltroReportes',
                        component: FormaAsignacionFiltroReporteComponent,
                    },
                    {
                        path: 'PDFCotizacionGlobal/:proyectoId',
                        component: CatalogoPDFCotizacionGlobalComponent,
                    },
                    {
                        path: 'PDFCotizacionGlobalSV/:svId',
                        component: CatalogoPDFCotizacionGlobalSVComponent,
                    },
                    {
                        path: 'TiposServicio',
                        component: CatalogoTiposServiciosComponent,
                    },
                    {
                        path: 'Servicios',
                        component: CatalogoServiciosComponent,
                    },
                    {
                        path: 'Gastos',
                        loadChildren:
                            './modulos/catalogos/gasto-operacion/gasto-operacion.module#GastoOperacionModule',
                    },
                    {
                        path: 'CartaPorte',
                        loadChildren:
                            './modulos/carta-porte/carta-porte.module#CartaPorteModule',
                    },
                ],
            },

            {
                path: 'Clientes',
                canActivate: [GuardiaPerfil],
                data: { perfil: TiposUsuario.Cliente },
                children: [
                    {
                        path: 'AsignacionDestinos',
                        component: AsignacionDireccionesServicioComponent,
                    },
                ],
            },
        ],
    },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(rutas, {
    useHash: true,
});

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { Ubicacion } from 'src/app/entidades/ubicacion';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Evidencia } from 'src/app/entidades/evidencia';
import { VisorComponent } from '../../operadores/visor/visor.component';
import { DocumentoOperador } from 'src/app/entidades/documento-operador';
import { VisorEvidenciasComponent } from '../visor-evidencias/visor-evidencias.component';

@Component({
  selector: 'app-catalogo-evidencias',
  templateUrl: './catalogo-evidencias.component.html',
  styleUrls: ['./catalogo-evidencias.component.scss']
})
@AutoUnsubscribe()
export class CatalogoEvidenciasComponent implements OnInit {
 
  @Input() public set evidencias(valor: Evidencia[]) {
    this.asignacionEvidencias.next(valor);
  }

  public get evidencias(): Evidencia[] {
    return this.asignacionEvidencias.getValue();
  }

  asignacionEvidencias = new BehaviorSubject<Evidencia[]>([]);

  // fuenteDatos: MatTableDataSource<Ubicacion> = new MatTableDataSource([]);
  // columnasMostradas = [
  //   'fecha',
  //   // 'longitud',
  //   // 'latitud',
  //   'direccion'
  // ];

  // cargando = true;

  // public get diametro(): number {
  //   if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
  //     return 10;
  //   }
  //   return 10;
  // }



  constructor(
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    public overlay: ServicioOverlayCargando,
    public visorDocumentos: MatDialog, 
  ) { }

  ngOnInit() {
    // this.cargando = true;
    // this.asignacionUbicaciones.pipe(first()).subscribe(ubicacion => {
    //   this.fuenteDatos = new MatTableDataSource(ubicacion);
    //   this.fuenteDatos.paginator = this.paginador;
    //   this.fuenteDatos.sort = this.ordenador;
    //   this.cargando = false;
    // });
  }

  abrirVisorDocumentos(img: Evidencia): void {
    this.visorDocumentos.open(VisorEvidenciasComponent,
      { data: img, panelClass: 'form-container' });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ProyectoFactura } from 'src/app/entidades/proyecto-factura';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';

@Component({
    selector: 'app-documentos-por-enviar',
    templateUrl: './documentos-por-enviar.component.html',
    styleUrls: ['./documentos-por-enviar.component.scss'],
})
export class DocumentosFacturaPorEnviarComponent implements OnInit {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    proyectos: ProyectoFactura[] = [];
    proyectosFiltrados: ProyectoFactura[] = [];

    constructor(private ctx: Contexto, private spinner: LoadingService) {}

    ngOnInit() {
        this.spinner.show('Cargando datos');
        this.ctx.documentosFactura
            .obtenerPendientes()
            .toPromise()
            .then((proyectos) => {
                this.proyectos = proyectos;
                this.proyectosFiltrados = proyectos;
                this.spinner.hide();
            });
    }

    cargarCompras(proyectoId: number, indice: number): void {
        this.ctx.documentosCompra
            .obtenerSinProcesar(proyectoId)
            .toPromise()
            .then((documentos) => {
                this.proyectos[indice].compras = documentos;
            });
    }

    cargarVentas(proyectoId: number, indice: number): void {
        this.ctx.documentosVenta
            .obtenerSinProcesar(proyectoId)
            .toPromise()
            .then((documentos) => {
                this.proyectos[indice].ventas = documentos;
            });
    }

    filtrar(filtro: string) {
        filtro = filtro.toLowerCase();
        this.proyectosFiltrados = this.proyectos.filter(
            (p) =>
                p.proyectoFolio.toLowerCase().includes(filtro) ||
                p.clienteNombreCorto.toLowerCase().includes(filtro) ||
                p.proyectoDescripcion.toLowerCase().includes(filtro) ||
                p.fechaProyecto.toLowerCase().includes(filtro) ||
                (p.usuario
                    ? p.usuario.toLowerCase().includes(filtro)
                    : false) ||
                p.tipoProyecto.toLowerCase().includes(filtro)
        );
    }
}

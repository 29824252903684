import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CatalogoEmergenteService {
    private _datos: unknown;
    public get datos(): unknown {
        return this._datos;
    }
    public set datos(v: unknown) {
        this._datos = v;
    }

    constructor() {}
}

import { HttpClient } from "@angular/common/http";
import { Trailer } from "../entidades/trailer";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AsignacionTrailer } from '../entidades/asignacion-trailer';

export class RepositorioTrailer extends RepositorioCatalogo<Trailer> {

    constructor(http: HttpClient) {
        super(http, "Trailers");
    }

    activar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }


    obtenerTodosActivos(): Observable<Trailer[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<Trailer[]>(ruta);

      }


      asignar(asignacion: AsignacionTrailer): Observable<AsignacionTrailer> {
        const ruta = `${this.ruta}/Asignar`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
          map((asig: AsignacionTrailer) => asig)
        );
      }

      desasignar(asignacion: AsignacionTrailer): Observable<void> {
        const ruta = `${this.ruta}/Desasignar/${asignacion.trailerId}/${asignacion.clienteId}`;
        return this.clienteHttp.delete(ruta).pipe(map(() => null));
      }



      obtenerCamionCliente(IdCliente: number): Observable<Trailer[]> {
        const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
        return this.clienteHttp.get<Trailer[]>(ruta);
      }
}

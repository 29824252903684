import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { animations, fadeInOut } from '../utilerias/animaciones';
import { Global } from '../globales';
import { Autenticacion } from '../autenticacion/autenticacion.service';
import { Contexto } from '../api/contexto.service';
import { TiposUsuario } from '../entidades/tipos-usuario';
import {
    MatSidenav,
    MatExpansionPanel,
    MatMenuTrigger,
    MatDialog,
    MatDialogConfig,
} from '@angular/material';
import { AnimationRouterService } from '../utilerias/animation-router.service';
import { BuscadorCvComponent } from './buscador-cv/buscador-cv.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.scss'],
    animations: [animations, fadeInOut],
})
export class InicioComponent implements OnInit {
    isExpanded = false;
    panelOpenState = true;
    verLogo = false;
    @ViewChild('start') sidenav: MatSidenav;
    @ViewChild('panel') panel: MatExpansionPanel;
    
    public location: Location;
    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(map((result) => result.matches));

    constructor(
        private breakpointObserver: BreakpointObserver,
        public autenticacion: Autenticacion,
        public contexto: Contexto,
        public aniRouter: AnimationRouterService,
        public dialog: MatDialog,        
        private ctx: Contexto,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.autenticacion.verificalPerfil();
    }

    cambiarTema(oscuro: boolean) {
        Global.TemaOscuro = oscuro;
    }

    enlaceVisible(
        tipoRuta: 'proveedor' | 'cliente' | 'empresa' | 'operaciones'
    ): boolean {
        let tienePermiso = false;
        const tipoUsuario = this.autenticacion.credencial.idTipoUsuario;
        const perfil = this.autenticacion.perfil;
        if (perfil) {
            if (tipoRuta === 'proveedor') {
                tienePermiso = perfil.esProveedor;
            } else if (tipoRuta === 'cliente') {
                tienePermiso = perfil.esCliente;
            }
        }
        if (
            tipoUsuario === TiposUsuario.Empresa ||
            tipoUsuario === TiposUsuario.Operaciones
        ) {
            tienePermiso = true;
        }
        return tienePermiso;
    }

    enlaceVisibleManagerVentas(tipoRuta: 'managerventas'): boolean {
        let tienePermiso = false;
        const tipoUsuario = this.autenticacion.credencial.idTipoUsuario;

        if (tipoUsuario === TiposUsuario.ManagerVentas) {
            tienePermiso = true;
        }
        return tienePermiso;
    }

    enlaceVisibleReporteador(tipoRuta: 'reporteador'): boolean {
        let tienePermiso = false;
        const tipoUsuario = this.autenticacion.credencial.idTipoUsuario;

        if (
            tipoUsuario === TiposUsuario.Empresa ||
            tipoUsuario === TiposUsuario.Operaciones ||
            tipoUsuario === TiposUsuario.Reporteador
        ) {
            tienePermiso = true;
        }
        return tienePermiso;
    }

    buscarCV(noCV: number){        
        this.ctx.documentosVenta.buscarAnalisisVentasEspecificoPromise(noCV)
        .then(analisis => {            
            if (analisis.length > 0) {
                console.log(noCV);
                const dialogRef = this.dialog.open(BuscadorCvComponent, {
                    width: '80vw',
                    data: { noCV},
                  });
              
                  dialogRef.afterClosed().subscribe(result => {
                    // console.log('The dialog was closed');
                  });
            } else {
                Swal.fire({
                    title: 'CV no encontrado',
                    text: `CV ${String(noCV)} no encontrado`,
                    type: 'warning',
                    confirmButtonText: 'Aceptar'
                  });                
            }
        });
        
    }

    expandir(valor) {
        if (valor) {
            this.sidenav.open();
            this.panel.close();
            this.sidenav.close().then((a) => {
                this.sidenav.mode = 'side';
                this.isExpanded = false;
                this.sidenav.opened = true;
                this.verLogo = false;
            });
        } else {
            this.panel.close();

            this.sidenav.toggle();
            this.sidenav.open();
            this.sidenav.mode = 'side';
            this.isExpanded = true;
            this.verLogo = true;
        }
    }

    descargarAyuda() {
        return window.open('assets/images/Proceso_OnMonitor.pdf');
    }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { EstatusServicios } from 'src/app/entidades/estatus-servicios';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { FormaStatus } from '../../forma-status/forma.component';

@Component({
  selector: 'catalogo-estatus-servicios',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
  animations: [animacionTabla]
})
export class CatalogoEstatusServiciosComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<EstatusServicios> = new MatTableDataSource(
    []
  );
  columnasMostradas = [
    'operador',
    'folio',
    'proyecto',
    'servicio',
    'fechaRequerida',
    'horaRequerida',
    'fechaComprometida',
    'horaComprometida',
    'estatus',
    // 'opciones',
    'ver_detalle',
    // 'origenCalle',
    // 'origenNumExterior',
    // 'origenNumInterior',
    // 'origenColonia',
    // 'origenCodigoPostal',
    // 'destinoCalle',
    // 'destinoNumExterior',
    // 'destinoNumInterior',
    // 'destinoColonia',
    // 'destinoCodigoPostal',
    // 'trailer',
    // 'estatus',
  ];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  subsTiposCarga: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  rowExapandido: EstatusServicios | null;
  iEstatusServicios: number;
  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {}

  ngOnInit() {
    this.iEstatusServicios = window.setInterval(
      () => this.cargarEstatusServicios(),
      20000
    );
    this.cargarEstatusServicios();
  }

  cargarEstatusServicios(): void {
    this.cargando = true;
    this.ctx.rastreo.obtenerEstatusServicios().subscribe(estatus => {
      this.fuenteDatos = new MatTableDataSource(estatus);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  CambiarStatus(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaStatus,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
       
      });
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) {
      this.fuenteDatos.disconnect();
    }
    if (this.subsTiposCarga) {
      this.subsTiposCarga.unsubscribe();
    }
    if (this.subsActivar) {
      this.subsActivar.unsubscribe();
    }
    if (this.subsDesactivar) {
      this.subsDesactivar.unsubscribe();
    }
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
    if (this.iEstatusServicios) {
      clearInterval(this.iEstatusServicios);
    }
  }
}


// ajajdkas
import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Frontera } from 'src/app/entidades/frontera';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaFronteraComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  frontera: Frontera;
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaFronteraComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx
        .fronteras
        .obtener(id)
        .subscribe(
          a => {
            this.frontera = a;
            Object.assign(this.forma.value,this.frontera);
            this.forma.reset(this.forma.value);
            
          });
    }
    else {
      this.idSeleccionado = 0;
      this.frontera = new Frontera();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        clave: ['', Validators.required],
        descripcion: ['', Validators.required]
      }
    );
  }

  limpiar(): void {
    this.forma.reset();
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.frontera, this.forma.value);

      let observable: Observable<Frontera>;
      let mensaje = 'Frontera guardada.';
      let guardar = true;
      if (this.idSeleccionado == 0) {
        observable = this.ctx
          .fronteras
          .guardar(this.frontera);
      } else {
        guardar = false;
        mensaje = 'Frontera actualizada';
        observable = this.ctx
          .fronteras
          .actualizar(this.idSeleccionado.toString(), this.frontera);
      }

      this.subsGuardar = observable.subscribe(() => {
        this.guardado.emit();
        this.alerta.mostrarExito(mensaje);
        this.cargando = false;
        this.limpiar();
        if(!guardar){
          this.ventana.close();
        }
      },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        });
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

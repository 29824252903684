import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Documento } from "../entidades/documento";
import { Observable } from "rxjs";
import { PendienteSAP } from "../entidades/PendienteSAP";
export class RepositorioAntiguedadSaldos extends RepositorioCatalogo<Documento> {
    constructor(http: HttpClient) {
        super(http, 'AntiguedadSaldo');
    }
    ObtenerVentasComprasSinEnviar(): Observable<PendienteSAP> {
        const ruta = `${this.ruta}/ObtenerVentasSinEnviarSAP`;
        return this.clienteHttp.get<PendienteSAP>(ruta);
    }
    
    ObtenerOperacionResumenComprasSap(): Observable<PendienteSAP>{
        const ruta = `${this.ruta}/ObtenerOperacionResumenComprasSap`;
        return this.clienteHttp.get<PendienteSAP>(ruta);
    }
}


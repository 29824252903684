import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
    ChangeDetectorRef
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatTableDataSource,
    MatDialog,
    MatOption,
} from '@angular/material';
import {
    FormGroup,
    Validators,
    FormBuilder,
    AbstractControl,
    ValidatorFn,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { Socio } from 'src/app/entidades/socio';
import { Pais } from 'src/app/entidades/pais';
import { Estado } from 'src/app/entidades/estado';
import { Ciudad } from 'src/app/entidades/ciudad';
import { MapsAPILoader } from '@agm/core';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { FormaCiudadComponent } from 'src/app/paginas/ciudades/forma/forma.component';
import { UbicacionCV } from 'src/app/entidades/ubicacionCV';

declare var google: any;

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaUbicacionComponent implements OnInit, OnDestroy {
    asignarFecha = new Date();
    forma: FormGroup;
    direccion: UbicacionCV;
    idSeleccionado = 0;
    cargando = false;
    subsGuardar: Subscription;
    guardado: EventEmitter<void> = new EventEmitter<void>();
    idCliente: number;
    estadoSeleccionado: number;
    tieneDireccion = false;
    calleM = "";
    // coordenadas motion si
    accion = 1;
    lat;
    lng;
    geoCoder: any;

    get f() {
        return this.forma.controls;
    }
    clientes: MatTableDataSource<Socio> = new MatTableDataSource([]);
    selectedValue: string;

    paises: MatTableDataSource<Pais> = new MatTableDataSource([]);
    estados: MatTableDataSource<Estado> = new MatTableDataSource([]);
    ciudades: MatTableDataSource<Ciudad> = new MatTableDataSource([]);

    disableEstado: boolean;
    disableCiudad: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) 
        public id: number,
        private ventana: MatDialogRef<FormaUbicacionComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private autenticacion: Autenticacion,
        public ventanaDocumento: MatDialog,
        public ventanaCatalogo: MatDialog,
        private mapLoader: MapsAPILoader,
        private ref: ChangeDetectorRef,
        
    ) {
        if (id && id > 0) {
            this.accion = 2;
            this.idSeleccionado = id;
            this.direccion = new UbicacionCV();

            this.ctx.documentosVenta
                .tieneDireccion(this.idSeleccionado)
                .then(tieneDireccion => {
                    if (tieneDireccion) {
                        this.ctx.documentosVenta.ObtenerUbicacion(this.idSeleccionado)
                            .subscribe((infor) => {
                                Object.assign(this.forma.value, infor);
                                this.forma.reset(this.forma.value);
                            });
                    } else {
                        this.ctx.documentosVenta.ObtenerUbicacionDestino(this.idSeleccionado)
                        .subscribe((infor) => {
                            Object.assign(this.forma.value, infor);
                            this.forma.reset(this.forma.value);
                        });
                    }

                    this.onChanges();

                })
                .catch(error => {
                    // let httpError = <HttpErrorResponse>error;
                    // this.notificacion.mostrarError(httpError.error);
                });


        } else {
            this.accion = 1;
            this.idSeleccionado = this.id;
            this.disableEstado = true;
            this.disableCiudad = true;
            this.direccion = new UbicacionCV();
        }




        // this.idSeleccionado = id;
        // this.ctx.empleados.obtener(id).subscribe(a => {
        //     this.empleado = a;
        //     Object.assign(this.forma.value, this.empleado);
        //     this.forma.reset(this.forma.value);
        //     this.color = this.empleado.color;
        //     this.jefeId = this.empleado.jefeId;

    }

    ngOnInit() {
        this.mapLoader.load()
            .then((success) => {
                this.geoCoder = new google.maps.Geocoder();
            });
        // let perfil = this.autenticacion.perfil;
        // this.idCliente = perfil.id;
        this.forma = this.formBuilder.group({
            id: [0, Validators.nullValidator],
            calle: ['', Validators.required],
            fechaRequerida: [null, Validators.required],

        });
        // this.clientes.subscribe(socios=>{console.log(socios)});
        if (this.accion === 1) {
            this.onChanges();
        }
    }

    onChanges(): void {
        this.forma.get('calle').valueChanges.subscribe(val => {
            this.actualizarGeolocalizacion();
        });
    }


    limpiar(): void {
        this.forma.reset();
    }

    actualizarGeolocalizacion() {
        var address;
        //Object.assign(this.calleM, this.forma.value.calle);
        if (!this.idSeleccionado) {
            //let ciudad = this.ciudades.data.find(mCiudad => mCiudad.id === this.direccion.ciudadId);
            //let pais = this.estados.data.find(mEstado => mEstado.id === this.direccion.estadoId);
            //let estado = this.paises.data.find(mPais => mPais.id === this.direccion.paisId);
            address = `${this.forma.value.calle ? this.forma.value.calle : ''}`;
        } else {
            address = `${this.forma.value.calle ? this.forma.value.calle : ''}`;
        }
        this.geoCoder.geocode({ 'address': address }, (results, status) => {
            if (status === 'OK') {
                this.lat = results[0].geometry.location.lat();
                this.lng = results[0].geometry.location.lng();
            }
            this.ref.detectChanges();
        });
    }

    actualizarCoordenadas(event: any) {
        this.lat = event.coords.lat;
        this.lng = event.coords.lng;
        this.forma.controls["lat"].setValue(this.lat);
        this.forma.controls["lng"].setValue(this.lng);
    }

    actualizarLatLng() {
        this.forma.value.lat
        if (this.forma.value.lat && this.forma.value.lng) {
            this.lat = Number(this.forma.value.lat);
            this.lng = Number(this.forma.value.lng);
        }

    }



    guardar(): void {
        console.log('valid-->', this.forma.valid)
        if (this.forma.valid) {
            this.cargando = true;
            Object.assign(this.direccion, this.forma.value);
            let observable: Observable<UbicacionCV>;
            let mensaje = 'Dirección guardada.';
            let guardar = true;

            mensaje = 'Dirección guardada';
            observable = this.ctx.documentosVenta.guardarUbicacionCV(
                this.direccion
            );

            this.subsGuardar = observable.subscribe(
                () => {
                    this.guardado.emit();
                    this.alerta.mostrarExito(mensaje);
                    this.cargando = false;
                    this.limpiar();
                    if (guardar) {
                        this.ventana.close();
                    }
                },
                () => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                }
            );
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }


    cerrar(): void {
        this.ventana.close();
    }

    ValidarContacto(tipo: 'origen' | 'destino') {
        // return (control: AbstractControl) => {
        //   let resultado = { contactoInvalido: true };
        //   if (!this.forma) {
        //     resultado = null;
        //   }
        //   else if (tipo == 'origen') {
        //     let origenSeleccionado: boolean = this.forma.get('esOrigen').value;
        //     if (!origenSeleccionado || control.value) {
        //       resultado = null;
        //     }
        //   }
        //   else if (tipo == 'destino') {
        //     let destinoSeleccionado: boolean = this.forma.get('esDestino').value;
        //     if (!destinoSeleccionado || control.value) {
        //       resultado = null;
        //     }
        //   }
        //   return resultado;
        // };
        if (tipo === 'origen') {
            this.f['contactoOrigen'].setValidators;
            this.f['contactoDestino'].clearValidators;
        } else if (tipo === 'destino') {
            this.f['contactoOrigen'].clearValidators;
            this.f['contactoDestino'].setValidators;
        }
    }

    irA(): void {
        let url: string = this.forma.get('ubicacion').value;
        url =
            url.toUpperCase().includes('HTTP://') ||
                url.toUpperCase().includes('HTTPS://')
                ? url
                : 'http://' + url;
        window.open(url, '_blank');
    }

    abrirCatalogo(tipo: String): void {
        if (tipo === 'Ciudades') {
            this.ventanaCatalogo
                .open(FormaCiudadComponent, {
                    data: {},
                    panelClass: 'form-container',
                })
                .afterClosed()
                .subscribe(() => {
                    this.ctx.ciudad
                        .obtenerEstadosCiudad(this.estadoSeleccionado)
                        .subscribe((ciudades) => {
                            this.ciudades.data = ciudades;
                        });
                });
        }
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
    }
}

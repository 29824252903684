import { HttpClient } from '@angular/common/http';
import { Operador } from '../entidades/operador';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentoOperador } from '../entidades/documento-operador';

export class RepositorioOperador extends RepositorioCatalogo<Operador> {
  constructor(http: HttpClient) {
    super(http, 'Operadores');
  }

  obtenerTodoPorPorProveedor(idProveedor: number): Observable<Operador[]> {
    const ruta = `${this.ruta}/PorProveedor/${idProveedor}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Operador[]) => {
        return data;
      })
    );
  }

  obtenerPorProveedorConDocumentos(idProveedor: number): Observable<Operador[]> {
    const ruta = `${this.ruta}/ObtenerPorProveedorConDocumentos/${idProveedor}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Operador[]) => {
        return data;
      })
    );
  }

  obtenerPropios(): Observable<Operador[]> {
    const ruta = `${this.ruta}/Propios`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Operador[]) => {
        return data;
      })
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  obtenerDocumentos(idOperador: number): Observable<DocumentoOperador[]> {
    const ruta = `${this.ruta}/${idOperador}/Documentos`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: DocumentoOperador[]) => {
        return data;
      })
    );
  }

  obtenerDocumento(idDocumento: number): Observable<DocumentoOperador> {
    const ruta = `${this.ruta}/Documentos/${idDocumento}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: DocumentoOperador) => {
        return data;
      })
    );
  }

  guardarDocumentos(documentos: FormData): Observable<void> {
    const ruta = `${this.ruta}/Documentos`;
    return this.clienteHttp.post(ruta, documentos).pipe(
      map(() => {
        return null;
      })
    );
  }

  descargarDocumento(idDocumento: number): Observable<Blob> {
    const ruta = `${this.ruta}/Documentos/Descargar/${idDocumento}`;
    return this.clienteHttp.get(ruta, {
      responseType: 'blob'
    }).pipe(map((pdf: Blob) => pdf));
  }



  generarFolio(): Observable<string> {
    const ruta = `${this.ruta}/GenerarFolio`;
    return this.clienteHttp.get(ruta).pipe(
      map((folio: string) => {
        return folio;
      })
    );
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Contexto } from 'src/app/api/contexto.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UtilidadProyectos } from 'src/app/entidades/utilidad-proyecto';

@Component({
  selector: 'app-utilidad-proyecto',
  templateUrl: './utilidad-proyecto.component.html',
  styleUrls: ['./utilidad-proyecto.component.scss']
})
export class UtilidadProyectoComponent implements OnInit {
  forma: FormGroup;
  utilidad: UtilidadProyectos;
  folio: string;

  get f() {
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private modal: MatDialog,
    private ventana: MatDialogRef<UtilidadProyectoComponent>
  ) {
  }

  ngOnInit() {
    this.crearForma();
    this.ctx.proyectos.obtenerUtilidadProyectosCerrados(this.id).subscribe(a => {
      this.utilidad = a;
      this.folio = this.utilidad.folioProyecto;
      this.forma.setValue(this.utilidad);
    });
  }

   crearForma(): void{
    this.forma = this.formBuilder.group({
      folioProyecto: ['', Validators.nullValidator],
      precioCompra: ['', Validators.nullValidator],
      precioVenta: ['', Validators.nullValidator],
      utilidadBruta: ['', Validators.nullValidator],
      porcentajeUtilidad: ['', Validators.nullValidator],
    });
   }
}

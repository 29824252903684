export class TiposDocumentos {
  id: number;
  clave: string;
  descripcion: string;
  activo: boolean;
  archivo: string;
  comentario: string;
  docSeleccionado: boolean;
  remarcar: boolean;
  extension:string;
  tipoArchivo:string;
  nombreArchivo:string;
  fechaRegistro: Date;
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { FormaLocalidadesSocioComponent } from '../forma/forma.component';
import { EspecificacionCarga } from 'src/app/entidades/especificacion-carga';
import { LocalidesSocio } from 'src/app/entidades/localidades-socio';
import { first } from 'rxjs/operators';


@Component({
  selector: 'catalogo-localidades-socio',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoLocalidadesSocioComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<LocalidesSocio> = new MatTableDataSource([]);
  columnasMostradas = ['socioDescripcion', 'paisDescripcion', 'estadoDescripcion', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }


  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.tabla();
  }

  tabla(): void {
    this.ctx.localidadesSocio.obtenerTodos().toPromise().then(resultado => {
      this.fuenteDatos = new MatTableDataSource(resultado);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;

    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al cargar las localidades de los proveedores');
      });
  }

  abrirForma(id?: string): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaLocalidadesSocioComponent, { data: dato, panelClass: 'form-container' });
    forma.componentInstance.guardado.subscribe(() => { this.tabla(); });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }


  eliminar(id: number): void {
    this.ctx.localidadesSocio.eliminar(id.toString()).toPromise().then(() => {
      this.alerta.mostrarExito('Registro eliminado');
      this.tabla();
    })
      .catch(e => {
        console.log(e);
        this.alerta.mostrarError('Error al eliminar');
      });
  }

  limpiar() {
    this.fuenteDatos.filter = '';
  }
}
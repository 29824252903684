import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { EstatusVentas } from 'src/app/entidades/estatus-ventas';
import { first } from 'rxjs/operators';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { CierreDespachoOperativoDataComponent } from './cierre-despacho-operativo-data/cierre-despacho-operativo-data.component';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import * as _ from 'underscore';
import { CierreDespachoOperativoSelectionComponent } from './cierre-despacho-operativo-selection/cierre-despacho-operativo-selection-ad.component';
import { Objetivo } from 'src/app/entidades/objetivo';
import { filter } from 'lodash';
import { CierreDespachoOperativoFilterComponent } from './cierre-despacho-operativo-filter/cierre-despacho-operativo-filter.component';

@Component({
    selector: 'app-grafica-cierre-despacho-operativo',
    templateUrl: './cierre-despacho-operativo.component.html',
    styleUrls: ['./cierre-despacho-operativo.component.scss']
})
export class CierreDespachoOperativoComponent implements OnInit, OnChanges {
    @Input() year: number = (new Date()).getFullYear();
    @Output() public cierreDespachoOperativoEvent = new EventEmitter<RastreoClienteModel[]>();

    estatusVentas: EstatusVentas;
    rastreos: RastreoClienteModel[];
    rastreos2: RastreoClienteModel[];
    servicios: RastreoClienteModel[];
    objetivos: Objetivo[];
    diasTolerancia: number;
    rastreosLastYear: RastreoClienteModel[];
    rastreosLastYear2: RastreoClienteModel[];
    rastreosTotales: RastreoClienteModel[];
    result: any;
    despachoText: string = 'Cierre despacho operativo!'
    filtroValor: string = '';

    //    AnnoFiltro:number=2023;

    promedioAnterior: number;
    objetivo: number;

    promedioPrevio: [] = [];

    public barChartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        return `${value}%`;
                    }
                }
            }]
        },
        legend: {
            position: 'bottom',
            labels: {
                filter: function name(item, chart) {
                    return !item.text.includes('Servicios');
                }
            }
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                }
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return `${Number(tooltipItem.yLabel).toFixed(2)} %`;
                }
            }
        },
        onClick: (event, active) => this.chartClicked({ event, active })
    };

    public barChartLabels = [];
    public barChartData: ChartDataSets[] = [
        { data: [], label: `Año ${this.year - 1}`, type: 'bar', datalabels: { display: false }, hideInLegendAndTooltip: false, hidden: false },
        { data: [], label: `Año ${this.year}`, type: 'bar', datalabels: { display: false }, hideInLegendAndTooltip: false, hidden: false },
        { data: [], label: 'Objetivo', type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 0 }
        //{ data: [], label: `Prom ${this.year - 1}`, type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 0, hidden: true },
        //{ data: [], label: `Prom ${this.year}`, type: 'line', lineTension: 0, datalabels: { display: false }, fill: false, pointRadius: 0, hidden: true  }
    ];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];
    public barChartColors = [
        {
            backgroundColor: []
        }
    ];

    constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando, public autenticacion: Autenticacion, public ventana: MatDialog) {
        console.log("Recargando")
        this.cargarDatos();
        console.log(`Child Ctor: ${this.year}`);
        this.diasTolerancia = 5;
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`Child OnChages: ${this.year}`);
        if (this.servicios && this.objetivos) {
            this.actualizarDatos();
        }
    }

    ngOnInit() {
        const year = window.localStorage.getItem('añoConsulta');
        console.log(`Child OnInit: ${this.year}`);
    }

    public chartHovered({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    // events
    public chartClicked({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
        if (active.length > 0) {

            let yearElementClicked = null;
            active.find(item => {
                const selectChart = item['_chart'];
                const clickedElement = selectChart.getElementAtEvent(event)[0];
                if(clickedElement){
                    yearElementClicked = clickedElement['_model']['datasetLabel'];
                    return true;
                }
                return false;
            })

            const yearSelected = yearElementClicked.split(" ")[1];

            const chart = active[0]['_chart'];
            const columnName = chart.data.labels[chart.getElementAtEvent(event)[0]['_index']];
            const colNum = active[0]['_index'];
            //const rastreosTotales = this.servicios.filter(c => moment(c.fechaEntrega).format('YYYY') == yearSelected);
            if (this.year == yearSelected) {
                const rastreos = _.chain(this.rastreos).filter((c) => {
                    if (columnName.startsWith('W')) {
                        // Si columnName es una semana, comprobar si la fecha de entrega cae dentro de esa semana del año
                        const weekNumber = parseInt(columnName.slice(1));
                        return (
                            moment(c.fechaEntrega).week() === weekNumber &&
                            moment(c.fechaEntrega).year() ===
                                Number(yearSelected)
                        );
                    } else {
                        // Si columnName es un mes, comprobar si la fecha de entrega cae dentro de ese mes del año
                        const monthName = moment(c.fechaEntrega)
                            .locale('es-mx')
                            .format('MMMM');
                        return (
                            monthName === columnName &&
                            moment(c.fechaEntrega).year() ===
                                Number(yearSelected)
                        );
                    }
                });
                this.ventana.open(CierreDespachoOperativoSelectionComponent, {
                    data: {
                        rastreos: rastreos.value(),
                        columnName,
                        colNum,
                        diasTolerancia: this.diasTolerancia,
                        year: yearSelected,
                    },
                    panelClass: 'form-container',
                    width: '50vw',
                });
            } else {
                const rastreos = _.chain(this.rastreosLastYear).filter((c) => {
                    if (columnName.startsWith('W')) {
                        // Si columnName es una semana, comprobar si la fecha de entrega cae dentro de esa semana del año
                        const weekNumber = parseInt(columnName.slice(1));
                        return (
                            moment(c.fechaEntrega).week() === weekNumber &&
                            moment(c.fechaEntrega).year() ===
                                Number(yearSelected)
                        );
                    } else {
                        // Si columnName es un mes, comprobar si la fecha de entrega cae dentro de ese mes del año
                        const monthName = moment(c.fechaEntrega)
                            .locale('es-mx')
                            .format('MMMM');
                        return (
                            monthName === columnName &&
                            moment(c.fechaEntrega).year() ===
                                Number(yearSelected)
                        );
                    }
                });
                this.ventana.open(CierreDespachoOperativoSelectionComponent, {
                    data: {
                        rastreos: rastreos.value(),
                        columnName,
                        colNum,
                        diasTolerancia: this.diasTolerancia,
                        year: yearSelected,
                    },
                    panelClass: 'form-container',
                    width: '50vw',
                });
            }
        }
    }



    cargarDatos(): void {
        this.ctx.documentosVenta
            .obtenerCierreDespacho()
            .pipe(first())
            .subscribe((response) => {
                this.servicios = response.servicios;
                this.objetivos = response.objetivos;
                this.actualizarDatos();
            });
    }

    filterAndGroup(rastreos, currentMonth){
        return _.chain(rastreos)
        .filter(c => moment(c.fechaEntrega).format('MM') <= currentMonth)
        .groupBy(c => moment(c.fechaEntrega).format('MM'))
        .map((x, y) => {
            return {
                monthNumber: y,
                monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                data: x
            }
        })
        .sortBy(c => Number(c.monthNumber)).value();
    }

    filtrarServicios(servicios, year, cliente = null, proyecto = null, useNumber = false){
        return servicios.filter(c => {
            const yearCondition = useNumber ? Number(moment(c.fechaEntrega).format('YYYY')) === year : moment(c.fechaEntrega).format('YYYY') === String(year);
            const clienteCondition = cliente ? c.socioNegocio === cliente : true;
            const proyectoCondition = proyecto ? c.proyectoExternoClave === proyecto : true;
            return yearCondition && clienteCondition && proyectoCondition;
        });
    }

    actualizarDatos() {
        if (this.result) {
            const cliente = this.result.cliente;
            const proyecto = this.result.proyecto;
        
            this.rastreos = this.filtrarServicios(this.servicios, this.year, cliente, proyecto);
            this.rastreosLastYear = this.filtrarServicios(this.servicios, this.year - 1, cliente, proyecto);
            this.rastreos2 = this.filtrarServicios(this.servicios, this.year, cliente, proyecto, true);
            this.rastreosLastYear2 = this.filtrarServicios(this.servicios, this.year - 1, cliente, proyecto, true);

            if(cliente){
                if(proyecto){
                    this.filtroValor = `${this.rastreos[0].cliente} ${this.rastreos[0].descripcionSocioNegocio}`
                }else{
                    this.filtroValor = `${this.rastreos[0].cliente} TODOS`
                }
            }
        } else {
            this.filtroValor = '';
            this.rastreos = this.filtrarServicios(this.servicios, this.year);
            this.rastreosLastYear = this.filtrarServicios(this.servicios, this.year - 1);
            this.rastreos2 = this.filtrarServicios(this.servicios, this.year, null, null, true);
            this.rastreosLastYear2 = this.filtrarServicios(this.servicios, this.year - 1, null, null, true);
        }

        //Obtenemos el mes actual    
        let currentMonth;

        //Obtenemos los valores del anio actual y el anterior
        const currentYear = moment().format('YYYY');

        if(currentYear == String(this.year)){
            currentMonth = moment().format('MM');
        } else {
            currentMonth = moment().year(this.year).endOf('year').format('MM');
        }

        //Encadenamos los meses de nuestros rastreos del anio actual en un arreglo nuevo llamado months
        const months = this.filterAndGroup(this.rastreos, currentMonth);

        //Encadenamos los meses de nuestro rastreo del anio anterior y lo filtramos para que solo nos regrese hasta el mes actual del anio en curso
        const monthsLastYear = this.filterAndGroup(this.rastreosLastYear, currentMonth);

        //Encadenamos los meses anteriores de nuestro rastreo 2 del anio en curso
        const monthsAnt = this.filterAndGroup(this.rastreos2, currentMonth);

        //Encadenamos los meses anteriores de nuestros rastreos 2 del anio anterior
        const monthsAntLastYear = this.filterAndGroup(this.rastreosLastYear2, currentMonth);
        


        //Almacenamos los valores de nuestros meses    
        const monthValues = _.chain(months.map(c => c.data)).map(c => {
            return (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > 3).length / c.length) * 100;
        }).value();


        const monthValuesPrev = _.chain(monthsAnt.map(c => c.data)).map(c => {
            return (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > 3).length / c.length) * 100;
        }).value();

        //Almacenamos los valores de los meses del anio anterior
        const monthValuesLastYear = _.chain(monthsLastYear.map(c => c.data)).map(c => {
            return (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > 3).length / c.length) * 100;
        }).value();
        

        const monthValuesPrevLastYear = _.chain(monthsAntLastYear.map(c => c.data)).map(c => {
            return (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > 3).length / c.length) * 100;
        }).value();

        //Almacenamos las llaves de los meses de este anio y del anterior
        const monthKeys = months.map(c => c.monthName);
        const monthKeysLastYear = monthsLastYear.map(c => c.monthName);

        //Obtenemos el ultimo mes del anio en curso
        const lastMonth = _.chain(months).map(c => c.data).last();

        //Obtenemos el ultimo mes del anio anterior
        const lastMonthLastYear = _.chain(monthsLastYear).map(c => c.data).last();


        this.cierreDespachoOperativoEvent.emit(lastMonth.value());

        //Dividimos nuestro ultimo mes en semanas
        const lastMonthWeeksGroups = lastMonth
            .groupBy(c => moment(c.fechaEntrega).week())
            .map((x, y) => {
                return {
                    weekNumber: `${Number(y)}`,
                    data: x
                }
            })
        .sortBy(c => Number(c.weekNumber));

        //Dividimos el mismo mes pero del anio pasado en semanas
        const lastMonthWeeksGroupsLastYear = lastMonthLastYear
            .groupBy(c => moment(c.fechaEntrega).week())
            .map((x, y) => {
                return {
                    weekNumber: `${Number(y)}`,
                    data: x
                }
        })
        .sortBy(c => Number(c.weekNumber));


        //Obtenemos los valores de las semanas del mes actual.
        const lastMonthValues = lastMonthWeeksGroups
            .map(c => c.data)
            .map(c => (this.division(c.length, c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega) > 3).length) * 100)).value();
        

        //Obtenemos los valores de las semanas del mes actual pero del anio pasado.
        const lastMonthValuesLastYear = lastMonthWeeksGroupsLastYear
            .map(c => c.data)
            .map(c => (this.division(c.length, c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega) > 3).length) * 100)).value();


        //Obtenemos las llaves de las semanas de nuestro mes actual
        const lastMonthKeys = lastMonthWeeksGroups.map(c => `W${c.weekNumber}`).value();


        //Obtenemos las llaves de las semanas de nuestro mes actual pero del anio previo.
        const lastMonthKeysLastYear = lastMonthWeeksGroupsLastYear.map(c => `W${c.weekNumber}`).value();
        

        //Eliminamos la llave y valores de nuestros ultimo mes dentro de nuestro arreglo
        monthValues.pop();
        monthKeys.pop();

        monthValuesLastYear.pop();
        monthKeysLastYear.pop();
        
        const actYear = this.year;
        const actLabel = `Año ${actYear}`;
        const prevLabel = `Año ${actYear - 1}`;

        const prevPromLabel = `Año ${_.chain(this.barChartData).filter(c => c.label.includes('Año')).map(c => Number(c.label.substring(4))).sortBy(x => x).first().value()}`;
        const actPromLabel = `Año ${_.chain(this.barChartData).filter(c => c.label.includes('Año')).map(c => Number(c.label.substring(4))).sortBy(x => x).last().value()}`;

        this.barChartData.find(c => c.label == prevPromLabel).label = prevLabel;
        this.barChartData.find(c => c.label == actPromLabel).label = actLabel;
        

        //Obtenemos el promedio de los meses del anio actual
        const promAct = months
            .map(c => c.data)
            .map(c => {
                return (this.division(c.length, c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasTolerancia).length)) * 100;
            });

        //Obtenemos el promedio de los meses del anio anterior
        const promActLastYear = monthsLastYear
            .map(c => c.data)
            .map(c => {
                return (this.division(c.length, c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasTolerancia).length)) * 100;
        });

        const efe = _.chain(this.servicios
            // .filter(c => Number(moment(c.fechaEntrega).format('YYYY')) == Number(this.AnnoFiltro) - 1))
            .filter(c => Number(moment(c.fechaEntrega).format('YYYY')) == Number(`${this.year}`) - 1))
            .groupBy(c => Number(moment(c.fechaEntrega).format('MM')))
            .map(c => (c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasTolerancia)));

        const promPrevio = _.chain(this.servicios
            // .filter(c => Number(moment(c.fechaEntrega).format('YYYY')) == Number(this.AnnoFiltro) - 1))
            .filter(c => Number(moment(c.fechaEntrega).format('YYYY')) == Number(`${this.year}`) - 1))
            .groupBy(c => Number(moment(c.fechaEntrega).format('MM')))
            .map(c => (this.division(c.length, c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasTolerancia).length)) * 100);

        const promActual = _.chain(this.servicios
            // .filter(c => Number(moment(c.fechaEntrega).format('YYYY')) == Number(this.AnnoFiltro)))
            .filter(c => Number(moment(c.fechaEntrega).format('YYYY')) == Number(`${this.year}`)))
            .groupBy(c => Number(moment(c.fechaEntrega).format('MM')))
            .map(c => (this.division(c.length, c.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasTolerancia).length)) * 100);


        //Eliminamos el ultimo elemento de nuestros promedios    
        promAct.pop();
        promActLastYear.pop();


        const numeroColumnas = monthKeys.concat(lastMonthKeys).length;

        //this.barChartData.find(c => c.label == actLabel).data = Array(12).fill(_.reduce(promAct, (memo, v) => memo + v, 0) / promAct.length);

        if (this.objetivos.length == 0)
            this.objetivo = 0;
        else
            // this.objetivo = response.objetivos.find(c => c.year == this.AnnoFiltro).valor;
            this.objetivo = this.objetivos.find(c => c.year == Number(`${this.year}`)).valor;


        //Anadimos los arreglos de datos de nuestros anios con sus meses a nuestra grafica
        this.barChartData.find(c => c.label == actLabel).data = promAct.concat(lastMonthValues);
        this.barChartData.find(c => c.label == prevLabel).data = promActLastYear.concat(lastMonthValuesLastYear)
        //this.barChartData.find(c => c.label == prevLabel).data = Array(numeroColumnas).fill(this.promedioAnterior);

        /*
        this.barChartData.find(c => c.label == prevLabel).data = monthValuesPrev.map(c => Number(c));
        this.barChartData.find(c => c.label == actLabel).data = monthValues.map(c => Number(c));
        */

        this.barChartData.find(c => c.label == 'Objetivo').data = Array(numeroColumnas).fill(this.objetivo);


        this.barChartLabels = monthKeys.concat(lastMonthKeys);
        // this.barChartColors[0].backgroundColor = monthKeys.map(c => 'rgba(100, 109, 113, 1)').concat(lastMonthKeys.map(v => 'rgba(30, 120, 152, 1)'))

        //Mostramos los datos que ingresamos en los anios senalados, mostramos sus colores correspondientes y los valores de los meses.
        this.barChartData.find(c => c.label == actLabel).backgroundColor = monthKeys.map(c => 'rgba(60, 143, 254, 0.8)').concat(lastMonthKeys.map(v => 'rgba(60, 143, 254, 0.8)'));
        this.barChartData.find(c => c.label == actLabel).borderColor = monthKeys.map(c => 'rgba(60, 143, 254, 0.8)').concat(lastMonthKeys.map(v => 'rgba(60, 143, 254, 0.8)'));
        this.barChartData.find(c => c.label == prevLabel).backgroundColor = monthKeysLastYear.map(c => 'rgba(158, 158, 158, 0.8)').concat(lastMonthKeysLastYear.map(v => 'rgba(158, 158, 158, 0.8)'));        
        this.barChartData.find(c => c.label == prevLabel).borderColor = monthKeysLastYear.map(c => 'rgba(158, 158, 158, 0.8)').concat(lastMonthKeysLastYear.map(v => 'rgba(158, 158, 158, 0.8)'));
        this.barChartData.find(c => c.label == 'Objetivo').backgroundColor = '#00B050';
        this.barChartData.find(c => c.label == 'Objetivo').borderColor = '#00B050';
    }

    eliminarFiltros(){
        this.result = '';
        this.actualizarDatos();
        this.filtroValor = '';
    }

    

    division(a: number, b: number): number {
        if (a == 0) {
            return 0;
        }
        else {
            return b / a;
        }
    }

    setTolerancia(valor: number): void{
        console.log('cambioo dia')
        this.diasTolerancia = valor

        this.actualizarDatos();
    }

    abrirFormaFiltro(): void{
        const forma = this.ventana.open(CierreDespachoOperativoFilterComponent, {
            data: {rastreos: this.servicios.filter(c => moment(c.fechaEntrega).format('YYYY') == String(this.year)), rastreos2: this.servicios.filter(c => Number(moment(c.fechaEntrega).format('YYYY')) == this.year)}, panelClass: 'form-container'
        })

        forma.afterClosed().subscribe(
            resultado => {
                if(resultado){
                    if(resultado == 'delete'){
                        this.result = '';
                        this.actualizarDatos();
                    }else{
                        this.result = resultado;
                        this.actualizarDatos();
                    }
                }
            }
        )
    }

    abrirForma(): void {
        const forma = this.ventana.open(CierreDespachoOperativoDataComponent, {
            data: { rastreos: this.rastreos, rastreoss: this.rastreos2, objetivo: this.objetivo, yearF: this.year, diasTolerancia: this.diasTolerancia },
            panelClass: 'form-container',
            width: '50vw'
        });
    }
}
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort, MatDialog } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { filter } from 'lodash';
import { Partidas } from 'src/app/entidades/partidas';

@Component({
    selector: 'app-partidas-cerradas-cliente',
    templateUrl: './partidas-cerradas-cliente.component.html',
    styleUrls: ['./partidas-cerradas-cliente.component.scss']
})
export class PartidasCerradasclienteComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = [];
    rastreosNuevos: Partidas[];
    cli: string;
    filtroP = false;
    filtro: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: Partidas[], cliente: string, filtro: string },
        private ventana: MatDialog) {
    }

    ngOnInit(): void {

        this.filtro = this.object.filtro

        if(this.object.filtro == 'Perdida'){
            this.filtroP = true;
            this.filtro = 'perdidas'
            this.columnasMostradas = ['empleado', 'proyecto', 'sv', 'fechaCotizacion', 'motivoPerdida'];
        }else{
            this.columnasMostradas = ['empleado', 'proyecto', 'sv', 'fechaCotizacion'];
            this.filtro = 'ganadas'
        }

        this.rastreosNuevos = this.object.rastreos.filter(e => e.cliente == this.object.cliente);
        const filtroPerdidasGanadas = this.rastreosNuevos.filter(e => e.estatus == this.object.filtro);
        this.cli = this.object.cliente;

        const months = _.chain(filtroPerdidasGanadas)
            .groupBy(e => e.folio)
            .map((x, y) => {
                return {
                    monthNumber: y,
                    cli: x[0].empleado,
                    pro: x[0].proyecto,
                    s: x[0].sv,
                    fc: x[0].fechaCotizacion,
                    motivoP: x[0].motivoPerdida
                }
            })
            .sortBy(c => c.cli).value();


        const monthsModel = _.chain(months).map((month, key) => {

            return {
                'empleado': month.cli,
                'proyecto': month.pro,
                'sv': month.s,
                'fechaCotizacion': month.fc,
                'motivoPerdida': month.motivoP
            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;
    }

}
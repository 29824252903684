import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Contexto } from '../../../api/contexto.service';
import { ServicioAlerta } from '../../../utilerias/alerta.service';
import { Rol } from '../../../entidades/rol';
import { Usuario } from '../../../entidades/usuario';
import { startWith, map } from 'rxjs/operators';
import { Autenticacion } from '../../../autenticacion/autenticacion.service';
import { Socio } from 'src/app/entidades/socio';


@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss']
})
export class FormaUsuarioComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    usuario: Usuario;
    idSeleccionado: number = 0;
    cargando: boolean = false;
    subsGuardar: Subscription;

    guardado: EventEmitter<void> = new EventEmitter<void>();
    get f() { return this.forma.controls; }
    roles: MatTableDataSource<Rol> = new MatTableDataSource([]);

    filtroEmpresa = new FormControl();
    subsEmpresa: Subscription;
    empresasFiltrados: Observable<Socio[]>;
    empresasSinFiltrar: Socio[];
    empresaSeleccionado: Socio = null;
    validarCampo: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public id: number, private autenticacion: Autenticacion, private ventana: MatDialogRef<FormaUsuarioComponent>, private formBuilder: FormBuilder,
        private ctx: Contexto, private alerta: ServicioAlerta) {


        if (id > 0) {
            this.idSeleccionado = id;
            this.ctx
                .usuarios
                .obtenerUsuariosDetalleSocio(id)
                .subscribe(
                    a => {
                        this.usuario = a;
                        Object.assign(this.forma.value, this.usuario);
                        this.forma.reset(this.forma.value);
                    });
        }
        else {
            this.idSeleccionado = 0;
            this.usuario = new Usuario();

        }
        this.validarCampo = false;
    }


    ngOnInit() {
        this.forma = this.formBuilder.group(
            {
                socioId: [''],
                rfc: ['', Validators.required],
                contrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]],
                confirmacionContrasena: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/)]],
                email: ['', [Validators.required, Validators.email]],
            }
        );
    }

    validarCampoContrasena(control: AbstractControl) {
        let resultado = { contrasenaInvalida: true }
        if (this.idSeleccionado > 0) {
            resultado = null;
        } else {
            if (control.value) {
                resultado = null;
            }
        }
        return resultado;
    }

    limpiar(): void {
        this.forma.reset();
    }

    guardar(): void {
        if (this.autenticacion.perfil == undefined) {
            this.f['socioId'].setValue(1);
            this.usuario.tipoUsuarioId = 1
        }
        else {
            this.f['socioId'].setValue(this.autenticacion.perfil.id);
            if(this.autenticacion.perfil.esCliente)
            {
                this.usuario.tipoUsuarioId = 2;
            }else if(this.autenticacion.perfil.esProveedor){
                this.usuario.tipoUsuarioId = 3;
            }
        }
        if (this.forma.valid) {
            this.cargando = true;
            Object.assign(this.usuario, this.forma.value);


            if (this.usuario.contrasena != this.usuario.confirmacionContrasena) {
                this.alerta.mostrarError('¡Error, la contraseña no coincide!');
                this.cargando = false;
                return;
            }

            let observable: Observable<Usuario>;
            let mensaje = 'Usuario guardado.';
            let guardar = true;
            if (this.idSeleccionado == 0) {
                observable = this.ctx.usuarios.guardar(this.usuario);
            } else {
                guardar = false;
                mensaje = 'Usuario actualizado';
                observable = this.ctx.usuarios.actualizar(this.idSeleccionado.toString(), this.usuario);
            }

            this.subsGuardar = observable.subscribe(() => {
                this.guardado.emit();
                this.alerta.mostrarExito(mensaje);
                this.cargando = false;
                this.limpiar();
                if (!guardar) {
                    this.ventana.close();
                }
            },
                error => {
                    if (error.error == "YaExiste") {
                        console.log(error);
                        this.alerta.mostrarAdvertencia('¡Ya existe un registro con ese usuario, verifique nuevamente!');
                        this.cargando = false;
                        return;
                    } else if (error.error == "NoRfc") {
                        this.alerta.mostrarError('¡El RFC es incorrecto, verifique nuevamente!');
                        this.cargando = false;
                        return;
                    } else if (error.error == "NoPass") {
                        this.alerta.mostrarError('¡El password debe contener al menos un carácter especial ($%#@), una letra mayúscula y un número, evite usar puntos!');
                        this.cargando = false;
                        return;
                    }
                });
        }
        else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    cargarRoles(): void {
        this.ctx.roles.obtenerTodos()
            .subscribe(roles => {
                this.roles.data = roles;
            });
    }

    campoMostrar(empresa: Socio) {
        return empresa ? empresa.nombreComercial : '';
    }

    cerrar(): void {
        this.ventana.close();
    }

    verContrasena(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
    }

    verContrasenaConfirmacion(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
        if (this.subsEmpresa) { this.subsEmpresa.unsubscribe(); }

    }
}


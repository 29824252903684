
import {Component,OnInit,ViewChild,ElementRef} from '@angular/core';

import {MatPaginator,MatSort, MatTableDataSource, MatDialog} from '@angular/material';

import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Contexto } from 'src/app/api/contexto.service';
import { ReporteSeguimientos } from 'src/app/entidades/reporte-seguimientos';
import { Empleados } from 'src/app/entidades/empleados';
import { Clientes } from 'src/app/entidades/clientes';
import { FiltroReporteSeguimientoLiberado } from 'src/app/entidades/filtro-reporte-seguimientos-liberados';
import { RastreoCliente } from 'src/app/entidades/rastreo-cliente';
import { ContextoPortalOnMonitor } from 'src/app/api/portal-OnMonitor/contexto-portal-onmonitor.service';
import { FiltroReporte } from 'src/app/entidades/filtro-reporte';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';


@Component({
  selector: 'selector-name',
  templateUrl: 'reporteseguimiento.component.html',
  styleUrls:['reporteseguimiento.component.scss']
})

export class ReporteSeguimientoComponent implements OnInit {
  constructor(private ctx:Contexto, private formBuilder:FormBuilder,public ventana: MatDialog, private ctxOnMonitor: ContextoPortalOnMonitor, private cargando: LoadingService) { }

  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  @ViewChild('filtro') filtro: ElementRef;

  fuenteDatos: MatTableDataSource<ReporteSeguimientos> = new MatTableDataSource([]);

  columnasMostradas = ['folioProyecto','descripcionProyecto','cvdescripcion','tiposervicio','cliente','proveedor',
  "folioCV","fechaRequerida","fechaCompromiso","tipoTransporte","origen","destino",
  "fechaTermino","horaTermino",'empleado'];

  //filtros empleados
  empleadoFiltrados: Observable<Empleados[]>;
  empleadoSinFiltrar: Empleados[];
  empleadofiltro = new FormControl();

  //filtros empleados
  clienteFiltrados: Observable<Clientes[]>;
  clienteSinFiltrar: Clientes[];
  clientefiltro = new FormControl();

  //filtros empleados
  proveedoresFiltrados: Observable<string[]>;
  proveedoresSinFiltrar: string[];
  proveedoresfiltro = new FormControl();

  //filtros empleados
  transporteFiltrados: Observable<string[]>;
  transporteSinFiltrar: string[];
  transportefiltro = new FormControl();

  //forma
  forma:FormGroup;
  get f(){return this.forma.controls}


  formaCliente:FormGroup;
  get fCliente(){return this.formaCliente.controls}

  ngOnInit() {
    this.forma=this.formBuilder.group({
      nombreEmpleado:['Todos'],
      nombreComercial:['Todos'],
      nombreProveedor:['Todos'],
      nombreTransporte:['Todos'],
      fechaCompromiso:[],
      fechaTermino:[],
      idEmpleado:[0],
      idCliente:[0],
    });

    this.formaCliente=this.formBuilder.group({
      nombreEmpleado:['Todos'],
      nombreComercial:['Todos'],
      nombreCliente:['Todos'],
      nombreTransporte:['Todos'],
      fechaCompromiso:[],
      fechaTermino:[],
      idEmpleado:[0],
      idCliente:[0],
    });

    this.cargando.show("");
    this.cargarProveedor();
    this.cargarEmpleado();
    this.cargarCliente();
    this.cargarTransporte();
    this.tabla();
    this.cargando.hide();

  }

  tabla(){
    this.ctxOnMonitor.ReporteSeguimientos.ServiciosEntregados().toPromise().then(datos=>{
      this.fuenteDatos=new MatTableDataSource(datos);
      this.fuenteDatos.paginator=this.paginador;
      this.fuenteDatos.sort=this.ordenador;
    }).catch(ex=>{
      console.log(ex)
    })
  }
  limpiarFiltro(){
    this.filtro.nativeElement.value = "";
    this.fuenteDatos.filter = '';
  }

  abrirVentana(row:RastreoCliente){
    // let forma = this.ventana.open(TablaReporteComponent, { data: row, panelClass: 'form-container' });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  descargarExcel(): void {

    let fechaActual = new Date();
    let nombreArchivo = "Seguimientos entregados";
    const modelCliente=this.formaCliente.value as FiltroReporteSeguimientoLiberado;
    const modelProveedor=this.forma.value as FiltroReporteSeguimientoLiberado;
    if(this.fCliente["nombreComercial"].value!=""){
      this.ctxOnMonitor.ReporteSeguimientos.obtenerExcel(modelCliente).toPromise().then(respuesta => {
        this.llamarExcel(respuesta, nombreArchivo)
      }).catch(e => {
      });
    }else{

      this.ctxOnMonitor.ReporteSeguimientos.obtenerExcel(modelProveedor).toPromise().then(respuesta => {
        this.llamarExcel(respuesta, nombreArchivo)
      }).catch(e => {
      });
    }

  }


  llamarExcel(respuesta, nombreArchivo) {
    let blob = new Blob([respuesta], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
      url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  //empleados filtro
  cargarEmpleado() {
    this.ctxOnMonitor.ReporteSeguimientos.obtenerEmpleador()
      .toPromise()
      .then((resultado) => {
        this.empleadoSinFiltrar = resultado;
        this.empleadoFiltrados = this.empleadofiltro.valueChanges.pipe(
          startWith<string | Empleados>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.nombre
          ),
          map((t) => this.filtrarEmpleado(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  limpiarFiltroEmpleado(): void {
    this.f['nombreEmpleado'].setValue('Todos');
    this.f['idEmpleado'].setValue(0);
    this.fCliente['nombreEmpleado'].setValue('Todos');
    this.fCliente['idEmpleado'].setValue(0);
    this.cargarEmpleado();
  }

  EmpleadoSeleccionado(modelo: Empleados) {
    this.f['nombreEmpleado'].setValue(modelo.nombre);
    this.f["idEmpleado"].setValue(modelo.id);

    this.fCliente['nombreEmpleado'].setValue(modelo.nombre);
    this.fCliente["idEmpleado"].setValue(modelo.id);
  }

  private filtrarEmpleado(modelo: string): Empleados[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.empleadoSinFiltrar.filter(
      (t) => t.nombre.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  //Cliente filtro
  cargarCliente() {
    this.ctxOnMonitor.ReporteSeguimientos.obtenerClientes()
      .toPromise()
      .then((resultado) => {
        this.clienteSinFiltrar = resultado;
        this.clienteFiltrados = this.clientefiltro.valueChanges.pipe(
          startWith<string | Clientes>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.nombreCorto
          ),
          map((t) => this.filtrarCliente(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  limpiarFiltroCliente(): void {
    this.f['nombreComercial'].setValue('Todos');
    this.f['idCliente'].setValue(0);
    this.cargarEmpleado();
  }

  ClienteSeleccionado(modelo: Clientes) {
    this.fCliente['nombreComercial'].setValue(modelo.nombreCorto);
    this.fCliente["idCliente"].setValue(modelo.id);
  }

  private filtrarCliente(modelo: string): Clientes[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.clienteSinFiltrar.filter(
      (t) => t.nombreCorto.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  //proveedores filtro
  cargarProveedor() {
    this.ctxOnMonitor.ReporteSeguimientos.obtenerProveedores()
      .toPromise()
      .then((resultado) => {
        this.proveedoresSinFiltrar = resultado;
        this.proveedoresFiltrados = this.proveedoresfiltro.valueChanges.pipe(
          startWith<string | string>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t
          ),
          map((t) => this.filtrarProvedor(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  limpiarFiltroProveedor(): void {
    this.f['nombreProveedor'].setValue('Todos');
    this.cargarProveedor();
  }

  ProveedorSeleccionado(modelo: string) {
    this.f['nombreProveedor'].setValue(modelo);
  }

  private filtrarProvedor(modelo: string): string[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.proveedoresSinFiltrar.filter(
      (t) => t != null? t.toLowerCase().indexOf(valorFiltro) === 0: ""
    );
    return filtro;
  }

  //transporte filtro
  cargarTransporte() {
    this.ctxOnMonitor.ReporteSeguimientos.tipoTransporte()
      .toPromise()
      .then((resultado) => {
        this.transporteSinFiltrar = resultado;
        this.transporteFiltrados = this.transportefiltro.valueChanges.pipe(
          startWith<string | string>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t
          ),
          map((t) => this.filtrarTransporte(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  limpiarFiltroTransporte(): void {
    this.f['nombreTransporte'].setValue('Todos');
    this.fCliente['nombreTransporte'].setValue('Todos');
    this.cargarTransporte();
  }

  transporteSeleccionado(modelo: string) {
    this.f['nombreTransporte'].setValue(modelo);
    this.fCliente['nombreTransporte'].setValue(modelo);

  }

  private filtrarTransporte(modelo: string): string[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.transporteSinFiltrar.filter(
      (t) => t.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  buscarFiltroProveedor(){
    const modelo = this.forma.value as FiltroReporteSeguimientoLiberado;
    modelo.idCliente=0;
    this.fCliente["nombreComercial"].setValue("");
    this.ctxOnMonitor.ReporteSeguimientos.filtroProveedores(modelo).toPromise().then(e=>{
      this.fuenteDatos=new MatTableDataSource(e);
      this.fuenteDatos.paginator=this.paginador;
      this.fuenteDatos.sort=this.ordenador;
    }).catch(ex=>{

    });
  }

  buscarFiltroCliente(){
    this.cargando.show("Generando reporte");
    const modelo = this.formaCliente.value as FiltroReporteSeguimientoLiberado;
    if(this.f["fechaCompromiso"].value==""){
      modelo.fechaCompromiso=null;
    }else if(this.f["fechaTermino"].value==""){
      modelo.fechaTermino=null;
    }
    modelo.nombreProveedor="";
    this.f["nombreProveedor"].setValue("");
    this.ctxOnMonitor.ReporteSeguimientos.filtroProveedores(modelo).toPromise().then(e=>{
      this.fuenteDatos=new MatTableDataSource(e);
      this.fuenteDatos.paginator=this.paginador;
      this.fuenteDatos.sort=this.ordenador;
      this.cargando.hide();
    }).catch(ex=>{
      this.cargando.hide();

    });

  }

  EliminarFechaCompromiso(){
    if(this.f["fechaCompromiso"].value !=""||this.fCliente["fechaCompromiso"].value!="")
    {
      this.f["fechaCompromiso"].setValue("");
      this.fCliente["fechaCompromiso"].setValue("");
    }
  }

  EliminarFechaTermino(){
    if(this.f["fechaTermino"].value !=""||this.fCliente["fechaTermino"].value!="")
    {
      this.f["fechaTermino"].setValue("");
      this.fCliente["fechaTermino"].setValue("");
    }
  }
  limpiarFormulario(){
    this.limpiarFiltroCliente();
    this.limpiarFiltroProveedor();
    this.limpiarFiltroTransporte();
    this.limpiarFiltroEmpleado();
    this.EliminarFechaCompromiso();
    this.EliminarFechaCompromiso();
  }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { ModoTransporte } from '../entidades/modo-transporte';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoServicio } from '../entidades/tipo-servicio';
import { AsignacionTipoServicio } from '../entidades/asignacion-tipo-servicio';

export class RepositorioTipoServicio extends RepositorioCatalogo<TipoServicio> {
    constructor(http: HttpClient) {
        super(http, 'TipoServicio');
    }

    activar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put<void>(ruta, null);
    }

    desactivar(id: number): Observable<void> {
        const ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put<void>(ruta, null);
    }

    obtenerTodosActivos(): Observable<TipoServicio[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<TipoServicio[]>(ruta);
    }

    asignar(
        asignacion: AsignacionTipoServicio
    ): Observable<AsignacionTipoServicio> {
        const ruta = `${this.ruta}/Asignar`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionTipoServicio) => asig)
        );
    }

    desasignar(asignacion: AsignacionTipoServicio): Observable<void> {
        const ruta = `${this.ruta}/Desasignar/${asignacion.tipoServicioId}/${asignacion.clienteId}`;
        return this.clienteHttp.delete(ruta).pipe(map(() => null));
    }

    obtenerTipoServicioCliente(IdCliente: number): Observable<TipoServicio[]> {
        const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
        return this.clienteHttp.get<TipoServicio[]>(ruta);
    }
}

import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { Documento } from "../entidades/documento";
import { Respuesta } from "../entidades/respuesta";

export class RepositorioBitacoraEnvioOCReferi extends RepositorioCatalogo<Documento> {

    constructor(http: HttpClient) {
        super(http, "BitacoraOCReferi");
    }

    alta(documento: Documento): Observable<Respuesta<Documento>> {
      const ruta = `${this.ruta}/Alta`;
      return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }

    cambio(documento: Documento): Observable<Respuesta<Documento>> {
      const ruta = `${this.ruta}/Cambio`;
      return this.clienteHttp.post<Respuesta<Documento>>(ruta, documento);
    }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { ModoTransporte } from '../entidades/modo-transporte';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoProveedor } from '../entidades/tipo-proveedor';
import { AsignacionTipoProveedor } from '../entidades/asignacion-tipo-proveedot';

export class RepositorioTipoProveedor extends RepositorioCatalogo<TipoProveedor> {
  constructor(http: HttpClient) {
    super(http, 'TipoProveedor');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);

  }


  obtenerTodosActivos(): Observable<ModoTransporte[]> {
    const ruta = `${this.ruta}/ObtenerTodosActivos`;
    return this.clienteHttp.get<ModoTransporte[]>(ruta);

  }


  asignar(asignacion: AsignacionTipoProveedor): Observable<AsignacionTipoProveedor> {
    const ruta = `${this.ruta}/Asignar`;
    return this.clienteHttp.post(ruta, asignacion).pipe(
      map((asig: AsignacionTipoProveedor) => asig)
    );
  }

  desasignar(asignacion: AsignacionTipoProveedor): Observable<void> {
    const ruta = `${this.ruta}/Desasignar/${asignacion.tipoProveedorId}/${asignacion.clienteId}`;
    return this.clienteHttp.delete(ruta).pipe(map(() => null));
  }



  obtenerTipoProveedorCliente(IdCliente: number): Observable<TipoProveedor[]> {
    const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
    return this.clienteHttp.get<TipoProveedor[]>(ruta);
  }
}

import { Injectable } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ValidadorFormas {
    marcarComoTocado(forma: FormGroup) {
        Object.keys(forma.controls).forEach((field) => {
            const control = forma.get(field);
            if (control instanceof FormArray) {
                for (const control1 of control.controls) {
                    if (control1 instanceof FormControl) {
                        control1.markAsTouched({
                            onlySelf: true,
                        });
                    }
                    if (control1 instanceof FormGroup) {
                        this.marcarComoTocado(control1);
                    }
                }
            }
            if (control instanceof FormControl) {
                control.markAsTouched({
                    onlySelf: true,
                });
            } else if (control instanceof FormGroup) {
                this.marcarComoTocado(control);
            }
        });
    }

    limpiarForma(forma: FormGroup) {
        Object.keys(forma.controls).forEach((field) => {
            const control = forma.get(field);
            if (control instanceof FormArray) {
                for (const control1 of control.controls) {
                    if (control1 instanceof FormControl) {
                        control1.reset();
                    }
                    if (control1 instanceof FormGroup) {
                        this.limpiarForma(control1);
                    }
                }
            }
            if (control instanceof FormControl) {
                control.reset();
            } else if (control instanceof FormGroup) {
                this.limpiarForma(control);
            }
        });
    }
}

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsignacionEmbarque } from 'src/app/entidades/asignacion-embarque';
import { CatalogoSAT } from 'src/app/entidades/catalogo-sat';
import { DireccionFolio } from 'src/app/entidades/direccion-folio';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { SolicitudEmbarque } from 'src/app/entidades/solicitud-embarque';
import { RepositorioCatalogo } from '../repositorio-catalogo';

export class RepositorioSolicitudesEmbarques extends RepositorioCatalogo<SolicitudEmbarque> {

    constructor(http: HttpClient) {
        super(http, 'SolicitudEmbarque');
    }

    obtenerSolicitudesEmbarque(cliente: number): Observable<SolicitudEmbarque[]> {
        const ruta = `${this.ruta}/ObtenerSolicitudesEmbarque/${cliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((solicitudesEmbarques: SolicitudEmbarque[]) => {
                return solicitudesEmbarques;
            })
        );
    }

    asignarSolicitudEmbarque(model: AsignacionEmbarque): Observable<AsignacionEmbarque> {
        const ruta = `${this.ruta}/AsignarSolicitudEmbarque`;
        return this.clienteHttp.post(ruta, model).pipe(
            map((asig: AsignacionEmbarque) => asig)
        );
    }

    obtenerFolio(cliente: number): Observable<string> {
        const ruta = `${this.ruta}/ObtenerFolio/${cliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((nuevoFolio: string) => {
                return nuevoFolio;
            })
        );
    }

    obtenerFolioCv(idDocumento: number): Observable<string> {
        const ruta = `${this.ruta}/ObtenerFolioCv/${idDocumento}`;
        return this.clienteHttp.get(ruta).pipe(
            map((cvsValidas: string) => {
                return cvsValidas;
            })
        );
    }

    obtenerDireccionesOrigen(cliente: number): Observable<Direccion[]> {
        const ruta = `${this.ruta}/ObtenerDireccionesOrigen/${cliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((direcciones: Direccion[]) => {
                return direcciones;
            })
        );
    }

    obtenerDireccionesDestino(cliente: number): Observable<Direccion[]> {
        const ruta = `${this.ruta}/ObtenerDireccionesDestino/${cliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((direcciones: Direccion[]) => {
                return direcciones;
            })
        );
    }

    obtenerDireccionesPorFolio(cvid: string): Observable<DireccionFolio[]> {
        const ruta = `${this.ruta}/ObtenerDireccionesPorFolio/${cvid}`;
        return this.clienteHttp.get(ruta).pipe(
            map((direcciones: DireccionFolio[]) => {
                return direcciones;
            })
        );
    }

    guardarSolicitudEmbarque(model: SolicitudEmbarque): Observable<SolicitudEmbarque> {
        const ruta = `${this.ruta}/GuardarSolicitudEmbarque`;
        return this.clienteHttp.post(ruta, model).pipe(
            map((embarque: SolicitudEmbarque) => embarque)
        );
    }

    validarCatalogosSat(llave: string, catalog: string): Observable<string> {
        const ruta = `${this.ruta}/ValidarCatalogo/${catalog}/${llave}`;
        return this.clienteHttp.get(ruta).pipe(
            map((resultado: string) => {
                return resultado;
            })
        );
    }

    obtenerCatalogosSat(catalogo: string): Observable<CatalogoSAT[]> {
        const ruta = `${this.ruta}/ObtenerCatalogoSAT/${catalogo}`;
        return this.clienteHttp.get(ruta).pipe(
            map((catalogos: CatalogoSAT[]) => {
                return catalogos;
            })
        );
    }

    obtenerSolicitudEmbarque(idDocumento: number, cliente: number): Observable<SolicitudEmbarque> {
        const ruta = `${this.ruta}/ObtenerSolicitudEmbarque/${idDocumento}/${cliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((catalogos: SolicitudEmbarque) => {
                return catalogos;
            })
        );
    }

    tieneSolicitudEmbarque(idDocumento: number, cliente: number): Observable<boolean> {
        const ruta = `${this.ruta}/TieneSolicitudEmbarque/${idDocumento}/${cliente}`;
        return this.clienteHttp.get(ruta).pipe(
            map((tieneSolicitudEmbarque: boolean) => {
                return tieneSolicitudEmbarque;
            })
        );
    }
}

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfMake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Archivo } from 'src/app/utilerias/control-archivos/control-archivo.component';
import { Contexto } from 'src/app/api/contexto.service';
import { ReporteBOL } from 'src/app/entidades/reportes';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Subscriber, Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './bol.html',
    styleUrls: ['./bol.scss'],
})
@AutoUnsubscribe()
export class BOLPDF implements OnInit, OnDestroy {
    rutaArchivo: string = '';
    title = 'BOL / CARTA DE INSTRUCCIONES';
    pdfMake: any;
    pdfFonts: any;
    index: string = '';
    pdfObj = null;
    pdfPrueba = null;

    reporte: ReporteBOL[] = [];
    subReporte: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public idCotizacion: number,
        private ctx: Contexto,
        public ventanaDocumento: MatDialog
    ) {
        //called first time before the ngOnInit()
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    ngOnInit() {
        // this.cargarArchivo();
        this.cargarInformacion(this.idCotizacion);
    }

    cargarInformacion(id): void {
        this.subReporte = this.ctx.reportes.obtenerBOL(id).subscribe((bol) => {
            this.reporte = bol;
            this.generar();
        });
    }

    async generar(): Promise<void> {
        let imagenBase64;

        const toDataURL = (url) =>
            fetch(url)
                .then((response) => response.blob())
                .then(
                    (blob) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        })
                );

        await toDataURL('/images/logo.jpg').then((dataUrl) => {
            imagenBase64 = dataUrl;
        });

        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        var rr = {
            header: {
                text: '\nBOL / CARTA DE INSTRUCCIONES',
                alignment: 'center',
                bold: true,
                fontSize: 8,
                italics: true,
            },
            pageSize: 'LETTER',
            content: [],
            styles: {
                titulo: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'right',
                },
                subtitulo: {
                    fontSize: 8,
                    color: '#D0D0D0',
                    alignment: 'right',
                },
                tituloTabla: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center',
                    fillColor: '#9B9B9B',
                },
                subTituloTabla00: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center',
                },
                subTituloTabla01: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'right',
                },
                subTituloTabla02: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'left',
                },
                contenido00: {
                    fontSize: 9,
                    bold: false,
                    alignment: 'center',
                },
                contenido01: {
                    fontSize: 9,
                    bold: false,
                    alignment: 'right',
                },
                contenido02: {
                    fontSize: 9,
                    bold: false,
                    alignment: 'left',
                },
                contenido03: {
                    fontSize: 8,
                    bold: false,
                    alignment: 'center',
                    color: '#393939',
                },
                importante: {
                    fontSize: 9,
                    bold: true,
                    alignment: 'center',
                    fillColor: 'yellow',
                },
            },
        };
        let lineas = this.reporte.length;
        let i = 1;
        this.reporte.forEach((element) => {
            this.index = element.folioDocVenta;
            rr.content.push({
                table: {
                    widths: [200, '*'],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                image: imagenBase64,
                                width: 200,
                                opacity: 0.5,
                            },
                            {
                                border: [false, false, false, false],
                                stack: [
                                    'MOTION SI MÉXICO S.A. DE C.V.',
                                    {
                                        text: 'PERIFERICO DE LA JUVENTUD  3106',
                                        style: 'subtitulo',
                                    },
                                    {
                                        text: 'HACIENDA SANTA FE, C.P. 31215',
                                        style: 'subtitulo',
                                    },
                                    {
                                        text: 'Chihuahua, Chihuahua, México',
                                        style: 'subtitulo',
                                    },
                                    {
                                        text: 'RFC: MSM141212SS5 ',
                                        style: 'subtitulo',
                                    },
                                ],
                                style: 'titulo',
                            },
                        ],
                    ],
                },
            });
            rr.content.push('\n');
            rr.content.push({
                table: {
                    widths: [4, 39, 119, 24, 36, 36, 36, 158],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                fillColor: '#99190c',
                                text: '',
                                rowSpan: 24,
                            },
                            {
                                border: [true, true, true, true],
                                text: element.fechaDocumento,
                                bold: true,
                                fontSize: 9,
                                alignment: 'center',
                                colSpan: 2,
                            },
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea fin
                        [
                            {},
                            {
                                border: [true, true, true, true],
                                text: 'MOTION SI Número de BOL',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                            {
                                border: [true, true, true, true],
                                text: element.folioDocVenta,
                                bold: true,
                                fontSize: 9,
                                alignment: 'center',
                                colSpan: 3,
                            },
                            {},
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea fin
                        [
                            {},
                            {
                                border: [true, true, true, true],
                                text: 'Programado para su recolección',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                            {
                                border: [true, true, true, true],
                                text: element.fechaRecoleccion,
                                bold: true,
                                fontSize: 9,
                                alignment: 'center',
                                colSpan: 3,
                            },
                            {},
                            {},
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea fin
                        [
                            {},
                            {
                                text: 'UNIDAD / EQUIPO',
                                style: 'tituloTabla',
                                colSpan: 2,
                            },
                            {},
                            {
                                text: 'Dirección Origen',
                                style: 'tituloTabla',
                                colSpan: 4,
                            },
                            {},
                            {},
                            {},
                            {
                                text: 'Dirección Destino',
                                style: 'tituloTabla',
                            },
                        ],
                        [
                            {},
                            {
                                text: 'Equipo',
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.equipo,
                                style: 'contenido00',
                            },
                            {
                                text: element.origen,
                                style: 'contenido00',
                                colSpan: 4,
                                rowSpan: 4,
                            },
                            {},
                            {},
                            {},
                            {
                                text: element.destino,
                                style: 'contenido00',
                                rowSpan: 4,
                            },
                        ],
                        [
                            {},
                            {
                                text: 'Camión',
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.placasCamion,
                                style: 'contenido00',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {},
                            {
                                text: element.trailer,
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.placasTrailer,
                                style: 'contenido00',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {},
                            {
                                text: 'Operador',
                                style: 'subTituloTabla02',
                            },
                            {
                                text: element.operador,
                                style: 'contenido00',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        //Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea fin
                        [
                            {},
                            {
                                text: 'Descripción',
                                colSpan: 2,
                                style: 'tituloTabla',
                            },
                            {},
                            {
                                text: 'Dimensiones y Pesos',
                                colSpan: 4,
                                style: 'tituloTabla',
                            },
                            {},
                            {},
                            {},
                            { text: 'INSTRUCCIONES', style: 'tituloTabla' },
                        ],
                        [
                            {},
                            {
                                text: element.descripcion,
                                colSpan: 2,
                                rowSpan: 4,
                                style: 'contenido00',
                            },
                            {},
                            {
                                text: element.medida,
                                colSpan: 2,
                                style: 'contenido03',
                            },
                            {},
                            { text: element.peso, style: 'contenido03' },
                            {
                                text: element.unidadPeso,
                                style: 'subTituloTabla00',
                            },
                            {
                                text: element.instrucciones,
                                rowSpan: 4,
                                style: 'importante',
                            },
                        ],
                        [
                            {},
                            {},
                            {},
                            {
                                text: 'Tipo de Carga',
                                colSpan: 2,
                                style: 'subTituloTabla00',
                            },
                            {},
                            {
                                text: element.tipoCarga,
                                colSpan: 2,
                                style: 'contenido03',
                            },
                            {},
                            {},
                        ],
                        [
                            {},
                            {},
                            {},
                            { text: 'Largo', style: 'subTituloTabla00' },
                            { text: 'Ancho', style: 'subTituloTabla00' },
                            { text: 'Alto', style: 'subTituloTabla00' },
                            {
                                text: element.unidadLongitud,
                                rowSpan: 2,
                                style: 'contenido03',
                            },
                            {},
                        ],
                        [
                            {},
                            {},
                            {},
                            { text: element.largo, style: 'contenido03' },
                            { text: element.ancho, style: 'contenido03' },
                            { text: element.alto, style: 'contenido03' },
                            {},
                            {},
                        ],
                        //Salto de linea inicio
                        [
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                            { border: [false, false, false, false], text: '' },
                        ],
                        //Salto de linea fin
                        [
                            {},
                            {
                                text: 'Referencias',
                                colSpan: 7,
                                style: 'tituloTabla',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {},
                            {
                                text: 'Pedimento No. ' + element.pedimento,
                                colSpan: 2,
                                rowSpan: 2,
                                style: 'subTituloTabla00',
                            },
                            {},
                            {
                                text: element.ordenCompra,
                                colSpan: 2,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {},
                            {
                                text: element.numeroContenedor,
                                colSpan: 2,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {},
                            {
                                text: element.adicional,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                        ],
                        [{}, {}, {}, {}, {}, {}, {}, {}],
                        [
                            {},
                            {
                                text: 'Index',
                                rowSpan: 2,
                                style: 'subTituloTabla00',
                            },
                            // { text: element.index, rowSpan: 2, style: 'contenido00' },
                            {
                                text: element.folioSV,
                                rowSpan: 2,
                                style: 'contenido00',
                            },
                            {
                                text: element.nombreCortoCliente,
                                colSpan: 5,
                                rowSpan: 2,
                                style: 'contenido00',
                                bold: true,
                            },
                            {},
                            {},
                            {},
                            {},
                        ],
                        [{}, {}, {}, {}, {}, {}, {}, {}],
                        [
                            {},
                            {
                                text: 'Proyecto',
                                rowSpan: 1,
                                style: 'subTituloTabla00',
                            },
                            {
                                text: element.referencia,
                                rowSpan: 1,
                                colSpan: 6,
                                style: 'contenido02',
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                    ],
                },
            });
            rr.content.push('\n');
            rr.content.push({
                table: {
                    widths: [325, 25, 150],
                    body: [
                        [
                            {
                                border: [false, false, false, false],
                                text: element.nombreEmpleado, //'Ivan Morales',
                                fontSize: 8,
                                bold: true,
                                alignment: 'center',
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, false],
                                text: 'Firma y Nombre de Recibido',
                                fontSize: 8,
                                alignment: 'center',
                            },
                        ],
                        [
                            {
                                border: [false, false, false, false],
                                text: 'Oficina Mex: +52 (614) 483-2156 ', //Móvil Mex: +52 1(614) 488-3662
                                fontSize: 8,
                                alignment: 'center',
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, false],
                                text: 'Fecha de Recibido',
                                fontSize: 8,
                                alignment: 'center',
                            },
                        ],
                        [
                            {
                                border: [false, false, false, true],
                                text: '',
                                fontSize: 9,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, true],
                                text: '',
                                fontSize: 9,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                        ],
                        [
                            {
                                border: [false, false, false, false],
                                text:
                                    'Representantes de Motion SI MEXICO, S.A. de C.V.',
                                fontSize: 6,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                            { border: [false, false, false, false], text: '' },
                            {
                                border: [false, false, false, false],
                                text: 'Carrier Reprensentative',
                                fontSize: 6,
                                alignment: 'center',
                                margin: [0, 5],
                            },
                        ],
                    ],
                },
            });
            rr.content.push('\n\n');
            rr.content.push({
                text:
                    'Para Pago:\n' +
                    '*** PRUEBA DE ENTREGA FIRMADA DEBE DE ENVIARSE A MOTION SI MÉXICO, S.A. DE C.V.\n'+
                    '*** FAVOR DE CONSIDERAR, EN TODO MOMENTO, EL SERVICIO DE GPS FUNCIONAL Y ÓPTIMO\n PARA EL RASTREO DE LA (S) UNIDAD (ES) DE TRANSPORTE',
                fontSize: 7,
                bold: true,
                color: 'black',
            });
            i++;
            if (i <= lineas) {
                rr.content.push({ text: '', pageBreak: 'after' });
            }
        });
        // this.pdfPrueba = pdfMake.createPdf(rr);
        this.pdfObj = pdfMake.createPdf(rr);

        const archivo = pdfMake.createPdf(rr);
        archivo.getBlob((blod) => {
            let blob = new Blob([blod], { type: 'application/pdf' });
            this.rutaArchivo = window.URL.createObjectURL(blob);
        });
    }

    descargar(): void {
        if (this.pdfObj != null) {
            this.pdfObj.download('bol-' + this.index + '.pdf');
        }
    }

    ngOnDestroy(): void {}
}

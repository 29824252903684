import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ConstructorFormaFactura } from '../constructor-forma.service';

@Component({
  selector: 'app-detalles-factura-venta',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss']
})
export class DetallesfacturaVentaComponent implements OnInit {
  @Input() formasDetalle: FormArray;

  constructor(private cf: ConstructorFormaFactura) {}

  ngOnInit() {}
}

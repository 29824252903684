import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ChartData, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { EstatusVentas } from 'src/app/entidades/estatus-ventas';
import { first } from 'rxjs/operators';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
    selector: 'app-grafica-fuera-tiempo',
    templateUrl: './fuera-tiempo.component.html',
    styleUrls: ['./fuera-tiempo.component.scss']
})
export class FueraTiempoComponent implements OnInit, OnChanges {

    @Input() rastreos: RastreoClienteModel[];

    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'bottom'
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                }
            }
        }
    };

    public pieChartLabels = [];
    public pieChartData: number[] = [];
    public pieChartType: ChartType = 'doughnut';
    public pieChartLegend = true;
    public pieChartPlugins = [pluginDataLabels];
    public pieChartColors = [
        {
            backgroundColor: [
                'rgba(255,0,0,0.85)',
                'rgba(250,222,15,0.85)',
                'rgba(0,139,0,0.85)'
            ]
        }
    ];

    constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando, public autenticacion: Autenticacion, public ventana: MatDialog) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.rastreos) {
            const fueraDeTiempo = _.chain(this.rastreos)
                .filter(d => d.fechaEntrega > d.fechaCompromiso)
                .groupBy(c => c.empleado)
                .map((x, y) => {
                    return {
                        label: y,
                        data: x
                    };
                });

            _.each(fueraDeTiempo.value(), (x) => {
                this.pieChartLabels.push(x.label);
                this.pieChartData.push(x.data.length);
            })
        }
    }

    ngOnInit() { }

    // events
    public chartClicked({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    public chartHovered({ event, active }: { event: MouseEvent; active: {}[]; }): void {
        console.log(event, active);
    }

    division(a: number, b: number): number {
        if (a == 0) {
            return 0;
        }
        else {
            return b / a;
        }
    }
}
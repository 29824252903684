import { ReporteServicioTransporteCliente } from '../entidades/reporte-servicio-transporte-cliente';
import { Camion } from './../entidades/camion';
import { Empleado } from './../entidades/empleado';
import { DatosEquipo } from './../entidades/datos-equipo';
import { Socio } from './../entidades/socio';
import { Observable } from 'rxjs';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Rol } from '../entidades/rol';
import { FiltroServicioTransporte } from '../entidades/FiltroServicioTransporte';

export class RepositorioServicioTransporteCliente extends RepositorioCatalogo<Rol> {

  constructor(http: HttpClient) {
    super(http, 'ServicioTransporteCliente');
  }


  obtenerClientes():Observable<Socio[]>{
    const ruta=`${this.ruta}/Clientes`;
    return this.clienteHttp.get<Socio[]>(ruta);
  }

  obtenerDatosEquipo():Observable<Camion[]>{
    const ruta=`${this.ruta}/TipoTransporte`;
    return this.clienteHttp.get<Camion[]>(ruta);
  }

  obtenerEmpleado():Observable<Empleado[]>{
    const ruta=`${this.ruta}/Empleados`;
    return this.clienteHttp.get<Empleado[]>(ruta);
  }

  obtenerDatosFiltrados(model:FiltroServicioTransporte):Observable<ReporteServicioTransporteCliente[]> {
    const ruta=`${this.ruta}/ObtenerDatosFiltrados`;
    return this.clienteHttp.post<ReporteServicioTransporteCliente[]>(ruta,model);
  }
}

import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Contexto } from 'src/app/api/contexto.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { ReportePenasquitoAcumulado } from 'src/app/entidades/reporte-penasquito-acumulado';
import Handsontable from 'handsontable';
import { HotTableRegisterer } from '@handsontable/angular';
import * as ExcelJS from 'exceljs';
import * as esMX from 'handsontable/commonjs/i18n/languages/es-MX'
import { saveAs } from 'file-saver';
import { IgxSpreadsheetComponent } from 'igniteui-angular-spreadsheet';
import { Spreadsheet } from '@syncfusion/ej2-spreadsheet';

Handsontable.languages.registerLanguageDictionary(esMX.default);

@Component({
  selector: 'app-root',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoPenasquitoReportesComponent implements OnInit, OnDestroy {

  //fuenteDatos: MatTableDataSource<ReporteServicioTransporteCliente> = new MatTableDataSource([]);

  columnasMostradas = ['folioProyecto', 'descripcionproyecto', 'descripcionDocumento', 'tiposervicio', 'cliente', 'proveedor',
    "folioCV", "fechaventa", "tipoTransporte", "noeconomico", "placas", "plataforma", "placasplataforma", "origen", "destino",
    'empleado'];


  forma: FormGroup;

  datos = [];
  settings: Handsontable.GridSettings = {};
  cargarDatos = false;
  language = 'es-MX';

  private hotRegisterer = new HotTableRegisterer();
  id = 'hotInstance';
  
  constructor(private formBuilder: FormBuilder, private ctx: Contexto,
    public spinner: LoadingService,) {}

  ngOnInit() { 
    this.forma = this.formBuilder.group({
      fechaInicio: [],
      fechaFinal: []
    });
  }

  solicitarReporte() {

    const model = this.forma.value as ReportePenasquitoAcumulado;

    this.spinner.show('Generando información...')
    this.ctx.reporteador
      .ObtenerReportePenasquitoAcumuladoExcel(model)
      .toPromise()
      .then((respuesta) => {

        this.datos = respuesta.map( dato => Object.values(dato))

        this.cargarDatosExcel();

        this.spinner.hide();
        this.hotRegisterer.getInstance(this.id).updateSettings(this.settings);


      })
      .then(() => {
        this.mostrarExcel();
      })
      .catch((e) => { });

  }

  cargarDatosExcel( ) {

    this.cargarDatos = false; 

    // Estilos de la primer fila que son los titulos
    const headers = {
      renderer(instance, td, row, col, prop, value, cellProperties) {  
      td.innerText = value;
       if(row == 0 ){
         td.style.background = '#002060';
         td.style.color = '#ffffff';
         td.style.alignContent = 'center';
         td.style.textAlign = 'center';
       }
       return td;
     },
    }

    this.settings = {
      data: [
        //['Fecha de solicitud', 'Direccion de origen', 'Estado origen', 'Direccion de destino', 'Estado destino', 'Pedido/Asunto de correo', 'Proveedor', 'Descripcion del material', 'CeCo', 'Asunto de correo', 'Dirigido', 'Estatus', 'Fecha recoleccion', 'Fecha de entrega', 'Tipo de servicio', 'Flete MXN mas impuestos', 'Flete USD mas impuestos', 'Tiempo incurrido para recolectar', 'CV'],
        ['Fecha', 'Direccion de origen', 'Estado origen', 'Direccion de destino', 'Estado destino', 'Asunto de correo', 'OC', 'Proveedor', 'Descripcion / material', 'CeCo', 'Solicita', 'Estatus', 'Fecha de recolección', 'Fecha de entrega', 'Tipo de servicio', 'Servicio', 'Flete en MXN mas impuestos', 'Flete en USD *mas impuestos en caso aplique', 'OC MOTION', 'CV / ITS', 'Almacen'],
        ...this.datos
      ],
      columns: [
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        {
          // Estilo de la fila de Estatus
         renderer(instance, td, row, col, prop, value, cellProperties) {  
          td.innerText = value;
           if(row != 0 ){

              td.style.background = instance.getDataAtRow(row)[instance.getDataAtRow(row).length - 1];
            
           }else {
            td.style.background = '#002060';
            td.style.color = '#ffffff'
            td.style.alignContent = 'center';
            td.style.textAlign = 'center';
           }
           return td;
         },
        },
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        headers,
        {
          renderer(instance, td, row, col, prop, value, cellProperties) {  
            td.innerText = value;
             if(row != 0 ){
              td.style.display = 'none'
             }else {
              td.style.display = 'none'
             }
             return td;
           },

        }
      ],
      rowHeaders: true,
      rowHeights: 40,
      colHeaders: true,
      formulas: true,
      manualColumnMove: true,
      manualRowMove: true,
      multiColumnSorting: true,
      comments: true,
      licenseKey: 'non-commercial-and-evaluation',        
    };

    this.cargarDatos = true;

  }

  mostrarExcel() {

    // Crear libro de excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reporte Semanal Friedrich');

    // Estilo de los titulos
    const headerStyle: Partial<ExcelJS.Style> = {
      font: { bold: true, color: { argb: 'ffffff'}},
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060'}
      },
      alignment: {
        horizontal: 'center',
        vertical: 'middle'
      },
    }

     // Agregar los datos 
     this.hotRegisterer.getInstance(this.id).getData().map((item, index) => {
       
        
       worksheet.addRow(item).eachCell((cell) => {

        // Poner los estilos a cada una de las celdas de los titulos
        if(Number(cell.row) == 1){
          cell.style = headerStyle;
        }

        // Agregar los datos y darle el estilo del color a las celdas de Estatus
        if(Number(cell.col) == 12 && Number(cell.row) != 1 ){
        
          cell.style = { 
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: item[item.length - 1].substring(1)}
            }
          };
        }
       })
     });

     // Ajustar el ancho de las celdas a que se ajusten al texto
    worksheet.columns.map((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
          const columnLength = cell.value ? cell.value.toString().length : 0;
          maxLength = Math.max(maxLength, columnLength);
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

  worksheet.spliceColumns(worksheet.columns.length, 1);

  

     // Guardar el libro creado como un blob y poder descargarlo 
     workbook.xlsx.writeBuffer().then((buffer) => {
       const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


        //Initialize the Spreadsheet control
      let spreadsheet: Spreadsheet = new Spreadsheet({
        
        openUrl: 'https://services.syncfusion.com/js/production/api/spreadsheet/open',
        saveUrl: 'https://services.syncfusion.com/js/production/api/spreadsheet/save',
        created: (): void => {
        
            let file = new File([blob], "Reporte_semanal.xlsx"); //convert the blob into file
            spreadsheet.open({ file: file }); // open the file into Spreadsheet
        }
      });
      //Render the initialized Spreadsheet
      spreadsheet.appendTo('#element');




      //  saveAs(blob, `Reporte_Semanal_del_${this.formatearFecha(this.forma.value.fechaInicio)}_al_${this.formatearFecha(this.forma.value.fechaFinal)}.xlsx`);
     });

  }

  formatearFecha(fechaOriginal: string) {

    const fecha = new Date(fechaOriginal);

    const day = String(fecha.getDate()).padStart(2, '0');
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const year = String(fecha.getFullYear()).slice(-2);

    const fechaInicio = `${day}${month}${year}`;

    return fechaInicio;
  }


  ngOnDestroy(): void { }
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReporteEvaluacionCliente } from '../entidades/reporte-evaluacion-cliente';

export class RepositorioEvaluaciones extends RepositorioCatalogo<
    ReporteEvaluacionCliente
> {
    constructor(http: HttpClient) {
        super(http, 'Evaluaciones');
    }

    ObtenerAlertasConDetalle(): Observable<ReporteEvaluacionCliente[]> {
        const ruta = this.ruta + '/ObtenerEvaluacionesClientes';
        return this.clienteHttp.get<ReporteEvaluacionCliente[]>(ruta);
    }
}

import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatTableDataSource,
} from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Proyecto } from 'src/app/entidades/proyecto';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Socio } from 'src/app/entidades/socio';
import { EstadosProyectos } from 'src/app/entidades/estado-proyecto';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { TipoProyecto } from 'src/app/entidades/tipo-proyecto';

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaProyectosComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    proyecto: Proyecto;
    idSeleccionado = 0;
    cargando = false;
    subsGuardar: Subscription;
    idCliente: number;
    guardado: EventEmitter<void> = new EventEmitter<void>();
    get f() {
        return this.forma.controls;
    }

    tiposProyecto: Observable<TipoProyecto[]>;

    clientes: MatTableDataSource<Socio> = new MatTableDataSource([]);
    estadosProyecto: MatTableDataSource<EstadosProyectos> = new MatTableDataSource(
        []
    );
    perfilProyectoId: number = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: number,
        private ventana: MatDialogRef<FormaProyectosComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private autenticacion: Autenticacion,
        private alerta: ServicioAlerta
    ) {
        if (id > 0) {
            this.idSeleccionado = id;

            this.ctx.proyectos.obtener(id).subscribe((a) => {
                this.proyecto = a;
                this.forma.patchValue(this.proyecto);
            });
            this.cargarSocios();
            this.cargarEstados();
        } else {
            this.idSeleccionado = 0;
            this.proyecto = new Proyecto();
            this.cargarSocios();
            this.cargarEstados();
        }
    }

    ngOnInit() {
        this.idCliente = this.autenticacion.perfil.id;
        this.forma = this.formBuilder.group({
            folio: ['PRJ-', Validators.nullValidator],
            descripcion: ['', Validators.required],
            clienteId: [this.idCliente, [Validators.required]],
            fechaDocumento: ['', Validators.required],
            estadoProyectoId: [0, Validators.nullValidator],
            esInternacional: [null, Validators.required],
            perfilProyectoId: [null, Validators.nullValidator],
            usuarioId: [null, Validators.nullValidator],
        });

        this.tiposProyecto = this.ctx.tiposProyecto.obtenerTodos();
    }
    cargarSocios(): void {
        this.ctx.socios.obtenerClientes().subscribe((socio) => {
            this.clientes.data = socio;
        });
    }

    cargarEstados(): void {
        this.ctx.estadosProyecto
            .obtenerTodos()
            .subscribe((estadosProyectos) => {
                this.estadosProyecto.data = estadosProyectos;
            });
    }

    limpiar(): void {
        this.forma.reset({
            clienteId: this.idCliente,
            folio: 'PRJ-',
            estadoProyectoId: 0,
            perfilProyectoId: this.perfilProyectoId,
        });
    }

    guardar(): void {
        if (this.forma.valid) {
            this.cargando = true;
            this.forma
                .get('usuarioId')
                .setValue(this.autenticacion.credencial.idUsuario);
            Object.assign(this.proyecto, this.forma.value);

            let observable: Observable<Proyecto>;
            let mensaje = 'Proyecto guardado.';
            let guardar = true;
            if (this.idSeleccionado === 0) {
                observable = this.ctx.proyectos.guardar(this.proyecto);
            } else {
                guardar = false;
                mensaje = 'Proyecto actualizado';
                observable = this.ctx.proyectos.actualizar(
                    this.idSeleccionado.toString(),
                    this.proyecto
                );
            }

            this.subsGuardar = observable.subscribe(
                () => {
                    this.guardado.emit();
                    this.alerta.mostrarExito(mensaje);
                    this.cargando = false;
                    this.limpiar();
                    if (!guardar) {
                        this.ventana.close();
                    }
                },
                () => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                }
            );
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    cerrar(): void {
        this.ventana.close();
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
    }
}

import { RelacionAsignacionCVs } from './../../../entidades/relacion-asignacion-cv';
import { Contexto } from 'src/app/api/contexto.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { join } from 'lodash';

@Component({
  selector: 'app-relacion-asignacion-documento',
  templateUrl: './relacion-asignacion-documento.component.html',
  styleUrls: ['./relacion-asignacion-documento.component.scss']
})
export class RelacionAsignacionDocumentoComponent implements OnInit {

  relaciones: RelacionAsignacionCVs[];
  cargando = false;

  constructor(@Inject(MAT_DIALOG_DATA) private datosProyecto: {id: number, folio: string}, public dialogRef: MatDialogRef<RelacionAsignacionDocumentoComponent>,private ctx: Contexto) { }

  ngOnInit() {
    this.cargarRelacionAsociacion();
  }

  cargarRelacionAsociacion(){
    this.cargando = true;
    this.ctx.proyectos.obtenerRelacionCvsAsociadas(this.datosProyecto.id).subscribe(
      r => {
        this.relaciones = r;
        this.cargando = false;
      }
    );
  }

  concatenar(foliosCvs: string[]): string {
    return join(foliosCvs,',');
  }

  cerrar(){
    this.dialogRef.close();
  }

}

import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Alerta } from 'src/app/entidades/alertas';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { DetalleAlerta } from 'src/app/entidades/detalle-alertas';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Correo } from 'src/app/entidades/correos';
import { CfdiEmisor } from 'src/app/entidades/cfdi-emisor';

export interface DescendienteAlerta {
  id: number;
  alertaId: number;
  alertaDescripcion: string;
}

@Component({
  selector: 'catalogo-cfdi',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
  animations: [animacionTabla]
})
export class CfdiComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  correo: Correo;
  cfdiEmisor: CfdiEmisor = {direccion:'',id:0,nombre: '',regimen:'',rfc:''};
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }

  constructor(private formBuilder: FormBuilder, private ctx: Contexto, private alerta: ServicioAlerta) {}

  ngOnInit() {
      this.generarForma();
      this.cargarForma();
  }

  generarForma():void {
      this.forma = this.formBuilder.group(
          {
              direccion: [''],
              nombre: ['', Validators.required],
              rfc: ['', Validators.required],
              regimen: ['', Validators.required],
          }
      );
  }

  cargarForma(): void {
      this.ctx
      .cfdiEmisor
      .obtenerTodos()
      .subscribe(
        cfdiEmisor => {
              this.cfdiEmisor.direccion = cfdiEmisor[0].direccion;
              this.cfdiEmisor.nombre = cfdiEmisor[0].nombre;
              this.cfdiEmisor.regimen = cfdiEmisor[0].regimen;
              this.cfdiEmisor.rfc = cfdiEmisor[0].rfc;
              Object.assign(this.forma.value, this.cfdiEmisor);
              this.forma.reset(this.forma.value);
          });
  }

  limpiar(): void {
      this.forma.reset();
  }

  guardar(): void {
      if (this.forma.valid) {
          this.cargando = true;
          Object.assign(this.cfdiEmisor, this.forma.value);

          let mensaje = 'Emisor actualizado';
          let observable = this.ctx.cfdiEmisor.guardar(this.cfdiEmisor);

          this.subsGuardar = observable.subscribe(() => {
              this.guardado.emit();
              this.alerta.mostrarExito(mensaje);
              this.cargando = false;
          },
              () => {
                  this.alerta.mostrarError('¡Error al guardar!');
                  this.cargando = false;
              });
      }
      else {
          this.alerta.mostrarAdvertencia('Los datos son inválidos.');
      }
  }

  ngOnDestroy(): void {
      if (this.subsGuardar) {
          this.subsGuardar.unsubscribe();
      }
  }
}

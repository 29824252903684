import { Component } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-grid-card',
  templateUrl: './contenedor.component.html',
  styleUrls: ['./contenedor.component.scss']
})
export class GridCardComponent {
  columns: string;
  gutter = '10px';
  flex = '0 1 1279.99px';

  constructor(public mediaObserver: MediaObserver) {
    mediaObserver.media$.subscribe(({ mqAlias }) => {
      console.dir(mqAlias);
      switch (mqAlias) {
        case 'xs':
          this.columns = 'repeat(4, 1fr)';
          break;
        case 'sm':
          this.columns = 'repeat(4, 1fr)';
          break;
        case 'md':
          this.columns = 'repeat(8, 1fr)';
          break;
        case 'lg':
          this.columns = 'repeat(12, 1fr)';
          break;
        case 'xl':
          this.columns = 'repeat(12, 1fr)';

          break;
        default:
          break;
      }
    });
  }
}

export class Articulo {
  id: number;
  clave: string;
  descripcion: string;
  activo: boolean;
  claveProductoServicioSat: string;
  claveUnidadSat: string;
  compra: string;
  venta: string;
  iva: string;
  retencion: string;
}

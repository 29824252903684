import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Correo } from "../entidades/correos";

export class RepositorioCorreo extends RepositorioCatalogo<Correo> {

    constructor(http: HttpClient) {
        super(http, "Correos");
    }

    obtenerCorreoUnico(): Observable<Correo> {
        return this.clienteHttp.get(`${this.ruta}/Unico`)
            .pipe(map((data: Correo) => {
                return data;
            }));
    }

    activar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    activarSSL(id: number): Observable<void>{
        let ruta = `${this.ruta}/ActivarSSL/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivarSSL(id: number): Observable<void>{
        let ruta = `${this.ruta}/DesactivarSSL/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }
}

import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatTableDataSource} from '@angular/material';
import { FormGroup, Validators, FormBuilder, PatternValidator } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { DetalleAlerta } from 'src/app/entidades/detalle-alertas';
import { DescendienteAlerta } from '../catalogo/catalogo.component';
import { Usuario } from 'src/app/entidades/usuario';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaListasComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  detalles: DetalleAlerta;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  cerrado: EventEmitter<boolean> = new EventEmitter<boolean>();
  guardoAlgo = false;
  mostrarCombo = false;
  tipoInterno: boolean;
  tipoExterno: boolean;

  envioMensaje: boolean;
  envioCorreo: boolean;

  mask: any[] = ['+', /\d/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  get f() {
    return this.forma.controls;
  }

  usuariosAsigandos: MatTableDataSource<Usuario> = new MatTableDataSource([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public datos: DescendienteAlerta,
    private ventana: MatDialogRef<FormaListasComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {
    if (datos.id > 0) {
      this.idSeleccionado = this.datos.id;
      this.ctx.detalleAlerta.obtener(this.idSeleccionado).subscribe(a => {
        this.detalles = a;
        Object.assign(this.forma.value, this.detalles);
        this.forma.reset(this.forma.value);
        this.cargarUsuarios();
        if (this.detalles.tipo === 2) {
          this.tipoInterno = false;
          this.tipoExterno = true;
          this.f['tipo'].setValue('2');
          this.mostrarCombo = false;
        } else {
          this.f['tipo'].setValue('1');
          this.tipoInterno = true;
          this.tipoExterno = false;
          this.mostrarCombo = true;
        }
        this.envioCorreo = this.detalles.envioCorreo;
        this.envioMensaje = this.detalles.envioMensaje;
      });
    } else {
      this.idSeleccionado = 0;
      this.detalles = new DetalleAlerta();
      this.tipoInterno = false;
      this.tipoExterno = true;
      this.envioCorreo = true;
      this.envioMensaje = false;
      this.cargarUsuarios();
    }
  }

  // ,Validators.pattern('[0-9]{3} [0-9]{3} [0-9]{4})]')
  ngOnInit() {
    this.forma = this.formBuilder.group({
      nombre: ['', Validators.required],
      telefono: [''],
      telefonoMovil: ['', Validators.required],
      correo: ['', Validators.compose([Validators.required])],
      tipo: ['', Validators.nullValidator],
      usuarioId: ['', Validators.nullValidator],
      envioMensaje: [false, Validators.nullValidator],
      envioCorreo: [false, Validators.nullValidator]
    });
    this.f['tipo'].setValue('2');
  }


  limpiar(): void {
    this.forma.reset();
  }

  cargarUsuarios(): void {
    this.cargando = true;
    this.ctx.usuarios.obtenerTodos().subscribe(usuarios => {
      this.usuariosAsigandos.data = usuarios;
    });
    this.cargando = false;
    // let tipoUsuario = usuarios.find(a=> a.tipoUsuarioId == 1);
  }

  mostrar(event): void {
    if (event.value === '1') {
      this.mostrarCombo = true;
      this.f['tipo'].setValue('1');
      this.forma.controls['usuarioId'].setValidators([Validators.required]);
      this.forma.controls['nombre'].setValidators([Validators.nullValidator]);
      this.f['nombre'].setValue('');
    } else {
      this.mostrarCombo = false;
      this.f['tipo'].setValue('2');
      this.forma.controls['usuarioId'].setValidators([
        Validators.nullValidator
      ]);
      this.forma.controls['nombre'].setValidators([Validators.required]);
      this.f['nombre'].setValue('');
    }
  }

  capturarUsuario(event) {
    const opcion = event.source.selected._element.nativeElement;
    this.f['nombre'].setValue(opcion.innerText.trim());
  }

  telefonoObligatorio(event): void {
    if (event.checked) {
      this.forma.controls['telefono'].setValidators([Validators.required]);
    } else {
      this.f.controls['telefono'].setValidators([Validators.nullValidator]);
      if (this.f['telefono'].value === '') {
        this.f['telefono'].setValue('');
      }
    }
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.detalles, this.forma.value);
      this.detalles.alertaId = this.datos.alertaId;
      this.detalles.alertaDescripcion = this.datos.alertaDescripcion;
      if (this.detalles.tipo.toString() == "2") {
        this.detalles.usuarioId = 0;
      }

      let observable: Observable<DetalleAlerta>;
      let mensaje = 'Detalle guardado.';
      let guardar = true;
      if (this.idSeleccionado === 0) {
        observable = this.ctx.detalleAlerta.guardar(this.detalles);
      } else {
        guardar = false;
        mensaje = 'Detalle actualizada';
        observable = this.ctx.detalleAlerta.actualizar(
          this.idSeleccionado.toString(),
          this.detalles
        );
      }

      this.subsGuardar = observable.subscribe(
        () => {
          // this.guardado.emit();
          this.alerta.mostrarExito(mensaje);
          this.cargando = false;
          this.limpiar();
          this.guardoAlgo = true;
          if (!guardar) {
            // this.ventana.close();
            this.cerrar();
          } else {
            this.cerrar();
          }
        },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        }
      );
    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    // this.ventana.close();
    this.cerrado.emit(this.guardoAlgo);
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Contexto } from 'src/app/api/contexto.service';
import { ControlArchivosComponent } from 'src/app/utilerias/control-archivos/control-archivo.component';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';

@Component({
    selector: 'destino-servicio-solicitud-cotizacion-compra',
    templateUrl:
        './destino-servicio-solicitud-cotizacion-compra.component.html',
    styleUrls: [
        './destino-servicio-solicitud-cotizacion-compra.component.scss',
    ],
})
@AutoUnsubscribe()
export class DestinoServicioSolicitudCotizacionCompraComponent
    implements OnInit {
    @Input() forma: FormGroup;
    @Input() modificando: boolean;
    @Input() destinos: Direccion[];
    @ViewChild(ControlArchivosComponent)
    controlArchivos: ControlArchivosComponent;

    filtroDestino = new FormControl();
    destinosFiltrados: Observable<Direccion[]>;
    subsDestinoSeleccionado: Subscription;

    constructor(private ctx: Contexto) {}

    ngOnInit() {
        this.destinosFiltrados = this.filtroDestino.valueChanges.pipe(
            startWith<string | Direccion>(''),
            map((direccion) =>
                typeof direccion === 'string'
                    ? direccion
                    : direccion == null
                    ? ''
                    : direccion.calle
            ),
            map((direccion) => this.filtrar(direccion))
        );

        if (this.forma.get('destinoId').value) {
            const idDestino = this.forma.get('destinoId').value;
            const destino = this.destinos.find((o) => o.id == idDestino);
            if (destino) {
                this.filtroDestino.setValue(destino);
            }
        }

        this.sustituirFuncionTocado();
    }

    destinoSeleccionado(idDestino: number): void {
        this.subsDestinoSeleccionado = this.ctx.direccionesServicio
            .obtener(idDestino)
            .subscribe((destino) => {
                this.forma.get('destinoId').setValue(idDestino);
                this.forma.get('contacto').setValue(destino.contactoDestino);
            });
    }

    sustituirFuncionTocado(): void {
        const funcionOriginal = this.forma.get('destinoId').markAsTouched;
        this.forma.get('destinoId').markAsTouched = (opts: {
            onlySelf: true;
        }) => {
            const funcionEnlazada = funcionOriginal.bind(
                this.forma.get('destinoId')
            );
            funcionEnlazada(opts);
            this.filtroDestino.markAsTouched(opts);
        };
    }

    marcarComoTocado(): void {
        this.forma.get('destinoId').markAsTouched({ onlySelf: true });
    }

    campoMostrar(direccion: Direccion) {
        let direccionCompleta = '';
        if (direccion) {
            direccionCompleta = `${direccion.calle},  ${direccion.numeroExterior}, `;
            if (direccion.numeroInterior) {
                direccionCompleta += `${direccion.numeroInterior}, `;
            }
            direccionCompleta += `${direccion.colonia}, ${direccion.codigoPostal}, ${direccion.ciudadDescripcion}, ${direccion.estadoDescripcion}, ${direccion.paisDescripcion}`;
        }
        return direccionCompleta;
    }

    private filtrar(dato: string): Direccion[] {
        if (!dato) {
            dato = '';
        }
        const valorFiltro = dato.toLowerCase();

        const direccionesAFiltrar = this.destinos;

        const direcciones = direccionesAFiltrar.filter(
            (direccion) =>
                direccion.calle.toLowerCase().indexOf(valorFiltro) === 0 ||
                direccion.numeroExterior.toLowerCase().indexOf(valorFiltro) ===
                    0 ||
                direccion.numeroInterior.toLowerCase().indexOf(valorFiltro) ===
                    0 ||
                direccion.colonia.toLowerCase().indexOf(valorFiltro) === 0 ||
                direccion.codigoPostal.toLowerCase().indexOf(valorFiltro) ===
                    0 ||
                direccion.ciudadDescripcion
                    .toLowerCase()
                    .indexOf(valorFiltro) === 0 ||
                direccion.estadoDescripcion
                    .toLowerCase()
                    .indexOf(valorFiltro) === 0 ||
                direccion.paisDescripcion.toLowerCase().indexOf(valorFiltro) ===
                    0
        );

        return direcciones;
    }

    limpiar(): void {
        this.forma.reset();
        this.limpiarArchivos();
    }

    limpiarArchivos(): void {
        this.controlArchivos.limpiar;
    }

    ngOnDestroy(): void {}
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'boton-progreso',
  templateUrl: './boton-progreso.html',
  styleUrls: ['./boton-progreso.css']
})
export class BotonProgresoComponent implements OnInit {
  @Input() cargando = false;
  @Input() diametro = 22;
  @Input() texto: string;
  spacesArray: string[];

  constructor() {}

  ngOnInit() {
    let spaces = this.texto.length / 2;
    spaces = Math.round(spaces);
    this.spacesArray = new Array(spaces);
  }
}

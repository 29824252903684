import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Empleado } from 'src/app/entidades/empleado';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Autorizaciones } from 'src/app/entidades/autorizaciones';  
import { TipoOpcion } from 'src/app/entidades/tipoOpcion';
import { RepositorioTipoOpcion } from 'src/app/api/reporitorio-tipoOpcion';
import { Empleados } from 'src/app/entidades/empleados';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAutorizacionesComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  autorizacion: Autorizaciones;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  color = '#101010';
  jefeId = 0;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  subsTipoOpcion: Subscription;
  tipoOpciones: TipoOpcion[];
  empleados: Empleados[];
  jefeArea: MatTableDataSource<Empleado> = new MatTableDataSource([]);
  cerrado: EventEmitter<boolean> = new EventEmitter<boolean>();
  get f() {
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaAutorizacionesComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {
    if (id > 0) {
      this.idSeleccionado = id;
      this.ctx.autorizaciones.obtener(id).subscribe(a => {
        this.autorizacion = a;
        Object.assign(this.forma.value, this.autorizacion);
        this.forma.reset(this.forma.value);
      });
    } else {
      this.idSeleccionado = 0;
      this.autorizacion = new Autorizaciones();
    }
  }

  ngOnInit() {
    this.cargarTipoOpciones();
    this.cargarEmpledaos();
    this.forma = this.formBuilder.group({
      id: [0, Validators.required],
      nombre: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      opcion: ['', Validators.required],
      nivel: ['', [Validators.required, Validators.min(1), Validators.max(2)]]
    });
  }

  cargarTipoOpciones(): void{
    this.subsTipoOpcion = this.ctx.tipoOpcion
      .obtenerTodos()
      .subscribe(tipoOpciones => {
        this.tipoOpciones = tipoOpciones
      })
  }

  cargarEmpledaos(): void{
    this.subsTipoOpcion = this.ctx.empleados
      .obtenerTodos()
      .subscribe(empleados => {
        this.empleados = empleados
      })
  }


  limpiar(): void {
    this.forma.reset();
  }

  async guardar(): Promise<void> {
    if(!this.forma.valid){
      this.alerta.mostrarAdvertencia('Los datos son invalidos');
      return;
    }

    this.cargando = true;
    Object.assign(this.autorizacion, this.forma.value);

    try{
      const mensaje = this.idSeleccionado === 0 ? 'Autorizacion guardada.' : 'Autorizacion actualizada';
      if(this.idSeleccionado === 0){
        await this.ctx.autorizaciones.guardar(this.autorizacion).toPromise();
      }else{
        await this.ctx.autorizaciones.actualizar(this.idSeleccionado.toString(), this.autorizacion).toPromise();
      }

      this.guardado.emit();
      this.alerta.mostrarExito(mensaje);
      this.limpiar();

      this.cerrado.emit(true);

      if (this.idSeleccionado !== 0 ){
        this.ventana.close();
      }
    } catch (error) {
      this.alerta.mostrarError('¡Error al guardar!');
    } finally {
      this.cargando = false;
    }
    // if (this.forma.valid) {

    //   this.cargando = true;
    //   Object.assign(this.autorizacion, this.forma.value);
    //   let observable: Observable<Autorizaciones>;
    //   let mensaje = 'Autorizacion guardada.';
    //   let guardar = true;
    //   if (this.idSeleccionado === 0) {
    //     observable = this.ctx.autorizaciones.guardar(this.autorizacion);
    //   } else {
    //     guardar = false;
    //     mensaje = 'Autorizacion actualizada';
    //     observable = this.ctx.autorizaciones.actualizar(
    //       this.idSeleccionado.toString(),
    //       this.autorizacion
    //     );
    //   }

    //   this.subsGuardar = observable.subscribe(
    //     () => {
    //       this.guardado.emit();
    //       this.alerta.mostrarExito(mensaje);
    //       this.cargando = false;
    //       this.limpiar();
    //       if (!guardar) {
    //         this.ventana.close();
    //       }
    //     },
    //     error => {
    //       // if (error.status == 515) {//error == "Clave Duplicada") {
    //       //   this.alerta.mostrarError('¡Error Clave Duplicada!');
    //       //   this.cargando = false;
    //       // }
    //       // else if (error.error == "YaExiste") {
    //       //   console.log(error);
    //       //   this.alerta.mostrarAdvertencia('¡Ya se encuentra una autorizacion guardada!');
    //       //   this.cargando = false;
    //       //   return;
    //       // } else {
    //       //   this.alerta.mostrarError('¡Error al guardar!');
    //       //   this.cargando = false;
    //       // }
    //       this.alerta.mostrarError('¡Error al guardar!');
    //       this.cargando = false;
    //     });

    // } else {
    //   this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    // }
  }


  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}

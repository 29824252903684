import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-ventas-prospectadas',
  templateUrl: './ventas-prospectadas.component.html',
  styleUrls: ['./ventas-prospectadas.component.scss']
})
export class VentasProspectadasComponent {
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(x => {
      if (x.matches) {
        return [
          // {
          //   title: 'Análisis de ventas',
          //   cols: 1,
          //   rows: 1,
          //   position: 1
          // },
          {
            title: 'Ventas por empleado',
            cols: 1,
            rows: 1,
            position: 2
          },
          { title: 'Ventas', cols: 1, rows: 1, position: 3 },
          { title: 'Ventas canceladas', cols: 1, rows: 1, position: 4 }
        ];
      }

      return [
        // { title: 'Análisis de ventas', cols: 2, rows: 1, position: 1 },
        { title: 'Ventas por empleado', cols: 1, rows: 1, position: 2 },
        { title: 'Ventas', cols: 1, rows: 2, position: 3 },
        { title: 'Ventas canceladas', cols: 1, rows: 1, position: 4 }
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver) {}
}

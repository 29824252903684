import { Component, OnInit, Inject, OnDestroy, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { TipoReporte } from 'src/app/entidades/tipo-reportes';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FiltroReporte } from 'src/app/entidades/filtro-reporte';

@AutoUnsubscribe()
@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaFiltroReporteComponent implements OnInit {
  forma: FormGroup;
  filtro: FiltroReporte;
  idSeleccionado: number = 0;
  cargando: boolean = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() { return this.forma.controls; }

  constructor(@Inject(MAT_DIALOG_DATA) public id: number, private ventana: MatDialogRef<FormaFiltroReporteComponent>, private formBuilder: FormBuilder,
    private ctx: Contexto, private alerta: ServicioAlerta) {
    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx
        .filtroReporte
        .obtener(id)
        .subscribe(
          a => {
            this.filtro = a;
            Object.assign(this.forma.value,this.filtro);
            this.forma.reset(this.forma.value);
            
          });
    }
    else {
      this.idSeleccionado = 0;
      this.filtro = new FiltroReporte();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group(
      {
        id: [0, Validators.nullValidator],
        clave: ['', Validators.required],
        descripcion: ['', Validators.required],
        activo: [true, Validators.nullValidator]
      }
    );
  }

  limpiar(): void {
    this.forma.reset();
  }


  guardar(): void {

    if (this.forma.valid) {

      const model = this.forma.value as FiltroReporte;

      if (this.idSeleccionado === 0) {
        this.ctx.filtroReporte.guardar(model).toPromise().then(() => {
          this.alerta.mostrarExito("Filtro de reporte guardado con éxito");
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.alerta.mostrarAdvertencia('¡Ya existe el filtro de reporte con esa clave o descripción! ');
          } else if (e.error === 'ErrorGuardar') {
            this.alerta.mostrarAdvertencia('¡Error al guardar el filtro de reporte! ');
          }
          else {
            this.alerta.mostrarError('¡Error interno al guardar! ');
          }

        });

      } else {
        this.ctx.filtroReporte.actualizar(this.idSeleccionado.toString(), model).toPromise().then(() => {
          this.alerta.mostrarExito("Filtro de proveedor actualizado con éxito");
          this.guardado.emit();
          this.cerrar();
        }).catch(e => {
          console.log(e);
          if (e.error === 'Existe') {
            this.alerta.mostrarAdvertencia('¡Ya existe un filtro de reporte con esa clave o descripción! ');
          } else {
            this.alerta.mostrarError('¡Error interno al guardar! ');
          }
        });
      }
    }
    else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }
  cerrar(): void {
    this.ventana.close();
  }

}

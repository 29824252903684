import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Rol } from '../entidades/rol';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RolEspecifico } from '../entidades/rol-especifico';

export class RepositorioRol extends RepositorioCatalogo<Rol> {
  constructor(http: HttpClient) {
    super(http, 'Roles');
  }

  ObtenerRolConDetalleId(id: number): Observable<Rol> {
    const ruta = `${this.ruta}/ObtenerRolConDetalleId/${id}`;
    return this.clienteHttp.post(ruta, null).pipe(map((r: Rol) => r));
  }

  obtenerRolEspecificoEmpresa(id: number): Observable<RolEspecifico[]> {
    const ruta = `${this.ruta}/ObtenerRolEspecificoEmpresa/${id}`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: RolEspecifico[]) => {
        return data;
      })
    );
  }

  Guardar(rol: Rol): Observable<Rol> {
    const ruta = `${this.ruta}/GuardarRol`;
    return this.clienteHttp.post(ruta, rol).pipe(map((r: Rol) => r));
  }

  obtenerTipoUsuarioPorIdRol(id: number): Observable<number> {
    const ruta = `${this.ruta}/ObtenerTipoUsuarioPorIdRol/${id}`;
    return this.clienteHttp.get(ruta).pipe(map((r: number) => r));
  }
}

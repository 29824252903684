import { Compra } from './compra';
import { Venta } from './venta';
import { PerfilProyecto } from './perfil-proyecto';

export class Proyecto {
    id: number;
    folio: string;
    fechaDocumento: Date;
    fechaTermino: Date;
    descripcion: string;
    referencia: string;
    activo: boolean;
    clienteId: number;
    clienteNombreComercial: string;
    nombreCorto: string;
    estadoProyectoId: number;
    estadoProyectoDescripcion: string;
    esInternacional: boolean;
    compras: Compra[];
    ventas: Venta[];
    perfilProyectoId: number;
    perfilProyectoDescripcion: string;
    usuarioId: number;
    nombreUsuario: string;
    estatusProyecto: string;
}

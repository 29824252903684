import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { first } from 'rxjs/operators';
import { VentasEmpleado } from 'src/app/entidades/ventas-empleado';

@Component({
  selector: 'app-ventas-empleado',
  templateUrl: './ventas-empleado.component.html',
  styleUrls: ['./ventas-empleado.component.scss']
})
export class VentasEmpleadoComponent implements OnInit {
  fuenteDatos: MatTableDataSource<VentasEmpleado> = new MatTableDataSource([]);
  ventas: VentasEmpleado[];

  columnasMostradas = [
    'nombre',
    'ventasCanceladas',
    'ventasAbiertas',
    'ventasCerradas',
    'ventasFacturadas',
    'subtotal'
  ];

  constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando) { }

  ngOnInit() {
    this.ctx.documentosVenta
      .obtenerVentasEmpleado()
      .pipe(first())
      .subscribe((ventas) => {
        console.log(ventas);
        this.ventas = ventas;
        this.mostrarTodo();
      });
  }

  private cargarVentas(ventas: VentasEmpleado[]): void {
    this.fuenteDatos = new MatTableDataSource(ventas);
  }

  mostrarTodo(): void {
    this.cargarVentas(this.ventas);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { first } from 'rxjs/operators';
import { Pareto } from 'src/app/entidades/pareto';
import { Observable, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-grafica-pareto',
  templateUrl: './grafica.component.html',
  styleUrls: ['./grafica.component.scss']
})
@AutoUnsubscribe()
export class GraficaParetoComponent implements OnInit {
  @Input() pareto: Observable<Pareto[]>;
  pareto$: Subscription;

  public chartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom'
    },
    plugins: {
      datalabels: {
        color: 'black',
        formatter: (value, ctx) => {
          if (ctx.datasetIndex === 0) {
            return this.decimal.transform(value, '1.2') + '%';
          }
          return '';
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'a',
          type: 'linear',
          position: 'left',
          ticks: {
            callback: (value, index, values) => {
              return this.currency.transform(value);
            }
          }
        },
        {
          id: 'b',
          position: 'right',
          ticks: {
            max: 100,
            min: 0,
            callback: (value, index, values) => {
              return `${value}%`;
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          return 'Ranking: ' + data.labels[tooltipItem[0].index];
        },
        label: (tooltipItem, data) => {
          const valor =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          let etiqueta: string;
          if (tooltipItem.datasetIndex === 0) {
            etiqueta = this.decimal.transform(valor, '1.2') + '%';
          } else {
            etiqueta = this.currency.transform(valor);
          }
          return etiqueta;
        }
      }
    }
  };

  public chartLabels: string[];
  public chartData: ChartDataSets[];
  public chartType: ChartType = 'bar';
  public chartLegend = true;
  public chartPlugins = [pluginDataLabels];

  constructor(private currency: CurrencyPipe, private decimal: DecimalPipe) {}

  ngOnInit() {
    this.pareto$ = this.pareto.subscribe(pareto => {
      this.chartLabels = pareto.map(p => p.ranking.toString());
      this.chartData = [
        {
          data: pareto.map(p => p.valorPorcentaje),
          label: 'Valor porcentual',
          type: 'line',
          fill: false,
          yAxisID: 'b',
          backgroundColor: '#4472c4',
          borderColor: '#4472c4',
          hoverBackgroundColor: '#3366CC',
          hoverBorderColor: '#3366CC'
        },
        {
          data: pareto.map(p => p.ventas),
          label: 'Ventas',
          stack: 'a',
          yAxisID: 'a',
          backgroundColor: '#ff5050',
          borderColor: '#ff5050',
          hoverBackgroundColor: '#ff6666',
          hoverBorderColor: '#ff6666'
        }
      ];
      console.log(this.chartData);
    });
  }

  // events
  public chartClicked({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }
}

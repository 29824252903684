export class ContactoSocio {
  id: number;
  nombre: string;
  telefono: string;
  correo: string;
  socioid: number;
  clave: string;
  porDefault: boolean;
  tipo: string;
}

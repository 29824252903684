import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Frontera } from "../entidades/frontera";

export class RepositorioFrontera extends RepositorioCatalogo<Frontera> {

    constructor(http: HttpClient) {
        super(http, "Fronteras");
    }

}

import { HttpClient } from "@angular/common/http";
import { Operador } from "../entidades/operador"
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Pais } from '../entidades/pais';

export class RepositorioPais extends RepositorioCatalogo<Pais> {

    constructor(http: HttpClient) {
        super(http, "Pais");
    }
}


// ad

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaTipoCambioComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TipoCambio } from 'src/app/entidades/tipo-cambio';

@Component({
  selector: 'catalogo-aduana',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoTipoCambioComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<TipoCambio> = new MatTableDataSource([]);
  columnasMostradas = ['fechaCambio', 'monedaNombre', 'monedaAbreviatura', 'valor', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  subsTiposCambio: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarTiposCambio();
  }

  cargarTiposCambio(): void {
    this.cargando = true;
    this.subsTiposCambio = this.ctx.tiposCambio
      .obtenerTodos()
      .subscribe(tiposCambio => {
        this.fuenteDatos = new MatTableDataSource(tiposCambio);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.fuenteDatos.sort.sort({ start: 'desc', disableClear: false, id: 'fechaCambio' });
        this.cargando = false;
      });
  }

  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaTipoCambioComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarTiposCambio();
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subsGuardar) { this.subsGuardar.unsubscribe(); }
  }
}
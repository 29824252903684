import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { GeneradorReportes } from 'src/app/entidades/generador-reportes';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { ReporteadorGlobal } from 'src/app/entidades/reporteador-global';

@Component({
    selector: 'catalogo-reporte-global-tabla',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoReporteGlobalTablaComponent implements OnInit {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;

    fuenteDatos: MatTableDataSource<ReporteadorGlobal> = new MatTableDataSource(
        []
    );

    columnasPorMostrar = [
        ['proyecto', 'Proyecto', true],
        ['cliente', 'Cliente', true],
        ['proyectoDescripcion', 'Descripción del proyecto', true],
        ['tipoProyectoDescripcion', 'Tipo de proyecto', true],
        ['fechaProyecto', 'Fecha de proyecto', true],
        ['estatusProyecto', 'Estatus del proyecto', true],
        ['sv', 'Solicitud de venta', true],
        ['ct', 'Cotización de costos', true],
        ['cv', 'Cotización de venta', true],
        ['fechaDocumento', 'Fecha de documento', true],
        ['subTotalVenta', 'Subtotal', true],
        ['importeIVA', 'IVA', true],
        ['aplicaRetencionVenta', 'Aplica retención', true],
        ['totalVenta', 'Total', true],
        ['monedaOriginalVenta', 'Moneda original', true],
        ['proveedor', 'Proveedor', true],
        ['fechaDocumentoCompra', 'Fecha de costos', true],
        ['subTotalCompra', 'Subtotal', true],
        ['importeIVACompra', 'IVA costos', true],
        ['aplicaRetencionCompra', 'Retención costos', true],
        ['totalCompra', 'Total costos', true],
        ['monedaOriginalCompra', 'Moneda orignial costos', true],
        ['tipoCambio', 'Tipo de cambio', true],
        ['utilidad', 'Utilidad', true],
        ['empleado', 'Empleado', true],
    ];

    get columnasSeleccionadas() {
        return this.columnasPorMostrar.filter((c) => c[2]).map((c) => c[0]);
    }

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
            return 10;
        }
        return 100;
    }

    constructor(
        public ventana: MatDialog,
        private ctx: Contexto,
        public spinner: LoadingService
    ) {}

    ngOnInit() {}

    crearReporte(reporteador: GeneradorReportes): void {
        this.spinner.show('Buscando...');
        this.ctx.reporteador
            .obtenerReporteGlobal(reporteador)
            .toPromise()
            .then((r) => {
                console.dir(r);
                this.fuenteDatos = new MatTableDataSource(r);
                this.fuenteDatos.paginator = this.paginador;
                this.fuenteDatos.sort = this.ordenador;
                this.spinner.hide();
            })
            .catch((e) => {
                console.log(e);
                this.spinner.hide();
            });
    }

    limpiarTabla(): void {}

    exportarExcel(reporteador: GeneradorReportes): void {
        this.spinner.show('Exportando a excel...');
        const fechaActual = new Date();
        const nombreArchivo =
            'Reporte_Global_hasta_el_dia_' + fechaActual.toLocaleString();
        this.ctx.reporteador
            .obtenerReporteGlobalExcel(reporteador)
            .toPromise()
            .then((respuesta) => {
                this.spinner.hide();
                this.llamarExcel(respuesta, nombreArchivo);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    llamarExcel(respuesta, nombreArchivo) {
        const blob = new Blob([respuesta], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = nombreArchivo;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    seleccionarColumna(indice: number) {
        const columna = this.columnasPorMostrar[indice];
        columna[2] = !columna[2];
    }

    seleccionarTodasLasColumnas() {
        this.columnasPorMostrar.forEach((c) => (c[2] = true));
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-detalles-documento-factura',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss']
})
export class DetallesDocumentoFacturaComponent implements OnInit {
  @Input() formasDetalle: FormArray;

  constructor() {}

  ngOnInit() {}
}

import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { ModoTransporte } from '../entidades/modo-transporte';
import { Observable } from 'rxjs';
import { AsignacionModoTransporte } from '../entidades/asignacion-modo-transporte-prov';
import { map } from 'rxjs/operators';

export class RepositorioModoTransporte extends RepositorioCatalogo<ModoTransporte> {
  constructor(http: HttpClient) {
    super(http, 'ModoTransporte');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put<void>(ruta, null);

  }


  obtenerTodosActivos(): Observable<ModoTransporte[]> {
    const ruta = `${this.ruta}/ObtenerTodosActivos`;
    return this.clienteHttp.get<ModoTransporte[]>(ruta);

  }


  asignar(asignacion: AsignacionModoTransporte): Observable<AsignacionModoTransporte> {
    const ruta = `${this.ruta}/Asignar`;
    return this.clienteHttp.post(ruta, asignacion).pipe(
      map((asig: AsignacionModoTransporte) => asig)
    );
  }

  desasignar(asignacion: AsignacionModoTransporte): Observable<void> {
    const ruta = `${this.ruta}/Desasignar/${asignacion.modoTransporteId}/${asignacion.clienteId}`;
    return this.clienteHttp.delete(ruta).pipe(map(() => null));
  }



  obtenerModosTransporteCliente(IdCliente: number): Observable<ModoTransporte[]> {
    const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
    return this.clienteHttp.get<ModoTransporte[]>(ruta);
  }
}


import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { FiltroExportacion } from '../entidades/filtro-exportacion';
import { Proveedor } from '../entidades/proveedor';
import { CompraExportacion } from '../entidades/compra-exportacion';
import { RepositorioReferi } from './portal-Referi/repo-referi';

export class RepositorioExportacionInfo extends RepositorioReferi<FiltroExportacion> {
    constructor(http: HttpClient) {
        super(http, 'Migracion');
    }

    migracionProveedores(proveedores: Proveedor[]): Observable<Proveedor[]> {
        const ruta = `${this.ruta}/MigracionProveedores`;
        return this.cliente.post(ruta, proveedores).pipe(
            map((proveedores: Proveedor[]) => proveedores)
        );
    }

    migracionOrdenesCompra(ordenes: CompraExportacion[]): Observable<CompraExportacion[]> {
        const ruta = `${this.ruta}/MigracionOrdenesCompra`;
        return this.cliente.post(ruta, ordenes).pipe(
            map((ordenes: CompraExportacion[]) => ordenes)
        );
    }

    migracionActualizarOrdeneCompra(ordenes: CompraExportacion[]): Observable<CompraExportacion[]> {
        const ruta = `${this.ruta}/ActualizarOrdenCompra`;
        return this.cliente.post(ruta, ordenes).pipe(
            map((ordenes: CompraExportacion[]) => ordenes)
        );
    }
}

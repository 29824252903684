import { Injectable } from '@angular/core';
import { ParametrosDocumentoFactura } from './parametros';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Articulo } from 'src/app/entidades/articulo';
import { ProyectoExterno } from 'src/app/entidades/proyecto-externo';
import { Documento } from 'src/app/entidades/documento';
import { CategoriasDocumento } from 'src/app/enumeraciones/enumeraciones';
import { EstadoFacturacion } from 'src/app/entidades/estado-facturacion';

@Injectable({
  providedIn: 'root'
})
export class ParametrosDocumentoFacturaService {
  private _parametros = {} as ParametrosDocumentoFactura;
  public get parametros(): ParametrosDocumentoFactura {
    return this._parametros;
  }

  constructor(private ctx: Contexto) {}

  cargarParametros(
    documento: Documento,
    tipo: 'venta' | 'compra'
  ): Observable<any> {
    return of(true).pipe(
      flatMap(() =>
        tipo === 'venta'
          ? this.cargarVenta(documento.id)
          : this.cargarCompra(documento.id)
      ),
      flatMap(() => this.cargarArticulos()),
      flatMap(() => this.cargarProyectosExternos()),
      flatMap(() => this.cargarEstadosFacturacion())

    );
  }

  cargarVenta(idVenta?: number): Observable<Documento> {
    if (idVenta) {
      return this.ctx.documentosVenta
        .obtenerVentaDocumento(idVenta)
        .pipe(map(venta => (this._parametros.documento = venta)));
    } else {
      return of(undefined);
    }
  }

  cargarCompra(idCompra?: number): Observable<Documento> {
    if (idCompra) {
      return this.ctx.documentosCompra
        .obtener(idCompra)
        .pipe(map(compra => (this._parametros.documento = compra)));
    } else {
      return of(undefined);
    }
  }

  cargarProyectosExternos(): Observable<ProyectoExterno[]> {
    return this.ctx.proyectosExternos
      .obtenerTodos()
      .pipe(map(proyectos => (this._parametros.proyectosExternos = proyectos)));
  }

  cargarEstadosFacturacion(): Observable<EstadoFacturacion[]> {
    return this.ctx.estadosFacturacion
      .obtenerTodos()
      .pipe(map(estadosF => (this._parametros.estadosFacturacion = estadosF)));
  }


  // cargarArticulos(): Observable<Articulo[]> {
  //   return this.ctx.articulos
  //     .obtenerTodos()
  //     .pipe(map(articulos => (this._parametros.articulos = articulos)));
  // }

  cargarArticulos(): Observable<Articulo[]> {
    ///COMPRA
    if(this._parametros.documento.categoriaDocumentoId === CategoriasDocumento.CotizacionCostos){

      const id = this._parametros.documento.id;

      return this.ctx.articulos
      .obtenerTodosCT(id)
      .pipe(map(articulos => (this._parametros.articulos = articulos)));

    }
    ///VENTA
    else{

      const id = this._parametros.documento.id;

      return this.ctx.articulos
      .obtenerTodosCV(id)
      .pipe(map(articulos => (this._parametros.articulos = articulos)));

    }
 }

}

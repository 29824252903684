import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaOperadorComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Operador } from 'src/app/entidades/operador';
import { FormaDocumentoOperadorComponent } from '../forma-documento/forma.component';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { VisorComponent } from '../visor/visor.component';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { DocumentoOperador } from 'src/app/entidades/documento-operador';
import { CatalogoEmergenteService } from 'src/app/modulos/catalogo-emergente/catalogo-emergente.service';

@Component({
    selector: 'catalogo-operador',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
    animations: [animacionTabla],
})
export class CatalogoOperadorComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    fuenteDatos: MatTableDataSource<Operador> = new MatTableDataSource([]);
    columnasMostradas = [
        'ver_detalle',
        'clave',
        'nombre',
        'rfc',
        'telefono',
        'correo',
        'activo',
        'opciones',
    ];
    cargando = true;

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
            return 50;
        }
        return 100;
    }

    subsOperadores: Subscription;
    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    subsDocumentos: Subscription;
    subsSubirDocumentos: Subscription;
    rowExapandido: Operador | null;
    idProveedorSeleccionado: number;

    constructor(
        public ventana: MatDialog,
        public ventanaDocumento: MatDialog,
        public visorDocumentos: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private autenticacion: Autenticacion,
        private servicioEmergente: CatalogoEmergenteService
    ) {}

    ngOnInit() {
        this.cargarOperadores();
    }

    cargarOperadores(): void {
        this.cargando = true;
        this.idProveedorSeleccionado =
            <number>this.servicioEmergente.datos ||
            this.autenticacion.perfil.id;
        this.ctx.operadores
            .obtenerTodoPorPorProveedor(this.idProveedorSeleccionado)
            .subscribe((operadores) => {
                this.fuenteDatos = new MatTableDataSource(operadores);
                this.fuenteDatos.paginator = this.paginador;
                this.fuenteDatos.sort = this.ordenador;
                this.cargando = false;
            });
    }

    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    abrirForma(id?: number): void {
        const forma = this.ventana.open(FormaOperadorComponent, {
            data: { id, proveedorId: this.idProveedorSeleccionado },
            panelClass: 'form-container',
        });

        this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
            this.cargarOperadores();
        });
    }

    abrirVentaDocumentos(id: number): void {
        const ventanaDocumento = this.ventanaDocumento.open(
            FormaDocumentoOperadorComponent,
            { data: id, panelClass: 'form-container' }
        );

        this.subsSubirDocumentos = ventanaDocumento.componentInstance.cerrado.subscribe(
            (guardo: boolean) => {
                if (guardo) {
                    this.cargarOperadores();
                }
            }
        );
    }

    abrirVisorDocumentos(documento: DocumentoOperador): void {
        this.visorDocumentos.open(VisorComponent, {
            data: documento,
            panelClass: 'form-container',
        });
    }

    cambiarEstado(activo: boolean, id: number): void {
        if (activo) {
            this.activar(id);
        } else {
            this.desactivar(id);
        }
    }

    activar(id: number): void {
        this.subsActivar = this.ctx.operadores.activar(id).subscribe(
            () => {},
            (error) => {
                this.alerta.mostrarError('Error al activar');
            }
        );
    }

    desactivar(id: number): void {
        this.subsActivar = this.ctx.operadores.desactivar(id).subscribe(
            () => {},
            (error) => {
                this.alerta.mostrarError('Error al desactivar');
            }
        );
    }

    eliminar(id: number): void {
        this.subsActivar = this.ctx.operadores
            .eliminar(id.toString())
            .subscribe(
                () => {
                    this.alerta.mostrarExito('Registro eliminado');
                    this.cargarOperadores();
                },
                (error) => {
                    this.alerta.mostrarError('Error al eliminar');
                }
            );
    }

    descargarDocumentos(idOperador: number): void {
        this.subsDocumentos = this.ctx.operadores
            .obtenerDocumentos(idOperador)
            .subscribe((documentos) => {
                documentos.forEach(async (doc) => {
                    const archivo = await this.ctx.operadores
                        .descargarDocumento(doc.id)
                        .toPromise();
                    const nombreArchivo = `${doc.operadorNombre}_${doc.tipoDocumentoDescripcion}${doc.extension}`;

                    const a = document.createElement('a');
                    const blob = new Blob([archivo], { type: 'octet/stream' });
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombreArchivo;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 100);
                });
            });
    }

    ngOnDestroy(): void {
        if (this.fuenteDatos) {
            this.fuenteDatos.disconnect();
        }
        if (this.subsOperadores) {
            this.subsOperadores.unsubscribe();
        }
        if (this.subsActivar) {
            this.subsActivar.unsubscribe();
        }
        if (this.subsDesactivar) {
            this.subsDesactivar.unsubscribe();
        }
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
        if (this.subsSubirDocumentos) {
            this.subsSubirDocumentos.unsubscribe();
        }
    }
}

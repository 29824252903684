import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { DocumentoFactura } from 'src/app/entidades/documento-factura';
import { Documento } from 'src/app/entidades/documento';
import { ParametrosDocumentoFacturaService } from '../../forma/parametros.service';
import { DocumentoFacturaComponent } from '../../forma/documento-factura.component';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

@Component({
  selector: 'app-ventas-por-enviar',
  templateUrl: './ventas-por-enviar.component.html',
  styleUrls: ['./ventas-por-enviar.component.scss']
})
export class VentasPorEnviarComponent implements OnInit {
  @Input() set ventas(v: Documento[]) {
    this.fuenteDatosVentas = new MatTableDataSource(v);
    this.fuenteDatosVentas.paginator = this.paginador;
    this.fuenteDatosVentas.sort = this.ordenador;
    this.cargando = false;
  }
  banderaGeneral: boolean = false;
  renglon: number = 0;
  @Input() banderaVenta: boolean = true;

  @Output() facturaAbierta = new EventEmitter();

  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatosVentas: MatTableDataSource<Documento> = new MatTableDataSource([]);
  columnasMostradas = [
    'proyectoFolio',
    'folioRe',
    'folio',
    'folioSv',
    'fechaDocumento',
    'clienteNombreCorto',
    'subtotal',
    'monedaNombre',
    'opciones'
  ];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatosVentas || this.fuenteDatosVentas.data.length === 0) {
      return 50;
    }
    return 100;
  }

  constructor(
    private ventana: MatDialog,
    private overlay: ServicioOverlayCargando,
    private parametros: ParametrosDocumentoFacturaService,
    private alerta: ServicioAlerta
  ) { }

  ngOnInit() { }

  llenarDatos(documento: Documento): void {
    if (documento.autorizado) {
      this.overlay.abrirCargando();
      this.banderaGeneral = true;
      this.renglon = documento.id;
      this.parametros
        .cargarParametros(documento, 'venta')
        .pipe(first())
        .subscribe((venta) => {
          this.abrirFactura();
          this.overlay.cerrarCargando();
        });
    } else {
      this.alerta.mostrarError(
        'Este documento no tiene autorización para enviarse'
      );
    }

  }

  abrirFactura(): void {
    this.ventana
      .open(DocumentoFacturaComponent, {
        panelClass: 'form-container-large'
      })
      .afterClosed()
      .toPromise()
      .then(() => {
        this.facturaAbierta.emit();
        this.banderaGeneral = this.banderaVenta;
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatosVentas.filter = filterValue.trim().toLowerCase();
  }
}

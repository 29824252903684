import { Injectable } from '@angular/core';
import { Contexto } from '../api/contexto.service';
import { FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TipoCambioService {
  constructor(private ctx: Contexto) {}

  async existeTipoCambio(idMoneda: number, fecha: string): Promise<boolean> {
    let respuesta = false;
    await this.ctx.tiposCambio
      .obtenerTipoCambio(idMoneda, fecha)
      .toPromise()
      .then(tipoCambio => {
        if (tipoCambio) {
          respuesta = true;
        }
      });
    return respuesta;
  }

  async validarTipoCambio(
    formasProveedores: FormArray,
    fechaDocumento: Date
  ): Promise<boolean> {
    let respuesta = true;
    for (
      let indiceFormaProveedor = 0;
      indiceFormaProveedor < formasProveedores.controls.length;
      indiceFormaProveedor++
    ) {
      const formaProveedor =
        formasProveedores.controls[indiceFormaProveedor].value;
      const idMoneda = formaProveedor.monedaId as number;
      const fechaString = fechaDocumento.toISOString();
      const existeTipoCambio = await this.existeTipoCambio(
        idMoneda,
        fechaString
      );

      if (!existeTipoCambio) {
        respuesta = false;
        break;
      }
    }
    return respuesta;
  }
}

import { Contexto } from 'src/app/api/contexto.service';
import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import {MatPaginator,MatSort, MatTableDataSource} from '@angular/material';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reporte-costo-km',
  templateUrl: './reporte-costo-km.component.html',
  styleUrls: ['./reporte-costo-km.component.scss']
})
export class ReporteCostoKmComponent implements OnInit {

  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  @ViewChild('filtro') filtro: ElementRef;

  proveedores: any;
  proveedoresFiltrados: Observable<any[]>;
  clientes: any;
  clientesFiltrados: Observable<any[]>;
  origenes: any;
  origenesFiltrados: Observable<any[]>;
  destinos: any;
  destinosFiltrados: Observable<any[]>;
  datosOriginalesReporte: any[] = [];
  reporteOriginal;
  reporteFiltro;
  fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
  columnasMostradas = ['fechaCompromisoCV',
                        'fechaCompromisoCT',
                        'cliente',
                        'proveedor',
                        'venta',
                        'compra',
                        'importeVenta',
                        'monedaVenta',
                        'importeCompra',
                        'monedaCompra',
                        'ruta',
                        'equipo',
                        'km',
                        'ventaKm',
                        'costoKm',
                        'paisOrigen',
                        'estadoOrigen',
                        'ciudadOrigen',
                        'codigoPostalOrigen',
                        'paisDest',
                        'estadoDest',
                        'ciudadDest',
                        'codigoPostalDest',
                        'cruce',
                        'usuarioOperaciones'
                      ];

  forma: FormGroup;

  tiposReportes = [
    {
      value: 'todos',
      display: "Todos"
    },
    {
      value: 'cliente',
      display: "Cliente"
    },
    {
      value: 'proveedor',
      display: "Proveedor"
    }
  ]

  opcion: any = 'todos';

  clientefiltro = new FormControl();
  proveedorfiltro = new FormControl();
  origenFiltro = new FormControl();
  destinoFiltro = new FormControl();

  mostrarClientes = false;

  constructor(
    private formBuilder: FormBuilder,
    private contexto: Contexto
  ) { }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      tipoReporte: ['todos', Validators.required],
      cliente: [null],
      proveedor: [null],
      fechaInicio: [null],
      fechaFinal: [null],
      origen: [null],
      destino: [null]
    });
    this.cargarClientesProveedores();
    this.cargarOrigenesDestinos();
    this.cargarBaseReporte();
  }

  cargarClientesProveedores() {
    this.clientes = [];
    this.proveedores = [];
    this.contexto.socios
      .obtenerSociosActivos()
      .toPromise()
      .then((socios) => {
        this.clientes = this.removeDuplicates(socios.filter(mSocio => mSocio.esCliente === true), 'nombreCorto');
        this.clientesFiltrados = this.clientefiltro.valueChanges.pipe(
          startWith<any>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.nombreComercial
          ),
          map((t) => this.filtrarCliente(t))
        );
        this.proveedores =  this.removeDuplicates(socios.filter(mSocio => mSocio.esProveedor === true),'nombreCorto');
        this.proveedoresFiltrados = this.proveedorfiltro.valueChanges.pipe(
          startWith<any>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.nombreComercial
          ),
          map((t) => this.filtrarProveedor(t))
        );
      });
  }

  cargarOrigenesDestinos() {
    this.origenes = [];
    this.destinos = [];
    this.contexto.direccionesServicio.obtenerTodasDirecciones()
    .toPromise()
    .then(direcciones => {
      const direccionesActivas = direcciones.filter(mDireccion => mDireccion.activo === true);
      this.origenes = this.removeDuplicates(direccionesActivas.filter(mdireccion => mdireccion.esOrigen === true),'id');
      this.origenesFiltrados = this.origenFiltro.valueChanges.pipe(
        startWith<any>(''),
        map((t) => this.filtrarOrigen(String(t)))
      );

      this.destinos = this.removeDuplicates(direccionesActivas.filter(mdireccion => mdireccion.esDestino === true),'id');
      this.destinosFiltrados = this.destinoFiltro.valueChanges.pipe(
        startWith<any>(''),
        map((t) => this.filtrarDestino(String(t)))
      );
    })
    .catch(error => {
      console.log('error-->', error);
    });

  }


  cargarBaseReporte() {
    this.contexto.documentosVenta.reporteCostoKm()
    .then(reporte => {
        this.reporteOriginal = reporte;
        this.reporteFiltro = reporte;
        this.fuenteDatos = new MatTableDataSource(this.reporteOriginal);
        this.fuenteDatos.paginator=this.paginador;
        this.fuenteDatos.sort=this.ordenador;
    })
    .catch(error => {
        console.log('error-->', error);
    });
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
  }

  tipoReporteSeleccionado(valor: any) {

    this.forma.patchValue({
      cliente: null,
      proveedor: null
    });
    this.opcion = valor;

  }

   // Cliente
   clienteSeleccionado(cliente: any) {
    this.forma.patchValue({
      cliente: cliente.nombreCorto
    });
   }

   proveedorSeleccionado(proveedor: any) {
    this.forma.patchValue({
      proveedor: proveedor.nombreCorto
    });
   }

   origenSeleccionado(origen: any) {
    this.forma.patchValue({
      origen: origen.direccionCompleta
    });
   }

   destinoSeleccionado(destino: any) {
    this.forma.patchValue({
      destino: destino.direccionCompleta
    });
   }


  private filtrarCliente(modelo: string): any[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = [];
    filtro = this.clientes.filter(
      (t) => t.nombreCorto.toLowerCase().indexOf(valorFiltro) >= 0
    );
    filtro.sort((a, b) => (a.nombreCorto > b.nombreCorto) ? 1 : -1);
    return filtro;
  }

  private filtrarProveedor(modelo: string): any[] {
    const valorFiltro = modelo.toLowerCase();
    let filtro = [];
    filtro = this.proveedores.filter(
      (t) => t.nombreCorto.toLowerCase().indexOf(valorFiltro) >= 0
    );
    filtro.sort((a, b) => (a.nombreCorto > b.nombreCorto) ? 1 : -1);
    return filtro;
  }

  private filtrarOrigen(dato: string): any[] {
    if (!dato) {
        dato = '';
    }
    const valorFiltro = dato.toLowerCase();
    let direcciones = [];
    const direccionesAFiltrar = this.origenes;
    if (direccionesAFiltrar && direccionesAFiltrar.length > 0) {
        direcciones = direccionesAFiltrar.filter( mDireccion => String(mDireccion.direccionCompleta).toLowerCase().indexOf(valorFiltro) >= 0 );
    }
     if (direcciones && direcciones.length > 0) direcciones.sort((a, b) => (a.establecimiento > b.establecimiento) ? 1 : -1);
    return direcciones;
  }

  private filtrarDestino(dato: string): any[] {
    if (!dato) {
        dato = '';
    }
    const valorFiltro = dato.toLowerCase();
    let direcciones = [];
    const direccionesAFiltrar = this.destinos;
    if (direccionesAFiltrar && direccionesAFiltrar.length > 0) {
        direcciones = direccionesAFiltrar.filter( mDireccion => String(mDireccion.direccionCompleta).toLowerCase().indexOf(valorFiltro) >= 0 );
    }
    direcciones.sort((a, b) => (a.establecimiento > b.establecimiento) ? 1 : -1);
    return direcciones;
  }

  crearReporte() {
    let reporteFiltro = _.cloneDeep(this.reporteOriginal);

    if (this.forma.value.origen) {
      const origenEncontrado = this.origenes.find(mOrigen => String(mOrigen.direccionCompleta) === String(this.forma.value.origen));
      if (origenEncontrado) {
        reporteFiltro = _.cloneDeep(reporteFiltro).filter(mVenta => mVenta.origen === origenEncontrado.id);
      }
    }

    if (this.forma.value.destino) {
      const destinoEncontrado = this.destinos.find(mDestino => String(mDestino.direccionCompleta) === String(this.forma.value.destino));
      if (destinoEncontrado) {
        reporteFiltro = _.cloneDeep(reporteFiltro).filter(mVenta => mVenta.destino === destinoEncontrado.id);
      }
    }

    if (this.forma.value.fechaInicio && this.forma.value.fechaFinal) {
      if (this.opcion === 'proveedor') {
        reporteFiltro = _.cloneDeep(reporteFiltro).filter(mVenta => new Date(mVenta.fechaCompromisoCT).getTime() >= new Date(this.forma.value.fechaInicio).getTime() && new Date(mVenta.fechaCompromisoCT).getTime() <= new Date(this.forma.value.fechaFinal).getTime());
      } else {
        reporteFiltro = _.cloneDeep(reporteFiltro).filter(mVenta => new Date(mVenta.fechaCompromisoCV).getTime() >= new Date(this.forma.value.fechaInicio).getTime() && new Date(mVenta.fechaCompromisoCV).getTime() <= new Date(this.forma.value.fechaFinal).getTime());
      }
    }

    if (this.opcion === 'cliente') {
      if (this.forma.value.cliente) {
        const clienteEncontardo = this.clientes.find(mCliente => String(mCliente.nombreCorto) === String(this.forma.value.cliente));
        if (clienteEncontardo) {
          reporteFiltro = _.cloneDeep(reporteFiltro).filter(mVenta => mVenta.clienteId === clienteEncontardo.id);
        }
      }
    }
    if (this.opcion === 'proveedor') {
      if (this.forma.value.proveedor) {
        const proveedorEncontardo = this.proveedores.find(mProveedor => String(mProveedor.nombreCorto) === String(this.forma.value.proveedor));
        if (proveedorEncontardo) {
          reporteFiltro = _.cloneDeep(reporteFiltro).filter(mVenta => mVenta.proveedorId === proveedorEncontardo.id);
        }
      }
    }

    this.reporteFiltro = _.cloneDeep(reporteFiltro);
    this.fuenteDatos = new MatTableDataSource(reporteFiltro);
    this.fuenteDatos.paginator=this.paginador;
    this.fuenteDatos.sort=this.ordenador;
  }

  limpiarFiltroCliente() {
    this.forma.patchValue({
      cliente: null
    });
  }

  limpiarFiltroProveedor() {
    this.forma.patchValue({
      proveedor: null
    });
  }

  limpiarFormulario() {
    this.forma.patchValue({
      tipoReporte: 'todos',
      cliente: null,
      proveedor: null,
      fechaInicio: '',
      fechaFinal: '',
      origen: '',
      destino: ''
    });
    this.opcion = 'todos';
    this.fuenteDatos = new MatTableDataSource(this.reporteOriginal);
    this.fuenteDatos.paginator=this.paginador;
    this.fuenteDatos.sort=this.ordenador;
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  descargarExcel() {
    const fileName = `Reporte Costos por Km`;
    const ventas = this.construirVentas(this.reporteFiltro);
    const workSheet = XLSX.utils.json_to_sheet(ventas);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, workSheet, fileName);
    const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  construirVentas(ventas: any): any {
    const ventasTabla = [];
    for(const venta of ventas) {
      ventasTabla.push(
        {
          "Fecha" : this.opcion === 'proveedor' ? this.formatDate(new Date(venta.fechaCompromisoCT)) : this.formatDate(new Date(venta.fechaCompromisoCV)),
          "Cliente" : venta.cliente,
          "Proveedor" : venta.proveedor,
          "#Venta" : venta.venta,
          "#Compra" : venta.compra,
          "Importe Venta" : venta.importeVenta,
          "Moneda Venta" : venta.monedaVenta,
          "Importe Compra" : venta.importeCompra,
          "Moneda Compra" : venta.monedaCompra,
          "Ruta" : venta.ruta,
          "Equipo" : venta.equipo,
          "Km" : venta.km,
          "Venta Km" : venta.ventaKm,
          "Costo Km" : venta.costoKm,
          "País Origen" : venta.paisOrigen,
          "Estado Origen" : venta.estadoOrigen,
          "Ciudad Origen" : venta.ciudadOrigen,
          "CP Origen" : venta.codigoPostalOrigen,
          "País Destino" : venta.paisDest,
          "Estado Destino" : venta.estadoDest,
          "Ciudad Destino" : venta.ciudadDest,
          "CP Destino" : venta.codigoPostalDest,
          "Cruce" : venta.cruce,
          "Us. Operaciones" : venta.usuarioOperaciones
        }
      );
    }
    return ventasTabla;
  }

  public formatDate(date: any) {
    const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      return [day, month, year].join('-');
  }
}

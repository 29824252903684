import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaAlertasComponent } from '../forma/forma.component';
import { FormaListasComponent } from '../forma-lista/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Alerta } from 'src/app/entidades/alertas';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { DetalleAlerta } from 'src/app/entidades/detalle-alertas';

export interface DescendienteAlerta {
  id: number;
  alertaId: number;
  alertaDescripcion: string;
}

@Component({
  selector: 'catalogo-alertas',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
  animations: [animacionTabla]
})
export class CatalogoAlertasComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  fuenteDatos: MatTableDataSource<Alerta> = new MatTableDataSource([]);
  columnasMostradas = [
    'ver_detalle',
    'clave',
    'descripcion',
    'documento',
    'activo',
    'opciones'
  ];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
      return 50;
    }
    return 100;
  }

  subsAlertas: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  subsContactos: Subscription;

  rowExapandido: Alerta | null;

  constructor(
    public ventana: MatDialog,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {}

  ngOnInit() {
    this.cargarAlertas();
  }

  cargarAlertas(): void {
    this.cargando = true;
    this.ctx.alertas.ObtenerAlertasConDetalle().subscribe(alerta => {
      this.fuenteDatos = new MatTableDataSource(alerta);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  abrirForma(id?: number): void {
    const dato = id ? id : null;
    const forma = this.ventana.open(FormaAlertasComponent, {
      data: dato,
      panelClass: 'form-container'
    });

    this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
      this.cargarAlertas();
    });
  }

  abrirFormaLista(id: number = 0, alerta: number, descripcion: string): void {
    const datos: DescendienteAlerta = {
      id: id,
      alertaId: alerta,
      alertaDescripcion: descripcion
    };
    const ventana = this.ventana.open(FormaListasComponent, {
      data: datos,
      panelClass: 'form-container'
    });

    this.subsContactos = ventana.componentInstance.cerrado.subscribe(
      (guardo: boolean) => {
        if (guardo) {
          this.cargarAlertas();
        }
      }
    );
  }

  cambiarEstado(activo: boolean, id: number): void {
    if (activo) {
      this.activar(id);
    } else {
      this.desactivar(id);
    }
  }

  activar(id: number): void {
    this.subsActivar = this.ctx.alertas.activar(id).subscribe(
      () => {},
      error => {
        this.alerta.mostrarError('Error al activar');
      }
    );
  }

  desactivar(id: number): void {
    this.subsActivar = this.ctx.alertas.desactivar(id).subscribe(
      () => {},
      error => {
        this.alerta.mostrarError('Error al desactivar');
      }
    );
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx.alertas.eliminar(id.toString()).subscribe(
      () => {
        this.alerta.mostrarError('Registro eliminado');
        this.cargarAlertas();
      },
      error => {
        this.alerta.mostrarError('Error al eliminar');
      }
    );
  }

  eliminarContacto(contacto: DetalleAlerta): void {
    this.subsActivar = this.ctx.detalleAlerta
      .eliminar(contacto.id.toString())
      .subscribe(
        () => {
          this.alerta.mostrarExito('Contacto eliminado');
          const indice = this.rowExapandido.detallesAlerta.indexOf(contacto);
          this.rowExapandido.detallesAlerta.splice(indice, 1);
        },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }
  ngOnDestroy(): void {
    if (this.fuenteDatos) {
      this.fuenteDatos.disconnect();
    }
    if (this.subsAlertas) {
      this.subsAlertas.unsubscribe();
    }
    if (this.subsActivar) {
      this.subsActivar.unsubscribe();
    }
    if (this.subsDesactivar) {
      this.subsDesactivar.unsubscribe();
    }
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
    if (this.subsContactos) {
      this.subsContactos.unsubscribe();
    }
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatExpansionModule,
  MatCardModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatTooltipModule,
  MatSelectModule,
  MatDialogModule,
  MatInputModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DuplicadoProyectoComponent } from './duplicado-proyecto.component';

@NgModule({
  declarations: [DuplicadoProyectoComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatSelectModule
  ],
  entryComponents: [DuplicadoProyectoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplicadoProyectoModule {}

import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { TipoReporte } from '../entidades/tipo-reportes';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsignacionFiltroReporte } from '../entidades/asignacion-filtro-reporte';

export class RepositorioTipoReporte extends RepositorioCatalogo<TipoReporte> {

    constructor(http: HttpClient) {
        super(http, "TiposReporte");
    }

    activar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    obtenerTodosActivos(): Observable<TipoReporte[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<TipoReporte[]>(ruta);

    }


    asignar(asignacion: AsignacionFiltroReporte): Observable<AsignacionFiltroReporte> {
        const ruta = `${this.ruta}/Asignar`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionFiltroReporte) => asig)
        );
    }

    desasignar(asignacion: AsignacionFiltroReporte): Observable<void> {
        const ruta = `${this.ruta}/Desasignar/${asignacion.tipoReporteId}/${asignacion.filtroReporteId}`;
        return this.clienteHttp.delete(ruta).pipe(map(() => null));
    }

    obtenerTipoCargaCliente(IdCliente: number): Observable<TipoReporte[]> {
        const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
        return this.clienteHttp.get<TipoReporte[]>(ruta);
    }

}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaFiltroReporteComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { TipoReporte } from 'src/app/entidades/tipo-reportes';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { FiltroReporte } from 'src/app/entidades/filtro-reporte';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-filtro-reporte',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoFiltroReporteComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<FiltroReporte> = new MatTableDataSource([]);
  columnasMostradas = ['clave', 'descripcion', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }
  subsActivar: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarFiltrosReporte();
  }

  cargarFiltrosReporte(): void {
    this.cargando = true;
    this.ctx.filtroReporte
      .obtenerTodos()
      .subscribe(TiposUnidad => {
        this.fuenteDatos = new MatTableDataSource(TiposUnidad);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }

  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaFiltroReporteComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarFiltrosReporte();
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx
      .filtroReporte.eliminar(id.toString())
      .subscribe(() => {
        this.alerta.mostrarError('Registro eliminado');
        this.cargarFiltrosReporte();
      },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }


}
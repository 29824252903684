import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormControl,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Operador } from 'src/app/entidades/operador';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaDocumentoOperadorComponent } from '../forma-documento/forma.component';
import { Socio } from 'src/app/entidades/socio';
import { startWith, map } from 'rxjs/operators';
import { CatalogoEmergenteService } from 'src/app/modulos/catalogo-emergente/catalogo-emergente.service';

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaOperadorComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    operador: Operador;
    subsEmpresa: Subscription;
    idSeleccionado = 0;
    cargando = false;
    subsGuardar: Subscription;
    subsGuardarDocumentos: Subscription;
    socios: Socio[] = [];
    sociosFiltrados: Observable<Socio[]>;
    sociosSinFiltrar: Socio[];
    guardado: EventEmitter<void> = new EventEmitter<void>();
    filtroEmpresa = new FormControl();
    idSocio = 0;
    get f() {
        return this.forma.controls;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public parametros: { id: number; proveedorId: number },
        private ventana: MatDialogRef<FormaOperadorComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        public ventanaDocumento: MatDialog
    ) {
        if (this.parametros.id > 0) {
            this.idSeleccionado = this.parametros.id;
            this.ctx.operadores.obtener(this.idSeleccionado).subscribe((a) => {
                this.operador = a;
                Object.assign(this.forma.value, this.operador);
                this.forma.reset(this.forma.value);
                this.f['confirmacionContrasena'].setValue(
                    this.operador.contrasena
                );
            });
        } else {
            this.idSeleccionado = 0;
            this.operador = new Operador();
            this.cargarFolio();
        }
    }

    ngOnInit() {
        this.forma = this.formBuilder.group({
            socioId: [null, Validators.required],
            clave: ['', Validators.required],
            nombre: ['', Validators.required],
            rfc: [
                '',

                Validators.pattern(
                    '^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z]|[0-9]){2}([A]|[0-9]){1})?$'
                ),
                ,
            ],
            telefono: ['', Validators.nullValidator],
            correo: ['', Validators.email],
            celular: ['+52', [Validators.nullValidator]],
            contrasena: [
                '',
                [
                    Validators.pattern(
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/
                    ),
                    Validators.minLength(6),
                ],
            ],
            confirmacionContrasena: [
                '',
                [
                    Validators.pattern(
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/
                    ),
                    Validators.minLength(6),
                ],
            ],
        });
        this.cargarEmpresas();
    }

    cargarFolio(): void {
        this.ctx.operadores.generarFolio().subscribe((folio) => {
            this.f['clave'].setValue('OP-' + folio);
        });
    }

    cargarProveedores(): void {
        this.ctx.socios.obtenerProveedores().subscribe((proveedores) => {
            this.socios = proveedores;
        });
    }

    empresaSeleccionada(socioId: number) {
        this.idSocio = socioId;
        this.f['socioId'].setValue(socioId);
    }

    cargarEmpresas() {
        this.subsEmpresa = this.ctx.socios
            .obtenerProveedores()
            .subscribe((empresas) => {
                this.sociosSinFiltrar = empresas;

                this.sociosFiltrados = this.filtroEmpresa.valueChanges.pipe(
                    startWith<string | Socio>(''),
                    map((proveedor) =>
                        typeof proveedor === 'string'
                            ? proveedor
                            : proveedor == null
                            ? ''
                            : proveedor.nombreComercial
                    ),
                    map((empresa) => this.filtrar(empresa))
                );

                if (this.parametros.proveedorId) {
                    const proveedor = this.sociosSinFiltrar.find(
                        (p) => p.id === this.parametros.proveedorId
                    );
                    this.empresaSeleccionada(proveedor.id);
                    this.filtroEmpresa.setValue(proveedor);
                }
            });
    }

    campoMostrar(proveedor: Socio) {
        let mostrar = '';
        if (proveedor) {
            mostrar = proveedor.nombreCorto;
        }
        return mostrar;
    }

    private filtrar(nombre: string): Socio[] {
        const valorFiltro = nombre.toLowerCase();
        const empresas = this.sociosSinFiltrar.filter(
            (emps) =>
                emps.nombreComercial.toLowerCase().indexOf(valorFiltro) === 0
        );

        return empresas;
    }

    abrirVentaDocumentos(id: number): void {
        const forma = this.ventanaDocumento.open(
            FormaDocumentoOperadorComponent,
            { data: id, panelClass: 'form-container' }
        );

        this.subsGuardarDocumentos = forma.componentInstance.cerrado.subscribe(
            (guardado: boolean) => {
                this.limpiar();
                if (guardado) {
                    this.guardado.emit();
                }
            }
        );
    }

    limpiar(): void {
        this.idSocio = 0;
        this.forma.reset({});
        this.sociosSinFiltrar = null;
        this.sociosFiltrados = null;
        this.cargarEmpresas();
    }

    guardar(): void {
        const celular = this.f['celular'].value;
        const correo = this.f['correo'].value;
        // if (correo === '' || celular === '') {
        //     this.alerta.mostrarAdvertencia(
        //         'El correo y el número movíl son necesarios para tener comunicación con el operador.'
        //     );
        //     this.cargando = false;
        //     return;
        // }
        if (this.forma.valid) {
            this.cargando = true;
            Object.assign(this.operador, this.forma.value);
            this.operador.socioId = this.idSocio;

            if (
                this.operador.contrasena !==
                this.operador.confirmacionContrasena
            ) {
                this.alerta.mostrarAdvertencia(
                    '¡Error, la contraseña no coincide!'
                );
                this.cargando = false;
                return;
            }
            // let perfil = this.autenticacion.perfil;

            // if (!perfil) {
            //   this.alerta.mostrarAdvertencia('Se debe seleccionar un proveedor');
            //   this.cargando = false;
            //   return;
            // }
            // this.operador.socioId = this.autenticacion.perfil.id;

            let observable: Observable<Operador>;
            let mensaje = 'Operador guardado.';
            let guardar = true;
            if (this.idSeleccionado === 0) {
                observable = this.ctx.operadores.guardar(this.operador);
            } else {
                guardar = false;
                mensaje = 'Operador actualizado';
                observable = this.ctx.operadores.actualizar(
                    this.idSeleccionado.toString(),
                    this.operador
                );
            }

            this.subsGuardar = observable.subscribe(
                (o) => {
                    this.guardado.emit();
                    this.alerta.mostrarExito(mensaje);
                    this.cargando = false;

                    if (!guardar) {
                        this.ventana.close();
                    } else {
                        this.abrirVentaDocumentos(o.id);
                    }
                },
                (error) => {
                    if (error.status === 515) {
                        // error == "Clave Duplicada") {
                        this.alerta.mostrarError('¡Error Clave Duplicada!');
                        this.cargando = false;
                    } else if (error.error === 'YaExiste') {
                        console.log(error);
                        this.alerta.mostrarAdvertencia(
                            '¡Ya se encuentra un operador asignado con ese correo o RFC!'
                        );
                        this.cargando = false;
                        return;
                    } else {
                        this.alerta.mostrarError('¡Error al guardar!');
                        this.cargando = false;
                    }
                }
            );
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    verContrasena(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
    }

    verContrasenaConfirmacion(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
    }

    cerrar(): void {
        this.ventana.close();
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
        if (this.subsGuardarDocumentos) {
            this.subsGuardarDocumentos.unsubscribe();
        }
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-parametrizacion',
    templateUrl: './parametrizacion.component.html',
    styleUrls: ['./parametrizacion.component.scss'],
})
export class ParametrizacionComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

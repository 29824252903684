export class DetalleAlerta {
  id: number;
  nombre: string;
  telefono: string;
  correo: string;
  tipo: number;
  envioMensaje: boolean;
  envioCorreo: boolean;
  envioWhatsApp: boolean;
  alertaId: number;
  alertaDescripcion: string;
  usuarioId: number;
  telefonoMovil: string;
}

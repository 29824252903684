import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Subscription } from 'rxjs';
import { TipoOpcion } from 'src/app/entidades/tipoOpcion';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { FormaTipoOpcionComponent } from '../forma/forma.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoTipoOpcionComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<TipoOpcion> = new MatTableDataSource([]);
  columnasMostradas = ['id', 'descripcion', 'activo', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if(!this.fuenteDatos || this.fuenteDatos.data.length == 0){
      return 50;
    }
    return 100;
  }

  subsTipoOpcion: Subscription;
  subsGuardar: Subscription;

  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarTipoOpciones();
  }

  cargarTipoOpciones(): void{
    this.cargando = true;
    this.subsTipoOpcion = this.ctx.tipoOpcion
      .obtenerTodos()
      .subscribe(tipoOpciones => {
        console.log(tipoOpciones);
        this.fuenteDatos = new MatTableDataSource(tipoOpciones);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.fuenteDatos.sort.sort({ start: 'desc', disableClear: false, id: 'id' });
        this.cargando = false
      });
  }

  abrirForma(id?: number): void{
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaTipoOpcionComponent,
      { data: dato, panelClass: 'form-container' }
    );

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarTipoOpciones();
      })
  }

  ngOnDestroy(): void {
      if(this.fuenteDatos) { this.fuenteDatos.disconnect(); }
      if(this.subsGuardar) { this.subsGuardar.unsubscribe(); }
  }

}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Contexto } from 'src/app/api/contexto.service';
import { MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { Moneda } from 'src/app/entidades/moneda';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Factura } from 'src/app/entidades/factura';
import { first } from 'rxjs/operators';
import { Venta } from 'src/app/entidades/venta';
import { ConstructorFormaFactura } from './constructor-forma.service';
import {
    DetalleFactura,
    DetalleCostosAdicionales,
} from 'src/app/entidades/detalle-factura';
import { ValidadorFormas } from 'src/app/utilerias/validadorFormas';
import { ParametrosFacturaVentaService } from './parametros.service';
import { ParametrosFacturaVenta } from './parametros';
import { CostoAdicional } from 'src/app/entidades/costo-adicional';

@Component({
    selector: 'app-factura-venta',
    templateUrl: './facturas.component.html',
    styleUrls: ['./facturas.component.scss'],
    providers: [ConstructorFormaFactura],
})
export class FacturaVentaComponent implements OnInit {
    cargando = false;

    monedas: Observable<Moneda[]>;

    forma: FormGroup;
    get formasDetalle(): FormArray {
        return <FormArray>this.forma.get('detalles');
    }

    get parametros(): ParametrosFacturaVenta {
        return this.servicioParametros.parametros;
    }

    constructor(
        private servicioParametros: ParametrosFacturaVentaService,
        private ctx: Contexto,
        private dialogoRef: MatDialogRef<FacturaVentaComponent>,
        private cf: ConstructorFormaFactura,
        private alerta: ServicioAlerta,
        private validadorForma: ValidadorFormas
    ) {
        this.forma = cf.crearFormaFactura();
    }

    ngOnInit() {
        if (this.parametros.factura) {
            this.cf.asignarFacturaAForma(this.forma, this.parametros.factura);
        } else {
            const factura = this.convertirVentaAFactura(this.parametros.venta);
            this.cf.asignarFacturaAForma(this.forma, factura);
        }

        if (this.parametros.soloVista) {
            this.forma.get('proyectoExternoId').disable();
        }
    }

    convertirVentaAFactura(venta: Venta): Factura {
        const factura: Factura = {
            monedaAbreviatura: venta.monedaAbreviatura,
            procesado: -1,
            proyectoExternoId: null,
            documentoId: venta.id,
            folioDocumento: venta.folio,
            folioProyecto: venta.proyectoFolio,
            folioSv: '',
            folioRe: '',
            fechaRegistro: new Date(),
            id: 0,
            socioId: venta.proveedorId,
            socioNombreCorto: venta.clienteNombreCorto,
            total: venta.total,
            procesar: true,
            descripcion: '',
            errorMensaje: '',
            fechaEnvio: null,
            detalles: venta.servicios.map((s) => {
                const detalle: DetalleFactura = {
                    id: 0,
                    articuloDescripcion: '',
                    articuloId: null,
                    facturaId: null,
                    texto: '',
                    total: s.total,
                    retencionId: s.retencionId,
                    trasladoId: s.trasladoId,
                    cantidad: s.cantidad,
                    importeMoneda: s.subtotal,
                    tieneCostos: s.subservicios.length > 0 ? false : true,
                    costos: s.subservicios.map((x) => {
                        const costosAdiciones: DetalleCostosAdicionales = {
                            // id: 0,
                            cantidad: x.cantidad,
                            servicioId: x.servicioId,
                            articuloId: x.articuloId,
                            articuloDescripcion: x.articuloDescripcion,
                            precio: x.precio,
                            total: x.precio,
                        };
                        return costosAdiciones;
                    }),
                };
                return detalle;
            }),
        };
        return factura;
    }

    cerrar(guardoFactura: boolean): void {
        this.dialogoRef.close(guardoFactura);
    }

    guardar(): void {
        if (this.forma.valid) {
            this.cargando = true;
            const factura = {} as Factura;
            Object.assign(factura, this.forma.value);
            factura.fechaRegistro = new Date().toISOString();
            if (factura.id === 0) {
                this.guardarNuevaFactura(factura);
            } else {
                this.actualizarFactura(factura);
            }
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
            this.validadorForma.marcarComoTocado(this.forma);
        }
    }

    guardarNuevaFactura(factura: Factura): void {
        this.ctx.facturasVenta
            .guardar(factura)
            .pipe(first())
            .subscribe(
                () => {
                    this.alerta.mostrarExito('Datos guardados');
                    this.cargando = false;
                    this.cerrar(true);
                },
                (e) => {
                    console.log(e);
                    this.cargando = false;
                    this.alerta.mostrarError('Ocurrio un error');
                }
            );
    }

    actualizarFactura(factura: Factura): void {
        this.ctx.facturasVenta
            .actualizar(factura.id.toString(), factura)
            .pipe(first())
            .subscribe(
                () => {
                    this.alerta.mostrarExito('Datos actualizados');
                    this.cargando = false;
                    this.cerrar(true);
                },
                (e) => {
                    console.log(e);
                    this.cargando = false;
                    this.alerta.mostrarError('Ocurrio un error');
                }
            );
    }
}


import { HttpClient } from '@angular/common/http';
import { Comprobante } from 'src/app/entidades/cfdi/comprobante';
import { RepositorioBase } from '../repositorio-base';

export class RepositorioComprobante extends RepositorioBase {

  constructor(http: HttpClient) {
    super(http, 'Comprobante');
  }

  guardar(comprobante: Comprobante){
    return this.clienteHttp.post<Comprobante>(`${this.ruta}`, comprobante);
  }

}

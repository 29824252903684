import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RepoBaseReferi } from './repo-base-referi';


export abstract class RepositorioReferi<T> extends RepoBaseReferi<T> {

  constructor(http: HttpClient, controlador: string) {
    super(http, controlador);
  }
}

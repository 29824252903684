export class Ciudad {
    id: number;
    descripcion: string;
    estadoId: string;
    estadoDescripcion: string;
    paisId: string;
    paisDescripcion: string;
    ciudadId: string;
}

// adasdas
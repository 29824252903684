import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaSociosComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Socio } from 'src/app/entidades/socio';
import { FormaDireccionesSocioComponent } from '../forma-direcciones/forma.component';
import { FormaContactosSocioComponent } from '../forma-contactos/forma.component';
import { animacionTabla } from 'src/app/utilerias/animaciones';
import { DireccionSocio } from 'src/app/entidades/direccion-socio';
import { ContactoSocio } from 'src/app/entidades/contacto-socio';
import { first } from 'rxjs/operators';
import { ModelSeleccionClienteComponent } from '../modal-seleccion-cliente/model-seleccion-cliente.component';

export interface DescendienteSocio {
    idSocio: number;
    idDescendiente: number;
}

@Component({
    selector: 'catalogo-socio',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
    animations: [animacionTabla],
})
export class CatalogoSocioComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    fuenteDatos: MatTableDataSource<Socio> = new MatTableDataSource([]);
    columnasMostradas = [
        'ver_detalle',
        'codigo',
        'rfc',
        'nombreCorto',
        'representante',
        'esProveedor',
        'esCliente',
        'activo',
        'encuesta',
        'opciones',
    ];
    cargando = true;

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
            return 50;
        }
        return 100;
    }

    subsSocios: Subscription;
    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    subsDirecciones: Subscription;
    subsContactos: Subscription;
    rowExapandido: Socio | null;

    constructor(
        public ventana: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta,
        private ventanaDirecciones: MatDialog,
        private ventanaContactos: MatDialog
    ) { }

    ngOnInit() {
        this.cargarSocios();
    }

    cargarSocios(): void {
        this.cargando = true;
        this.ctx.socios.obtenerTodos().subscribe((socios) => {
            this.fuenteDatos = new MatTableDataSource(socios);
            this.fuenteDatos.paginator = this.paginador;
            this.fuenteDatos.sort = this.ordenador;
            this.cargando = false;
        });
    }

    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    abrirForma(id?: number): void {
        const dato = id ? id : null;
        const forma = this.ventana.open(FormaSociosComponent, {
            data: dato,
            panelClass: 'form-container',
        });

        this.subsGuardar = forma.componentInstance.cerrado.subscribe(() => {
            this.cargarSocios();
        });
    }

    cambiarEstado(activo: boolean, id: number): void {
        if (activo) {
            this.activar(id);
        } else {
            this.desactivar(id);
        }
    }

    abrirFormaDirecciones(idSocio: number, idDireccion: number = 0): void {
        const datos: DescendienteSocio = {
            idDescendiente: idDireccion,
            idSocio: idSocio,
        };
        const ventana = this.ventanaContactos.open(
            FormaDireccionesSocioComponent,
            {
                data: datos,
                panelClass: 'form-container',
            }
        );

        this.subsDirecciones = ventana.componentInstance.cerrado.subscribe(
            (guardo: boolean) => {
                if (guardo) {
                    this.cargarSocios();
                }
            }
        );
    }

    abrirFormaContactos(idSocio: number, idContacto: number = 0): void {
        const datos: DescendienteSocio = {
            idDescendiente: idContacto,
            idSocio: idSocio,
        };
        const ventana = this.ventanaContactos.open(
            FormaContactosSocioComponent,
            {
                data: datos,
                panelClass: 'form-container',
            }
        );

        this.subsContactos = ventana.componentInstance.cerrado.subscribe(
            (guardo: boolean) => {
                if (guardo) {
                    this.cargarSocios();
                }
            }
        );
    }

    activar(id: number): void {
        this.subsActivar = this.ctx.socios.activar(id).subscribe(
            () => { },
            (error) => {
                this.alerta.mostrarError('Error al activar');
            }
        );
    }

    desactivar(id: number): void {
        this.subsActivar = this.ctx.socios.desactivar(id).subscribe(
            () => { },
            (error) => {
                this.alerta.mostrarError('Error al desactivar');
            }
        );
    }

    eliminar(id: number): void {
        this.subsActivar = this.ctx.socios.eliminar(id.toString()).subscribe(
            () => {
                this.alerta.mostrarExito('Registro eliminado');
                this.cargarSocios();
            },
            (error) => {
                this.alerta.mostrarError('Error al eliminar');
            }
        );
    }

    eliminarDireccion(direccion: DireccionSocio): void {
        this.subsActivar = this.ctx.direccionesSocio
            .eliminar(direccion.id.toString())
            .subscribe(
                () => {
                    this.alerta.mostrarExito('Dirección eliminada');
                    const indice = this.rowExapandido.direccionesSocio.indexOf(
                        direccion
                    );
                    this.rowExapandido.direccionesSocio.splice(indice, 1);
                },
                (error) => {
                    this.alerta.mostrarError('Error al eliminar');
                }
            );
    }

    eliminarContacto(contacto: ContactoSocio): void {
        this.subsActivar = this.ctx.contactosSocio
            .eliminar(contacto.id.toString())
            .subscribe(
                () => {
                    this.alerta.mostrarExito('Contacto eliminado');
                    const indice = this.rowExapandido.contactos.indexOf(
                        contacto
                    );
                    this.rowExapandido.contactos.splice(indice, 1);
                },
                (error) => {
                    this.alerta.mostrarError('Error al eliminar');
                }
            );
    }

    seleccionarClienteDestino(clienteFuenteId: number) {
        const forma = this.ventana.open(ModelSeleccionClienteComponent, { data: clienteFuenteId, panelClass: 'form-container-small' });
        forma.afterClosed().pipe(first()).subscribe(clienteDestinoId => {
            if (clienteDestinoId) {
                this.migrarCliente(clienteFuenteId, clienteDestinoId);
            }
        });
    }

    migrarCliente(clienteFuenteId: number, clienteDestinoId: number) {
        this.cargando = true;
        this.ctx.socios.migrarCliente(clienteFuenteId, clienteDestinoId).pipe(first())
            .subscribe(() => {
                this.cargarSocios();
                this.alerta.mostrarExito('Migración completada.');
            }, err => {
                this.alerta.mostrarError('Ocurrio un error.');
            }, () => this.cargando = false);
    }

    aplicaMigracion(socio: Socio): boolean {
        return socio.esCliente && socio.codigoCliente.includes('CL')
    }

    ngOnDestroy(): void {
        if (this.fuenteDatos) {
            this.fuenteDatos.disconnect();
        }
        if (this.subsSocios) {
            this.subsSocios.unsubscribe();
        }
        if (this.subsActivar) {
            this.subsActivar.unsubscribe();
        }
        if (this.subsDesactivar) {
            this.subsDesactivar.unsubscribe();
        }
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
        if (this.subsDirecciones) {
            this.subsDirecciones.unsubscribe();
        }
        if (this.subsContactos) {
            this.subsContactos.unsubscribe();
        }
    }
}

import { Injectable } from '@angular/core';

export type transition = 'right' | 'left' | 'fade';

@Injectable({
  providedIn: 'root'
})
export class AnimationRouterService {
  private _selectedAnimation: transition = 'right';
  get selectedAnimation(): transition {
    return this._selectedAnimation;
  }
  set selectedAnimation(v: transition) {
    this._selectedAnimation = v;
  }

  private _activated = true;
  public get activated(): boolean {
    return this._activated;
  }
  public set activated(v: boolean) {
    this._activated = v;
  }

  constructor() {}

  prepareRoute(): string {
    return 'FilterPage';
    if (!this._activated) {
      return null;
    } else {
      return this._selectedAnimation;
    }
  }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Tablas } from 'src/app/entidades/tablas';
import { Proveedor } from 'src/app/entidades/proveedor';

@Component({
  selector: 'app-sincroinizacion',
  templateUrl: './sincronizacion.component.html',
  styleUrls: ['./sincronizacion.component.scss']
})
export class SincronizacionComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<Tablas> = new MatTableDataSource([]);
  columnasMostradas = ['clave', 'descripcion1', 'status'];
  cargando = false;
  subSincroinizacon: Subscription;
  subSincronizar: Subscription;
  tabla = 2
  sincronizar: boolean = true;
  encabezado1: string = 'Clave';
  encabezado2: string = 'Descripción';
  encabezado3: string = 'Descripción';
  encabezado4: string = 'ID Fiscal';
  proveedoresReferi: any[]= [];


  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    //this.cargarInformacion();
  }

  cargarInformacion(event) {
    let id = event.value;
    this.tabla = id;
    if (id > 0) {
      if (id >= 4 && id <= 6) {
        this.columnasMostradas = ['clave', 'descripcion1', 'descripcion2', 'status'];
        this.encabezado1 = id == 6 ? 'Fecha de Cambio' : 'Clave';
        this.encabezado2 = id == 6 ? 'Moneda' : 'RFC';
        this.encabezado3 = id == 6 ? 'Tipo de Cambio' : 'Razón Social';
      }
      else if (id == 2 || id == 1)  {
        this.columnasMostradas = ['clave', 'descripcion1', 'status'];
        this.encabezado1 = 'Clave';
        this.encabezado2 = 'Descripción';
      }
      else if (id == 7)  {
        this.columnasMostradas = ['clave', 'descripcion1','dSocioNegocio', 'status',];
        this.encabezado1 = 'Clave';
        this.encabezado2 = 'Descripción';
      }
      else{
        this.columnasMostradas = ['clave', 'descripcion1', 'descripcion2', 'vatidUnCmp', 'status'];
        this.encabezado1 = id == 3 ? 'RFC' : 'Clave';
        this.encabezado2 = id == 3 ? 'Razón Social' : 'Descripción';
        this.encabezado3 = id == 3 ? 'Nombre Corto' : 'Razón Social';
        this.encabezado4 = 'ID Fiscal';
      }
      this.cargando = true;
      this.subSincroinizacon = this.ctx.tablas.obtenerTabla(id).subscribe(tablaSinc => {
        // si la tabla es socios, buscamos proveedores
        if(Number(id) === 3) {
          this.establecerProveedoresReferi(tablaSinc);
        }
        this.fuenteDatos = new MatTableDataSource(tablaSinc);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
        if (this.fuenteDatos.data.length > 0) { this.sincronizar = false; } else { this.sincronizar = true; }
      });
    }
    else{
      this.fuenteDatos = new MatTableDataSource([]);
      this.sincronizar = true;
    }
  }

  refresacarInformacion(id: number){
    this.proveedoresReferi = [];
    this.tabla = id;
    if (id > 0) {
      if (id >= 4 && id <= 6) {
        this.columnasMostradas = ['clave', 'descripcion1', 'descripcion2', 'status'];
        this.encabezado1 = id == 6 ? 'Fecha de Cambio' : 'Clave';
        this.encabezado2 = id == 6 ? 'Moneda' : 'RFC';
        this.encabezado3 = id == 6 ? 'Tipo de Cambio' : 'Razón Social';
      }
      else if (id == 2 || id == 1)  {
        this.columnasMostradas = ['clave', 'descripcion1', 'status'];
        this.encabezado1 = 'Clave';
        this.encabezado2 = 'Descripción';
      }
      else if (id == 7)  {
        this.columnasMostradas = ['clave', 'descripcion1', 'dSocioNegocio','status'];
        this.encabezado1 = 'Clave';
        this.encabezado2 = 'Descripción';
      }
      else{
        this.columnasMostradas = ['clave', 'descripcion1', 'descripcion2', 'status'];
        this.encabezado1 = id == 3 ? 'RFC' : 'Clave';
        this.encabezado2 = id == 3 ? 'Razón Social' : 'Descripción';
        this.encabezado3 = id == 3 ? 'Nombre Corto' : 'Razón Social';
      }
      this.cargando = true;
      this.subSincroinizacon = this.ctx.tablas.obtenerTabla(id).subscribe(tablaSinc => {
        // si la tabla es socios, buscamos proveedores
        if(Number(id) === 3) {

          this.establecerProveedoresReferi(tablaSinc);
        }
        this.fuenteDatos = new MatTableDataSource(tablaSinc);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
        if (this.fuenteDatos.data.length > 0) { this.sincronizar = false; } else { this.sincronizar = true; }
      });
    }
    else{
      this.fuenteDatos = new MatTableDataSource([]);
      this.sincronizar = true;
    }
  }

  async establecerProveedoresReferi(data: any[]) {
    this.proveedoresReferi = [];
    for(const socio of data) {
      // para que sea proveedore debe tener valor en el campo descripcion6, que es el codigo de proveedor de xmonitor
      if(socio.descripcion6) {
        this.proveedoresReferi.push(socio.clave);
      }
    }
    return;
  }

  sincronizarTabla(): void{
    this.cargando = true;


    this.subSincronizar = this.ctx
    .tablas.sincronizarTabla(this.tabla)
    .subscribe(async () => {

      if(Number(this.tabla) === 3 && this.proveedoresReferi.length > 0) {
        // si la tabla es socios, y tenemos proveedores por migrar
        const proveedoresPorMigrar: Proveedor[] = await this.proveedoresPorMigrar(this.proveedoresReferi);
        // llamamos a la funcion proveedoresPorMigrar, para determinar si los proveedores ya estan en xmonitor,
        // ya que los datos de la tabla no traen el correo para registrar el usuario en la migracion de Referi
        if (proveedoresPorMigrar.length > 0) {
          // si hay al menos un proveedor por migrar llamamos a la api de migracion de referi
          this.ctx.exportacion.migracionProveedores(proveedoresPorMigrar).toPromise()
          .then(proveedores => {
            this.cargando=false;
            this.alerta.mostrarExito("Sincronización Finalizada con Exito");
            this.refresacarInformacion(this.tabla);
          })
        } else {
          this.cargando=false;
          this.alerta.mostrarExito("Sincronización Finalizada con Exito");
          this.refresacarInformacion(this.tabla);
        }

      } else {
        this.cargando=false;
        this.alerta.mostrarExito("Sincronización Finalizada con Exito");
        this.refresacarInformacion(this.tabla);
      }
     },
      error => {
        this.alerta.mostrarError('Error al sincronizar');
        this.refresacarInformacion(this.tabla);
      }
    );

  }

  proveedoresPorMigrar(proveedores: any): Promise<Proveedor[]>  {
    let proveedoresPorMigrar: Proveedor[] = [];
    return new Promise(async (resolve, reject) => {
      if(proveedores.length > 0) {
        const proveedoresXmonitor = await this.ctx.socios
        .obtenerProveedoresMigracion()
        .toPromise();
        for(const proveedor of proveedores){
          // obtenemos los proveedores de xmonitor, tras haber realizado la sincronización en xmonitor
          const proveedorXmonitor = proveedoresXmonitor.find(p => String(p.rfc) === String(proveedor));
          //si existe coincidencia en el rfc, lo ponemos en el arreglo para sincronizar
          if (proveedorXmonitor) {
            proveedoresPorMigrar.push(proveedorXmonitor);
          }
        }
        resolve(proveedoresPorMigrar);
      } else {
        resolve(proveedoresPorMigrar);
      }
    });
  }

  /*
     buscarPrediosCajaPaginacion(criterio: string, limit: Number): Promise<any>  {
    const url = environment.apiUrl + '/predio/caja/buscar/paginacion/' + criterio;
    return new Promise((resolve, reject) => {
      this.httpclient.post(url,{limit}).toPromise()
      .then( (success: any) => {
        resolve(success);
      })
      .catch((error) => {
        let message: string;
        message = error.message;
        reject(message);
      });
    });
  }
  */



  ngOnDestroy(): void {
    if (this.fuenteDatos) { this.fuenteDatos.disconnect(); }
    if (this.subSincroinizacon) { this.subSincroinizacon.unsubscribe(); }
    if (this.subSincronizar) { this.subSincronizar.unsubscribe(); }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatTableDataSource, MatDialogRef } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { Observable } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SolicitudEmbarque } from 'src/app/entidades/solicitud-embarque';
import { Mercancia } from 'src/app/entidades/mercancia';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import * as XLSX from 'xlsx';
import { map, startWith } from 'rxjs/operators';
import { RegistroManualMercanciaComponent } from '../registro-manual-mercancia/registro-manual-mercancia.component';

@Component({
    selector: 'app-solicitud-embarque',
    templateUrl: './solicitud-embarque.component.html',
    styleUrls: ['./solicitud-embarque.component.scss'],
})
export class SolicitudEmbarqueComponent implements OnInit {
    get f() { return this.forma.controls; }
    get ventanaNormal(): boolean { return this.parametros.tipo === 'normal'; }
    fechaHoy: Date = new Date();

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public parametros: { idDocumento: number; tipo: 'normal' | 'confirmacion'; },
        private ctx: Contexto,
        public dialogRef: MatDialogRef<SolicitudEmbarqueComponent>,
        public ventanaDocumento: MatDialog,
        private formBuilder: FormBuilder,
        public autenticacion: Autenticacion,
        public alerta: ServicioAlerta
    ) {
    }
    forma: FormGroup;
    boardingId: number;
    embarque: SolicitudEmbarque;

    displayedColumns: string[] = ['descripcionProducto', 'descripcionUnidad', 'cantidad', 'peso', 'valorUnitario',
        'moneda', 'largo', 'ancho', 'alto', 'descripcionEmbalaje', 'descripcionFraccionArancelaria', 'descripcionMaterialPeligroso',
        'pedimento', 'uuid'];
    footerColumns: string[] = ['descripcionProducto', 'descripcionUnidad', 'cantidad', 'peso', 'valorUnitario',
        'moneda', 'largo', 'ancho', 'alto', 'descripcionEmbalaje', 'descripcionFraccionArancelaria',
        'descripcionMaterialPeligroso', 'pedimento', 'uuid'];

    dataSource: MatTableDataSource<Mercancia>;

    origenes: Direccion[] = [];
    origenFiltrados: Observable<Direccion[]> = new Observable<Direccion[]>();
    filtroOrigen = new FormControl();

    destinos: Direccion[] = [];
    destinoFiltrados: Observable<Direccion[]> = new Observable<Direccion[]>();
    filtroDestino = new FormControl();

    ngOnInit() {
        this.forma = this.formBuilder.group({
            id: [0],
            folio: [{ disabled: true, value: 0 }, Validators.required],
            referencia: ['', Validators.required],
            fechaDocumento: [{ disabled: true, value: new Date() }, Validators.required],
            fechaRequerida: [null, Validators.required],
            folioCotizacion: [{ disabled: true, value: null }, Validators.required],
            origen: ['', Validators.required],
            destino: ['', Validators.required],
            usuarioId: [0],
            guardado: [false],
            wares: this.formBuilder.array([]),
            valor: [0],
            cantidad: [0],
            peso: [0],
            cliente: [this.autenticacion.perfil.razonSocial]
        });
        this.dataSource = new MatTableDataSource(this.waresArray);

        this.ctx.solicitudesEmbarque.obtenerFolio(this.autenticacion.perfil.id).subscribe(result => {
            this.f['folio'].setValue(result);
        }, error => console.error(error));

        this.ctx.solicitudesEmbarque.obtenerFolioCv(this.parametros.idDocumento).subscribe(result => {
            this.f.folioCotizacion.setValue(result);
            this.cargarOrigenes();
            this.cargarDestinos();
            this.cargarDirecciones(result);
        }, error => console.error(error));
    }

    cargarOrigenes() {
        this.ctx.solicitudesEmbarque.obtenerDireccionesOrigen(this.autenticacion.perfil.id).subscribe(result => {
            this.origenes = result;
            this.origenFiltrados = this.filtroOrigen.valueChanges
                .pipe(
                    startWith(''),
                    map(t => this.filtrarOrigen(t))
                );
        }, error => console.error(error));
    }

    private filtrarOrigen(nombre: string): Direccion[] {
        const valorFiltro = nombre.toLowerCase();
        return this.origenes.filter(t => t.direccionCompleta.toLowerCase().includes(valorFiltro));
    }

    origenSeleccionado(origen: Direccion) {
        this.f['origen'].setValue(origen.direccionCompleta);
        this.filtroOrigen.setValue(origen.direccionCompleta);
    }

    cargarDestinos() {
        this.ctx.solicitudesEmbarque.obtenerDireccionesDestino(this.autenticacion.perfil.id).subscribe(result => {
            this.destinos = result;
            this.destinoFiltrados = this.filtroDestino.valueChanges
                .pipe(
                    startWith(''),
                    map(t => this.filtrarDestino(t))
                );
        }, error => console.error(error));
    }

    private filtrarDestino(nombre: string): Direccion[] {
        const valorFiltro = nombre.toLowerCase();
        return this.destinos.filter(t => t.direccionCompleta.toLowerCase().includes(valorFiltro));
    }

    destinoSeleccionado(destino: Direccion) {
        this.f['destino'].setValue(destino.direccionCompleta);
        this.filtroDestino.setValue(destino.direccionCompleta);
    }

    cargarDirecciones(cvid: string) {
        this.ctx.solicitudesEmbarque.obtenerDireccionesPorFolio(cvid).subscribe(result => {
            this.f['origen'].setValue(result[0].origen);
            this.f['destino'].setValue(result[0].destino);
            this.filtroOrigen.setValue(result[0].origen);
            this.filtroDestino.setValue(result[0].destino);
        }, error => console.error(error));
    }

    guardar() {
        if (this.forma.valid) {
            this.f.folio.enable();
            this.f.folioCotizacion.enable();
            this.f.fechaDocumento.enable();
            const model = this.forma.value as SolicitudEmbarque;
            this.f.folio.disable();
            this.f.folioCotizacion.disable();
            this.f.fechaDocumento.disable();
            model.guardado = true;
            this.ctx.solicitudesEmbarque.guardarSolicitudEmbarque(model).toPromise().then((result: any) => {
                if (result.success) {
                    this.alerta.mostrarExito('Solicitud guardada con exito');
                    this.dialogRef.close(result.success);
                }
            }, error => console.error(error));
        }
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];
        if (file) {
            let workBook: XLSX.WorkBook;
            let jsonData = null;

            const reader = new FileReader();

            // tslint:disable-next-line: no-shadowed-variable
            reader.onload = async (event: ProgressEvent) => {
                const data = reader.result;
                workBook = XLSX.read(data, { type: 'binary' });
                jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                    const sheet = workBook.Sheets[name];
                    initial[name] = XLSX.utils.sheet_to_json(sheet);
                    return initial;
                }, {});
                await this.validateAndAssignMerchandise(jsonData);
            };
            reader.readAsBinaryString(file);
        }
    }

    async validateAndAssignMerchandise(json: any) {
        const data: [] = json[Object.keys(json)[0]];
        const list = await Promise.all(
            data.map(async (c, i) => {
                const index = i + 2;

                const descripcionProducto = await this.validateCatalogSAT(c['CLAVE PRODUCTO'], 'ClaveProdServCP');
                const descripcionUnidad = await this.validateCatalogSAT(c['CLAVE UNIDAD'], 'ClaveUnidad');
                const descripcionFraccionArancelaria = c['CLAVE FRACCION ARANCELARIA'] === undefined || c['CLAVE FRACCION ARANCELARIA'] === null
                    ? '' : await this.validateCatalogSAT(c['CLAVE FRACCION ARANCELARIA'], 'FraccionArancelaria');
                const descripcionMaterialPeligroso = c['CLAVE MATERIAL PELIGROSO'] === undefined || c['CLAVE MATERIAL PELIGROSO'] === null
                    ? '' : await this.validateCatalogSAT(c['CLAVE MATERIAL PELIGROSO'], 'MaterialPeligroso');
                const descripcionEmbalaje = await this.validateCatalogSAT(c['CLAVE EMBALAJE'], 'TipoEmbalaje');

                const monedas: string[] = ['MXN', 'USD', 'CAN'];

                if (descripcionProducto && descripcionUnidad && (descripcionFraccionArancelaria || descripcionFraccionArancelaria == '')
                    && (descripcionMaterialPeligroso || descripcionMaterialPeligroso == '') && descripcionEmbalaje) {
                    const cantidad = Number(c['CANTIDAD']);
                    const valorUnitario = Number(c['VALOR UNITARIO']);
                    const moneda = String(c['MONEDA']).toUpperCase();
                    const largo = Number(c['LARGO']);
                    const ancho = Number(c['ANCHO']);
                    const alto = Number(c['ALTO']);
                    const peso = Number(c['PESO EN KG']);

                    if (!cantidad) {
                        this.alerta.mostrarError(`El valor 'Cantidad' no es un numero de la fila ${index}`);
                        return;
                    } else if (!valorUnitario) {
                        this.alerta.mostrarError(`El valor 'Valor Unitario' no es un numero de la fila ${index}`);
                        return;
                    } else if (!monedas.includes(moneda)) {
                        this.alerta.mostrarError(`El valor 'Moneda' no es un correcta en la fila ${index}`);
                        return;
                    } else if (!largo) {
                        this.alerta.mostrarError(`El valor 'Largo' no es un numero de la fila ${index}`);
                        return;
                    } else if (!ancho) {
                        this.alerta.mostrarError(`El valor 'Ancho' no es un numero de la fila ${index}`);
                        return;
                    } else if (!alto) {
                        this.alerta.mostrarError(`El valor 'Alto' no es un numero de la fila ${index}`);
                        return;
                    } else if (!peso) {
                        this.alerta.mostrarError(`El valor 'Peso' no es un numero de la fila ${index}`);
                        return;
                    } else {
                        return {
                            id: 0,
                            claveProducto: c['CLAVE PRODUCTO'],
                            descripcionProducto: descripcionProducto,
                            claveUnidad: c['CLAVE UNIDAD'],
                            descripcionUnidad: descripcionUnidad,
                            claveFraccionArancelaria: c['CLAVE FRACCION ARANCELARIA'] ? c['CLAVE FRACCION ARANCELARIA'] : '',
                            descripcionFraccionArancelaria: descripcionFraccionArancelaria,
                            claveMaterialPeligroso: c['CLAVE MATERIAL PELIGROSO'] ? c['CLAVE MATERIAL PELIGROSO'] : '',
                            descripcionMaterialPeligroso: descripcionMaterialPeligroso,
                            claveEmbalaje: c['CLAVE EMBALAJE'],
                            descripcionEmbalaje: descripcionEmbalaje,
                            cantidad: cantidad,
                            valorUnitario: valorUnitario,
                            moneda: moneda,
                            largo: largo,
                            ancho: ancho,
                            alto: alto,
                            peso: peso,
                            pedimento: c['PEDIMENTO'],
                            uuid: c['UUID'],
                            shipmentId: this.f.id.value,
                            shipmentRequest: this.forma.value as SolicitudEmbarque
                        } as Mercancia;
                    }
                } else {
                    if (!descripcionProducto) {
                        this.alerta.mostrarError(`La clave del producto en la fila ${index} no es valida.`);
                    } else if (!descripcionUnidad) {
                        this.alerta.mostrarError(`La clave de unidad en la fila ${index} no es valida.`);
                    } else if (!descripcionFraccionArancelaria) {
                        this.alerta.mostrarError(`La clave de la fraccion arancelaria en la fila ${index} no es valida.`);
                    } else if (!descripcionMaterialPeligroso) {
                        this.alerta.mostrarError(`La clave del material peligroso en la fila ${index} no es valida.`);
                    } else if (!descripcionEmbalaje) {
                        this.alerta.mostrarError(`La clave del embalaje en la fila ${index} no es valida.`);
                    } else {
                        this.alerta.mostrarError(`Hay un error inesperado en la fila ${index}.`);
                    }
                    return;
                }
            }));
        const allSuccess = list.filter(element => element === undefined).length === 0;
        if (allSuccess) {
            const res = list as Mercancia[];
            res.forEach(c => {
                this.waresFormArray.push(this.buildWares(c));
            });
            this.dataSource = new MatTableDataSource(this.waresArray);
        }
    }

    async validateCatalogSAT(llave: string, catalog: string): Promise<string | null> {
        return await this.ctx.solicitudesEmbarque.validarCatalogosSat(llave, catalog).toPromise();
    }

    setDownload(data: any) {
        const willDownload = true;
        setTimeout(() => {
            const el = document.querySelector('#download');
            el.setAttribute('href', `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
            el.setAttribute('download', 'xlsxtojson.json');
        }, 1000);
    }

    openRegister(): void {
        const dialogRef = this.ventanaDocumento.open(RegistroManualMercanciaComponent, {
            data: { idDocumento: this.parametros.idDocumento, tipo: 'normal' },
            width: '1200px',
            panelClass: 'form-container'
        });

        dialogRef.afterClosed().subscribe((forms: FormGroup[]) => {
            forms.forEach(form => this.waresFormArray.push(form));
            this.dataSource = new MatTableDataSource(this.waresArray);
        });
    }

    buildWares(ware: Mercancia) {
        return this.formBuilder.group({
            id: [ware.id],
            claveProducto: [ware.claveProducto],
            descripcionProducto: [ware.descripcionProducto],
            claveUnidad: [ware.claveUnidad],
            descripcionUnidad: [ware.descripcionUnidad],
            claveFraccionArancelaria: [ware.descripcionFraccionArancelaria],
            descripcionFraccionArancelaria: [ware.descripcionFraccionArancelaria],
            claveMaterialPeligroso: [ware.claveMaterialPeligroso],
            descripcionMaterialPeligroso: [ware.descripcionMaterialPeligroso],
            claveEmbalaje: [ware.claveEmbalaje],
            descripcionEmbalaje: [ware.descripcionEmbalaje],
            cantidad: [ware.cantidad],
            valorUnitario: [ware.valorUnitario],
            moneda: [ware.moneda],
            largo: [ware.largo],
            ancho: [ware.ancho],
            alto: [ware.alto],
            peso: [ware.peso],
            pedimento: [ware.pedimento],
            uuid: [ware.uuid],
            shipmentId: [ware.shipmentId]
        });
    }

    get waresFormArray(): FormArray { return this.f.wares as FormArray; }
    get waresArray(): Mercancia[] { return this.waresFormArray.value as Mercancia[]; }
    get cliente(): string { return this.f.cliente.value; }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Socio } from 'src/app/entidades/socio';
import { Contexto } from 'src/app/api/contexto.service';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { animacionTexto } from 'src/app/utilerias/animaciones';
import { MatMenuTrigger } from '@angular/material';
import { TiposUsuario } from 'src/app/entidades/tipos-usuario';
import { PerfilService } from './perfil.service';

@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html',
    styleUrls: ['./perfil.component.scss'],
    animations: [animacionTexto],
})
export class PerfilComponent implements OnInit, OnDestroy {
    @ViewChild(MatMenuTrigger) disparadorMenu: MatMenuTrigger;
    filtroSocio = new FormControl();
    filtroTipoUsuario: 'c' | 'p' = 'p';
    subsSocios: Subscription;
    sociosFiltrados: Observable<Socio[]>;
    sociosSinFiltrar: Socio[];
    socioSeleccionado: Socio = null;
    estadoEtiquetaPerfil: 'hidden' | 'shown' = 'hidden';

    constructor(
        private contexto: Contexto,
        private autenticacion: Autenticacion,
        private servicioPerfil: PerfilService
    ) {}
    tipoUsuario: TiposUsuario;

    async ngOnInit() {
        this.tipoUsuario = this.autenticacion.credencial.idTipoUsuario;
        if (
            this.tipoUsuario === TiposUsuario.Empresa ||
            this.tipoUsuario === TiposUsuario.Operaciones
        ) {
            this.cargarSocios();
        }
        await this.autenticacion.verificalPerfil();
        const perfil = this.autenticacion.perfil;
        if (perfil) {
            this.socioSeleccionado = perfil;
            this.estadoEtiquetaPerfil = 'shown';
        }
    }

    cargarSocios() {
        this.subsSocios = this.servicioPerfil.socios$.subscribe((socios) => {
            this.sociosSinFiltrar = socios;
        });

        this.sociosFiltrados = this.filtroSocio.valueChanges.pipe(
            startWith<string | Socio>(''),
            map((socio) =>
                typeof socio === 'string'
                    ? socio
                    : socio == null
                    ? ''
                    : socio.nombreComercial
            ),
            map((socio) => this.filtrar(socio))
        );

        this.servicioPerfil.cargarSocios();
    }

    cambiaTipoSocio() {
        this.filtroSocio.reset();
    }

    abrirMenu() {
        if (
            this.tipoUsuario === TiposUsuario.Empresa ||
            this.tipoUsuario === TiposUsuario.Operaciones
        ) {
            this.disparadorMenu.openMenu();
        }
    }

    perfilSelecciondado(socio: Socio) {
        this.socioSeleccionado = socio;
        this.estadoEtiquetaPerfil = 'shown';
        this.autenticacion.asignarPerfil(socio);
        this.filtroSocio.reset();
        this.disparadorMenu.closeMenu();
    }

    campoMostrar(socio: Socio) {
        return socio ? socio.nombreComercial : '';
    }

    private filtrar(nombre: string): Socio[] {
        const valorFiltro = nombre.toLowerCase();
        const mostrarProveedores = this.filtroTipoUsuario === 'p';
        const mostrarClientes = this.filtroTipoUsuario === 'c';

        const socios = this.sociosSinFiltrar.filter(
            (socio) =>
                (socio.nombreComercial.toLowerCase().includes(valorFiltro) ||
                    (socio.codigoCliente || '')
                        .toLowerCase()
                        .includes(valorFiltro) ||
                    (socio.codigoProveedor || '')
                        .toLowerCase()
                        .includes(valorFiltro)) &&
                (socio.esProveedor === mostrarProveedores ||
                    socio.esCliente === mostrarClientes)
        );

        return socios;
    }

    ngOnDestroy(): void {
        if (this.subsSocios) {
            this.subsSocios.unsubscribe();
        }
    }
}

import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoadingService {
  mensaje: string;

  constructor(private spinner: NgxSpinnerService) {}

  public show(mensaje: string): void {
    this.mensaje = mensaje;
    this.spinner.show(undefined,{
      type: 'ball-clip-rotate-multiple',
      size: 'medium',
      bdColor: 'rgba(51,51,51,0.50)',
      color: 'white',
      fullScreen: true
    });
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 5000);
  }

  public hide(): void {
    this.spinner.hide();
  }
}

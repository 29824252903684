import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { EstadoFacturacion } from '../entidades/estado-facturacion';

export class RepositorioEstadoFacturacion extends RepositorioCatalogo<
  EstadoFacturacion
> {
  constructor(http: HttpClient) {
    super(http, 'EstadosFacturacion');
  }
}

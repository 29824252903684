export class DireccionSocio {
  id: number;
  calle: string;
  numeroExterior: string;
  numeroInterior: string;
  colonia: string;
  codigoPostal: string;
  ciudad: string;
  estado: string;
  pais: string;
  socioId: number;
  clave: string;
}

import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { TipoProyecto } from "../entidades/tipo-proyecto";

export class RepositorioTipoProyecto extends RepositorioCatalogo<TipoProyecto> {

    constructor(http: HttpClient) {
        super(http, "TiposProyecto");
    }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DocumentoDuplicar } from 'src/app/entidades/documento-duplicar';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Proyecto } from 'src/app/entidades/proyecto';
import { FormaProyectosComponent } from '../forma/forma.component';

@Component({
  selector: 'app-duplicado',
  templateUrl: './duplicado.component.html',
  styleUrls: ['./duplicado.component.scss']
})
export class DuplicadoComponent implements OnInit {
  controlProyecto = new FormControl();
  proyectos$: Observable<Proyecto[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public parametros: { idDocumento: number; tipo: 'compra' | 'venta' },
    private dialog: MatDialogRef<DuplicadoComponent>,
    private ctx: Contexto,
    private overlay: ServicioOverlayCargando,
    private alerta: ServicioAlerta,
    private modal: MatDialog
  ) {}

  ngOnInit() {
    this.proyectos$ = this.ctx.proyectos.obtenerTodos();
  }

  duplicar(): void {
    this.overlay.abrirCargando();
    const documentoDuplicar: DocumentoDuplicar = {
      documentoId: this.parametros.idDocumento,
      proyectoId: this.controlProyecto.value
    };

    let obs: Observable<any>;
    if (this.parametros.tipo === 'compra') {
      obs = this.ctx.compras.duplicar(documentoDuplicar);
    } else {
      obs = this.ctx.ventas.duplicar(documentoDuplicar);
    }
    obs.pipe(first()).subscribe(
      documento => {
        this.overlay.cerrarCargando();
        this.alerta.mostrarExito('Documento duplicado');
        this.dialog.close(true);
      },
      error => {
        this.alerta.mostrarError('Error al duplicar');
      }
    );
  }

  abrirFormaProyecto(e: any): void {
    e.stopPropagation();
    this.modal
      .open(FormaProyectosComponent, { data: 0, panelClass: 'form-container' })
      .afterClosed()
      .pipe(first())
      .subscribe(() => (this.proyectos$ = this.ctx.proyectos.obtenerTodos()));
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';

import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { Ubicacion } from 'src/app/entidades/ubicacion';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-catalogo-ubicaciones',
  templateUrl: './catalogo-ubicaciones.component.html',
  styleUrls: ['./catalogo-ubicaciones.component.scss']
})
@AutoUnsubscribe()
export class CatalogoUbicacionesComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  @Input() public set ubicaciones(valor: Ubicacion[]) {
    this.asignacionUbicaciones.next(valor);
  }

  public get ubicaciones(): Ubicacion[] {
    return this.asignacionUbicaciones.getValue();
  }

  asignacionUbicaciones = new BehaviorSubject<Ubicacion[]>([]);

  fuenteDatos: MatTableDataSource<Ubicacion> = new MatTableDataSource([]);
  columnasMostradas = [
    'fecha',
    // 'longitud',
    // 'latitud',
    'direccion'
  ];

  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 10;
    }
    return 10;
  }



  constructor(
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private ventanaModal: MatDialog,
    public overlay: ServicioOverlayCargando
  ) { }

  ngOnInit() {
    this.cargando = true;
    this.asignacionUbicaciones.pipe(first()).subscribe(ubicacion => {
      this.fuenteDatos = new MatTableDataSource(ubicacion);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.cargando = false;
    });
  }
}

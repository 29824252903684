import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
 
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Unidad } from 'src/app/entidades/Unidad';
import { AutoUnsubscribe } from 'src/app/utilerias/autounsubscribe';
import { Ciudad } from 'src/app/entidades/ciudad';
import { FormaCiudadComponent } from '../forma/forma.component';

@AutoUnsubscribe()
@Component({
  selector: 'catalogo-Ciudad',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoCiudadesComponent implements OnInit {
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;

  fuenteDatos: MatTableDataSource<Ciudad> = new MatTableDataSource([]);
    columnasMostradas = ['paisDescripcion', 'estadoDescripcion', 'descripcion', 'opciones'];
  cargando = true;

  public get diametro(): number {
    if (!this.fuenteDatos || this.fuenteDatos.data.length == 0) {
      return 50;
    }
    return 100;
  }

  subsArticulos: Subscription;
  subsActivar: Subscription;
  subsDesactivar: Subscription;
  subsGuardar: Subscription;
  constructor(public ventana: MatDialog, private ctx: Contexto, private alerta: ServicioAlerta) { }

  ngOnInit() {
    this.cargarCiudades();
  }

  cargarCiudades(): void {
    this.cargando = true;
    this.ctx.ciudad
      .obtenerTodos()
      .subscribe(Unidads => {
        this.fuenteDatos = new MatTableDataSource(Unidads);
        this.fuenteDatos.paginator = this.paginador;
        this.fuenteDatos.sort = this.ordenador;
        this.cargando = false;
      });
  }
  
  abrirForma(id?: number): void {
    let dato = id ? id : null;
    let forma = this.ventana.open(FormaCiudadComponent,
      { data: dato, panelClass: 'form-container' });

    this.subsGuardar = forma.componentInstance.guardado
      .subscribe(() => {
        this.cargarCiudades();
      });
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  eliminar(id: number): void {
    this.subsActivar = this.ctx
      .unidades.eliminar(id.toString())
      .subscribe(() => {
        this.alerta.mostrarError('Registro eliminado');
        this.cargarCiudades();
      },
        error => {
          this.alerta.mostrarError('Error al eliminar');
        }
      );
  }
}
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TipoCambio } from 'src/app/entidades/tipo-cambio';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Moneda } from 'src/app/entidades/moneda';
import { TipoEstatusCliente } from 'src/app/entidades/tipo-estatus-cliente';
import { EstatusParaCV } from 'src/app/entidades/estatusParaCV';
import { DescripcionSV } from 'src/app/entidades/descripcionSV';
import { AsignarFechaCvComponent } from '../../asignar-fecha-cv/asignar-fecha-cv.component';
import { FormaUbicacionComponent } from '../formaUbicacion/forma.component';
import { FormaUbicacionObligatorioComponent } from '../formaUbicacionObligatorio/forma.component';
import { EstatusClienteDocumentoVenta } from 'src/app/entidades/estatus-cliente-documento-venta';
import { EstatusAsignadosComponent } from './estatus-asignados/estatus-asignados.component';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaAsignarEstatusCVComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  estatus: Observable<TipoEstatusCliente[]>;
  AsignarEstatus: EstatusParaCV;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  estatusCliente: MatTableDataSource<TipoEstatusCliente> = new MatTableDataSource([]);
  habilitar = false;
  estatusDesc: DescripcionSV = new DescripcionSV();
  estatusFinal = "";
  estatusDeCV: EstatusClienteDocumentoVenta[];
  estatusRepetido = false;
  get f() {
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaAsignarEstatusCVComponent>,
    private ventana2: MatDialog,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta,
    private ventanaModal: MatDialog,
  ) {

    this.idSeleccionado = id;
    this.AsignarEstatus = new EstatusParaCV();


    // this.ctx.tiposEstatusClientes.obtener(id).subscribe(a => {
    //   this.tipoEstatus = a;
    //   Object.assign(this.forma.value, this.tipoEstatus);
    //   this.forma.reset(this.forma.value);
    //   this.habilitar = true;
    // });

  }

  ngOnInit() {

    this.ctx.tiposEstatusClientes.obtenerTodos().subscribe(tiposEstatusClientes => {
      this.estatusCliente.data = tiposEstatusClientes;
    });


    this.ctx.tiposEstatusClientes.obtenerUltimoEstatusCV(this.idSeleccionado).subscribe(estatusDescripcion => {

      this.estatusFinal = estatusDescripcion.descripcionEquipo;

    });

    //OBTENGO TODOS LOS ESTATUS DEL DOCUMENTO ASIGNADOS A LA CV
    this.ctx.tiposEstatusClientes.ObtenerEstatusPorCV(this.idSeleccionado).subscribe(listaEstatus => {
      this.estatusDeCV = listaEstatus;
    });





    // this.subsTiposEstatusClientes = this.ctx.tiposEstatusClientes
    //   .obtenerTodos()
    //   .subscribe(tiposEstatusClientes => {
    //     this.fuenteDatos = new MatTableDataSource(tiposEstatusClientes);
    //     this.fuenteDatos.paginator = this.paginador;
    //     this.fuenteDatos.sort = this.ordenador;
    //     this.fuenteDatos.sort.sort({ start: 'desc', disableClear: false, id: 'id' });
    //     this.cargando = false;
    //   });

    this.forma = this.formBuilder.group({
      id: [this.idSeleccionado, Validators.required],
      estatusId: ['', Validators.required],
      estatusIdSel: ['', Validators.nullValidator],
    });
  }

  limpiar(): void {
    this.forma.reset({ id: 0 });
  }

  guardar(): void {

    if (this.forma.valid) {
      Object.assign(this.AsignarEstatus, this.forma.value);

      
      this.estatusDeCV.forEach(est => {
        if (this.AsignarEstatus.estatusId == est.id) {
          this.estatusRepetido = true;
        }
      });

      if (this.estatusRepetido == true) {
        this.alerta.mostrarAdvertencia('Estatus asignado previamente');

      } else {
        this.cargando = true;

        if (this.AsignarEstatus.estatusId == 9 || this.AsignarEstatus.estatusId == 11) {
          let dato = this.AsignarEstatus.id
          this.ventanaModal.open(FormaUbicacionObligatorioComponent,
            { data: dato, panelClass: 'form-container' });
        }

        let observable: Observable<EstatusParaCV>;
        let mensaje = 'Estatus guardado.';
        let guardar = true;

        if (this.idSeleccionado === 0) {
          observable = this.ctx.tiposEstatusClientes.guardoEstatusCV(this.AsignarEstatus);
        } else {
          guardar = false;
          mensaje = 'Estatus actualizado';
          observable = this.ctx.tiposEstatusClientes.guardoEstatusCV(this.AsignarEstatus);
        }

        this.subsGuardar = observable.subscribe(
          () => {
            this.guardado.emit();
            this.alerta.mostrarExito(mensaje);
            this.cargando = false;
            this.limpiar();
            if (!guardar) {
              this.ventana.close();
            }
          },
          () => {
            this.alerta.mostrarError('¡Error al guardar!');
            this.cargando = false;
          }
        );
      }


    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }


  verEstatusAsignados(): void {
    const forma = this.ventana2.open(EstatusAsignadosComponent, {
        data: { estatus: this.estatusDeCV},
        panelClass: 'form-container',
        width: '35vw'
    });
}
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'underscore';
import * as moment from 'moment';
import { CierreDespachoOperativoSelectionAdComponent } from '../cierre-despacho-operativo-selection-ad/cierre-despacho-operativo-selection-ad.component';

@Component({
    selector: 'app-cierre-despacho-operativo-selection',
    templateUrl: './cierre-despacho-operativo-selection-ad.component.html',
    styleUrls: ['./cierre-despacho-operativo-selection-ad.component.scss']
})
export class CierreDespachoOperativoSelectionComponent implements OnInit {
    mes: string;
    mesNuum: number;
    diasDeTolerancia: number;
    actualYear: number;

    public barChartOptions: ChartOptions = {

        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [
                {
                    id: 'a',
                    type: 'linear',
                    position: 'left',
                    ticks: {
                        max: 100,
                        min: 0,
                        callback: (value, index, values) => {
                            return value;
                        }
                    }
                },
                {
                    id: 'b',
                    type: 'linear',
                    position: 'right',
                    ticks: {
                        max: 100,
                        min: 0,
                        callback: (value) => {
                            return `${value}%`;
                        }
                    }
                }
            ],
            xAxes: [{
                barPercentage: 1.0
            }]
        },
        legend: {
            position: 'bottom'
        },
        plugins: {
            datalabels: {
            //     formatter: (value, ctx) => {
            //         const label = ctx.chart.data.labels[ctx.dataIndex];
            //         return label;
            //     }
                anchor: 'end', 
                offset: 9,
                formatter: (value, context) => {
                    // Formatear los valores de las etiquetas como porcentaje
                    if (context.dataset.yAxisID === 'b') {
                        return `${value}%`;
                    }
                    return value;
                } 
            }
        },
        // tooltips: {
        //     callbacks: {
        //         label: function (tooltipItem, data) {
        //             return `${Number(tooltipItem.yLabel).toFixed(2)} %`;
        //         }
        //     }
        // },
        // events: ['click'],
    };

    public barChartLabels = [];
    public barChartData: ChartDataSets[] = [
        { data: [], label: 'Enviados a facturar fuera de tiempo', type: 'line', lineTension: 0, fill: false, yAxisID: 'b' },
        { data: [], label: 'Enviados a facturar en tiempo', type: 'line', lineTension: 0, fill: false, yAxisID: 'b' },
        { data: [], label: 'Servicios', type: 'bar' }
    ];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [pluginDataLabels];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], promedioAnterior: number, objetivo: number, columnName: string, colNum: number, diasTolerancia: number, year: number },
        private ventana: MatDialogRef<CierreDespachoOperativoSelectionComponent>,
        public ventana2: MatDialog) {
    }

    ngOnInit(): void {
        this.diasDeTolerancia = this.object.diasTolerancia;
        this.mes = this.object.columnName;
        this.actualYear = this.object.year;
        this.mesNuum = this.object.colNum;
        const grupoEmpleados = _.chain(this.object.rastreos)
            .groupBy(c => c.empleado)
            .map((x, y) => {
                return {
                    empleado: y == null || y == 'null' ? 'No asignado' : y,
                    data: x
                };
            });

        const servicios = grupoEmpleados.map(c => c.data.length);
        const serviciosATiempo = grupoEmpleados.map(c => {
            const aTiempo = c.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') <= this.diasDeTolerancia).length;
            const total = c.data.length;
            const percent = (aTiempo / total) * 100;
            return Number(percent.toFixed(2));
        });
        const serviciosFueraTiempo = grupoEmpleados.map(c => {
            const fueraDeTiempo = c.data.filter(d => moment(d.fechaEnvioSAP).diff(d.fechaEntrega, 'days') > this.diasDeTolerancia).length;
            const total = c.data.length;
            const percent = (fueraDeTiempo / total) * 100;
            return Number(percent.toFixed(2));
        });

        this.barChartData.find(c => c.label == 'Servicios').data = servicios.value();
        this.barChartData.find(c => c.label == 'Servicios').backgroundColor = '#FEC200';
        this.barChartData.find(c => c.label == 'Servicios').borderColor = '#FEC200';


        this.barChartData.find(c => c.label == 'Enviados a facturar en tiempo').data = serviciosATiempo.value();
        this.barChartData.find(c => c.label == 'Enviados a facturar en tiempo').backgroundColor = '#00B050';
        this.barChartData.find(c => c.label == 'Enviados a facturar en tiempo').borderColor = '#00B050';


        this.barChartData.find(c => c.label == 'Enviados a facturar fuera de tiempo').data = serviciosFueraTiempo.value();
        this.barChartData.find(c => c.label == 'Enviados a facturar fuera de tiempo').backgroundColor = '#c90202';
        this.barChartData.find(c => c.label == 'Enviados a facturar fuera de tiempo').borderColor = '#c90202';


        this.barChartLabels = grupoEmpleados.map(c => c.empleado).value();
    }

    abrirForma2(): void {
        const forma = this.ventana2.open(CierreDespachoOperativoSelectionAdComponent, {
            data: { rastreos: this.object.rastreos, mees: this.mes, nuumeroMes: this.mesNuum, diasTolerancia: this.diasDeTolerancia },
            panelClass: 'form-container',
            width: '35vw'
        });
    }
}
import { HttpClient } from "@angular/common/http";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ContactoSocio } from "../entidades/contacto-socio";

export class RepositorioContactoSocio extends RepositorioCatalogo<ContactoSocio> {

    constructor(http: HttpClient) {
        super(http, "ContactosSocio");
    }

    obtenerTodasPorSocio(idSocio: number): Observable<ContactoSocio[]> {
        let ruta = `${this.ruta}/PorSocio/${idSocio}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: ContactoSocio[]) => {
                return data;
            }));
    }

}

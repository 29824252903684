import { HttpClient } from "@angular/common/http";
import { Operador } from "../entidades/operador"
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Pais } from '../entidades/pais';
import { Ciudad } from '../entidades/ciudad';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioCiudad extends RepositorioCatalogo<Ciudad> {

    constructor(http: HttpClient) {
        super(http, "Ciudad");
    }

    obtenerEstadosCiudad(id: number): Observable<Ciudad[]> {
        let ruta = `${this.ruta}/ObtenerEstadosCiudad/${id}`;
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Ciudad[]) => {
                return data;
            }));
    }

}


// asdasd

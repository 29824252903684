import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export function FechaMinima(fecha: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const fechaValidar = moment(fecha);

    const fechaControl = moment(control.value, moment.defaultFormatUtc);
    const respuesta = fechaControl.isAfter(fechaValidar)
      ? null
      : {
          FechaMinima: true
        };

    return respuesta;
  };
}

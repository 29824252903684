import { Component, OnInit } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Pareto } from 'src/app/entidades/pareto';
import { Contexto } from 'src/app/api/contexto.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pareto',
  templateUrl: './pareto.component.html',
  styleUrls: ['./pareto.component.scss']
})
export class ParetoComponent implements OnInit {
  pareto = new BehaviorSubject<Pareto[]>([]);
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(x => {
      if (x.matches) {
        return [
          {
            title: '',
            cols: 2,
            rows: 2,
            position: 1
          },
          {
            title: '',
            cols: 2,
            rows: 2,
            position: 2
          }
        ];
      } else {
        return [
          { title: '', cols: 1, rows: 2, position: 1 },
          { title: '', cols: 1, rows: 2, position: 2 }
        ];
      }
    })
  );

  constructor(
    private ctx: Contexto,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.ctx.reportes
      .obtenerTablaPareto()
      .toPromise()
      .then(pareto => this.pareto.next(pareto));
  }
}

import { Component, OnInit } from '@angular/core';
import { Venta } from 'src/app/entidades/venta';
import { MatTableDataSource } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { Empleado } from 'src/app/entidades/empleado';
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { EstatusVentas } from 'src/app/entidades/estatus-ventas';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-grafica-venta',
  templateUrl: './grafica-venta.component.html',
  styleUrls: ['./grafica-venta.component.scss']
})
export class GraficaVentaComponent implements OnInit {
  estatusVentas: EstatusVentas;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'left'
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        }
      }
    }
  };

  public pieChartLabels = ['Canceladas', 'Abiertas', 'Cerradas'];
  public pieChartData: number[] = [0, 0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [
        'rgba(255,0,0,0.85)',
        'rgba(250,222,15,0.85)',
        'rgba(0,139,0,0.85)'
      ]
    }
  ];

  constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando) {
    this.cargarDatos();
  }

  ngOnInit() {}

  // events
  public chartClicked({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

  cargarDatos(): void {
    this.ctx.documentosVenta
      .obtenerEstatusVentas()
      .pipe(first())
      .subscribe((estatus) => {
        this.pieChartData = [
          estatus.ventasCanceladas,
          estatus.ventasAbiertas,
          estatus.ventasCerradas
        ];
      });
  }
}

export class PerfilProyecto {
    id: number;
    clave: string;
    descripcion: string;
}

export enum PerfilesProyecto {
    Logistica = 1,
    Motion_USA,
    Transportista,
}

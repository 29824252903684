import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Proveeduria } from '../entidades/proveeduria';
import { RepositorioCatalogo } from './repositorio-catalogo';

export class RepositorioProveeduria extends RepositorioCatalogo<Proveeduria> {
        constructor(http: HttpClient) {
            super(http, 'Proveeduria');
          }
        
          obtenerDetallado(): Observable<Proveeduria[]> {
            const ruta = this.ruta;
            return this.clienteHttp.get<Proveeduria[]>(ruta);
          }
}
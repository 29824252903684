import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { map } from 'rxjs/operators';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';
import { FacturacionPendiente } from 'src/app/entidades/facturacionPendiente';

@Component({
    selector: 'app-dashboard-principal',
    templateUrl: './principal.component.html',
    styleUrls: ['./principal.component.scss'],
    animations: []
})
export class DashboardPrincipalComponent implements OnInit {
    recargarGrafica: number = 0;
    selectedYear: number;
    years: number[] = [];

    fueraDeTiempo: RastreoClienteModel[];
    facturacionPendiente: FacturacionPendiente[];
    enviadosFacturar: { empleado: string; noFacturasEnviadas: number; }[];
    nofacturadosFueraTiempo: string;

    cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(x => {
            if (x.matches) {
                return [
                    { title: '$ Ventas', cols: 3, rows: 1, position: 6 },
                    { title: '$ Compras', cols: 3, rows: 1, position: 7 },
                    { title: 'Año de Consulta', col: 6, rows: 1 },
                    //{ title: 'Facturados fuera de tiempo', cols: 6, rows: 1, position: 3 },
                    { title: 'Cierre despacho operativo', cols: 2, rows: 2, position: 1 },
                    { title: 'LC enviadas a proveedor pendiente de facturar al cliente', cols: 2, rows: 2, position: 2 },
                    { title: 'Cotizaciones convertidas a negocio', cols: 2, rows: 2, position: 3 },
                    { title: 'Perfect Order', cols: 3, rows: 2, position: 4 },
                    { title: 'Rentabilidad del proyecto', cols: 3, rows: 2, position: 5 }
                ];
            }

            return [
                { title: '$ Ventas', cols: 3, rows: 2, position: 6 },
                { title: '$ Compras', cols: 3, rows: 2, position: 7 },
                { title: 'Año de Consulta', col: 6, rows: 1 },
                //{ title: 'Facturados fuera de tiempo', cols: 6, rows: 1, position: 3 },
                { title: 'Cierre despacho operativo', cols: 2, rows: 2, position: 1 },
                { title: 'LC enviadas a proveedor pendiente de facturar al cliente', cols: 2, rows: 2, position: 2 },
                { title: 'Cotizaciones convertidas a negocio', cols: 2, rows: 2, position: 3 },
                { title: 'Perfect Order', cols: 3, rows: 2, position: 4 },
                { title: 'Rentabilidad del proyecto', cols: 3, rows: 2, position: 5 }
            ];
        })
    );

    get getMonth() {
        return moment(new Date()).locale('es-mx').format('MMMM');
    }

    constructor(private breakpointObserver: BreakpointObserver, 
        public ventana: MatDialog) {
        this.selectedYears();
    }

    ngOnInit(): void { }

    onYearChange() {
        console.log(`Father SelectOnChange: ${this.selectedYear}`);
    }

    selectedYears() {
        const currentYear = new Date().getFullYear();
        for (let i = 0; i < 2; i++) {
            this.years.push(currentYear - i);
        }
        this.selectedYear = currentYear;
    }

    agregarFueraDeTiempoGrafica(rastreos: RastreoClienteModel[]) {
        this.fueraDeTiempo = rastreos;
    }

    agregarFacturacionPendienteGrafica(rastreos: FacturacionPendiente[]) {
        this.facturacionPendiente = rastreos;
    }

    agregarFaturacionTabla(rastreos: RastreoClienteModel[]) {
        this.enviadosFacturar = _.chain(rastreos)
            .filter(c => c.empleado != null)
            .groupBy(c => c.empleado)
            .map((c, d) => {
                return {
                    empleado: d,
                    noFacturasEnviadas: c.filter(f => f.fechaEnvioSAP != null).length
                };
            })
            .value();


        const months = _.chain(rastreos)
            .groupBy(c => moment(c.fechaCompromiso).format('MM'))
            .map((x, y) => {
                return {
                    monthNumber: y,
                    monthName: `${moment(y, 'MM').locale('es-mx').format('MMMM')}`,
                    data: x
                }
            })
            .sortBy(c => Number(c.monthNumber)).value();

        const monthsModel = _.chain(months).map((month, key) => {
            return {
                'periodo': month.monthName,
                'servicios': month.data.length,
                'aTiempo': month.data.filter(d => moment(d.fechaEntrega).diff(d.fechaEnvioSAP) > 3).length,
                'fueraTiempo': `${((month.data.filter(d => moment(d.fechaEntrega).diff(d.fechaEnvioSAP) > 3).length / month.data.length) * 100).toFixed(2)}`
            };
        }).value();

        const facturadosFueraTiempo = _.chain(rastreos)
            .map(c => {
                return moment(c.fechaEntrega).diff(c.fechaEnvioSAP, 'days') > 3;
            })
            .value();

        //this.nofacturadosFueraTiempo = monthsModel[0].fueraTiempo;

    }
}

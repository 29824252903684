import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatButton } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { TipoDocumento } from 'src/app/entidades/tipo-documento';
import { DocumentoOperador } from 'src/app/entidades/documento-operador';
import { FechaMinima } from 'src/app/utilerias/validador-fecha';

export interface DocumentoArchivo {
    documento: DocumentoOperador;
    archivo: File;
}

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaDocumentoOperadorComponent implements OnInit, OnDestroy {
    cerrado: EventEmitter<boolean> = new EventEmitter<boolean>();
    cargando = false;
    forma: FormGroup;
    idOperador = 0;
    subsGuardar: Subscription;
    subsTiposDocumento: Subscription;
    subsDocumentos: Subscription;
    tiposDocumento: TipoDocumento[];
    listaDocumentos: DocumentoArchivo[] = [];
    hoy: Date;

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: number,
        private ventana: MatDialogRef<FormaDocumentoOperadorComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta
    ) {
        this.idOperador = id;
        this.hoy = new Date();
    }

    cargarTiposDocumento() {
        this.subsTiposDocumento = this.ctx.tiposDocumento
            .obtenerTodos()
            .subscribe((tipos) => {
                tipos.forEach((tipo, indice) => {
                    this.formaDocumentos.insert(
                        indice,
                        this.crearFormaVigencia()
                    );
                });
                this.tiposDocumento = tipos;
                this.cargarDocumentos();
            });
    }

    ngOnInit() {
        this.cargarTiposDocumento();
        this.forma = this.formBuilder.group({
            documentos: this.formBuilder.array([this.crearFormaVigencia()]),
        });
    }

    crearFormaVigencia(): FormGroup {
        return this.formBuilder.group({
            vigencia: [Date, [Validators.required, FechaMinima(this.hoy)]],
        });
    }

    get formaDocumentos(): FormArray {
        return this.forma.get('documentos') as FormArray;
    }

    get formaVigencia(): FormGroup[] {
        return this.formaDocumentos.controls as FormGroup[];
    }

    cargarDocumentos() {
        this.subsDocumentos = this.ctx.operadores
            .obtenerDocumentos(this.idOperador)
            .subscribe((documentos) => {
                documentos.map((d) => {
                    const documento = this.tiposDocumento.find(
                        (t) => t.id === d.tipoDocumentoId
                    );
                    if (!documento) {
                        documento.vigencia = null;
                    } else {
                        documento.vigencia = d.vigencia;
                    }
                });
            });
    }

    agregarArchivo(
        archivo: any,
        vigencia: string,
        idTipoDocumento: number,
        botonNombre: MatButton
    ) {
        if (archivo.target.files && archivo.target.files[0]) {
            const documento: DocumentoOperador = {
                id: 0,
                operadorId: this.idOperador,
                operadorNombre: '',
                tipoDocumentoDescripcion: '',
                tipoDocumentoId: idTipoDocumento,
                vigencia: new Date(vigencia),
                extension: '',
            };

            const archivoNuevo = archivo.target.files[0];
            const docArchivo: DocumentoArchivo = {
                documento: documento,
                archivo: archivoNuevo,
            };

            const indice = this.listaDocumentos.findIndex(
                (d) => d.documento.tipoDocumentoId === idTipoDocumento
            );
            if (indice === -1) {
                this.listaDocumentos.push(docArchivo);
            } else {
                this.listaDocumentos[indice] = docArchivo;
            }

            (<HTMLButtonElement>(
                botonNombre._elementRef.nativeElement
            )).innerText = archivo.target.files[0].name;
        }
    }

    agregarVigencia(fecha: any, idTipoDocumento: number) {
        const indice = this.listaDocumentos.findIndex(
            (da) => da.documento.tipoDocumentoId === idTipoDocumento
        );
        if (indice > -1) {
            const docArchivo = this.listaDocumentos[indice];
            const vigencia = new Date(fecha.value);
            console.log(vigencia);
            docArchivo.documento.vigencia = new Date(vigencia);
        }
    }

    limpiarForma(
        idTipoDocumento: number,
        i: number,
        arch: HTMLInputElement,
        botonNombre: MatButton
    ) {
        this.formaVigencia[i].reset();
        arch.value = '';
        (<HTMLButtonElement>botonNombre._elementRef.nativeElement).innerText =
            'Seleccionar';
        const indice = this.listaDocumentos.findIndex(
            (da) => da.documento.tipoDocumentoId === idTipoDocumento
        );
        this.listaDocumentos.splice(indice, 1);
    }

    guardar(): void {
        if (this.listaDocumentos.length > 0) {
            this.cargando = true;

            const formaSubir: FormData = new FormData();

            const todosDocumentos: DocumentoOperador[] = [];
            for (let i = 0; i < this.listaDocumentos.length; i++) {
                const da = this.listaDocumentos[i];

                if (
                    !da.documento.vigencia ||
                    da.documento.vigencia <= this.hoy
                ) {
                    this.alerta.mostrarAdvertencia('La vigencia es inválida.');
                    this.cargando = false;
                    return;
                }

                const documento: DocumentoOperador = {
                    id: 0,
                    operadorId: this.idOperador,
                    operadorNombre: '',
                    tipoDocumentoDescripcion: '',
                    tipoDocumentoId: da.documento.tipoDocumentoId,
                    vigencia: da.documento.vigencia,
                    extension: '',
                };

                todosDocumentos.push(documento);
                formaSubir.append('archivos', da.archivo);
            }
            formaSubir.append(
                'documentosEnTexto',
                JSON.stringify(todosDocumentos)
            );

            let observable: Observable<void>;
            const mensaje = 'Documentos guardados.';
            observable = this.ctx.operadores.guardarDocumentos(formaSubir);

            this.subsGuardar = observable.subscribe(
                () => {
                    this.alerta.mostrarExito(mensaje);
                    this.cargando = false;
                    this.cerrar(true);
                },
                (error) => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                }
            );
        } else {
            this.alerta.mostrarAdvertencia(
                'No se ha seleccionado ningún archivo.'
            );
        }
    }

    cerrar(guardo: boolean): void {
        this.cerrado.emit(guardo);
        this.ventana.close();
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
        if (this.subsTiposDocumento) {
            this.subsTiposDocumento.unsubscribe();
        }
        if (this.subsDocumentos) {
            this.subsDocumentos.unsubscribe();
        }
    }
}

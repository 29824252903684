import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadComponent } from './components/loading.component';
import { LoadingService } from './services/loading.service';

const routes = [];

@NgModule({
  imports: [
    NgxSpinnerModule,
  ],
  declarations: [LoadComponent],
  providers: [LoadingService],
  exports: [LoadComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoadingModule { }

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatSort } from '@angular/material';
import { RastreoClienteModel } from 'src/app/entidades/rastreo-cliente';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
    selector: 'app-rentabilidad-data',
    templateUrl: './rentabilidad-selection-ad.component.html',
    styleUrls: ['./rentabilidad-selection-ad.component.scss']
})
export class RentabilidadSelectionAdicionalComponent implements OnInit {
    fuenteDatos: MatTableDataSource<any> = new MatTableDataSource([]);
    @ViewChild(MatSort) ordenador: MatSort;
    columnasMostradas = ['jefeOperacion', 'percentRentabilidad'];
    rastre: RastreoClienteModel[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public object: { rastreos: RastreoClienteModel[], colNum: number, columnName: string },
        private ventana: MatDialogRef<RentabilidadSelectionAdicionalComponent>) {
    }

    NumerColuma = this.object.colNum + 1;
    NombreColumna = this.object.columnName;

    ngOnInit(): void {
        const rastreos = _.chain(this.object.rastreos)
            .filter(c => moment(c.fechaCompromiso).locale('es-mx').format('MMMM') == this.NombreColumna)
            .groupBy(c => c.jefeOperacion)
            .map((x, y) => {
                return {
                    infos: y,
                    jefe: x[0].jefeOperacion,
                    data: x
                }
            })
            .sortBy(c => Number(c.jefe)).value();

        const monthsModel = _.chain(rastreos).map((month, key) => {
            const venta = month.data.reduce((s, f) => s + f.importeVenta, 0);
            const costo = month.data.reduce((s, f) => s + f.importeCosto, 0);
            const rentabilidad = venta - costo;
            return {
                'jefeOperacion': month.jefe,
                'percentRentabilidad': `${((rentabilidad / venta) * 100).toFixed(2)} %`,

            };
        }).value();

        this.fuenteDatos = new MatTableDataSource(monthsModel);
        this.fuenteDatos.sort = this.ordenador;

    }

}
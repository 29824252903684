import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Camion } from '../entidades/camion';
import { AsignacionCamion } from '../entidades/asignacion-camion';

export class RepositorioCamion extends RepositorioCatalogo<Camion> {
  constructor(http: HttpClient) {
    super(http, 'Camiones');
  }

  obtenerPorEquipo(idEquipo: number): Observable<Camion[]> {
    const ruta = `${this.ruta}/PorEquipo/${idEquipo}`;
    return this.clienteHttp.get(ruta).pipe(
      map((camiones: Camion[]) => camiones)
    );
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  obtenerTodosActivos(): Observable<Camion[]> {
    const ruta = `${this.ruta}/ObtenerTodosActivos`;
    return this.clienteHttp.get<Camion[]>(ruta);

  }


  asignar(asignacion: AsignacionCamion): Observable<AsignacionCamion> {
    const ruta = `${this.ruta}/Asignar`;
    return this.clienteHttp.post(ruta, asignacion).pipe(
      map((asig: AsignacionCamion) => asig)
    );
  }

  desasignar(asignacion: AsignacionCamion): Observable<void> {
    const ruta = `${this.ruta}/Desasignar/${asignacion.camionId}/${asignacion.clienteId}`;
    return this.clienteHttp.delete(ruta).pipe(map(() => null));
  }



  obtenerCamionCliente(IdCliente: number): Observable<Camion[]> {
    const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
    return this.clienteHttp.get<Camion[]>(ruta);
  }
}

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReporteFacturasProyecto } from '../entidades/reporte-proyectos-factura';
import { ReporteRentabilidadDetallado } from '../entidades/reporte-rentabilidad-detallado';
import { ReporteUtilidadProyecto } from '../entidades/reporte-utilidad-proyecto';

export class RepositorioReporteComisiones {
    ruta: string;

    constructor(private http: HttpClient) {
        this.ruta = '/api/ReporteComisiones';
    }

    obtenerDetallado(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        svId: number,
        proyectoId: number,
        servicio: string
    ): Observable<ReporteRentabilidadDetallado[]> {
        return this.http.get<ReporteRentabilidadDetallado[]>(
            `${this.ruta}/${clienteId}/${desde}/${hasta}/${svId}/${proyectoId}/${servicio}`
        );
    }

    obtenerExcelDetallado(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        svId: number,
        proyectoId: number,
        servicio: string
    ): Observable<Blob> {
        return this.http.get(
            `${this.ruta}/Excel/${clienteId}/${desde}/${hasta}/${svId}/${proyectoId}/${servicio}`,
            {
                responseType: 'blob',
            }
        );
    }

    obtenerAgrupado(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        svId: number,
        proyectoId: number,
        servicio: string
    ): Observable<ReporteRentabilidadDetallado[]> {
        return this.http.get<ReporteRentabilidadDetallado[]>(
            `${this.ruta}/Agrupado/${clienteId}/${desde}/${hasta}/${svId}/${proyectoId}/${servicio}`
        );
    }

    obtenerExcelAgrupado(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        svId: number,
        proyectoId: number,
        servicio: string
    ): Observable<Blob> {
        return this.http.get(
            `${this.ruta}/ExcelAgrupado/${clienteId}/${desde}/${hasta}/${svId}/${proyectoId}/${servicio}`,
            {
                responseType: 'blob',
            }
        );
    }

    obtenerReporteUtilidadProyecto(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        servicio: number,
        porcentajeComision: number
    ): Observable<ReporteUtilidadProyecto[]> {
        return this.http.get<ReporteUtilidadProyecto[]>(
            `${this.ruta}/ObtenerReporteUtilidad/${clienteId}/${desde}/${hasta}/${servicio}/${porcentajeComision}`
        );
    }

    obtenerReporteProyectoFacturas(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        servicio: number,
        agregarGastos: boolean,
        porcentajeComision: number,
        porcentajeInteres: number
    ): Observable<ReporteFacturasProyecto[]> {
        return this.http.get<ReporteFacturasProyecto[]>(
            `${this.ruta}/ObtenerReporteProyectoFacturas/${clienteId}/${desde}/${hasta}/${servicio}/${agregarGastos}/${porcentajeComision}/${porcentajeInteres}`
        );
    }

    obtenerExcelReporteUtilidadProyecto(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        servicio: number,
        porcentajeComision: number
    ): Observable<Blob> {
        return this.http.get(
            `${this.ruta}/ObtenerExcelReporteUtilidad/${clienteId}/${desde}/${hasta}/${servicio}/${porcentajeComision}`,
            {
                responseType: 'blob',
            }
        );
    }

    obtenerReporteProyectoFacturasExcel(
        clienteId: number,
        desde: Date | string,
        hasta: Date | string,
        servicio: number,
        agregarGastos: boolean,
        porcentajeComision: number,
        porcentajeInteres: number
    ): Observable<Blob> {
        return this.http.get(
            `${this.ruta}/ObtenerReporteProyectoFacturasExcel/${clienteId}/${desde}/${hasta}/${servicio}/${agregarGastos}/${porcentajeComision}/${porcentajeInteres}`,
            {
                responseType: 'blob',
            }
        );
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Credencial } from '../entidades/credencial';
import { TiposUsuario } from '../entidades/tipos-usuario';
import { Socio } from '../entidades/socio';
import { Contexto } from '../api/contexto.service';

@Injectable({
    providedIn: 'root',
})
export class Autenticacion {
    readonly propiedadCredencial = 'credencial';
    readonly propiedadPerfil = 'perfil';
    rutaRedireccion: string;
    constructor(private router: Router, private contexto: Contexto) {}

    public get ingreso(): boolean {
        const credencial = window.localStorage.getItem(
            this.propiedadCredencial
        );
        if (!credencial) {
            return false;
        }
        return true;
    }

    public get credencial(): Credencial {
        const credencial = window.localStorage.getItem(
            this.propiedadCredencial
        );
        if (credencial) {
            return <Credencial>JSON.parse(credencial);
        }
        return null;
    }

    public get perfil(): Socio {
        const perfil = window.localStorage.getItem(this.propiedadPerfil);
        let perfilSeleccionado: Socio;
        if (perfil) {
            perfilSeleccionado = <Socio>JSON.parse(perfil);
        }
        return perfilSeleccionado;
    }

    async verificalPerfil(): Promise<void> {
        const credencial = this.credencial;
        if (
            credencial == null &&
            !window.location.href.includes('ResultadosEncuestas')
        ) {
            this.salir();
        }

        if (
            credencial.idTipoUsuario !== TiposUsuario.Empresa &&
            credencial.idTipoUsuario !== TiposUsuario.Operaciones &&
            credencial.idSocio
        ) {
            const socio = await this.contexto.socios
                .obtener(credencial.idSocio)
                .toPromise();
            this.asignarPerfil(socio);
        }
    }

    asignarPerfil(socio: Socio): void {
        const perfilActual = this.perfil;
        window.localStorage.setItem(
            this.propiedadPerfil,
            JSON.stringify(socio)
        );

        const urlActual = this.router.url;
        this.router
            .navigateByUrl('_')
            .then(() => this.router.navigateByUrl(urlActual));
    }

    puedePasar(perfilRequerido: TiposUsuario): boolean {

        let puedePasar = false;
        const perfilSeleccionado = this.perfil;
        if (perfilSeleccionado) {
            if (perfilRequerido === TiposUsuario.Proveedor) {
                puedePasar = perfilSeleccionado.esProveedor;
            } else if (perfilRequerido === TiposUsuario.Cliente) {
                puedePasar = perfilSeleccionado.esCliente;
            }
        }
        if (
            perfilRequerido === TiposUsuario.Empresa ||
            perfilRequerido === TiposUsuario.Operaciones ||
            perfilRequerido === TiposUsuario.Reporteador
        ) {
            puedePasar =
                this.credencial.idTipoUsuario === TiposUsuario.Empresa ||
                this.credencial.idTipoUsuario === TiposUsuario.Operaciones ||
                perfilRequerido === TiposUsuario.Reporteador;
        }
        return puedePasar;
    }

    salir(): void {
        window.localStorage.removeItem(this.propiedadCredencial);
        window.localStorage.removeItem(this.propiedadPerfil);
        window.localStorage.clear();
        window.location.href = '/Login/Index';
    }
}

import { ReporteServicioTransporteCliente } from './../../entidades/reporte-servicio-transporte-cliente';
import { FiltroServicioTransporte } from './../../entidades/FiltroServicioTransporte';
import { Camion } from './../../entidades/camion';
import { Empleado } from './../../entidades/empleado';
import { Socio } from './../../entidades/socio';
import { DatosEquipo } from './../../entidades/datos-equipo';
import { Reporteador } from 'src/app/entidades/reporteador';
import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { LoadingService } from 'src/app/modulos/loading/services/loading.service';
import { FlujoService } from 'src/app/modulos/flujo-ventas/servicios/flujo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class ServicioTipoTransporteClienteComponent implements OnInit, OnDestroy {

  fuenteDatos: MatTableDataSource<ReporteServicioTransporteCliente> = new MatTableDataSource([]);

  columnasMostradas = ['folioProyecto', 'descripcionproyecto', 'descripcionDocumento', 'tiposervicio', 'cliente', 'proveedor',
    "folioCV", "importeCV","fechaventa", "tipoTransporte", "noeconomico", "placas", "plataforma", "placasplataforma", "origen", "destino",
    'empleado'];

  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) ordenador: MatSort;
  forma: FormGroup;
  get f() { return this.forma.controls; }
  // datos necesarios para los tres autocomplete
  camionFiltrados: Observable<Camion[]>;
  camionFiltrar: Camion[];
  camionFiltro = new FormControl();

  clienteFiltrados: Observable<Socio[]>;
  clienteFiltrar: Socio[];
  clientefiltro = new FormControl();

  empleadoFiltrados: Observable<Empleado[]>;
  empleadoFiltrar: Empleado[];
  empleadofiltro = new FormControl();

  constructor(private formBuilder: FormBuilder, private ctx: Contexto,
    public spinner: LoadingService, private webapi: Contexto, private servicioFlujo: FlujoService,private router: Router
  ) {


  }

  ngOnInit() {

    this.forma = this.formBuilder.group({
      camionDescripcion: ['Todos'],
      camionId: [0],
      clienteId: [0],
      clienteDescripcion: ['Todos'],
      empleadoId: [0],
      empleadoDescripcion: ['Todos'],
      fechaInicio: [],
      fechaFinal: []
    });

    this.cargarDatoEquipo();
    this.cargarEmpleado();
    this.CargarCliente();
  }

  filtrar(filterValue: string) {
    this.fuenteDatos.filter = filterValue.trim().toLowerCase();
  }

  crearInformacion() {
    const filtro = this.forma.value as FiltroServicioTransporte;
    console.log(filtro)
    this.spinner.show('Generando información...')
    this.ctx.ServicioTransporteCliente.obtenerDatosFiltrados(filtro).toPromise().then(e => {
      this.fuenteDatos = new MatTableDataSource(e);
      this.fuenteDatos.paginator = this.paginador;
      this.fuenteDatos.sort = this.ordenador;
      this.spinner.hide();
    })
  }
  // Cliente
  CargarCliente() {
    this.ctx.ServicioTransporteCliente.obtenerClientes()
      .toPromise()
      .then((resultado) => {
        this.clienteFiltrar = resultado;
        this.clienteFiltrados = this.clientefiltro.valueChanges.pipe(
          startWith<string | Socio>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.nombreComercial
          ),
          map((t) => this.FiltrarCliente(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  LimpiarFiltroCliente(): void {
    this.f['clienteDescripcion'].setValue('Todos');
    this.f["clienteId"].setValue(0);
    this.CargarCliente();
  }

  clienteSeleccionado(modelo: Socio) {
    if(modelo.nombreComercial==undefined){
      this.f['clienteDescripcion'].setValue('Todos');
      this.f["clienteId"].setValue(0);
    }else{
      this.f['clienteDescripcion'].setValue(modelo.nombreComercial);
      this.f["clienteId"].setValue(modelo.id);
    }

  }

  private FiltrarCliente(modelo: string): Socio[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.clienteFiltrar.filter(
      (t) => t.nombreComercial.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  // Empleado
  cargarEmpleado() {
    this.ctx.ServicioTransporteCliente.obtenerEmpleado()
      .toPromise()
      .then((resultado) => {
        this.empleadoFiltrar = resultado;
        this.empleadoFiltrados = this.empleadofiltro.valueChanges.pipe(
          startWith<string | Empleado>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.nombre
          ),
          map((t) => this.FiltrarEmpleado(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  LimpiarFiltroEmpleado(): void {
    this.f['empleadoDescripcion'].setValue('Todos');
    this.f["empleadoId"].setValue(0);
    this.cargarEmpleado();
  }

  empleadoSeleccionado(modelo: Empleado) {
    if(modelo.nombre==undefined){
      this.f['empleadoDescripcion'].setValue('Todos');
      this.f["empleadoId"].setValue(0);
    }else {
      this.f['empleadoDescripcion'].setValue(modelo.nombre);
      this.f["empleadoId"].setValue(modelo.id);
    }

  }

  private FiltrarEmpleado(modelo: string): Empleado[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.empleadoFiltrar.filter(
      (t) => t.nombre.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  // DatoEquipo
  cargarDatoEquipo() {
    this.ctx.ServicioTransporteCliente.obtenerDatosEquipo()
      .toPromise()
      .then((resultado) => {
        this.camionFiltrar = resultado;
        this.camionFiltrados = this.camionFiltro.valueChanges.pipe(
          startWith<string | DatosEquipo>(''),
          map((t) =>
            typeof t === 'string' ? t : t == null ? '' : t.descripcion
          ),
          map((t) => this.FiltrarCamion(t))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  LimpiarFiltroCamion(): void {
    this.f['camionDescripcion'].setValue('Todos');
    this.f["camionId"].setValue(0);
    this.cargarDatoEquipo();
  }

  camionSeleccionado(modelo: Camion) {
    if(modelo.descripcion==undefined){
      this.f['camionDescripcion'].setValue("Todos");
      this.f["camionId"].setValue(0);

    }else{

      this.f['camionDescripcion'].setValue(modelo.descripcion);
      this.f["camionId"].setValue(modelo.id);
    }

  }

  private FiltrarCamion(modelo: string): Camion[] {
    const valorFiltro = modelo.toLowerCase();
    const filtro = this.camionFiltrar.filter(
      (t) => t.descripcion.toLowerCase().indexOf(valorFiltro) === 0
    );
    return filtro;
  }

  limpiarFormulario() {
    this.f['clienteDescripcion'].setValue('Todos');
    this.f["clienteId"].setValue(0);
    this.f['camionDescripcion'].setValue('Todos');
    this.f["camionId"].setValue(0);
    this.f['empleadoDescripcion'].setValue('Todos');
    this.f["empleadoId"].setValue(0);
    this.f['fechaInicio'].setValue('');
    this.f["fechaFinal"].setValue('');
  }

  desacargarExcel() {
    const fechaActual = new Date();
    const nombreArchivo =
      'Reporte_Servicios_transporte_cliente';
    const model = this.forma.value as ReporteServicioTransporteCliente;
    this.spinner.show('Generando información...')
    this.ctx.reporteador
      .obtenerReporteServicioTransporteExcel(model)
      .toPromise()
      .then((respuesta) => {
        this.llamarExcel(respuesta, nombreArchivo);

        this.spinner.hide();
      })
      .catch((e) => { });
  }

  llamarExcel(respuesta, nombreArchivo) {
    const blob = new Blob([respuesta], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
      url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = nombreArchivo;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  irAlFlujo(reporte: ReporteServicioTransporteCliente) {
    console.log(reporte);
    this.spinner.show('Cargando el flujo de documentos...');
    forkJoin(
      this.webapi.documentosVenta.ObtenerSV(reporte.servicioVentaid),
      this.webapi.proyectos.obtener(reporte.idProyecto)
    )
      .toPromise()
      .then((objetos) =>
        this.servicioFlujo
          .cargarParametros(objetos[1], true, false, objetos[0].id)
          .toPromise()
          .then(() => {
            this.router
              .navigate(['Empresa/FlujoVentas'])
              .then(() => this.spinner.hide());
          }).catch(e=>{
            this.spinner.hide();
          })
      );
  }


  ngOnDestroy(): void { }
}

import { HttpClient } from '@angular/common/http';

export abstract class RepositorioBase {
    private readonly _ruta: string;
    public get ruta() {
        return this._ruta;
    }
    public get clienteHttp() {
        return this.httpClient;
    }

    constructor(private httpClient: HttpClient, controlador: string) {
        this._ruta = '/api/' + controlador;
    }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
    MatDialog,
    MatPaginator,
    MatSort,
    MatTableDataSource,
} from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { FormaDireccionServicioComponent } from '../forma/forma.component';
import { Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Direccion } from 'src/app/entidades/direcciones-entregas';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';

@Component({
    selector: 'catalogo-direcciones-destino',
    templateUrl: './catalogo.component.html',
    styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoDireccionServicioComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginador: MatPaginator;
    @ViewChild(MatSort) ordenador: MatSort;
    fuenteDatos: MatTableDataSource<Direccion> = new MatTableDataSource([]);
    columnasMostradas = [
        'paisDescripcion',
        'estadoDescripcion',
        'ciudadDescripcion',
        'calle',
        'codigoPostal',
        'esDestino',
        'esOrigen',
        'activo',
        'establecimiento',
        'opciones',
    ];
    cargando = true;

    public get diametro(): number {
        if (!this.fuenteDatos || this.fuenteDatos.data.length === 0) {
            return 50;
        }
        return 100;
    }

    subsUnidadesPeso: Subscription;
    subsActivar: Subscription;
    subsDesactivar: Subscription;
    subsGuardar: Subscription;
    constructor(
        public ventana: MatDialog,
        private ctx: Contexto,
        private alerta: ServicioAlerta
    ) {}

    ngOnInit() {
        this.cargarDirecciones();
    }

    cargarDirecciones(): void {
        this.cargando = true;
        this.ctx.direccionesServicio
            .obtenerTodasDirecciones()
            .subscribe((direccionServicio) => {
                this.fuenteDatos = new MatTableDataSource(direccionServicio);
                this.fuenteDatos.paginator = this.paginador;
                this.fuenteDatos.sort = this.ordenador;
                this.cargando = false;
            });
    }

    filtrar(filterValue: string) {
        this.fuenteDatos.filter = filterValue.trim().toLowerCase();
    }

    abrirForma(id?: number): void {
        const dato = id ? id : null;
        const forma = this.ventana.open(FormaDireccionServicioComponent, {
            data: dato,
            panelClass: 'form-container',
        });

        this.subsGuardar = forma.componentInstance.guardado.subscribe(() => {
            this.cargarDirecciones();
        });
    }

    cambiarEstado(activo: boolean, id: number): void {
        if (activo) {
            this.activar(id);
        } else {
            this.desactivar(id);
        }
    }

    activar(id: number): void {
        this.subsActivar = this.ctx.direccionesServicio.activar(id).subscribe(
            () => {},
            (error) => {
                this.alerta.mostrarError('Error al activar');
            }
        );
    }

    desactivar(id: number): void {
        this.subsActivar = this.ctx.direccionesServicio
            .desactivar(id)
            .subscribe(
                () => {},
                (error) => {
                    this.alerta.mostrarError('Error al desactivar');
                }
            );
    }

    eliminar(id: number): void {
        this.subsActivar = this.ctx.direccionesServicio
            .eliminar(id.toString())
            .subscribe(
                () => {
                    this.alerta.mostrarError('Registro eliminado');
                    this.cargarDirecciones();
                },
                (error) => {
                    this.alerta.mostrarError('Error al eliminar');
                }
            );
    }

    ngOnDestroy(): void {
        if (this.fuenteDatos) {
            this.fuenteDatos.disconnect();
        }
        if (this.subsUnidadesPeso) {
            this.subsUnidadesPeso.unsubscribe();
        }
        if (this.subsActivar) {
            this.subsActivar.unsubscribe();
        }
        if (this.subsDesactivar) {
            this.subsDesactivar.unsubscribe();
        }
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
    }
}

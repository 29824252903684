import { Injectable } from '@angular/core';
import { Socio } from 'src/app/entidades/socio';
import { BehaviorSubject } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  socios$ = new BehaviorSubject<Socio[]>([]);

  constructor(private contexto: Contexto) {}

  public cargarSocios(): void {
    this.contexto.socios
      .obtenerSociosActivos()
      .toPromise()
      .then((socios) => {
        this.socios$.next(socios);
      });
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ReporteCotizacion } from 'src/app/entidades/reportes';
import { AsignarEmbarqueComponent } from '../asignar-solicitud-embarque/asignar-solicitud-embarque.component';
import { SolicitudEmbarqueComponent } from '../nueva-solicitud-embarque/solicitud-embarque.component';

@Component({
    selector: 'app-embarque-selector',
    templateUrl: './embarque-selector.html',
    styleUrls: ['./embarque-selector.scss'],
})
export class EmbarqueSelectorComponent {
    title = 'Cotización Venta';
    imagenLogo = null;
    archivo: any;

    reporte: ReporteCotizacion[] = [];

    folio: string;

    get ventanaNormal(): boolean {
        return this.parametros.tipo === 'normal';
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public parametros: { idDocumento: number; tipo: 'normal' | 'confirmacion'; },
        public dialogRef: MatDialogRef<EmbarqueSelectorComponent>,
        public ventanaDocumento: MatDialog,
        private ctx: Contexto
    ) {
    }

    abrirAsignamiento() {
        const vent = this.ventanaDocumento.open(AsignarEmbarqueComponent, {
            data: { idDocumento: this.parametros.idDocumento, tipo: 'normal' },
            panelClass: 'form-container'
        });
        vent.afterClosed().subscribe(response => {
            this.actualizarBotonesAsigSolEmb(response);
        });
    }

    abrirNuevaSolicitud() {
        const vent = this.ventanaDocumento.open(SolicitudEmbarqueComponent, {
            data: { idDocumento: this.parametros.idDocumento, tipo: 'normal' },
            panelClass: 'form-container',
            width: '70%'
        });
        vent.afterClosed().subscribe(response => {
            this.actualizarBotonesAsigSolEmb(response);
        });
    }

    actualizarBotonesAsigSolEmb(condition: boolean): void {
        if (condition) {
            this.dialogRef.close(condition);           
        }
    }
}
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentoFactura } from '../entidades/documento-factura';
import { Factura } from '../entidades/factura';
import { ProyectoFactura } from '../entidades/proyecto-factura';

export class RepositorioDocumentoFactura {
  ruta: string;
  constructor(private http: HttpClient) {
    this.ruta = '/api/DocumentosFactura';
  }

  obtenerPendientes(): Observable<ProyectoFactura[]> {
    return this.http.get<ProyectoFactura[]>(this.ruta);
  }

  guardarCompra(documento: Factura): Observable<void> {
    const ruta = `${this.ruta}/Compra`;
    return this.http.post<void>(ruta, documento);
  }

  guardarVenta(documento: Factura): Observable<void> {
    const ruta = `${this.ruta}/Venta`;
    return this.http.post<void>(ruta, documento);
  }

  validoProyectoExternoCT(documento: Factura): Observable<void>{
    const ruta = `${this.ruta}/ValidoProyectoExternoCT`;
    return this.http.post<void>(ruta, documento);
  }

  validoProyectoExternoCV(documento: Factura): Observable<void>{
    const ruta = `${this.ruta}/ValidoProyectoExternoCV`;
    return this.http.post<void>(ruta, documento);
  }
}

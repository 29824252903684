import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DocumentoDuplicar } from 'src/app/entidades/documento-duplicar';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Proyecto } from 'src/app/entidades/proyecto';
import { FormaProyectosComponent } from 'src/app/paginas/proyectos/forma/forma.component';

@Component({
  selector: 'app-duplicado-proyecto',
  templateUrl: './duplicado-proyecto.component.html',
  styleUrls: ['./duplicado-proyecto.component.scss']
})
export class DuplicadoProyectoComponent implements OnInit {
  controlProyecto = new FormControl();
  proyectos$: Observable<Proyecto[]>;

  constructor(
    private dialog: MatDialogRef<DuplicadoProyectoComponent>,
    private ctx: Contexto,
    private overlay: ServicioOverlayCargando,
    private alerta: ServicioAlerta,
    private modal: MatDialog
  ) {}

  ngOnInit() {
    this.proyectos$ = this.ctx.proyectos.obtenerTodos();
  }

  seleccionarProyecto(): void {
    this.dialog.close(this.controlProyecto.value);
  }

  abrirFormaProyecto(e: any): void {
    e.stopPropagation();
    this.modal
      .open(FormaProyectosComponent, { data: 0, panelClass: 'form-container' })
      .afterClosed()
      .toPromise()
      .then(() => (this.proyectos$ = this.ctx.proyectos.obtenerTodos()));
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Contexto } from 'src/app/api/contexto.service';
import { first } from 'rxjs/operators';
import { MotivoCancelacion } from 'src/app/entidades/motivo-cancelacion';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormaMotivoCancelacionComponent } from '../../motivos-cancelacion/forma/forma.component';

@Component({
  selector: 'app-confirmacion-cancelacion',
  templateUrl: './confirmacion-cancelacion.component.html',
  styleUrls: ['./confirmacion-cancelacion.component.scss']
})
export class ConfirmacionCancelacionComponent implements OnInit {
  controlMotivo = new FormControl();
  motivos$: Observable<MotivoCancelacion[]>;

  constructor(
    private ctx: Contexto,
    private modal: MatDialog,
    private ventana: MatDialogRef<ConfirmacionCancelacionComponent>
  ) {}

  ngOnInit() {
    this.motivos$ = this.ctx.motivosCancelacion.obtenerTodos();
  }

  confirmar() {
    if (this.controlMotivo.valid) {
      this.ventana.close(this.controlMotivo.value);
    }
  }

  abrirFormaMotivoCancelacion(e: any): void {
    e.stopPropagation();
    this.modal
      .open(FormaMotivoCancelacionComponent, {
        data: 0,
        panelClass: 'form-container'
      })
      .afterClosed()
      .pipe(first())
      .subscribe(
        () => (this.motivos$ = this.ctx.motivosCancelacion.obtenerTodos())
      );
  }
}

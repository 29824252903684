import {
    Component,
    OnInit,
    Inject,
    OnDestroy,
    EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { TiposServicios } from 'src/app/entidades/tipos-servicios';

@Component({
    templateUrl: './forma.component.html',
    styleUrls: ['./forma.component.scss'],
})
export class FormaTiposServiciosComponent implements OnInit, OnDestroy {
    forma: FormGroup;
    tiposServicios: TiposServicios;
    idSeleccionado: number = 0;
    cargando: boolean = false;
    subsGuardar: Subscription;
    guardado: EventEmitter<void> = new EventEmitter<void>();
    get f() {
        return this.forma.controls;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public id: number,
        private ventana: MatDialogRef<FormaTiposServiciosComponent>,
        private formBuilder: FormBuilder,
        private ctx: Contexto,
        private alerta: ServicioAlerta
    ) {
        if (id > 0) {
            this.idSeleccionado = id;

            this.ctx.tiposServicios.obtener(id).subscribe((a) => {
                this.tiposServicios = a;
                Object.assign(this.forma.value, this.tiposServicios);
                this.forma.reset(this.forma.value);
            });
        } else {
            this.idSeleccionado = 0;
            this.tiposServicios = new TiposServicios();
        }
    }

    ngOnInit() {
        this.forma = this.formBuilder.group({
            clave: ['', Validators.required],
            descripcion: ['', Validators.required],
            imagen: ['', Validators.nullValidator],
            tipoImagen: ['', Validators.nullValidator],
            aplicaDatoEquipo: [false]
        });
    }

    limpiar(): void {
        this.forma.reset();
    }

    obtenerImg(event): void {
        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                // let extencion = event.target.files[i].name.split('.')[1];
                let obtenerBase64 = event.target.files[i].type;
                let nombre = event.target.files[i].name;
                if (
                    obtenerBase64 == 'image/jpeg' ||
                    obtenerBase64 == 'image/png'
                ) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        let docBs64 = event.target.result.split(',')[1];
                        let tipo = event.target.result.split(',')[0];
                        this.forma.get('imagen').setValue(docBs64);
                        this.forma.get('tipoImagen').setValue(tipo);
                    };
                    reader.readAsDataURL(event.target.files[i]);
                } else {
                }
            }
        }
    }

    guardar(): void {
        if (this.forma.valid) {
            this.cargando = true;
            Object.assign(this.tiposServicios, this.forma.value);

            let observable: Observable<TiposServicios>;
            let mensaje = 'Tipo de servicio guardada.';
            let guardar = true;
            if (this.idSeleccionado == 0) {
                observable = this.ctx.tiposServicios.guardar(
                    this.tiposServicios
                );
            } else {
                guardar = false;
                mensaje = 'tipoUnidad actualizada';
                observable = this.ctx.tiposServicios.actualizar(
                    this.idSeleccionado.toString(),
                    this.tiposServicios
                );
            }

            this.subsGuardar = observable.subscribe(
                () => {
                    this.guardado.emit();
                    this.alerta.mostrarExito(mensaje);
                    this.cargando = false;
                    this.limpiar();
                    if (!guardar) {
                        this.ventana.close();
                    }
                },
                () => {
                    this.alerta.mostrarError('¡Error al guardar!');
                    this.cargando = false;
                }
            );
        } else {
            this.alerta.mostrarAdvertencia('Los datos son inválidos.');
        }
    }

    cerrar(): void {
        this.ventana.close();
    }

    ngOnDestroy(): void {
        if (this.subsGuardar) {
            this.subsGuardar.unsubscribe();
        }
    }
}

import { HttpClient } from "@angular/common/http";
import { TipoCarga } from "../entidades/tipo-carga";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AsignacionTipoCarga } from '../entidades/asignacion-tipo-carga';

export class RepositorioTipoCarga extends RepositorioCatalogo<TipoCarga> {

    constructor(http: HttpClient) {
        super(http, "TiposCarga");
    }

    activar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void> {
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
            .pipe(map(() => null));
    }

    obtenerTodosActivos(): Observable<TipoCarga[]> {
        const ruta = `${this.ruta}/ObtenerTodosActivos`;
        return this.clienteHttp.get<TipoCarga[]>(ruta);

    }


    asignar(asignacion: AsignacionTipoCarga): Observable<AsignacionTipoCarga> {
        const ruta = `${this.ruta}/Asignar`;
        return this.clienteHttp.post(ruta, asignacion).pipe(
            map((asig: AsignacionTipoCarga) => asig)
        );
    }

    desasignar(asignacion: AsignacionTipoCarga): Observable<void> {
        const ruta = `${this.ruta}/Desasignar/${asignacion.tipoCargaId}/${asignacion.clienteId}`;
        return this.clienteHttp.delete(ruta).pipe(map(() => null));
    }

    obtenerTipoCargaCliente(IdCliente: number): Observable<TipoCarga[]> {
        const ruta = `${this.ruta}/Proveedor/${IdCliente}`;
        return this.clienteHttp.get<TipoCarga[]>(ruta);
    }
}



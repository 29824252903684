import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Contexto } from 'src/app/api/contexto.service';
import { CatalogoSAT } from 'src/app/entidades/catalogo-sat';

@Component({
    selector: 'app-buscador-catalogo',
    templateUrl: './buscador-catalogo.component.html',
    styleUrls: ['./buscador-catalogo.component.scss']
})
export class BuscadorCatalogoComponent implements OnInit {
    displayedColumns: string[] = ['key', 'description'];
    dataSource: MatTableDataSource<CatalogoSAT>;
    filterString = '';
    isLoading = true;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<BuscadorCatalogoComponent>,
        private ctx: Contexto
    ) {
        this.ctx.solicitudesEmbarque.obtenerCatalogosSat(data.catalogo).subscribe(result => {
            this.dataSource = new MatTableDataSource(result);
            this.dataSource.paginator = this.paginator;
            this.isLoading = false;
        }, error => console.error(error));
    }

    ngOnInit(): void {
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    selectedRow(row: CatalogoSAT) {
        this.dialogRef.close(row);
    }

    close() {
        this.dialogRef.close();
    }
}

import { Alerta } from "../entidades/alertas";
import { RepositorioCatalogo } from "./repositorio-catalogo";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class RepositorioAlerta extends RepositorioCatalogo<Alerta> {

    constructor(http: HttpClient) {
        super(http, "Alertas");
    }

    ObtenerAlertasConDetalle(): Observable<Alerta[]> {
        let ruta = this.ruta + "/ObtenerAlertasConDetalle";
        return this.clienteHttp.get(ruta)
            .pipe(map((data: Alerta[]) => {
                return data;
            }));
    }

    activar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Activar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }

    desactivar(id: number): Observable<void>{
        let ruta = `${this.ruta}/Desactivar/${id}`;
        return this.clienteHttp.put(ruta, null)
        .pipe(map(() => null));
    }
}
